import { IAssetClass } from '../../types';
import { postAssetClass } from '../../api';

interface Result {
    id: number;
    success: boolean;
}

export const createAssetClass = async (values: IAssetClass): Promise<Result> => {
      const { tenantId } = values;
      const createResult = { id: 0, success: true };
      try {
        const payload = { ...values, id: undefined } as unknown as IAssetClass;
        const resp = await postAssetClass(payload, tenantId as number);
        createResult.id = resp.data.id; 
      } catch (error) {
        createResult.success = false;
      }

      return createResult;
  }
