import { Box, Divider, Grid, Paper, TextField, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import { useCallback } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import FormAssignDropDown from '../../../../components/FormAssignDropDown/FormAssignDropDown'
import FormDropDownClearable from '../../../../components/FormDropDownClearable/FormDropDownClearable'
import { fetchUserTypesSelector } from '../../../TenantSettings/Components/AssetSettings/selectors'
import { fetchTransportsAssetsSelector, transportsEmployeeDropdownSelector } from '../../selectors'
import { ICreateTransportForm, ITransportAsset } from '../../types'
import AssetLoads from './AssetLoads'

export interface IAssetProps {
  formik: FormikProps<ICreateTransportForm>
  idx: number
  assetConfigIdx: number
  asset: ITransportAsset
}

const Asset = ({ formik, idx, assetConfigIdx, asset }: IAssetProps) => {
  const employees = useAppSelector(transportsEmployeeDropdownSelector)
  const availableAssets = useAppSelector(fetchTransportsAssetsSelector.data) ?? []
  const userTypes = useAppSelector(fetchUserTypesSelector.data) ?? []
  const name = `assetConfigurations.${assetConfigIdx}.allocations.${idx}`
  const seals = asset.seals ?? []
  const userAllocations = asset.assetType?.userTypeAllocations ?? []
  const getUsedAssetIds = useCallback(() => {
    let usedAssetsIds: number[] = []
    formik.values.assetConfigurations.forEach((ac) =>
      ac.allocations.forEach((aca) => {
        usedAssetsIds = [...usedAssetsIds, aca.id ?? -1]
      })
    )
    return usedAssetsIds
  }, [formik.values.assetConfigurations])

  const loadsToAdd = formik.values.route.loads
    .filter((l) => !asset.loadIds || !asset.loadIds.some((id) => id === l.loadId))
    .map((l) => {
      return {
        id: l.loadId,
        name: l.description ?? '',
      }
    })
  const handleSealKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const currentValue = seals
      if (e.target.value && e.target.value !== '') {
        formik.setFieldValue(e.target.id, [...currentValue, e.target.value])
        e.target.value = ''
      }
    }
  }
  // const assetsData = mockAssets.filter((a) => a.assetTypeId === asset.assetTypeId)

  // With filter
  const assetsData = availableAssets.filter(
    (a) => a.assetTypeId === asset.assetTypeId && !getUsedAssetIds().some((uaid) => uaid === a.id)
  )

  const handleDropdownAssignmentChange = (e: any, id: string) => {
    const currentValue = formik.getFieldProps(id).value as unknown as []
    formik.setFieldValue(id, [...currentValue, e.target.value])
  }

  const handleDropdownAssignmentUserChange = (e: any, id: string, userTypeId?: number) => {
    const currentValue = formik.getFieldProps(id).value as unknown as []
    const newValue = { id: e.target.value, userTypeId: userTypeId ?? 0, isDriver: userTypeId ? false : true }
    formik.setFieldValue(id, [...currentValue, newValue])
  }

  const handleSelectAsset = (e: any, newValue: any) => {
    const id = name
    if (!newValue) {
      formik.setFieldValue(`${id}.id`, undefined)
    } else {
      const currentValue = formik.getFieldProps(id).value as ITransportAsset

      const assetTemlate = assetsData.find((ad) => ad.id === newValue.id)

      formik.setFieldValue(id, { ...currentValue, ...assetTemlate })
    }
  }

  const renderSelectedValues = (value: string) => {
    return <Box sx={{ pt: '8px' }}>{value}</Box>
  }
  const getTitle = () => {
    return `${asset.assetType ? asset.assetType.name : ''}${asset.detail?.model ? ` - ${asset.detail.model}` : ''
      }${asset.identification ? ` - ${asset.identification}` : ''}`
  }
  const assetDropdownData = assetsData.map((ad) => {
    return { id: ad.id, name: ad.identification }
  })


  return (
    <>
      <Paper
        sx={{
          m: '16px',
          width: '30%',
        }}
      >
        <Grid
          container
          sx={{
            p: '16px',
          }}
          gap={3}
        >
          <Grid
            item
            xs={12}
            sx={{
              margin: '-10px 0 19px 0',
            }}
          >
            <Typography>{getTitle()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormDropDownClearable
              id={name}
              data={assetDropdownData}
              handleChange={(e: any, value: any) => {
                handleSelectAsset(e, value)
              }}
              label={`Select ${asset.assetType?.name}`}
              value={asset.id ?? 0}
            />
          </Grid>
          {asset.assetType && asset.assetType.requiresDriver && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  margin: '8px 0 8px 0',
                }}
              >
                <Divider>Drivers</Divider>
              </Grid>
              <Grid item xs={12}>
                <FormAssignDropDown
                  id={`${name}.employes`}
                  items={employees.filter(
                    (e) => !asset.employes || !asset.employes.some((ae) => ae.id === e.id && ae.userTypeId === 0)
                  )}
                  label='Select Driver'
                  onChange={(e, id) => handleDropdownAssignmentUserChange(e, id)}
                />
                {asset.employes.length > 0 &&
                  asset.employes.filter((e) => e.userTypeId === 0).map((e) => {
                    const employee = employees.find((ei: any) => ei.id === e.id)
                    return renderSelectedValues(employee?.name ?? '')
                  })}
              </Grid>
            </>
          )}
          {asset.assetType && asset.assetType.sealable && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  margin: '8px 0 8px 0',
                }}
              >
                <Divider>Seals</Divider>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={`${name}.seals`}
                  label='Seal number'
                  onKeyDown={handleSealKeyDown}
                  fullWidth
                  onBlur={(e) => {
                    if (e.target.value && e.target.value !== '') {
                      formik.setFieldValue(e.target.id, [...seals, e.target.value])
                      e.target.value = ''
                    }
                  }}
                />
                {seals.length > 0 && seals.map((seal) => renderSelectedValues(seal))}
              </Grid>
            </>
          )}
          {userAllocations && userAllocations.filter(a => a).map((allocation, index) => {
            const userTypeName = userTypes.find(ut => ut.id === allocation.userTypeId)?.name ?? ''
            return (
              <>
                <Grid
                  key={index}
                  item
                  xs={12}
                  sx={{
                    margin: '8px 0 8px 0',
                  }}
                >
                  <Divider>{`${userTypeName}s` ?? ''}</Divider>
                </Grid>
                <Grid item xs={12}>
                  <FormAssignDropDown
                    id={`${name}.employes`}
                    items={employees.filter(
                      (e) => e.userTypeId == allocation.userTypeId && (!asset.employes
                        || !asset.employes.some((ae) => ae.id === e.id && ae.userTypeId === allocation.userTypeId))
                    )}
                    label={`Select ${userTypeName}`}
                    onChange={(e, id) => handleDropdownAssignmentUserChange(e, id, allocation.userTypeId)}
                  />
                  {asset.employes.length > 0 &&
                    asset.employes.filter((e) => e.userTypeId === allocation.userTypeId).map((e) => {
                      const employee = employees.find((ei: any) => ei.id === e.id)
                      return renderSelectedValues(employee?.name ?? '')
                    })}
                </Grid>
              </>
            )
          })}
          {asset.assetType && asset.assetType.carriesPayload && (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  margin: '8px 0 8px 0',
                }}
              >
                <Divider>Loads</Divider>
              </Grid>
              <Grid item xs={12}>
                <FormAssignDropDown
                  id={`${name}.loadIds`}
                  items={loadsToAdd}
                  label='Select Load'
                  onChange={(e, id) => handleDropdownAssignmentChange(e, id)}
                />
              </Grid>
              <Grid item xs={12} sx={{ pt: '8px' }}>
                <AssetLoads formik={formik} asset={asset} assetIdx={idx} acIdx={assetConfigIdx} />
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default Asset
