import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useGetBrandQuery } from '../../../app/redux-fetch/apiQuery';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import FormDropdown from '../../../components/FormDropDown/FormDropdown';
import { TableSubTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import { FormGrid } from '../FormGrid/FormGrid';
import { FormGridItem } from '../FormGrid/FormGridItem';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';
import { messages } from '../messages';
import { getMaxDate, getMinDate } from '../utils/utils';

export function DetailDates({ disabled }: { disabled?: boolean }) {
    const { formatMessage } = useIntl();
    const { values, setFieldValue } = useAssetManagementFormContext();
    const { detail } = values;

    const registrationExpiryMinDate = getMinDate(values.detail?.registrationDate);
    const registrationDateMaxDate = getMaxDate(values.detail?.registrationExpiry);
    const { data: brands = [] } = useGetBrandQuery();

    return (<>
        <Grid item xs={12} sx={{ ml: '16px', mt: '12px' }}>
            <TableSubTitleTypography>Asset Details</TableSubTitleTypography>
        </Grid>
        <FormGrid>
            <FormGridItem>
                <FormDropdown
                    id='brandId'
                    items={brands}
                    label={formatMessage(messages.assetsDetailsBrand)}
                    onChange={(_e: any, _name: string, value: number) => {
                        setFieldValue('detail.brandId', value);
                        setFieldValue('detail.manufacturerId', value);
                    }}
                    value={detail?.brandId}
                    defaultValue={detail?.brandId}
                    disabled={disabled || isEmpty(brands)}
                />
                <TextField
                    label={formatMessage(messages.assetsDetailsModel)}
                    name="model"
                    onChange={({ target }) => setFieldValue('detail.model', target.value)}
                    disabled={disabled}
                    value={detail?.model}
                    defaultValue={detail?.model}
                    InputLabelProps={{ shrink: true }}
                />
                <DatePicker
                    label={formatMessage(messages.assetsDetailsProductionYear)}
                    name={'productionYear'}
                    value={detail?.productionYear}
                    disablePast={false}
                    disabled={disabled}
                    onChange={(date) => setFieldValue('detail.productionYear', date)}
                />
            </FormGridItem>
            <FormGridItem>
                <TextField
                    label={formatMessage(messages.assetsDetailsRegistrationNumber)}
                    name="registrationNumber"
                    onChange={({ target }) => setFieldValue('detail.registrationNumber', target.value)}
                    disabled={disabled}
                    value={detail?.registrationNumber}
                    defaultValue={detail?.registrationNumber}
                    InputLabelProps={{ shrink: true }}
                />
                <DatePicker
                    label={formatMessage(messages.assetsDetailsRegistrationDate)}
                    name={'registrationDate'}
                    value={detail?.registrationDate}
                    disablePast={false}
                    disabled={disabled}
                    maxDate={registrationDateMaxDate}
                    onChange={(date) => setFieldValue('detail.registrationDate', date)}
                />
                <DatePicker
                    label={formatMessage(messages.assetsDetailsRegistrationExpiry)}
                    name={'registrationExpiry'}
                    value={detail?.registrationExpiry}
                    minDate={registrationExpiryMinDate}
                    disabled={disabled || !detail?.registrationDate}
                    onChange={(date) => setFieldValue('detail.registrationExpiry', date)}
                />
            </FormGridItem>
            <FormGridItem>
                <TextField
                    label={formatMessage(messages.assetsDetailsChassisNumber)}
                    name="chassisNumber"
                    onChange={({ target }) => setFieldValue('detail.chassisNumber', target.value)}
                    disabled={disabled}
                    value={detail?.chassisNumber}
                    defaultValue={detail?.chassisNumber}
                    placeholder='chassisNumber'
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label={formatMessage(messages.assetsDetailsNumberOfDoors)}
                    name="numberOfDoors"
                    onChange={({ target }) => setFieldValue('detail.numberOfDoors', target.value)}
                    disabled={disabled}
                    value={detail?.numberOfDoors}
                    defaultValue={detail?.numberOfDoors}
                    type='number'
                    InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                    sx={{ justifyContent: 'start', minHeight: '56px' }}
                    value={detail?.isOwned}
                    labelPlacement="start"
                    control={
                        <Switch
                            color="primary"
                            checked={detail?.isOwned}
                            value={detail?.isOwned}
                            onChange={({ target }) => {
                                setFieldValue('detail.isOwned', target.checked)
                            }}
                            disabled={disabled}
                        />
                    }
                    label={formatMessage(messages.assetsDetailsIsOwned)}
                    disabled={disabled}
                />
            </FormGridItem>
            <FormGridItem>
                <DatePicker
                    label={formatMessage(messages.assetsDetailsInsuranceExpiry)}
                    name={'insuranceExpiry'}
                    value={detail?.insuranceExpiry}
                    disabled={disabled}
                    onChange={(date) => setFieldValue('detail.insuranceExpiry', date)}
                />
                <DatePicker
                    label={formatMessage(messages.assetsDetailsOperationCardExpiry)}
                    name={'operationCardExpiry'}
                    value={detail?.operationCardExpiry}
                    disabled={disabled}
                    onChange={(date) => setFieldValue('detail.operationCardExpiry', date)}
                />
                <DatePicker
                    label={formatMessage(messages.assetsDetailsMvpiExpiry)}
                    name={'mvpiExpiry'}
                    value={detail?.mvpiExpiry}
                    disabled={disabled}
                    onChange={(date) => setFieldValue('detail.mvpiExpiry', date)}
                />
            </FormGridItem>
            <FormGridItem>
                <DatePicker
                    label={formatMessage(messages.assetsDetailsMaintenanceEndDate)}
                    name={'maintenanceEndDate'}
                    value={detail?.maintenanceEndDate}
                    disabled={disabled}
                    onChange={(date) => setFieldValue('detail.maintenanceEndDate', date)}
                />
            </FormGridItem>
        </FormGrid>
    </>
    );
}
