import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { getProperty } from '../../../../../app/utils'
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown'
import { IAddress } from '../../../../Shipments/types'
import messages from '../../messages'
import { FormikCustomersSettings, IDropdownOption, ITenantCustomer } from '../../types'
import { isUniqueCustomer } from '../../utils'

// import { isUnique } from '../../../utils'

interface IAddCustomerModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Partial<ITenantCustomer> & IAddress) => Promise<void>
  currencyFormik: FormikCustomersSettings
  currencyOptions: IDropdownOption[]
  countryOptions: IDropdownOption[]
}

export const AddCustomerModal = ({
  open,
  onClose,
  onSubmit,
  currencyFormik,
  currencyOptions,
  countryOptions,
}: IAddCustomerModalProps) => {
  const { formatMessage } = useIntl()

  const formik = useFormik<Partial<ITenantCustomer> & IAddress>({
    initialValues: {
      id: 0,
      name: '',
      fullAddress: '',
      address1: '',
      address2: '',
      address3: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      locationName: '',
      defaultCurrencyName: 'Euro',
      enabled: 'Yes',
    },
    validationSchema: getAddNewCurrencyValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values)
      onClose()
    },
  })

  const isUniqueValue = function isUniqueValue(value: string | undefined, context: any) {
    return isUniqueCustomer({
      formik: currencyFormik,
      id: -1,
      propToCheck: context.path,
      valueToCheck: value as string,
    })
  }

  function getAddNewCurrencyValidationSchema() {
    return yup.object({
      id: yup.number().optional(),
      name: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'name' }))
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, {
            propertyName: 'length',
            exactLength: 3,
          })
        )
        .test({
          name: 'isUniqueValue',
          message: formatMessage(messages.propertyValueMustBeUnique, { propertyName: 'name' }),
          test: isUniqueValue,
        }),
      address1: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'address1' }))
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, {
            propertyName: 'length',
            maxLength: 10,
          })
        ),
      address2: yup
        .string()
        .optional()
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'length', maxLength: 10 })
        ),
      address3: yup
        .string()
        .optional()
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'length', maxLength: 10 })
        ),
      city: yup
        .string()
        .optional()
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'length', maxLength: 10 })
        ),
      state: yup
        .string()
        .optional()
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'length', maxLength: 10 })
        ),
      postalCode: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'postalCode' }))
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'length', maxLength: 10 })
        ),
      country: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'country' })),
      defaultCurrencyName: yup
        .string()
        .required(
          formatMessage(messages.propertyValueRequired, { propertyName: 'defaultCurrencyName' })
        ),
      enabled: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'enabled' })),
    })
  }

  const getError = (propertyName: string): boolean => {
    const touched = getProperty(formik.touched, propertyName)
    const errorMessage = getProperty(formik.errors as unknown, propertyName)
    return touched && (errorMessage as any)
  }

  const handleCancelAddNewCurrency = () => {
    formik.resetForm()
    onClose()
  }

  const handleSubmit = () => {
    formik.values.locationName = formik.values.name + ' HQ'
    formik.handleSubmit()
  }

  useEffect(() => formik.resetForm(), [open])

  const currencyItems = currencyOptions.map((currency) => ({
    id: currency.value,
    name: currency.text,
  }))

  const countryItems = countryOptions.map((country) => ({
    id: country.value,
    name: country.text,
  }))

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    newValue: number
  ) => {
    formik.setFieldValue(name, newValue)
  }

  const enabledItems = [
    { id: 'Yes', name: 'Yes' },
    { id: 'No', name: 'No' },
  ]
  return (
    <Dialog
      open={open}
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>
        {formatMessage(messages.addNewCustomerModalTitle)}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <>
              <TextField
                id='name'
                name='name'
                key='name'
                label={formatMessage(messages.name)}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={getError('name')}
              />
              <TextField
                id='address1'
                name='address1'
                key='address1'
                label={formatMessage(messages.address1)}
                value={formik.values.address1}
                onChange={formik.handleChange}
                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                helperText={getError('address1')}
              />

              <TextField
                id='address2'
                name='address2'
                key='address2'
                label={formatMessage(messages.address2)}
                value={formik.values.address2}
                onChange={formik.handleChange}
                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                helperText={getError('address2')}
              />

              <TextField
                id='address3'
                name='address3'
                key='address3'
                label={formatMessage(messages.address3)}
                value={formik.values.address3}
                onChange={formik.handleChange}
                error={formik.touched.address3 && Boolean(formik.errors.address3)}
                helperText={getError('address3')}
              />

              <TextField
                id='city'
                name='city'
                key='city'
                label={formatMessage(messages.city)}
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={getError('city')}
              />

              <TextField
                id='state'
                name='state'
                key='state'
                label={formatMessage(messages.state)}
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={getError('state')}
              />

              <TextField
                id='postalCode'
                name='postalCode'
                key='postalCode'
                label={formatMessage(messages.postalCode)}
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                helperText={getError('postalCode')}
              />

              <FormDropdown
                id='country'
                items={countryItems}
                label={formatMessage(messages.country)}
                onChange={handleOptionChange}
                value={formik.values.country}
              />

              <FormDropdown
                id={'defaultCurrencyName'}
                items={currencyItems}
                label={formatMessage(messages.defaultCurrency)}
                onChange={handleOptionChange}
                defaultValue='Euro'
                value={formik.values.defaultCurrencyName}
              />

              <FormDropdown
                id='enabled'
                items={enabledItems}
                label={formatMessage(messages.enabled)}
                onChange={handleOptionChange}
                value={formik.values.enabled}
              />
            </>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={handleCancelAddNewCurrency}>Cancel</Button>
        <Button color='secondary' onClick={handleSubmit} variant='contained'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCustomerModal
