import Button from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

export interface IAddMoreDialog extends DialogProps {
  open: boolean
  onAdd: () => void
  onDiscard: () => void
}

export default function AddMoreDialog({
  open: openDialog,
  onAdd,
  onDiscard,
  title,
  children
}: IAddMoreDialog) {
  return (
    <div>
      <Dialog open={openDialog} aria-labelledby='confirm-dialog-title' maxWidth='md'>
        <DialogTitle id='confirm-dialog-title'>{title}</DialogTitle>
        <DialogContent>{children ? <>{children}</> : null}</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onDiscard} variant='outlined'>
            Discard
          </Button>
          <Button onClick={onAdd} variant='contained'>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
