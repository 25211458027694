import { Box } from '@mui/material'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import ControlBar from '../../../components/ControlBar/ControlBar'
import MultiGridLayout from '../../../components/MultiGridLayout/MultiGridLayout'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { Role } from './Role/Role'
import { postRole, putRole } from './Role/api'
import { fetchAllRoleSelector, fetchAllSortedRoleSelector } from './Role/selectors'
import { fetchAllRolesActions, fetchAllRolesThunk } from './Role/slices'
import { IRole } from './Role/types'
import { SecurityPrincipal } from './SecurityPrincipal/SecurityPrincipal'
import { fetchAllSecurityPrincipalSelector } from './SecurityPrincipal/selectors'
import {
  fetchAllSecurityPrincipalsActions,
  fetchAllSecurityPrincipalsThunk,
} from './SecurityPrincipal/slices'
import { ISecurityPrincipal, ISecurityPrincipalAllocation } from './SecurityPrincipal/types'
import { FormikRoleSettings, IRoleSettingsValues } from './types'

export const RoleSettings = () => {
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { tenantId, customerId } = loggedInUser

  // Get all roles from api
  const allRoles = useAppSelector(fetchAllSortedRoleSelector)
  const isFetchingRole = useAppSelector(fetchAllRoleSelector.isFetching)

  // Get all security principals from api
  const allRolesPrincipals = useAppSelector(fetchAllSecurityPrincipalSelector.data) || []
  const isFetchingSecurityPrincipal = useAppSelector(fetchAllSecurityPrincipalSelector.isFetching)

  useEffect(() => {
    void dispatch(fetchAllRolesThunk(tenantId, customerId))
    void dispatch(fetchAllSecurityPrincipalsThunk(tenantId, customerId))
  }, [fetchAllRolesThunk])

  useEffect(() => {
    return () => {
      formik.resetForm()
      void dispatch(fetchAllRolesActions.clear())
      void dispatch(fetchAllSecurityPrincipalsActions.clear())
    }
  }, [])

  const initialValues = useMemo<FormikRoleSettings['initialValues']>(
    (): IRoleSettingsValues => ({
      formikRoles: allRoles,
      formikNewRoles: [],
      formikEditedRole: undefined,
      formikOriginalRole: undefined,
      formikSecurityPrincipals: allRolesPrincipals,
      formikSelectedRoleId: undefined,
      formikSelectedSecurityPrincipalId: undefined,

      formIsDirty: false,
      formHasErrors: false,
      formSubmissionSuccessful: false,
      formSubmissionFailure: false,

      openActionConfirmationDialog: false,
      newSelectedSecurityPrincipals: [],
      originalSelectedSecurityPrincipals: [],
    }),
    [isFetchingRole, isFetchingSecurityPrincipal, allRoles, allRolesPrincipals]
  )

  const formik = useFormik<IRoleSettingsValues>({
    initialValues: initialValues,
    onSubmit: async (values: IRoleSettingsValues) => onSubmit(values),
    enableReinitialize: true,
  })

  const handleSubmit = () => formik.handleSubmit()

  const onSubmit = async (values: IRoleSettingsValues) => {
    // Get selected role id from formik
    const selectedRoleId = values.formikSelectedRoleId || 0

    // Get role from formik by selected role id
    const selectedRole =
      values.formikRoles?.find((role) => role.id === selectedRoleId) || ({} as IRole)

    // Build security principals

    const isNewRole = values.formikNewRoles?.length !== 0

    if (isNewRole) {
      // Post new role
      try {
        const securityPrincipals = buildSecurityPrincipalToPost()

        // Build security principal allocations
        const securityPrincipalAllocationsForPost =
          buildSecurityPrincipalAllocationsForPost(securityPrincipals)

        const roleToAdd = {
          id: selectedRole.id,
          name: selectedRole.name,
          description: selectedRole.description,
          ...(securityPrincipalAllocationsForPost && {
            securityPrincipalAllocations: securityPrincipalAllocationsForPost,
          }),
          tenantId,
          ...(customerId && { customerId }),
        } as IRole

        await postRole({ tenantId, customerId, role: roleToAdd })
        enqueueSnackbar('Role created successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      } catch (error) {
        enqueueSnackbar('Failed to create role', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })

        return
      }
      refreshPage()
      return
    }

    try {
      // Build security principal allocations
      const securityPrincipalAllocationsForPut = buildSecurityPrincipalAllocationsForPut()

      const roleToUpdate = {
        id: selectedRole.id,
        name: selectedRole.name,
        description: selectedRole.description,
        ...(securityPrincipalAllocationsForPut && {
          securityPrincipalAllocations: securityPrincipalAllocationsForPut,
        }),
        tenantId,
        ...(customerId && { customerId }),
      } as IRole

      await putRole({ tenantId, customerId, role: roleToUpdate })
      enqueueSnackbar('Role created successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      // enqueueSnackbar(formatMessage(messages.userGroupTableUserGroupCreationFailed), {
      enqueueSnackbar('Failed to update role', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })

      return
    }

    refreshPage()

    function buildSecurityPrincipalToPost() {
      // Get new selected security principals from formik
      const newSelectedSecurityPrincipalIds = values.newSelectedSecurityPrincipals

      // Get security principals from formikSecurityPrincipals by newSelectedSecurityPrincipalIds
      const securityPrincipalToUpsert = (values.formikSecurityPrincipals || [])?.filter((sp) =>
        newSelectedSecurityPrincipalIds.includes(sp.id)
      )

      return securityPrincipalToUpsert
    }

    function buildSecurityPrincipalAllocationsForPost(
      securityPrincipalToUpsert: ISecurityPrincipal[]
    ) {
      const isNewRole = values.formikNewRoles?.length !== 0
      if (isNewRole) {
        const securityPrincipalAllocations: Omit<ISecurityPrincipalAllocation, 'id' | 'roleId'>[] =
          securityPrincipalToUpsert.map((sp) => ({
            securityPrincipalId: sp.id,
          }))

        return securityPrincipalAllocations
      }
    }

    function buildSecurityPrincipalAllocationsForPut() {
      const selectedRoleId = values.formikSelectedRoleId
      const allSecuirtyPrincipals = values.formikSecurityPrincipals || []

      const newIds = values.newSelectedSecurityPrincipals
      const originalIds = values.originalSelectedSecurityPrincipals

      const toAdd = newIds.filter((newId) => !originalIds.includes(newId))
      const toKeep = newIds.filter((newId) => originalIds.includes(newId))

      const allocationToKeep =
        (values.formikRoles || [])
          .find((role) => role.id == selectedRoleId)
          ?.securityPrincipalAllocations.filter((spa) =>
            toKeep.includes(spa.securityPrincipalId)
          ) || []

      const allocationsToAdd = allSecuirtyPrincipals
        .filter((sp) => toAdd.includes(sp.id))
        .map((sp) => ({
          securityPrincipalId: sp.id,
        })) as ISecurityPrincipalAllocation[]

      return allocationToKeep.concat(allocationsToAdd)
    }
  }

  const refreshPage = () => {
    formik.resetForm()
    void dispatch(fetchAllRolesActions.clear())
    void dispatch(fetchAllSecurityPrincipalsActions.clear())

    void dispatch(fetchAllRolesThunk(tenantId, customerId))
    void dispatch(fetchAllSecurityPrincipalsThunk(tenantId, customerId))
  }

  return (
    <>
      <Box sx={{ pt: '48px' }}>
        <ControlBar
          formik={formik}
          isFormDirty={() => isFormDirty(formik)}
          onSubmit={handleSubmit}
        />
        <MultiGridLayout>
          <Role formik={formik} isLoading={isFetchingRole} />
          <SecurityPrincipal formik={formik} isLoading={isFetchingSecurityPrincipal} />
        </MultiGridLayout>
      </Box>
    </>
  )
}

export const isFormDirty = (formik: FormikRoleSettings): boolean => {
  const originalSecurityPrincipalIds = formik.values.originalSelectedSecurityPrincipals
  const newSecurityPrincipalIds = formik.values.newSelectedSecurityPrincipals

  const hasNewRoles = formik.values.formikNewRoles?.length !== 0
  const hasNewSecurityPrincipals = newSecurityPrincipalIds.length !== 0

  // Create new roles with Security Principals
  if (hasNewRoles && !hasNewSecurityPrincipals) return false

  const hasOriginal = originalSecurityPrincipalIds.length !== 0
  const hasNew = newSecurityPrincipalIds.length !== 0

  const hasNoOriginalButHasNew = !hasOriginal && hasNew
  const hasOriginalAndNew = hasOriginal && hasNew
  const hasOriginalAndNewButNotEqual =
    hasOriginalAndNew &&
    (originalSecurityPrincipalIds.find(
      (originalId) => !newSecurityPrincipalIds.includes(originalId)
    ) !== undefined ||
      newSecurityPrincipalIds.find((newId) => !originalSecurityPrincipalIds.includes(newId)) !==
      undefined)

  const hasOriginalButNotNew = hasOriginal && !hasNew
  const hasEditedRole = formik.values.formikEditedRole !== undefined

  const hasRowSelectionChanged =
    hasNoOriginalButHasNew ||
    hasOriginalAndNewButNotEqual ||
    hasOriginalButNotNew ||
    hasNewRoles ||
    hasEditedRole

  return hasRowSelectionChanged
}
