import { Grid, TextField, type SelectChangeEvent } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import ValueUnitControl from '../../../../components/ValueUnitControl/ValueUnitControl'
import {
  fetchAllCurrenciesSelector,
  fetchAllLocationsByTenantCustomerSelector,
  fetchAllShippersSelector,
  fetchSortedCurrenciesSelector,
} from '../../selectors'
import { type IShipment } from '../../types'
import messages from './messages'

interface ShipmentDetailsProps {
  formik: FormikProps<IShipment>
  disabled?: boolean
  handleFormDropDownChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
}

const ShipmentDetails = (props: ShipmentDetailsProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { formik, handleFormDropDownChanges, disabled } = props
  const getError = (propertyName: string): boolean => {
    const touched = getProperty(formik.touched, propertyName)
    const errorMessage = getProperty(formik.errors as unknown as IShipment, propertyName)
    return touched && (errorMessage as any)
  }

  const currencies = useAppSelector(fetchSortedCurrenciesSelector)
  const shippers = useAppSelector(fetchAllShippersSelector)
  const isLocationLoading = useAppSelector(fetchAllLocationsByTenantCustomerSelector.isFetching)
  const isCurrencyLoading = useAppSelector(fetchAllCurrenciesSelector.isFetching)

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormDropdown
          id='shipperId'
          label={formatMessage(messages.shipper)}
          onChange={handleFormDropDownChanges}
          value={formik.values.shipperId}
          handleOnBlur={formik.handleBlur}
          error={getError('shipperId')}
          errorText={getError('shipperId')}
          items={shippers}
          sx={{ borderColor: 'red' }}
          disabled={disabled || isLocationLoading}
          showSystemEntitiesText={formatMessage(messages.locations)}
          itemsFilterForNonSystemEntities={(l) => l.locationTypeId == 255} // 255 is user-defined
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          id='description'
          name='description'
          label={formatMessage(messages.shipmentDescription)}
          multiline
          sx={{ width: '100%', borderColor: 'red', borderBlockColor: 'red' }}
          onChange={formik.handleChange}
          value={formik.values.description}
          onBlur={formik.handleBlur}
          error={getError('description')}
          helperText={getError('description')}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        />
      </Grid>
      <ValueUnitControl
        unitId='currencyId'
        valueId='value'
        handleOnBlur={formik.handleBlur}
        errorUnit={getError('currencyId')}
        errorValue={getError('value')}
        handleChangeOfUnit={formik.setFieldValue}
        handleChangeOfValue={formik.setFieldValue}
        isCurrency={true}
        labelUnit={formatMessage(messages.currencyLabel)}
        labelValue={formatMessage(messages.cargoValue)}
        name='currencyValueControl'
        selectedUnitId={formik.values.currencyId ?? 1}
        unitList={currencies}
        value={formik.values.value}
        disabled={disabled || isCurrencyLoading}
      />
    </Grid>
  )
}

export default ShipmentDetails
