import { SubType } from '../../../../types/common'
import { isLocaleEquals } from '../../../utils'
import { IExtendedUser, IUser, IValidateUniqueUser } from './types'

export const compareUser = (a: IUser, b: IUser) => {
  const result =
    isLocaleEquals(a.firstName, b.firstName) &&
    isLocaleEquals(a.lastName, b.lastName) &&
    isLocaleEquals(a.email, b.email) &&
    isLocaleEquals(a.externalId, b.externalId) &&
    a.IdentityProviderIntegrationId == b.IdentityProviderIntegrationId

  return result
}

export const isPureUserDefined = (user: IExtendedUser) =>
  SubType.UserDefined === user.subType && !user.isCloneOfSysmtemDefined

export const validateUniqueUser = ({
  formik,
  id,
  firstName,
  lastName,
  email,
}: IValidateUniqueUser) => {
  const user = formik.values.formikUsers
    .filter((user) => user.id !== id)
    .find((user) => compareUser(user as IUser, { firstName, lastName, email } as IUser))
  if (user) {
    return 'User with this email already exists'
  }
  return true
}
