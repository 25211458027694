import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import { DragEvent, useState } from 'react'
import { useIntl } from 'react-intl'
import { IDropDownItem } from '../../../../app/types'
import { IContainerDetail, ILoad, IShipment } from '../../types'
import { handleMapLoad, handleRemoveLoadMap } from '../../utils'
import messages from './messages'
import { ContainerAccordion } from './styles'

export interface AssignmentContainerProps {
  formik: FormikProps<IShipment>
  container: IContainerDetail
  containerIndex: number
  disabled?: boolean
  viewMode?: boolean
}

const AssignmentContainer = (props: AssignmentContainerProps) => {
  const { formatMessage } = useIntl()

  const { formik, container, containerIndex, disabled, viewMode } = props

  const handleDrop = (event: DragEvent, targetIndex: number): void => {
    event.preventDefault()
    const incomingData = event.dataTransfer.getData('string')
    if ((incomingData?.substring(0, 5) ?? '') === 'LOAD_') {
      const incomingLoadIndex = Number(incomingData.substring(5))
      const incomingLoad = formik.values.loads[incomingLoadIndex]
      const target = formik.values.containerDetails[targetIndex]

      handleMapLoad(formik, incomingLoad.id, null, target.id)
    }
  }
  const clearMaps = (container: IContainerDetail): void => {
    if ((container.loadsId?.length ?? 0) > 0) {
      container.loadsId.forEach((id) => {
        const load = formik.values.loads.find((l) => l.id === id)
        load && removeLoad(load.id, container.id)
      })
    }
  }
  const [isExpanded, setIsExpanded] = useState(true)
  const handleDragOver = (event: DragEvent): void => {
    event.preventDefault()
  }
  const removeLoad = (loadId: number, containerId: number): void => {
    handleRemoveLoadMap(formik, loadId, null, containerId)
  }
  const handleRemoveContainer = (index: number): void => {
    const containers = [...formik.values.containerDetails]
    const remContainer = containers.splice(index, 1)[0]
    if (remContainer.loadsId)
      for (const loadId of remContainer.loadsId) {
        const loadIndex = formik.values.loads.findIndex((x) => x.id === loadId)
        const loadContainerAllocations = formik.values.loads[loadIndex].containerAllocations.filter(
          (ca) => ca.containerDetailId !== remContainer.id
        )
        void formik.setFieldValue(
          `loads.${loadIndex}.containerAllocations`,
          loadContainerAllocations
        )
      }
    void formik.setFieldValue('containerDetails', containers)
  }
  const RenderLoad = (
    mappedLoadIndex: number,
    load: ILoad | undefined,
    removeLoad: (loadId: number, containerId: number) => void,
    container: IContainerDetail
  ): JSX.Element => {
    return (
      <Grid item key={mappedLoadIndex} xs={12}>
        <Box
          display='flex'
          justifyContent='start'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '3px',
            margin: '2px',
          }}
        >
          {!disabled && (
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                load && removeLoad(load.id, container.id)
              }}
              sx={{ color: 'red', cursor: 'pointer' }}
            />
          )}
          <Typography>
            Load #{load?.displayOrder}: {load?.quantity} {load?.description ?? load?.productId}
          </Typography>
        </Box>
      </Grid>
    )
  }
  const isGenSetRequired = formik.values.loads.some(
    (l) => container.loadsId?.includes(l.id) && l.temperatureSetting
  )
  const handleChangeAssignmentDropDown = (event: SelectChangeEvent<IDropDownItem>) => {
    const {
      target: { value },
    } = event
    handleMapLoad(formik, +value, null, container.id)
  }

  const RenderAssignmentDropDown = (): JSX.Element => {
    const notSelectedLoads = formik.values.loads
      .filter((l) => l.isDefined)
      .reduce((acc, curr) => {
        if (!container?.loadsId || container?.loadsId.length === 0) {
          acc.push({ id: curr.id, name: `Load # ${curr.displayOrder}` })
        }
        if (
          container?.loadsId &&
          container?.loadsId.length > 0 &&
          !container?.loadsId?.some((c) => c === curr.id)
        ) {
          acc.push({ id: curr.id, name: `Load # ${curr.displayOrder}` })
        }
        return acc
      }, [] as IDropDownItem[])

    const items = [...notSelectedLoads]
    if (items.length === 0) {
      return <></>
    }
    return (
      <Grid item xs={12}>
        <Box sx={{ paddingTop: '16px' }}>
          <FormControl fullWidth>
            <InputLabel>Assign load</InputLabel>
            <Select
              label='Assignments'
              onChange={(e: SelectChangeEvent<IDropDownItem>) => {
                handleChangeAssignmentDropDown(e)
              }}
            >
              {items.map((item) => (
                <MenuItem key={item?.id ?? -1} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    )
  }

  return (
    <ContainerAccordion
      draggable
      defaultExpanded={!disabled}
      key={containerIndex}
      onDragOver={handleDragOver}
      onDragStart={(event: DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('string', `CONTAINER_${container.id}`)
      }}
      onDrop={(event: any) => {
        handleDrop(event, containerIndex)
      }}
      onChange={(event: any, expanded: boolean) => {
        setIsExpanded(expanded)
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightIcon />}
        sx={{ flexDirection: 'row-reverse' }}
      >
        <Grid container spacing={2}>
          <Grid item container xs={3} direction='column' justifyContent='center'>
            <Typography>
              {formatMessage(messages.container)} # {containerIndex + 1}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {isGenSetRequired && (
              <>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <FormControlLabel
                    disabled={disabled}
                    control={
                      <Switch
                        size='small'
                        checked={formik.values.containerDetails?.[containerIndex].isGenSetRequired}
                        onChange={(e, newValue) => {
                          e.stopPropagation()
                          e.preventDefault()
                          formik.setFieldValue(
                            `containerDetails.${containerIndex}.isGenSetRequired`,
                            newValue
                          )
                        }}
                      />
                    }
                    label={formatMessage(messages.genSetRequired)}
                  />
                </Box>
              </>
            )}
          </Grid>
          {!disabled ? (
            <Grid item xs={3}>
              <Box display='flex' justifyContent='flex-end'>
                <Fab
                  aria-label='remove'
                  color='info'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    clearMaps(container)
                    handleRemoveContainer(containerIndex)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <DeleteIcon />
                </Fab>
              </Box>
            </Grid>
          ) : (
            <></>
          )}
          {(formik.errors.containerDetails?.[containerIndex] as unknown as IContainerDetail)
            ?.loadsId && (
              <Grid item xs={12} sx={{ paddingTop: '12px' }}>
                <Typography color={'error'}>
                  {
                    (formik.errors.containerDetails?.[containerIndex] as unknown as IContainerDetail)
                      ?.loadsId
                  }
                </Typography>
              </Grid>
            )}
          {!isExpanded &&
            (container.loadsId?.length ?? 0) > 0 &&
            container.loadsId.map((loadId, mappedLoadIndex) => {
              const load = formik.values.loads.find((l) => l.id === loadId)
              if (load && load.isDefined) {
                return RenderLoad(mappedLoadIndex, load, removeLoad, container)
              }
            })}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {!disabled ? (
          <TextField
            id={`containerDetails.${containerIndex}.containerNumber`}
            name={`containerDetails.${containerIndex}.containerNumber`}
            label={formatMessage(messages.containerNumber)}
            multiline
            sx={{ width: '100%' }}
            onChange={formik.handleChange}
            value={formik.values.containerDetails?.[containerIndex].containerNumber}
            error={Boolean(
              (formik.errors.containerDetails?.[containerIndex] as unknown as IContainerDetail)
                ?.containerNumber
            )}
            helperText={
              (formik.errors.containerDetails?.[containerIndex] as unknown as IContainerDetail)
                ?.containerNumber
            }
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        ) : (
          <Typography>{`${formatMessage(messages.containerNumber)}: ${formik.values.containerDetails?.[containerIndex].containerNumber
            }`}</Typography>
        )}
        <Grid container>
          {isExpanded &&
            (container.loadsId?.length ?? 0) > 0 &&
            container.loadsId.map((loadId, mappedLoadIndex) => {
              const load = viewMode
                ? formik.values.loads[mappedLoadIndex]
                : formik.values.loads.find((l) => l.id === loadId)
              if (load && load.isDefined) {
                return RenderLoad(mappedLoadIndex, load, removeLoad, container)
              }
            })}
          {!disabled && RenderAssignmentDropDown()}
        </Grid>
      </AccordionDetails>
    </ContainerAccordion>
  )
}

export default AssignmentContainer
