import { Delete, Edit, Undo } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_RowSelectionState as MRTRowSelectionState,
  MRT_TableInstance as MRTTableInstance,
  MaterialReactTableProps,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import MRTDataGrid from '../../../components/MRTDataGrid/MRTDataGrid'
import { SubType } from '../../../types/common'
import { HttpAction } from '../../enums'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { AddItemModal } from '../UserGroupSettings/Components/AddItemModal/AddItemModal'
import { isFormDirty } from '../UserGroupSettings/UserGroupSettings'
import { IExtendedUserGroup, IUserGroup } from '../UserGroupSettings/UserGroups/types'
import {
  FormikUserGroupSettings,
  IUserGroupMemberByUserGroupId,
  IUserGroupRoleByUserGroupId,
} from '../UserGroupSettings/types'
import messages from './messages'

interface IUserGroupMRTProps {
  formik: FormikUserGroupSettings
  isLoading: boolean
}
export const UserGroupMRT = ({ formik, isLoading }: IUserGroupMRTProps) => {
  const { formatMessage } = useIntl()
  const [OpenConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const data = formik.values.formikUserGroups
  const formikSelectedUserGroupId = formik.values.formikSelectedUserGroupId as number
  const formikSelectedNextUserGroupId = formik.values.formikSelectedNextUserGroupId as number
  const allUserGroupMembers = formik.values.formikUserGroupMembersByGroupId || []

  const formikUserGroupBeingEdited = formik.values.formikUserGroupBeingEdited
  const editingInProgress = formikUserGroupBeingEdited !== undefined

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { tenantId } = loggedInUser

  const columns = useMemo<MRTColumnDef<IUserGroup>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'lastUpdated',
        header: 'Last Updated',
      },
      {
        accessorKey: 'lastSync',
        header: 'Last Sync',
      },
    ]
  }, [])

  const handleRowSelectionChange = ({
    row,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IUserGroup>
    table: MRTTableInstance<IUserGroup>
  }) => {
    return {
      onClick: () => {
        if (!formikSelectedUserGroupId) {
          formik.setFieldValue('formikSelectedUserGroupId', Number(row.id))
          return
        }

        if (`${formikSelectedUserGroupId}` != row.id) {
          formik.setFieldValue('formikSelectedNextUserGroupId', Number(row.id))
          if (isFormDirty(formik) || formik.values.formikNewUserGroups !== undefined) {
            setOpenConfirmDialog((prev) => {
              if (prev) return prev
              if (editingInProgress && formikUserGroupBeingEdited.id === Number(row.id)) return prev
              return true
            })
            return
          }
          formik.setFieldValue('formikSelectedUserGroupId', Number(row.id))
          formik.values.formikSelectedMemberIdsByUserGroupId = []
          return
        }
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          row.id === `${formik.values.formikSelectedUserGroupId}` ? 'lightgrey' : 'initial',
      },
    }
  }

  const [addUserGroupModalOpen, setAddUserGroupModalOpen] = useState(false)
  const handleAddItem = () => setAddUserGroupModalOpen(true)

  const handleSaveRowEdits: MaterialReactTableProps<IUserGroup>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const editedUserGroup = data.find((group) => group.id === Number(row.id))
    if (!editedUserGroup) {
      exitEditingMode()
      return
    }

    // Save off original user group values
    const originalUserGroup = { ...editedUserGroup }
    formik.setFieldValue('formikOriginalUserGroup', originalUserGroup)

    editedUserGroup.name = values.name
    editedUserGroup.description = values.description

    const updatedUserGroups = data.map((group) => {
      if (group.id === Number(row.id)) {
        return editedUserGroup
      }
      return group
    })

    formik.setFieldValue('formikUserGroups', updatedUserGroups)
    formik.setFieldValue(
      'formikUserGroupBeingEdited',
      updatedUserGroups.find((group) => group.id === Number(row.id))
    )
    formik.setFieldValue('formikOriginalUserGroupMemberByGroupId', originalUserGroup.users)
    formik.setFieldValue('formikOriginalUserGroupRoleByGroupId', originalUserGroup.roles)

    exitEditingMode() // required to exit editing mode and close modal
  }

  const handleDelete = (row: MRTRow<IExtendedUserGroup>, table: IExtendedUserGroup[]) => {
    const userGroup = row.original
    const { httpAction } = userGroup

    if (httpAction === HttpAction.POST) {
      userGroup.dirty = false
      userGroup.httpAction = undefined

      const foundIndex = table.findIndex((item) => item.id === Number(row.id))
      table.splice(foundIndex, 1)

      formik.setFieldValue('formikRemoveUserGroup', userGroup)
      formik.setFieldValue('formikDeletedUserGroup', undefined)
      formik.setFieldValue('formikSelectedUserGroupId', undefined)
      formik.setFieldValue('formIsDirty', false)
      return
    }

    if (httpAction === HttpAction.DELETE) {
      userGroup.dirty = false
      userGroup.httpAction = undefined
      formik.setFieldValue('formikDeletedUserGroup', userGroup)
      formik.setFieldValue('formikSelectedUserGroupId', userGroup.id)
      formik.setFieldValue('formIsDirty', false)
      return
    }

    userGroup.dirty = true
    userGroup.httpAction = HttpAction.DELETE
    formik.setFieldValue('formikDeletedUserGroup', userGroup)
    formik.setFieldValue('formikSelectedUserGroupId', userGroup.id)
    formik.setFieldValue('formIsDirty', true)
  }

  const handleAddNewUserGroup = (values: IExtendedUserGroup) => {
    const newItem = {
      id: -1,
      name: values.name,
      tenantId,
      customerSelectable: true,
      enabled: true,
      entity: 'userGroup',
      dirty: false,
      httpAction: HttpAction.POST,
      subType: SubType.UserDefined,
      isPureUserDefined: true,
      isCloneOfSysmtemDefined: false,
      canDelete: true,

      description: values.description,
      lastSync: '',
      lastUpdated: '',

      users: [],
      roles: [],
      userAllocations: [],
      roleAllocations: [],
    }
    const newItems = [newItem, ...data]
    formik.setFieldValue('formikUserGroups', newItems)
    formik.setFieldValue('formikNewUserGroups', [newItem])
    formik.setFieldValue('formikSelectedUserGroupId', newItem.id)

    const allUserGroupMembersIncludingNewOne: IUserGroupMemberByUserGroupId[] = [
      ...allUserGroupMembers,
      {
        groupId: newItem.id,
        members: [],
      },
    ]

    const allUserGroupRolesIncludingNewOne: IUserGroupRoleByUserGroupId[] = [
      ...formik.values.formikUserGroupRolesByGroupId,
      {
        groupId: newItem.id,
        roles: [],
      },
    ]

    formik.values.formikUserGroupMembersByGroupId = allUserGroupMembersIncludingNewOne
    formik.values.formikUserGroupRolesByGroupId = allUserGroupRolesIncludingNewOne
    setAddUserGroupModalOpen(false)
  }

  const handleContinueEdit = () => {
    setOpenConfirmDialog(false)
    formik.values.formikSelectedNextUserGroupId = undefined
    formik.values.formikSelectedUserGroupId = formikSelectedUserGroupId
    setRowSelection({ [formikSelectedUserGroupId]: true })
  }

  const handleDiscardChanges = () => {
    setOpenConfirmDialog(false)
    formik.setFieldValue('formikSelectedUserGroupId', undefined)

    // Get formikUserGroups from formik values
    const formikUserGroups: Partial<IUserGroup>[] = formik.values.formikUserGroups || []

    // Get formikNewUserGroups from formik values
    const formikNewUserGroups: Partial<IUserGroup>[] = formik.values.formikNewUserGroups || []
    const formikOriginalUserGroup = formik.values.formikOriginalUserGroup
    const formikOriginalUserGroups = formik.values.formikOriginalUserGroups
    const formikOriginalUserGroupMemberByGroupId =
      formik.values.formikOriginalUserGroupMemberByGroupId
    const formikOriginalUserGroupRoleByGroupId = formik.values.formikOriginalUserGroupRoleByGroupId

    // Remove new groups from formikUserGroups
    const newUserGroups = formikUserGroups
      .filter((group) => !formikNewUserGroups.map((newGroup) => newGroup.id).includes(group.id))
      .map((group) => {
        // Reinstate orignal group values
        if (group.id === formikOriginalUserGroup?.id) {
          return formikOriginalUserGroup
        }
        return group
      })

    // Remove new members from formikUserGroupMembersByGroupId
    const newUserGroupMembersByGroupId = formik.values.formikUserGroupMembersByGroupId.map(
      (group) => {
        // Reinstate orignal group values
        if (group.groupId === formikOriginalUserGroupMemberByGroupId?.groupId) {
          return formikOriginalUserGroupMemberByGroupId
        }
        return group
      }
    )

    // Remove new roles from formikUserGroupRolesByGroupId
    const newUserGroupRolesByGroupId = formik.values.formikUserGroupRolesByGroupId.map((group) => {
      // Reinstate orignal group values
      if (group.groupId === formikOriginalUserGroupRoleByGroupId?.groupId) {
        return formikOriginalUserGroupRoleByGroupId
      }
      return group
    })

    // Save new groups to formik values
    formik.setFieldValue('formikUserGroups', newUserGroups)
    formik.setFieldValue('formikUserGroupMembersByGroupId', newUserGroupMembersByGroupId)
    formik.setFieldValue('formikUserGroupRolesByGroupId', newUserGroupRolesByGroupId)

    // Reset other values
    formik.values.formIsDirty = false
    formik.setFieldValue('formikNewUserGroups', undefined)
    formik.setFieldValue('formikUserGroupBeingEdited', undefined)
    formik.setFieldValue('formikOriginalUserGroup', undefined)
    formik.setFieldValue('formikSelectedUserIdsByGroupId', undefined)
    formik.setFieldValue('formikSelectedMemberRoleIdsByGroupId', undefined)
    formik.setFieldValue('formikSelectedUserIdsByGroupId', undefined)
    formik.setFieldValue('formikRolesLastMarkedForDeletion', undefined)
  }

  interface ITable {
    row: MRTRow<IUserGroup>
    table: MRTTableInstance<IUserGroup>
  }

  const [rowToEdit, setRowToEdit] = useState<ITable | undefined>(undefined)

  return (
    <>
      <ConfirmDialog
        open={OpenConfirmDialog}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogMessageContent)}
        continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
        discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
        onContinueEdit={handleContinueEdit}
        onDiscardChanges={handleDiscardChanges}
      />
      <AddItemModal
        columns={(columns as any[]).filter((column) =>
          ['name', 'description'].includes(column.accessorKey ?? '')
        )}
        onClose={() => setAddUserGroupModalOpen(false)}
        onSubmit={handleAddNewUserGroup}
        open={addUserGroupModalOpen}
        dialogTitle={formatMessage(messages.addUserGroupTitle)}
      />
      <MRTDataGrid
        heading={messages.userGroupTableHeading}
        columns={columns}
        data={data}
        handleAddItem={handleAddItem}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading, rowSelection }}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => {
          const { httpAction, externalId } = row.original as IExtendedUserGroup
          const markedForDeletion = httpAction === HttpAction.DELETE
          const deletable = externalId === undefined
          const userGroups = data.map((userGroup) => userGroup)

          return (
            <>
              <Tooltip arrow placement='left' title='Edit'>
                <IconButton color='info' onClick={() => setRowToEdit({ row, table })}>
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip arrow placement='right' title='Delete'>
                <IconButton
                  color='info'
                  disabled={!deletable}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    handleDelete(row as unknown as MRTRow<IExtendedUserGroup>, userGroups)
                  }}
                >
                  {!markedForDeletion ? <Delete /> : <Undo color='info' />}
                </IconButton>
              </Tooltip>
            </>
          )
        }}
      />
    </>
  )
}
