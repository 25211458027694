import { Grid } from '@mui/material';
import { FieldArray } from 'formik';
import { AddItemAction } from '../../../components/Buttons/AddItemAction';
import { TableSubTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import { PortPass } from '../../types';
import { PortPassesCard } from '../Cards/PortPassesCard';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';

const defaultPortPass = {
    portName: '',
} as const;

interface PortPassesProps {
    disabled?: boolean;
}

export function PortPasses({
    disabled
}: PortPassesProps) {

    const { values, setFieldValue } = useAssetManagementFormContext();
    const { portPasses = [] } = values;

    return (
        <Grid spacing={1}
            container
            sx={{
                background: 'rgb(243, 246, 249)',
                padding: '20px',
            }}
        >
            <FieldArray
                name="portPasses"
                render={(arrayHelpers) => {

                    const onAddCard = () => {
                        arrayHelpers.push({ ...defaultPortPass })
                    }

                    const onRemoveCard = (itemIndex: number) => {
                        arrayHelpers.remove(itemIndex)
                    }
                    const onPortPassChange = (itemIndex: number, prop: string, value: PortPass[keyof PortPass]) => {
                        setFieldValue(`portPasses.${itemIndex}.${prop}`, value);
                    }

                    return (
                        <>
                            <Grid item xs={12}>
                                <TableSubTitleTypography>Port Passes</TableSubTitleTypography>
                            </Grid>
                            {portPasses && portPasses.length > 0 ?
                                portPasses.map((portPass, index) => (
                                    <Grid item key={portPass.portName}>
                                        <PortPassesCard
                                            index={index}
                                            portPass={portPass}
                                            onAdd={disabled ? undefined : onAddCard}
                                            onRemove={disabled ? undefined : onRemoveCard}
                                            onChange={onPortPassChange}
                                            disabled={disabled}
                                        />
                                    </Grid>)
                                ) : (<>

                                    <Grid item xs={12}>
                                        <AddItemAction onClick={onAddCard} />
                                    </Grid>

                                </>)
                            }
                        </>)
                }
                }
            />
        </Grid >
    );
}
