import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import AddButton from '../../../components/Buttons/AddButton'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../components/Typographies/TableTitleTypography'
import ConditionalRender from '../../Shipments/components/EditShipment/Components/ConditionalRender'
import { fetchAllCountriesSelector } from '../../Shipments/selectors'
import { IHsLocalTariff } from '../types'
import HsLocalTariffModal from './HsLocalTariffModal'
import { useProductCategoryFormContext } from './hooks/useProductCategoryFormContext'

type HsLocalTariffTableProps = {
  isDisabled: boolean
}

const HsLocalTariffTable = ({ isDisabled = false }: HsLocalTariffTableProps) => {
  const { values, errors, setFieldValue } = useProductCategoryFormContext()
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []

    const columns = useMemo<MRTColumnDef<IHsLocalTariff>[]>(() => {
        return [
            {
                accessorKey: 'description',
                header: 'Description',
            },
            {
                accessorKey: 'classificationCode',
                header: 'HS Local Classification Code (optional)',
            },
            {
                accessorFn: (row) => {
                    return countries.find(country => country.id === row.countryId)?.name
                },
                header: 'Country',
            },
            {
                accessorFn: (row) => {
                    return `${row.tariff} %`
                },
                header: 'Tariff (%)',
            },
        ]
    }, [])

  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [seletedTarrifId, setSelectedTarrifId] = useState(-1)

  const getContactToEdit = useCallback(() => {
    if (seletedTarrifId > -1) {
      return values.hsLocalTariffs[seletedTarrifId]
    }
    return undefined
  }, [values.hsLocalTariffs, seletedTarrifId])
  return (
    <Box sx={{ pt: '32px', pb: '32px', width: '100%' }}>
      <HsLocalTariffModal
        isOpen={createOpen}
        handleCancel={function (): void {
          setCreateOpen((pv) => !pv)
        }}
        handleSubmit={(hsLocalTarrif) => {
          setFieldValue('hsLocalTariffs', [...values.hsLocalTariffs, hsLocalTarrif])
          setCreateOpen((pv) => !pv)
          setSelectedTarrifId(-1)
        }}
      />
      <HsLocalTariffModal
        isOpen={editOpen}
        handleCancel={function (): void {
          setEditOpen((pv) => !pv)
        }}
        hsLocalTariffToEdit={getContactToEdit()}
        handleSubmit={(hsLocalTarrif) => {
          setFieldValue(`hsLocalTariffs.${seletedTarrifId}`, hsLocalTarrif)
          setEditOpen((pv) => !pv)
          setSelectedTarrifId(-1)
        }}
      />
      <HsLocalTariffModal
        isOpen={viewOpen}
        hsLocalTariffToEdit={getContactToEdit()}
        handleCancel={function (): void {
          setViewOpen((pv) => !pv)
        }}
      />

      <MRTDataGridv2
        // state={{ isLoading: isLoading }}
        leftHeadingComponent={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TableTitleTypography sx={{ fontSize: '20px' }}>
              Country-specific import tarrifs
            </TableTitleTypography>
          </Box>
        }
        rightHeadingComponent={
          <ConditionalRender condition={!isDisabled}>
            <AddButton
              disabled={!values.hsSubheadingId}
              text={'New'}
              onClick={() => setCreateOpen(true)}
            />
          </ConditionalRender>
        }
        columns={columns}
        data={values.hsLocalTariffs}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions={!isDisabled}
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              {!isDisabled && (
                <>
                  <Link
                    underline='none'
                    color='secondary'
                    key={row.id}
                    onClick={() => {
                      setSelectedTarrifId(row?.index)
                      setEditOpen(true)
                    }}
                    sx={{
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  >
                    Edit
                  </Link>
                  <Typography color='secondary'>/</Typography>
                </>
              )}
              {
                <Link
                  underline='none'
                  color='secondary'
                  key={row.id}
                  onClick={() => {
                    setSelectedTarrifId(row?.index)
                    setViewOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  View
                </Link>
              }
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default HsLocalTariffTable
