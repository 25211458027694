import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { MRT_Row as MRTRow } from 'material-react-table';
import { IAssetType } from '../../types';
import { FieldInputProps } from 'formik';
import { useAssetTypeMrtFormContext } from '../hooks/useAssetTypeMrtFormContext';
import { UserTypesAutocomplete } from '../fields/UserTypesAutocomplete';
import { SubType } from '../../../../../../types/common';
import { SubmitButton } from '../buttons/SubmitButton';

interface DeleteAssetTypeMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetType) => void
  dialogTitle: string
  row: MRTRow<Partial<IAssetType>>;
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function DeleteAssetTypeMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  row,
  columnDef,
  loading,
}: DeleteAssetTypeMrtModalProps){

  const fieldName = `assetTypes.${row.index}`;
  const { getFieldProps } = useAssetTypeMrtFormContext()
  const field = getFieldProps(fieldName) as FieldInputProps<IAssetType>;

  const isSystemDefined = field.value.subType === SubType.SystemDefined;


  const submit = () => {
    onSubmit(field.value);
  }

  const disabled = true;
  
  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
             <TextField
                label={columnDef['name']}
                name="name"
                value={field.value.name}
                defaultValue={field.value.name}
                disabled={disabled}
              />
              <FormGroup aria-label="position">
                <FormControlLabel
                    value={field.value.enabled}
                    control={
                          <Switch 
                            color="primary" 
                            checked={field.value.enabled} 
                            value={field.value.enabled}
                            disabled={disabled}
                          />
                    }
                    label={columnDef['enabled']}
                  />
                <FormControlLabel
                    value={field.value.isMotorized}
                    label={columnDef['isMotorized']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={field.value.isMotorized} 
                        value={field.value.isMotorized}
                        disabled={disabled}
                      />
                }
                  />
                <FormControlLabel
                    value={field.value.requiresDriver}
                    label={columnDef['requiresDriver']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={field.value.requiresDriver} 
                        value={field.value.requiresDriver}
                        disabled={disabled}
                      />
                }
                  />
                <FormControlLabel
                    value={field.value.sealable}
                    label={columnDef['sealable']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={field.value.sealable} 
                        value={field.value.sealable}
                        disabled={disabled}
                      />
                  } 
                />
                <FormControlLabel
                    value={field.value.sealable}
                    label={columnDef['carriesPayload']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={field.value.carriesPayload} 
                        value={field.value.carriesPayload}
                        disabled={disabled}
                      />
                    }
                />
              </FormGroup>
              <UserTypesAutocomplete 
                  defaultValue={field.value.userTypes} 
                  label={columnDef['userTypes']}
                  disabled={disabled}
              />
          </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
         <SubmitButton 
            btnText='Delete'
            btnColor='error' 
            onClick={submit} 
            disabled={isSystemDefined} 
            loading={loading} 
          />
      </DialogActions>
    </Dialog>
  )
}
