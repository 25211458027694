import CloseIcon from '@mui/icons-material/Close'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent,
} from '@mui/material'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { cloneDeep } from 'lodash'
import { useState, type DragEvent } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { useAppSelector } from '../../../../app/hooks'
import { IDropDownItem } from '../../../../app/types'
import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { assetConfigurationsByTransportModeId } from '../../../Transport/selectors'
import {
  fetchAllPickupConsigneeSelector,
  fetchAllPickupLocationsSelector,
  fetchAllTemperatureRangesSelector,
  fetchAllTemperatureUnitsSelector,
  fetchAllTransportModesSelector
} from '../../selectors'
import { IAssetRequest, ILoad, IShipment } from '../../types'
import { handleMapLoad, handleRemoveLoadMap } from '../../utils'
import TemperatureSetting from './TemperatureSetting'
import TransportDetail from './TransportDetail'
import messages from './messages'
import { AssetRequestAccordion } from './styles'


export interface AssetRequestAccardionProps {
  formik: FormikProps<IShipment>
  assetRequestIndex: number
  assetRequest: IAssetRequest
  isVirtualLoadMode: boolean
  disabled?: boolean
  viewMode?: boolean
  handleRemoveAssetRequest: (index: number) => void
  handleSwitchLoadChanges: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ) => void
  handleSelectLoadChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
  handleFormDropDownChanges: (e: SelectChangeEvent<number>, name: string, newValue: any) => void
}

const AssetRequestAccardion = (props: AssetRequestAccardionProps) => {
  const {
    formik,
    assetRequestIndex,
    assetRequest,
    isVirtualLoadMode,
    disabled,
    viewMode,
    handleRemoveAssetRequest,
    handleSwitchLoadChanges,
    handleSelectLoadChanges,
    handleFormDropDownChanges,
  } = props
  const { formatMessage } = useIntl()
  const [isCopyMode, setIsCopyMode] = useState(false)
  const [arForCopy, setArForCopy] = useState(1)
  const [isExpanded, setIsExpanded] = useState(true)
  const tempUnitItems = useAppSelector(fetchAllTemperatureUnitsSelector)
  const temperatureRangesItems = useAppSelector(fetchAllTemperatureRangesSelector.data) ?? []
  const pickUpLocations = useAppSelector(fetchAllPickupLocationsSelector)
  const consigneeLocations = useAppSelector(fetchAllPickupConsigneeSelector)

  // const assetCategoryRows = useAppSelector(fetchSortedAssetCategoriesByTransportModeSelector)

  const transportModesIsFetching = useAppSelector(fetchAllTransportModesSelector.isFetching)
  const transportModes = useAppSelector(fetchAllTransportModesSelector.data) ?? []

  const assetConfigurations = useAppSelector(state => assetConfigurationsByTransportModeId(state, formik.values.assetRequests[assetRequestIndex].transportModeId))

  const handleDrop = (event: DragEvent, targetIndex: number): void => {
    event.preventDefault()
    const incomingData = event.dataTransfer.getData('string')
    if ((incomingData?.substring(0, 5) ?? '') === 'LOAD_') {
      const incomingLoadIndex = Number(incomingData.substring(5))
      const incomingLoad = formik.values.loads[incomingLoadIndex]
      const target = formik.values.assetRequests[targetIndex]

      handleMapLoad(formik, incomingLoad.id, target.id, null)
    }
  }
  const handleDragOver = (event: DragEvent): void => {
    event.preventDefault()
  }
  const removeLoad = (loadId: number, assetRequestId: number): void => {
    handleRemoveLoadMap(formik, loadId, assetRequestId, null)
  }

  const handleChangeAssetConfig = (e: any, name: string, newValue: number) => {
    formik.setFieldValue(name, newValue)
  }

  const handleCopyAssetRequest = (assetRequestIndex: number, count: number): void => {
    let lastIndex = formik.values.assetRequests.slice(-1)[0].id - 1
    let lastDisplayOrder = formik.values.assetRequests.slice(-1)[0].displayOrder
    let lastLoadIndex = formik.values.loads.slice(-1)[0].id - 1
    const newAssetRequests = []
    const newVirtualLoads = []
    for (let i = 0; i < count; i++) {
      lastDisplayOrder++
      const copiedAssetRequest = cloneDeep(formik.values.assetRequests[assetRequestIndex])
      copiedAssetRequest.id = lastIndex
      copiedAssetRequest.loadsId = []
      copiedAssetRequest.shipmentId = formik.values.id ?? -1
      copiedAssetRequest.displayOrder = lastDisplayOrder
      const loadForCopyIndex = formik.values.loads.findIndex(
        (l) => l.id === assetRequest.loadsId[0]
      )
      const loadForCopy = formik.values.loads[loadForCopyIndex]
      newAssetRequests.push(copiedAssetRequest)
      const initialLoadPaylaod = {
        id: lastLoadIndex,
        value: 1,
        palletDetails: [],
        isDefined: false,
        quantity: 1,
        displayOrder: -1,
        shipmentId: formik.values.id,
        temperatureSetting: cloneDeep(loadForCopy.temperatureSetting),
        transportDetail: cloneDeep(loadForCopy.transportDetail),
        description: `Virtual load for asset request ${lastIndex}`,
        currencyId: formik.values.currencyId,
        assetRequestAllocations: [{ assetRequestId: lastIndex, loadId: lastLoadIndex }],
      }
      copiedAssetRequest.loadsId.push(initialLoadPaylaod.id)
      newVirtualLoads.push(initialLoadPaylaod)

      lastIndex--
      lastLoadIndex--
    }
    void formik.setFieldValue('loads', [...formik.values.loads, ...newVirtualLoads])

    void formik.setFieldValue('assetRequests', [
      ...formik.values.assetRequests,
      ...newAssetRequests,
    ])
  }
  const removeAssetRequest = (): void => {
    const loadIndex = formik.values.loads.findIndex((x) => x.id === assetRequest.loadsId[0])
    const loads = [...formik.values.loads]
    loads.splice(loadIndex, 1)
    formik.setFieldValue('loads', loads, true)
    const assetRequests = [...formik.values.assetRequests]
    assetRequests.splice(assetRequestIndex, 1)
    void formik.setFieldValue('assetRequests', assetRequests)
    formik.validateForm()
  }
  const renderTemperatureUnit = (value: number, unitId: number) => {
    return value ? `${value} ${tempUnitItems.find((t) => t.id === unitId)?.name}` : undefined
  }
  const renderRow = (label: string, value: string | number | undefined): JSX.Element => {
    if (value) {
      return (
        <>
          <Grid item xs={6}>
            {label}:
          </Grid>
          <Grid item xs={6}>
            {value}
          </Grid>
        </>
      )
    }
    return <></>
  }
  const RenderTransportDetail = () => {
    if (!isVirtualLoadMode) {
      return
    }
    const loadId = assetRequest?.loadsId?.[0]
    const loadIndex = formik.values.loads.findIndex((x) => x.id === loadId)
    const load = formik.values.loads[loadIndex]

    if (disabled) {
      return (
        <>
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
              {formatMessage(messages.transportDetailTitle)}
            </Divider>
          </Grid>
          {renderRow(
            formatMessage(messages.pickUpDate),
            dayjs(load?.transportDetail?.pickupDate).format('DD/MM/YYYY')
          )}
          {renderRow(
            formatMessage(messages.pickUpLocation),
            pickUpLocations.find((l) => l.id === load?.transportDetail?.pickupLocationId)?.name
          )}
          {renderRow(
            formatMessage(messages.requestedDeliveryLocationStatementLabel),
            load.transportDetail?.deliveryDate
              ? dayjs(load?.transportDetail?.deliveryDate).format('DD/MM/YYYY')
              : undefined
          )}
          {renderRow(
            formatMessage(messages.requestedDeliveryLocationLabel),
            consigneeLocations.find((l) => l.id === load?.transportDetail?.deliveryLocationId)?.name
          )}
          {load && load.temperatureSetting && load.temperatureSetting.isTemperatureControlled && (
            <>
              <Grid item xs={12}>
                <Divider
                  textAlign='left'
                  sx={{ mt: 0.5, ml: 2, pt: 2, pb: 2 }}
                  color='text.secondary'
                >
                  {formatMessage(messages.temperatureSettings)}
                </Divider>
              </Grid>
              {renderRow(
                formatMessage(messages.temperatureRange),
                temperatureRangesItems.find(
                  (l) => l.id === load.temperatureSetting?.temperatureRangeId
                )?.name
              )}
              {renderRow(
                formatMessage(messages.setPoint),
                renderTemperatureUnit(
                  load.temperatureSetting.setPoint,
                  load.temperatureSetting.temperatureUnitId
                )
              )}
              {renderRow(
                formatMessage(messages.lowerThresholdWarning),
                renderTemperatureUnit(
                  load.temperatureSetting.lowerThresholdWarning,
                  load.temperatureSetting.temperatureUnitId
                )
              )}
              {renderRow(
                formatMessage(messages.lowerThresholdCritical),
                renderTemperatureUnit(
                  load.temperatureSetting.lowerThresholdCritical,
                  load.temperatureSetting.temperatureUnitId
                )
              )}
              {renderRow(
                formatMessage(messages.upperThresholdWarning),
                renderTemperatureUnit(
                  load.temperatureSetting.upperThresholdWarning,
                  load.temperatureSetting.temperatureUnitId
                )
              )}
              {renderRow(
                formatMessage(messages.upperThresholdCritical),
                renderTemperatureUnit(
                  load.temperatureSetting.upperThresholdWarning,
                  load.temperatureSetting.temperatureUnitId
                )
              )}
            </>
          )}
        </>
      )
    }

    return loadIndex > -1 ? (
      <TransportDetail
        formik={formik}
        index={loadIndex}
        handleSelectLoadChanges={handleSelectLoadChanges}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        load={formik.values.loads[loadIndex]}
        disabled={disabled}
      />
    ) : (
      <></>
    )
  }

  const RenderTemperatureSettings = () => {
    if (!isVirtualLoadMode) {
      return
    }
    const loadId = assetRequest.loadsId[0]
    const loadIndex = formik.values.loads.findIndex((x) => x.id === loadId)

    return !disabled && loadIndex > -1 ? (
      <TemperatureSetting
        formik={formik}
        index={loadIndex}
        handleSelectLoadChanges={handleFormDropDownChanges}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        load={formik.values.loads[loadIndex]}
        path={`loads.${loadIndex}`}
        disabled={disabled}
      />
    ) : (
      <></>
    )
  }
  const RenderLoad = (
    loadIndex: number,
    load: ILoad | undefined,
    removeLoad: (loadId: number, assetRequestId: number) => void,
    assetRequest: IAssetRequest
  ): JSX.Element => {
    return (
      <Grid item key={loadIndex} xs={12} sx={{ paddingTop: '8px' }}>
        <Box
          display='flex'
          justifyContent='start'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '3px',
            margin: '2px',
          }}
        >
          {!disabled && (
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                load && removeLoad(load.id, assetRequest.id)
              }}
              sx={{ color: 'red', cursor: 'pointer' }}
            />
          )}
          Load #{load?.displayOrder}: {load?.quantity} {load?.description ?? load?.productId}
        </Box>
      </Grid>
    )
  }
  const handleChangeAssignmentDropDown = (event: SelectChangeEvent<IDropDownItem>) => {
    const {
      target: { value },
    } = event
    handleMapLoad(formik, +value, assetRequest.id, null)
  }

  const RenderAssignmentDropDown = (): JSX.Element => {
    const notSelectedLoads = formik.values.loads
      .filter((l) => l.isDefined)
      .reduce((acc, curr) => {
        if (!assetRequest?.loadsId || assetRequest?.loadsId.length === 0) {
          acc.push({ id: curr.id, name: `Load # ${curr.displayOrder}` })
        }
        if (
          assetRequest?.loadsId &&
          assetRequest?.loadsId.length > 0 &&
          !assetRequest?.loadsId?.some((c) => c === curr.id)
        ) {
          acc.push({ id: curr.id, name: `Load # ${curr.displayOrder}` })
        }
        return acc
      }, [] as IDropDownItem[])

    const items = [...notSelectedLoads]
    if (items.length === 0 || isVirtualLoadMode) {
      return <></>
    }
    return (
      <Grid item xs={12}>
        <Box sx={{ paddingTop: '16px' }}>
          <FormControl fullWidth>
            <InputLabel>Assign load</InputLabel>
            <Select
              label='Assignments'
              onChange={(e: SelectChangeEvent<IDropDownItem>) => {
                handleChangeAssignmentDropDown(e)
              }}
            >
              {items.map((item) => (
                <MenuItem key={item?.id ?? -1} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    )
  }
  const getError = (propertyName: string): boolean => {
    const touched =
      formik.touched.assetRequests &&
      formik.touched.assetRequests[assetRequestIndex] &&
      getProperty(formik.touched.assetRequests[assetRequestIndex], propertyName)
    const errorMessage =
      formik.errors.assetRequests &&
      formik.errors.assetRequests[assetRequestIndex] &&
      (getProperty(
        formik.errors.assetRequests[assetRequestIndex] as unknown as IAssetRequest,
        propertyName
      ) as any)
    return touched && errorMessage
  }

  return (
    <AssetRequestAccordion
      draggable
      defaultExpanded={disabled ? false : true}
      key={assetRequestIndex}
      onDragOver={handleDragOver}
      onDragStart={(event: DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('string', `ASSETREQUEST_${assetRequest.id}`)
      }}
      onDrop={(event: any) => {
        handleDrop(event, assetRequestIndex)
      }}
      onChange={(event: any, expanded: boolean) => {
        setIsExpanded(expanded)
      }}
      TransitionProps={{ unmountOnExit: true }}
      data-accordion={`assetRequest${assetRequestIndex}`}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightIcon />}
        sx={{ flexDirection: 'row-reverse' }}
      >
        <Grid container>
          <Grid item container xs={4} direction='column' justifyContent='center'>
            <Typography>
              {formatMessage(messages.assetRequest)} # {assetRequestIndex + 1}
            </Typography>
          </Grid>
          {!disabled && (
            <Grid item xs={8}>
              <Box display='flex' justifyContent='flex-end'>
                {isCopyMode ? (
                  <Box display='flex' justifyContent='flex-end'>
                    <NumericFormat
                      customInput={TextField}
                      decimalScale={0}
                      label='How many?'
                      sx={{ paddingRight: '16px' }}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                      onValueChange={(vals) => {
                        setArForCopy(vals.floatValue ?? 0)
                      }}
                      value={arForCopy}
                      InputLabelProps={{ shrink: true }}
                    />
                    <IconButton
                      size='large'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleCopyAssetRequest(assetRequestIndex, arForCopy)
                        setArForCopy(0)
                        setIsCopyMode(false)
                      }}
                    >
                      <DoneIcon color='success' />
                    </IconButton>
                    <IconButton
                      size='large'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setArForCopy(0)
                        setIsCopyMode(false)
                      }}
                    >
                      <CloseIcon color='error' />
                    </IconButton>
                  </Box>
                ) : (
                  <Fab
                    aria-label='copy'
                    color='primary'
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setIsCopyMode(true)
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <CopyIcon />
                  </Fab>
                )}
                <Fab
                  aria-label='remove'
                  color='info'
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    const hasVirtualLoad =
                      assetRequest.loadsId &&
                      assetRequest.loadsId.length === 1 &&
                      !formik.values.loads.find((l) => l.id === assetRequest.loadsId[0])?.isDefined
                    if (isVirtualLoadMode || hasVirtualLoad) {
                      removeAssetRequest()
                    } else {
                      handleRemoveAssetRequest(assetRequestIndex)
                    }
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <DeleteIcon />
                </Fab>
              </Box>
            </Grid>
          )}
          {(formik.errors.assetRequests?.[assetRequestIndex] as unknown as IAssetRequest)
            ?.loadsId && (
              <Grid item xs={12} sx={{ paddingTop: '12px' }}>
                <Typography color={'error'}>
                  {
                    (formik.errors.assetRequests?.[assetRequestIndex] as unknown as IAssetRequest)
                      ?.loadsId
                  }
                </Typography>
              </Grid>
            )}
          {!isExpanded &&
            !isVirtualLoadMode &&
            (assetRequest.loadsId?.length ?? 0) > 0 &&
            assetRequest.loadsId.map((loadId, loadIndex) => {
              const load = formik.values.loads.find((l) => l.id === loadId)
              if (load && load.isDefined) {
                return RenderLoad(loadIndex, load, removeLoad, assetRequest)
              }
            })}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={disabled ? 0 : 4}>
          {!disabled ? (
            <Grid item xs={12}>
              <NumericFormat
                id={`assetRequests.${assetRequestIndex}.quantity`}
                name={`assetRequests.${assetRequestIndex}.quantity`}
                customInput={TextField}
                fullWidth={true}
                decimalScale={3}
                allowNegative={false}
                label={formatMessage(messages.assetRequestQuantity)}
                multiline
                sx={{ width: '100%', paddingBottom: '10px' }}
                onBlur={formik.handleBlur}
                onValueChange={(vals) => {
                  formik.setFieldValue(
                    `assetRequests.${assetRequestIndex}.quantity`,
                    vals.floatValue
                  )
                }}
                value={formik.values.assetRequests?.[assetRequestIndex].quantity}
                helperText={getError('quantity')}
                error={Boolean(getError('quantity'))}
                inputProps={{ maxLength: 11 }}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
              />
            </Grid>
          ) : (
            <>
              {renderRow(
                formatMessage(messages.assetRequestQuantity),
                formik.values.assetRequests?.[assetRequestIndex].quantity
              )}
            </>
          )}

          {!disabled ? (
            <>
              <Grid item xs={12}>
                <FormDropdown
                  id={`assetRequests.${assetRequestIndex}.transportModeId`}
                  items={transportModes}
                  label={formatMessage(messages.transportMode)}
                  onChange={(e, name, newValue) => {
                    formik.setFieldValue(name, newValue)
                  }}
                  sx={{ margin: '4px 0' }}
                  error={Boolean(
                    (formik.errors.assetRequests?.[assetRequestIndex] as unknown as IAssetRequest)
                      ?.transportModeId
                  )}
                  disabled={transportModesIsFetching}
                  value={formik.values.assetRequests[assetRequestIndex].transportModeId}
                />
              </Grid>
              <Grid item xs={12}>
                <FormDropdown
                  id={`assetRequests.${assetRequestIndex}.assetConfigurationId`}
                  items={assetConfigurations}
                  label={formatMessage(messages.assetConfigurationName)}
                  onChange={handleChangeAssetConfig}
                  sx={{ margin: '4px' }}
                  error={Boolean(
                    (formik.errors.assetRequests?.[assetRequestIndex] as unknown as IAssetRequest)
                      ?.assetConfigurationId
                  )}
                  value={formik.values.assetRequests[assetRequestIndex].assetConfigurationId}
                  disabled={disabled || assetRequest.transportModeId < 1}
                />
              </Grid>
            </>
          ) : (
            <>
              {renderRow(
                formatMessage(messages.assetConfigurationName),
                assetConfigurations.find(
                  (ac) =>
                    ac.id === formik.values.assetRequests[assetRequestIndex].assetConfigurationId
                )?.name
              )}
            </>
          )}

          {isVirtualLoadMode && RenderTransportDetail()}
          {isVirtualLoadMode && RenderTemperatureSettings()}
        </Grid>
        {isExpanded &&
          !isVirtualLoadMode &&
          (assetRequest.loadsId?.length ?? 0) > 0 &&
          assetRequest.loadsId.map((loadId, loadIndex) => {
            const load = viewMode
              ? formik.values.loads[loadIndex]
              : formik.values.loads.find((l) => l.id === loadId)
            if (load && load.isDefined) {
              return RenderLoad(loadIndex, load, removeLoad, assetRequest)
            }
          })}
        {!disabled && RenderAssignmentDropDown()}
      </AccordionDetails>
    </AssetRequestAccordion>
  )
}

export default AssetRequestAccardion
