import { combineReducers } from '@reduxjs/toolkit'
import { fetchAllCustomerSettingsCurrenciesReducers } from './CurrencySettings/slice'
import { CUSTOMER_SETTINGS_ALL_CURRENCIES_REDUCER_NAME } from './CurrencySettings/consts'
import {
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME,
  CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME,
} from '../CommonSettings/UserSettings/User/consts'
import {
  fetchAllCustomerSettingsUserByCustomerIdReducers,
  postUserByCustomerIdReducers,
} from '../CommonSettings/UserSettings/User/slice'
import { CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME } from '../CommonSettings/UserGroupSettings/UserGroups/consts'
import { fetchAllCustomerSettingsUserGroupByCustomerIdReducers } from '../CommonSettings/UserGroupSettings/UserGroups/slice'
import { COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME } from '../CommonSettings/UserGroupSettings/UserRoleAllocation/Role/consts'
import { fetchAllRolesReducers } from '../CommonSettings/UserGroupSettings/UserRoleAllocation/Role/slice'

export const customerSettingsReducers = combineReducers({
  [CUSTOMER_SETTINGS_ALL_CURRENCIES_REDUCER_NAME]: fetchAllCustomerSettingsCurrenciesReducers,
  [CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME]: fetchAllCustomerSettingsUserByCustomerIdReducers,
  [CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME]:
    fetchAllCustomerSettingsUserGroupByCustomerIdReducers,
  [CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME]: postUserByCustomerIdReducers,
  [COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME]: fetchAllRolesReducers,
})
