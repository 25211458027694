import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../../../app/axios/omnialog'
import { ISecurityPrincipal } from './types'

export interface ISecurityPrincipalPayload {
  tenantId?: number
  customerId?: number
  securityPrincipal?: ISecurityPrincipal
}

export const fetchAllSecurityPrincipals = (): Promise<AxiosResponse<any, any> | any> => {
  return usersAuthorizedFetch.get('/security-principal')
}
