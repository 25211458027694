import AnyChart from 'anychart-react'
import { useShipmentByIdQuery } from '../app/redux-fetch/apiQuery'

const dataDashboard = [
  { name: 'Defaf Owned', value: 14 },
  { name: 'Global Star Transportation ESt.', value: 205 },
  { name: 'Het. Transportation Est.', value: 41 },
  { name: 'Kanoo Terminal Services LTD', value: 1 },
  { name: 'Mohammed Shaaban Bahkali', value: 7 },
]

const complexSettings = {
  width: 800,
  height: 600,
  contextMenu: true,
  type: 'pie',
  data: dataDashboard,
  labels: { position: 'outside', format: '{%name} - {%value}{scale:(1000000)(1)|( mln)}' },
  title: 'Rental Assigned Job Stats',
  legend: {
    background: 'lightgreen 0.4',
    padding: 0,
  },
  exports: {
    path: 'https://cdn.anychart.com/3rd/', // path to dependencies
    enabled: true, // clientside export enabled
    fallback: true, // fallback to server side exporting
  },
}

const Dashboard = () => {
  return <AnyChart {...complexSettings} />
}

export default Dashboard
