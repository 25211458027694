import { Box, Grid, TextField } from '@mui/material'
import React, { type ReactElement } from 'react'

interface IValuePairControlProps {
  columns?: number
  leftId: string
  rightId: string
  leftError?: any
  rightError?: any
  fieldNameLeft?: string
  fieldNameRight?: string
  handleChangeOfLeft: (name: string, newValue: string) => void
  handleChangeOfRight: (name: string, newValue: string) => void
  handleOnBlur: React.FocusEventHandler<HTMLDivElement | HTMLTextAreaElement> | undefined
  labelLeft: string
  labelRight: string
  mode?: string
  leftValue: number | string | undefined
  rightValue: number | string | undefined
  disabled?: boolean
}

const ValuePairControl = (props: IValuePairControlProps): ReactElement<any, any> => {
  const {
    leftId,
    rightId,
    leftError,
    rightError,
    handleOnBlur,
    handleChangeOfLeft,
    handleChangeOfRight,
    labelLeft,
    labelRight,
    leftValue,
    rightValue,
    disabled,
  } = props
  let { columns, mode } = props

  // Apply defaults.
  if (typeof columns === 'undefined') {
    columns = 4
  }
  if (typeof mode === 'undefined') {
    mode = 'standard'
  }

  // Parse mode.
  let boxFlag = false
  let inGridItemFlag = false
  // Validate mode.
  switch (mode) {
    case 'box':
      boxFlag = true
      break
    case 'boxInGridItem':
      boxFlag = true
      inGridItemFlag = true
      break
    case 'justTheControls':
      break

    case 'standard':
    case 'inGridItem':
    default:
      inGridItemFlag = true
      break
  }

  // The inner content is always the same.
  let renderedContent = (
    <Grid container>
      <Grid item xs={6}>
        <TextField
          fullWidth={true}
          id={leftId}
          name={leftId}
          label={labelLeft}
          value={leftValue}
          onBlur={handleOnBlur}
          onChange={(e) => {
            handleChangeOfLeft(leftId, e.target.value)
          }}
          error={leftError}
          helperText={leftError}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth={true}
          id={rightId}
          name={rightId}
          label={labelRight}
          value={rightValue}
          onBlur={handleOnBlur}
          onChange={(e) => {
            handleChangeOfRight(rightId, e.target.value)
          }}
          error={rightError}
          helperText={rightError}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )

  if (boxFlag) {
    renderedContent = (
      <Box sx={{ border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '3px' }}>
        {renderedContent}
      </Box>
    )
  }

  if (inGridItemFlag) {
    renderedContent = (
      <Grid item xs={columns}>
        {renderedContent}
      </Grid>
    )
  }

  return renderedContent
}

export default ValuePairControl
