import { Box, Grid, TextField } from '@mui/material'
import React, { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'

import FormDropdown, { type DropdownItem } from '../FormDropDown/FormDropdown'


interface IValueUnitControlProps {
  unitId: string
  valueId: string
  columns?: number
  errorValue?: boolean
  errorUnit?: boolean
  handleChangeOfUnit: (name: string, newValue: string) => void
  handleChangeOfValue: (name: string, newValue: number | string | undefined) => void
  handleOnBlur: React.FocusEventHandler<HTMLDivElement | HTMLTextAreaElement> | undefined
  isAllowed?: boolean
  isCurrency?: boolean
  labelUnit: string
  labelValue: string
  mode?: string
  noSelectPrompt?: boolean
  name?: string
  selectedUnitId: number | string
  unitList: DropdownItem[]
  value: number | string
  disabled?: boolean
  sxNumeric?: object
  sxCurrency?: object
  sx?: object
}

const ValueUnitControl = (props: IValueUnitControlProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const {
    unitId,
    valueId,
    errorValue,
    errorUnit,
    handleChangeOfUnit,
    handleChangeOfValue,
    handleOnBlur,
    isAllowed,
    labelUnit,
    labelValue,
    unitList,
    value,
    disabled,
    sx = {},
    sxNumeric = {},
    sxCurrency = {}
  } = props
  let { columns, isCurrency, mode, noSelectPrompt } = props
  const { selectedUnitId } = props
  // Apply defaults.
  if (typeof columns === 'undefined') {
    columns = 4
  }
  if (typeof mode === 'undefined') {
    mode = 'standard'
  }

  if (typeof isCurrency === 'undefined') {
    isCurrency = false
  }

  if (typeof noSelectPrompt === 'undefined') {
    noSelectPrompt = false
  }

  // Parse mode.
  let boxFlag = false
  let inGridItemFlag = false
  // Validate mode.
  switch (mode) {
    case 'box':
      boxFlag = true
      break
    case 'boxInGridItem':
      boxFlag = true
      inGridItemFlag = true
      break
    case 'justTheControls':
      break

    case 'standard':
    case 'inGridItem':
    default:
      inGridItemFlag = true
      break
  }

  // Copy the unit list, and unless suppressed, add default empty value.
  const renderUnitList = [...unitList]

  let renderedContent = (
    <Grid container>
      <Grid item xs={8}>
        {!isCurrency && (
          <NumericFormat
            customInput={TextField}
            fullWidth={true}
            id={valueId}
            name={valueId}
            label={labelValue}
            value={value}
            onBlur={handleOnBlur}
            onValueChange={(vals) => handleChangeOfValue(valueId, vals.floatValue)}
            allowNegative={isAllowed}
            decimalScale={6}
            inputProps={{ maxLength: 22 }}
            thousandSeparator={true}
            error={errorValue}
            sx={{
              ...sxNumeric,
              borderRight: 'none',
            }}
            helperText={errorValue}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        )}
        {isCurrency && (
          <NumericFormat
            customInput={TextField}
            allowNegative={false}
            fixedDecimalScale
            decimalScale={2}
            maxLength={9}
            inputProps={{ maxLength: 22 }}
            fullWidth={true}
            id={valueId}
            name={valueId}
            label={labelValue}
            value={value}
            onBlur={handleOnBlur}
            thousandSeparator={true}
            onValueChange={(vals) => {
              handleChangeOfValue(valueId, vals.floatValue)
            }}
            error={errorValue}
            sx={{
              ...sxNumeric,
              borderRight: 'none',
            }}
            helperText={errorValue}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <FormDropdown
          id={unitId}
          sx={{
            ...sxCurrency,
            borderRight: 'none',
          }}
          label={labelUnit}
          handleOnBlur={handleOnBlur}
          onChange={(e, id, newValue) => {
            handleChangeOfUnit(unitId, newValue)
          }}
          value={selectedUnitId}
          error={errorUnit}
          items={renderUnitList}
          errorText={errorUnit}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )

  if (boxFlag) {
    renderedContent = (
      <Box sx={{ ...sx, border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '3px' }}>
        {renderedContent}
      </Box>
    )
  }

  if (inGridItemFlag) {
    renderedContent = (
      <Grid item xs={columns} sx={sx}>
        {renderedContent}
      </Grid>
    )
  }

  return renderedContent
}

export default ValueUnitControl
