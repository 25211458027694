import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { SnackbarProvider } from 'notistack'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import 'typeface-roboto'
import App from './App'
import { store } from './app/store/store'
import { msalConfig } from './authConfig'
import RequestInterceptor from './components/RequestIntercepter/RequestIntercepter'
import './index.css'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

root.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <IntlProvider locale='en'>
        <RequestInterceptor>
          <Router>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </Router>
        </RequestInterceptor>
      </IntlProvider>
    </Provider>
  </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
