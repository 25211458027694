import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  quantity: {
    id: 'loadSummaryHeader.loadQuantity',
    description: 'Load quantity summary label',
    defaultMessage: 'Quantity: {qty}',
  },
  description: {
    id: 'loadSummaryHeader.description',
    description: 'Load description summary label',
    defaultMessage: 'Description: {desc}',
  },
  palletsQuantity: {
    id: 'loadSummaryHeader.palletsQuantity',
    description: 'Pallets Quantity summary label',
    defaultMessage: 'Pallet(s): {count}',
  },
  pickupDate: {
    id: 'loadSummaryHeader.pickupDate',
    description: 'Pickup Date summary label',
    defaultMessage: 'Pickup date: {date}',
  },
})
