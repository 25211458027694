import { Grid } from '@mui/material'
import React from 'react'

export interface IMultiGridLayoutProps {
  children?: React.ReactNode[]
}
const MultiGridLayout = ({ children }: IMultiGridLayoutProps) => {
  const dataGridLeft = children && children[0]
  const dataGridRight = children && children[1]
  return (
    <Grid container>
      <Grid item xs={12} md={8} key={'datagrid-left'}>
        <>{dataGridLeft}</>
      </Grid>
      <Grid item xs={12} md={4} key={'datagrid-right'}>
        <>{dataGridRight}</>
      </Grid>
    </Grid>
  )
}
export default MultiGridLayout
