import { Button, Grid } from '@mui/material'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import logger from '../../../app/middleware/log'
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery'
import { getAddressObject } from '../../../app/utils'
import PolygonMap from '../../../components/PolygonMap/PolygonMap'
import { SpinnerModalCenter } from '../../../components/Spinner/styles'
import { SectionTitleTypography } from '../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../../features/selectors'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import ContactTable from '../../Contacts/ContactTable'
import { fetchContactTypesThunk } from '../../Contacts/slice'
import { postLocation } from '../../Shipments/api'
import { useNotificationStack } from '../../Shipments/hooks/useNotificationStack'
import { fetchAllCountriesSelector } from '../../Shipments/selectors'
import {
  fetchAllCountriesThunk,
  fetchAllLocationTypesThunk,
  fetchAllTransportModesThunk,
  fetchSystemCurrenciesThunk,
} from '../../Shipments/slice'
import { ApiErrorMsgsType, ICreateLocationByTenantCustomer, IMarker } from '../../Shipments/types'
import AddressDetails from './AddressDetails'
import DefaultValues from './DefaultValues'
import MainInfo from './MainInfo'
import SubmitButton from './SubmitButton'
import messages from './messages'
import { getLocationValidationSchema } from './validation'

const CreateLocation = (): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const { enqueueMsg } = useNotificationStack()

  const { onClose, setBottomComponent } = useSideModalContext()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const [polygons, setPolygons] = useState([])
  const [marker, setMarker] = useState<IMarker>()
  const customerId = loggedInUser?.customerId
  const tenantId = loggedInUser?.tenantId
  const { data: customers, isSuccess: isCustomerLoaded } = useGetCustomersQuery(tenantId ?? -1)
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const initialValues = (): ICreateLocationByTenantCustomer => {
    const initialValues = {
      name: '',
      address: { address1: '', address2: '', address3: '', city: '', state: '', postalCode: '' },
      lat: undefined,
      lng: undefined,
      tenantId,
      customerId,
      locationTypeId: 255, // TODO: Hardcoded for userdefined
      contacts: [],
    }

    return initialValues
  }
  const formik = useFormik<ICreateLocationByTenantCustomer>({
    initialValues: initialValues(),
    enableReinitialize: true,
    validationSchema: getLocationValidationSchema(formatMessage),
    onSubmit: async (values: ICreateLocationByTenantCustomer) => {
      try {
        values.lat = marker?.lat
        values.lng = marker?.lng
        values.geofence = polygons
        await postLocation(values, tenantId, customerId)
        onClose()
      } catch (e: any) {
        logger.error(e)
        enqueueMsg(e.response.data as ApiErrorMsgsType)
      }
    },
  })

  const onHandleDeletePolygon = () => {
    setPolygons([])
    setMarker(undefined)
  }

  const fetchData = useCallback(async () => {
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllLocationTypesThunk())
    dispatch(fetchAllTransportModesThunk())
    dispatch(fetchSystemCurrenciesThunk())
    dispatch(fetchContactTypesThunk(tenantId ?? -1, customerId))
  }, [dispatch, tenantId, customerId])

  useEffect(() => {
    fetchData()
  }, [customerId])

  const handleGeocoder = (geoCoderResult: any) => {
    const parsedAddress = getAddressObject(geoCoderResult.address_components)
    if (parsedAddress?.country) {
      const parsedCountry = countries.find((c) => c.iso2 === parsedAddress?.country)

      if (parsedCountry) {
        formik.setFieldValue('address.countryId', parsedCountry.id)
      }
    } else {
      formik.setFieldValue('address.countryId', 0)
    }
    if (parsedAddress?.city) {
      formik.setFieldValue('address.city', parsedAddress?.city)
    } else {
      formik.setFieldValue('address.city', '')
    }
    if (parsedAddress?.postalCode) {
      formik.setFieldValue('address.postalCode', parsedAddress?.postalCode)
    } else {
      formik.setFieldValue('address.postalCode', '')
    }
    if (parsedAddress?.region) {
      formik.setFieldValue('address.state', parsedAddress?.region)
    } else {
      formik.setFieldValue('address.state', '')
    }
    formik.setFieldValue('address.address1', geoCoderResult.formatted_address)
    formik.setFieldValue('lat', geoCoderResult.geometry.location.lat())
    formik.setFieldValue('lng', geoCoderResult.geometry.location.lng())
  }

  const onHandleDeleteMarker = () => {
    formik.setFieldValue('lat', '')
    formik.setFieldValue('lng', '')

    setMarker(undefined)
  }
  const updateMarker = (coords: any) => {
    if (coords.lat != marker?.lat || coords.lng != marker?.lng) {
      setMarker(!coords.lat || !coords.lng ? undefined : coords)
      if (!polygons || polygons.length == 0) {
        // TODO: pan & zoom to new co-ords
        // panTo(coords)
        // setZoom(13)
      }
    }
  }
  const getCustomers = useCallback(() => {
    return customers ?? []
  }, [customers])


  useEffect(() => {
    setBottomComponent && setBottomComponent(<SubmitButton create={true} formik={formik} />)
    return () => {
      setBottomComponent && setBottomComponent(null)
    }
  }, [])

  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId
  if (!isCustomerLoaded) { return (<SpinnerModalCenter />) }
  return (
    <Grid container spacing={2} sx={{ p: '48px' }}>
      <Grid display='flex' flexDirection='row' gap={4} width='100%'>
        <Grid display='flex' flexDirection='column' width='50%'>
          <MainInfo type={'create'} formik={formik} updateMarker={updateMarker} customers={getCustomers()} isTenantUser={Boolean(isTenantUser)} />
          <DefaultValues formik={formik} />
          <ContactTable formik={formik} />
        </Grid>
        <Grid display='flex' flexDirection='column' width='50%'>
          <Grid container item xs={12} gap={2} justifyContent="space-between" sx={{ mb: '16px' }}>
            <Grid item>
              <SectionTitleTypography >{formatMessage(messages.addressDetails)}</SectionTitleTypography>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                disabled={polygons && polygons.length === 0}
                onClick={onHandleDeletePolygon}
                sx={{ marginRight: '12px' }}
              >
                {formatMessage(messages.deletePolygon)}
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                disabled={!marker?.lat && !marker?.lng}
                onClick={onHandleDeleteMarker}
              >
                {formatMessage(messages.deleteMarker)}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <PolygonMap
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
              defaultCenter={{
                lat: 24.638517161652892,
                lng: 45.84234953431573,
                zoom: 5,
              }}
              marker={marker}
              height={239}
              width='85%'
              polygons={polygons}
              onChangePolygons={setPolygons}
              onChangeMarker={setMarker}
              handleGeocode={handleGeocoder}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressDetails formik={formik} type='create' updateMarker={updateMarker} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateLocation
