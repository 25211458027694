import { defineMessages } from 'react-intl'

const messages = defineMessages({
  memeberSettingsTableHeading: {
    id: 'userRoleAllocation.tabs.memeberSettingsTableHeading',
    description: 'Memeber settings table heading',
    defaultMessage: 'Members',
  },
})

export default messages
