import { Box, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import { useState } from 'react'
import { AssetCategories } from './AssetCategories'
import { AssetConfigurations } from './AssetConfigurations/AssetConfigurations'
import { TabPanel } from './Components/TabPanel'

export const AssetSettings = () => {
  const [activeTab, setActiveTab] = useState(0)
  let theme = useTheme()
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }
  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          <Tabs
            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
            value={activeTab}
            onChange={handleChange}
            centered
            variant='standard'
            scrollButtons={false}
            aria-label='Tenant settings scrollable tabs'
          >
            <Tab label='Asset Categories' />
            <Tab label='ASset Configurations' />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Grid item xs={12}>
          <AssetCategories />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Grid item xs={12}>
          <AssetConfigurations />
        </Grid>
      </TabPanel>
    </>
  )
}

export default AssetSettings
