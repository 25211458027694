import logger from '../../app/middleware/log'
import { fetchShipmentsDataThunk, makeFetchSlice } from '../../app/redux-fetch'
import { AppDispatch } from '../../app/store/store'
import { fetchContactTypes } from './api'
import { APP_CONTACTS_CONTACT_TYPES } from './consts'

const fetchContactTypesSlice = makeFetchSlice(APP_CONTACTS_CONTACT_TYPES)

export const fetchContactTypesSliceReducers = fetchContactTypesSlice.reducer
export const fetchContactTypesSliceActions = fetchContactTypesSlice.actions

export const fetchContactTypesThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchContactTypesSliceActions,
        asyncFunc: () => fetchContactTypes(tenantId, customerId),
      })
    } catch (error) {
      logger.error('Error fetching transport employee', error)
    }
  }
}
