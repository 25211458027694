import { MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { useContext, useState, type ReactElement } from 'react'
import { NavbarContext } from '../AppBarDetail/NavbarContext'
import { Context } from '../Localization/Localization'

interface LanguageType {
  code: string
  label: string
  language: string
  suggested?: boolean
}

const languages: readonly LanguageType[] = [
  { code: 'US', label: 'English', language: 'en-US' },
  // { code: 'GB', label: 'English', language: 'en-GB' },
  {
    code: 'SA',
    label: 'SAU',
    language: 'ar-SA',
    suggested: true,
  },
  // { code: 'FR', label: 'Franche', language: 'fr-CA' },
  // { code: 'IT', label: 'Italian', language: 'it-IT' },
]

const LanguageSelector = (): ReactElement<any, any> => {
  const context = useContext(Context)
  const [lang, setLang] = useState(context.locale)
  const { setMenuPosition } = useContext(NavbarContext)

  lang === 'ar-SA' ? setMenuPosition('right') : setMenuPosition('left')

  const handleChange = (event: SelectChangeEvent): void => {
    if (event.target.value === 'ar-SA') {
      document.dir = 'rtl'
      setMenuPosition('right')
    } else {
      document.dir = ''
      setMenuPosition('left')
    }
    setLang(event.target.value)
    context.selectLanguage(event.target.value)
  }

  return (
    <Select
      aria-label='LanguageSelector'
      labelId='label'
      id='select'
      value={lang}
      onChange={handleChange}
      sx={{
        margin: '0, 20',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }}
    >
      {languages.map((language) => (
        <MenuItem key={language.language} value={language.language}>
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${language.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${language.code.toLowerCase()}.png 2x`}
            alt=''
          />
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguageSelector
