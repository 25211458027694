import { Box, Button } from '@mui/material'
import { FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import messages from './messages'

type SubmitButtonProps = {
  disabled?: boolean
  create: boolean
  delete?: boolean
  formik: any
}

const SubmitButton = ({
  delete: isDelete,
  disabled = false,
  create,
  formik,
}: SubmitButtonProps) => {
  const { formatMessage } = useIntl()
  const { onClose, onDelete } = useSideModalContext()

  const handleSubmit = () => {
    formik.validateForm().then((errors: FormikErrors<any>) => {
      formik.submitForm().finally(() => {
        formik.setTouched(setNestedObjectValues(errors, true))
      })
    })
  }
  return !disabled ? (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {isDelete && (
        <Button color='secondary' variant='contained' onClick={onDelete}>
          {formatMessage(messages.delete)}
        </Button>
      )}
      <Button color='secondary' variant='contained' onClick={handleSubmit}>
        {create ? formatMessage(messages.create) : formatMessage(messages.update)}
      </Button>
      <Button color='secondary' variant='outlined' onClick={onClose}>
        {formatMessage(messages.cancel)}
      </Button>
    </Box>
  ) : (
    <></>
  )
}

export default SubmitButton
