// eslint-disable-next-line camelcase
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { fetchLoggedInCustomerSelector } from '../../selectors'
import { ILoggedInCustomer } from '../../types'
import messages from './messages'

import { Box } from '@mui/material'
import ControlBar from '../../../components/ControlBar/ControlBar'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import { fetchLoggedInCustomerThunk } from '../../slice'
import { NotiStackOptions } from '../../utils'
import { putCustomerDefaultCurrency } from '../api'
import { compareCurrencyAllProps } from '../utils'
import CurrencyMRT from './Currency/CurrencyMRT'
import { ICurrencyRow } from './Currency/types'
import { deleteCurrency, postCurrency, putCurrency } from './api'
import {
  fetchAllCustomerSettingsCurrenciesSelector,
  fetchAllCustomerSettingsSortedCurrenciesSelector,
} from './selectors'
import {
  fetchAllCustomerSettingsCurrenciesActions,
  fetchAllCustomerSettingsCurrenciesThunk,
} from './slice'
import { FormikCurrencySettings, ICurrency, ICurrencySettingsFormValues } from './types'

const CurrencySettings = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const loggedInCustomer =
    useAppSelector(fetchLoggedInCustomerSelector.data) || ({} as ILoggedInCustomer)
  const { id: customerId, defaultCurrencyId, tenantId } = loggedInCustomer

  const [selectedCurrency, setSelectedCurrency] = useState<number>(defaultCurrencyId as number)

  const isFetching = useAppSelector(fetchAllCustomerSettingsCurrenciesSelector.isFetching)

  const allCurrencies = useAppSelector(
    fetchAllCustomerSettingsSortedCurrenciesSelector(defaultCurrencyId)
  )

  const fetchCurrencies = useCallback(async () => {
    dispatch(fetchAllCustomerSettingsCurrenciesThunk(customerId))
  }, [dispatch, customerId])

  useEffect(() => {
    void fetchCurrencies()
  }, [fetchCurrencies])

  useEffect(() => {
    if (!defaultCurrencyId) return
    setSelectedCurrency(defaultCurrencyId)
  }, [defaultCurrencyId])

  const submitForm = async (values: Partial<ICurrencySettingsFormValues>) => {
    formik.setFieldValue('formHasErrors', false)
    const { currencySettings } = values

    const hasCurrencyError =
      currencySettings?.find((currency) => (currency as ICurrencyRow).error) !== undefined

    if (hasCurrencyError) {
      formik.setFieldValue('formHasErrors', true)
      return
    }

    currencySettings?.forEach((currency) => {
      currency.name = currency.name?.trim()
      currency.iso3 = currency.iso3?.trim()
      currency.symbol = currency.symbol?.trim()
      currency.localisedSymbol = currency.localisedSymbol?.trim()

      // Add customer id
      currency.customerId = customerId
    })

    if (isFormDirty(formik)) {
      formik.setSubmitting(true)
      formik.setFieldValue('forHasErrors', false)
    }

    const modifiedCurrencies = getModifiedCurrencies(
      values.currencySettings as ICurrencyRow[],
      values.originalCurrencySettings as ICurrencyRow[]
    )

    const newCurrencies = getNewCurrencies(
      values.currencySettings as ICurrencyRow[],
      values.originalCurrencySettings as ICurrencyRow[]
    )

    const currenciesToUpdate = [...modifiedCurrencies, ...newCurrencies]

    currenciesToUpdate.forEach(async (currency) => {
      if (currency.httpAction === 'POST') {
        currency.id = undefined
        const { iso3 } = currency
        try {
          await postCurrency(currency as unknown as ICurrency)
          enqueueSnackbar(
            formatMessage(messages.currencyEnabledSuccess, { iso3 }),
            NotiStackOptions.success
          )
          return
        } catch (error) {
          enqueueSnackbar(
            formatMessage(messages.currencyEnabledError, { iso3 }),
            NotiStackOptions.error
          )
        }
        return
      }

      if (currency.httpAction === 'DELETE') {
        const { iso3 } = currency
        try {
          await deleteCurrency(currency as unknown as ICurrency)
          enqueueSnackbar(
            formatMessage(messages.currencyDeletedSuccess, { iso3 }),
            NotiStackOptions.success
          )
        } catch (error) {
          enqueueSnackbar(
            formatMessage(messages.currencyDeletedError, { iso3 }),
            NotiStackOptions.error
          )
        }
        return
      }

      const { iso3 } = currency
      try {
        await putCurrency(currency as unknown as ICurrency)
        enqueueSnackbar(
          formatMessage(messages.currencyUpdatedSuccess, { iso3 }),
          NotiStackOptions.success
        )
      } catch (error) {
        enqueueSnackbar(
          formatMessage(messages.currencyUpdatedError, { iso3 }),
          NotiStackOptions.error
        )
      }
    })

    const defaultCurrencyChanged = selectedCurrency !== defaultCurrencyId
    if (defaultCurrencyChanged) {
      const { name } = allCurrencies.find((currency) => currency.id === selectedCurrency) || {}
      try {
        await putCustomerDefaultCurrency({
          ...loggedInCustomer,
          defaultCurrencyId: selectedCurrency,
        })

        enqueueSnackbar(
          formatMessage(messages.defaultCurrencyUpdatedSuccess, { name }),
          NotiStackOptions.success
        )

        dispatch(
          fetchLoggedInCustomerThunk({
            tenantId,
            customerId,
          })
        )
      } catch (error) {
        enqueueSnackbar(
          formatMessage(messages.defaultCurrencyUpdatedError, { name }),
          NotiStackOptions.error
        )
      }
    }

    formik.setSubmitting(false)

    formik.setFieldValue('currencySettings', [])
    void dispatch(fetchAllCustomerSettingsCurrenciesActions.clear())

    setTimeout(() => {
      void dispatch(fetchAllCustomerSettingsCurrenciesThunk(customerId))
    }, 500)
  }

  const initialValues = useMemo<
    FormikCurrencySettings['initialValues']
  >((): ICurrencySettingsFormValues => {
    return {
      defaultCurrencyId,
      selectedCurrency,
      currencySettings: allCurrencies,
      formIsDirty: false,
      formHasErrors: false,
      formSubmissionSuccessful: false,
      selectedCurrencyRow: undefined,
      originalCurrencySettings: allCurrencies,
    }
  }, [allCurrencies, selectedCurrency, defaultCurrencyId])

  const formik = useFormik<ICurrencySettingsFormValues>({
    initialValues,
    onSubmit: (values) => submitForm(values),
    enableReinitialize: true,
  })

  const handleCurrencyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
    newValue: number
  ) => {
    formik.setFieldValue(name, newValue)
    formik.setFieldValue('formIsDirty', isFormDirty(formik))
    setSelectedCurrency(newValue)
  }

  const handleSubmit = () => formik.handleSubmit()

  return (
    <>
      <Box sx={{ pt: '48px' }}>
        <ControlBar formik={formik} isFormDirty={() => isFormDirty(formik)} onSubmit={handleSubmit} />
        <form onSubmit={formik.handleSubmit}>
          {allCurrencies?.length > 0 ? (
            <FormDropdown
              id='selectedCurrency'
              items={allCurrencies.filter((c) => c.enabled) as ICurrency[]}
              label={formatMessage(messages.currencyDropdownTitle)}
              onChange={handleCurrencyChange}
              sx={{ padding: '10px', marginBottom: '20px' }}
              error={false}
              value={selectedCurrency}
            />
          ) : null}

          <CurrencyMRT formik={formik} isLoading={formik.isSubmitting || isFetching} />
        </form>
      </Box>
    </>
  )
}

export default CurrencySettings

export const isFormDirty = (formik: FormikCurrencySettings) => {
  const { currencySettings, originalCurrencySettings, selectedCurrency, defaultCurrencyId } =
    formik.values

  const defaultCurrencyModified =
    selectedCurrency !== undefined && selectedCurrency !== defaultCurrencyId

  const currenciesModified = compare(currencySettings, originalCurrencySettings)

  const newCurrencies = getNewCurrencies(currencySettings, originalCurrencySettings)

  const hasNewCurrencies = newCurrencies.length > 0

  return currenciesModified || hasNewCurrencies || defaultCurrencyModified
}

export function compare(
  currencySettings: ICurrencyRow[],
  originalCurrencySettings: readonly ICurrencyRow[]
) {
  if (currencySettings.length !== originalCurrencySettings.length) return false

  const foundMismatch =
    currencySettings.find((currency) => {
      const foundInOriginalCurrnecies = originalCurrencySettings.find(
        (originalCurrency) => originalCurrency.id === currency.id
      )

      if (!foundInOriginalCurrnecies) return true

      const currenciesMatch = compareCurrencyAllProps(currency, foundInOriginalCurrnecies)
      return !currenciesMatch
    }) !== undefined

  return foundMismatch
}

export function getModifiedCurrencies(
  currencySettings: ICurrencyRow[],
  originalCurrencySettings: readonly ICurrencyRow[]
): ICurrencyRow[] {
  const modifiedCurrencies = currencySettings.reduce((acc, currency) => {
    const originalCurrency = originalCurrencySettings.find((c) => c.id === currency.id)

    if (!originalCurrency) return acc

    const match = compareCurrencyAllProps(currency, originalCurrency)
    if (!match) {
      acc.push(currency)
    }

    return acc
  }, [] as ICurrencyRow[])

  return modifiedCurrencies
}

export function getNewCurrencies(
  currencySettings: ICurrencyRow[],
  originalCurrencySettings: readonly ICurrencyRow[]
) {
  const newCurrencies = currencySettings.reduce((acc, currency) => {
    const originalCurrency = originalCurrencySettings.find((c) => c.id === currency.id)

    if (!originalCurrency) {
      acc.push(currency)
    }

    return acc
  }, [] as ICurrencyRow[])

  return newCurrencies
}
