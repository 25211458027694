import { SubType } from '../../../../../../types/common';
import { IAssetType } from '../../types';

import { updateAssetType as update } from '../../api';

interface Result {
    isSystemDefined: boolean;
    success: boolean;
}

export const updateAssetType = async (
    assetType: IAssetType
): Promise<Result> => {
      const { subType, tenantId } = assetType;
      const updateResult = { isSystemDefined: subType === SubType.SystemDefined, success: true };
      try {
        const payload = { ...assetType, tenantId } as IAssetType
        await update(payload, tenantId as number)
      } catch (error) {
        updateResult.isSystemDefined = false;
      }

      return updateResult;
  }
