import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { MRT_Row as MRTRow } from 'material-react-table';
import { IAssetClass } from '../../types';
import { FieldInputProps } from 'formik';
import { useAssetClassMrtFormContext } from '../hooks/useAssetClassMrtFormContext';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { assetClassTypeSchema } from '../schemas/assetClassTypeSchema';
import { SubType } from '../../../../../../types/common';
import isEqual from 'lodash/isEqual';
import { SubmitButton } from '../../../../../../components/Buttons/SubmitButton';

interface EditAssetClassMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetClass) => void
  dialogTitle: string
  row: MRTRow<Partial<IAssetClass>>;
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function EditAssetClassMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  row,
  columnDef,
  loading,
}: EditAssetClassMrtModalProps) {

  const { formatMessage } = useIntl();
  const fieldName = `assetClasses.${row.index}`;
  const { getFieldProps } = useAssetClassMrtFormContext();
  const field = getFieldProps(fieldName) as FieldInputProps<IAssetClass>;
  const [values, setValues] = useState<IAssetClass>({ ...field.value, ...field.value, isCloneOfSysmtemDefined: row.original.isCloneOfSysmtemDefined });

  const isValid = assetClassTypeSchema(formatMessage).isValidSync(values);
  const isSystemDefined = values.subType === SubType.SystemDefined || field.value.isCloneOfSysmtemDefined;
  const submitIsDisabled = !isValid || isEqual(values, row.original);
  const onChange = (propName: string, value: string | boolean) => {
    setValues((prev) => ({ ...prev, [propName]: value }))
  }

  const submit = () => {
    onSubmit(values);
  }

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          <TextField
            label={columnDef['name']}
            name="name"
            value={values.name}
            defaultValue={values.name}
            onChange={({ target }) => onChange('name', target.value)}
            disabled={isSystemDefined || loading}
          />
          <FormGroup aria-label="position">
            <FormControlLabel
              value={values.enabled}
              control={
                <Switch
                  color="primary"
                  checked={values.enabled}
                  value={values.enabled}
                  onChange={({ target }) => onChange('enabled', target.checked)}
                  disabled={loading}
                />
              }
              label={columnDef['enabled']}
            />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <SubmitButton
          btnText='Save'
          onClick={submit}
          disabled={submitIsDisabled}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
