import { Grid } from '@mui/material'
import TabPanel from '../../../../components/TabPanel/TabPanel'
import { MemberMRT } from './Member/MemberMRT'
import { RoleMRT } from './Role/RoleMRT'

export interface IUserRoleAllocationProps {
  activeTab: number
  formik: any
}

const UserRoleAllocation = ({ activeTab, formik }: IUserRoleAllocationProps) => {
  return (
    <>
      <TabPanel value={activeTab} index={0}>
        <Grid item xs={12}>
          <MemberMRT formik={formik} />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Grid item xs={12}>
          <RoleMRT formik={formik} />
        </Grid>
      </TabPanel>
    </>
  )
}

export default UserRoleAllocation
