import { Box, Button } from '@mui/material'
import { FormikErrors, setNestedObjectValues } from 'formik'
import { useIntl } from 'react-intl'
import { useSideModalContext } from '../../features/AssetsManagement/modals/SideModalContext'
import { useProductCategoryFormContext } from '../../features/Products/ProductCategoryTab/hooks/useProductCategoryFormContext'
import messages from './messages'

type SubmitButtonProps = {
  disabled?: boolean
  create: boolean
  delete?: boolean
}

const SubmitButtons = ({
  delete: isDelete,
  disabled = false,
  create,
}: SubmitButtonProps) => {
  const { formatMessage } = useIntl()
  const { onClose, onDelete } = useSideModalContext()
  const { submitForm, validateForm, setTouched } = useProductCategoryFormContext();


  const handleSubmit = () => {
    validateForm().then((errors: FormikErrors<any>) => {
      submitForm().finally(() => {
        setTouched(setNestedObjectValues(errors, true))
      })
    })
  }
  return !disabled ? (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {isDelete && (
        <Button color='secondary' variant='contained' onClick={onDelete}>
          {formatMessage(messages.deleteBtn)})
        </Button>
      )}
      <Button color='secondary' variant='contained' onClick={handleSubmit}>
        {create ? formatMessage(messages.createBtn) : formatMessage(messages.updateBtn)}
      </Button>
      <Button color='secondary' variant='outlined' onClick={onClose}>
        {formatMessage(messages.cancelBtn)}
      </Button>
    </Box>
  ) : (
    <></>
  )
}

export default SubmitButtons
