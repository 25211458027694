import logger from '../../../../app/middleware/log'
import { fetchCustomerSettingsDataThunk, makeFetchSlice } from '../../../../app/redux-fetch'
import { AppDispatch } from '../../../../app/store/store'
import { fetchAllUserGroupsByCustomerId } from './api'
import { CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME } from './consts'

// fetch all user groups by customer id
export const fetchAllCustomerSettingsUserGroupByCustomerIdSlice = makeFetchSlice(
  CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME
)

export const fetchAllCustomerSettingsUserGroupByCustomerIdReducers =
  fetchAllCustomerSettingsUserGroupByCustomerIdSlice.reducer
export const fetchAllCustomerSettingsUserGroupByCustomerIdActions =
  fetchAllCustomerSettingsUserGroupByCustomerIdSlice.actions

export const fetchAllCustomerSettingsUserGroupsThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCustomerSettingsDataThunk({
        dispatch,
        actions: fetchAllCustomerSettingsUserGroupByCustomerIdActions,
        asyncFunc: () => fetchAllUserGroupsByCustomerId({ tenantId, customerId }),
      })
    } catch (error) {
      logger.error('🚀 Error fetching user group by customer id', error)
    }
  }
}
