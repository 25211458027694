
import { FormGroup, Grid } from '@mui/material';

export function FormGridItem({
  children,
  sm = 2
}: React.PropsWithChildren<{ sm?: number }>) {

  return (<Grid item sm={sm}>
    <FormGroup
      aria-label="position"
      sx={{
        display: 'flex',
        direction: 'column',
        rowGap: '16px'
      }}>
      {children}
    </FormGroup>
  </Grid>)
}
