import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import {
    useAssetCategoriesByTransportModeIdQuery,
    useGetAssetClassesByCategoryQuery,
    useGetAssetTypesByCategoryQuery,
    useGetTransportModeQuery
} from '../../../app/redux-fetch/apiQuery';
import FormDropdown, { DropdownItem } from '../../../components/FormDropDown/FormDropdown';
import { FormGrid } from '../FormGrid/FormGrid';
import { FormGridItem } from '../FormGrid/FormGridItem';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';
import { messages } from '../messages';

interface AssetAttributeGroupProps {
    disabled?: boolean;
}

export function AssetAttributeGroup({
    disabled,
}: AssetAttributeGroupProps) {
    const { formatMessage } = useIntl();
    const { values, setFieldValue, errors } = useAssetManagementFormContext();
    const {
        assetCategoryId = 0,
        assetClassId = 0,
        assetTypeId = 0,
        transportModeId = 0,
        tenantId = 0,
        detail,
        identification,
    } = values;

    const { data: transportModesData = [] } = useGetTransportModeQuery();
    const {
        data: assetCategoriesData = [],
        isLoading: isLoadingAssetCategories,
    } = useAssetCategoriesByTransportModeIdQuery({ tenantId, transportModeId });

    const {
        data: assetTypesData = [],
        isLoading: isLoadingAssetTypes,
    } = useGetAssetTypesByCategoryQuery({ tenantId, assetCategoryId });

    const {
        data: assetClassesData = [],
        isLoading: isLoadingAssetClasses,
    } = useGetAssetClassesByCategoryQuery({ tenantId, assetCategoryId });


    const isDisabled = disabled || isLoadingAssetCategories || isLoadingAssetTypes || isLoadingAssetClasses;

    return (
        <FormGrid>
            <FormGridItem>
                <FormDropdown
                    id='transportModeId'
                    items={transportModesData as DropdownItem[]}
                    label={formatMessage(messages.transportMode)}
                    onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                    value={transportModeId}
                    defaultValue={transportModeId}
                    disabled={isDisabled}
                    required
                />
            </FormGridItem>
            <FormGridItem>
                <FormDropdown
                    id='assetCategoryId'
                    items={assetCategoriesData as DropdownItem[]}
                    label={formatMessage(messages.assetsCategory)}
                    onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                    error={false}
                    value={assetCategoryId}
                    defaultValue={assetCategoryId}
                    disabled={isDisabled || isEmpty(assetCategoriesData)}
                    required
                />
                <FormDropdown
                    id='assetTypeId'
                    items={assetTypesData as DropdownItem[]}
                    label={formatMessage(messages.assetsTypes)}
                    onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                    error={false}
                    value={assetTypeId}
                    disabled={isDisabled || isEmpty(assetTypesData)}
                    defaultValue={assetTypeId}
                    required
                />
                <FormDropdown
                    id='assetClassId'
                    disabled={isDisabled || isEmpty(assetClassesData)}
                    items={assetClassesData as DropdownItem[]}
                    label={formatMessage(messages.assetsClasses)}
                    onChange={(_e: any, name: string, value: number) => setFieldValue(name, value)}
                    error={false}
                    value={assetClassId}
                    defaultValue={assetClassId}
                    required
                />
            </FormGridItem>

            <FormGridItem>
                <TextField
                    id='identification'
                    label={formatMessage(messages.assetsDetailsIdentification)}
                    name="identification"
                    onChange={({ target }) => setFieldValue('identification', target.value)}
                    disabled={disabled}
                    value={identification}
                    defaultValue={identification}
                    InputLabelProps={{ shrink: true }}

                />
            </FormGridItem>
        </FormGrid>
    );
}
