import { Delete, Edit } from '@mui/icons-material'
import UndoIcon from '@mui/icons-material/Undo'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  MRT_Cell as MRTCell,
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MaterialReactTableProps,
} from 'material-react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { generateRandomId, validateEmail, validateRequired } from '../../../utils'
import { AddUserModal } from '../Components/AddUserModal'
import messages from './messages'
import {
  fetchAllCustomerSettingsUserByCustomeIdActions,
  postUserByCustomerIdActions,
} from './slice'
import { IExtendedUser, IUserMRTProps } from './types'
import { validateUniqueUser } from './utils'

export const UserMRT = ({ formik, allUsers, isFormDirty, isFetchingUsers }: IUserMRTProps) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId

  const data: IExtendedUser[] = formik.values.formikUsers || []

  const formikRemoveUser = formik.getFieldProps('formikRemoveUser').value as IExtendedUser

  useEffect(() => {
    return () => {
      void dispatch(fetchAllCustomerSettingsUserByCustomeIdActions.clear())
      void dispatch(postUserByCustomerIdActions.clear())
    }
  }, [
    dispatch,
    fetchAllCustomerSettingsUserByCustomeIdActions.clear,
    postUserByCustomerIdActions.clear,
  ])

  useEffect(() => {
    if (!formikRemoveUser) return
    const newUsers = data.filter((user) => user.id !== formikRemoveUser.id)
    formik.setFieldValue('formikUsers', newUsers)
    formik.setFieldError('formikRemoveUser', undefined)
    formik.setFieldValue('formIsDirty', false)
  }, [formikRemoveUser])

  const handleDelete = (row: MRTRow<IExtendedUser>, table: IExtendedUser[], editable: boolean) => {
    if (!editable) return

    const user = row.original
    const { httpAction } = user

    if (httpAction === 'POST') {
      // If user is newly added, simply remove it from formikUsers
      // Do as a side effect
      // formikUsers.splice(foundIndex, customerId)
      // formik.setFieldValue(fieldProp, formikUsers)
      user.dirty = false
      user.httpAction = undefined
      formik.setFieldValue('formikRemoveUser', user)
      formik.setFieldValue('formikDeletedUser', undefined)
      formik.setFieldValue('formikSelectedUserId', undefined)
      formik.setFieldValue('formIsDirty', false)
      return
    }

    if (httpAction === 'DELETE') {
      user.dirty = false
      user.httpAction = undefined
      formik.setFieldValue('formikDeletedUser', user)
      formik.setFieldValue('formikSelectedUserId', user.id)
      formik.setFieldValue('formIsDirty', false)
      return
    }

    user.dirty = true
    user.httpAction = 'DELETE'
    formik.setFieldValue('formikDeletedUser', user)
    formik.setFieldValue('formikSelectedUserId', user.id)
    formik.setFieldValue('formIsDirty', true)
  }

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string
  }>({})

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRTCell<IExtendedUser>
    ): MRTColumnDef<IExtendedUser>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const columnId = cell.column.id
          const isValid =
            columnId === 'email'
              ? validateEmail(event.target.value)
              : ['firstName', 'lastName', 'email'].includes(columnId)
                ? validateRequired(event.target.value) &&
                validateUniqueUser({
                  formik,
                  id: cell.row.original.id,
                  firstName: cell.row.original.firstName || '',
                  lastName: cell.row.original.lastName || '',
                  email: cell.row.original.email || '',
                })
                : false

          if (!isValid) {
            // set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            })
          } else {
            // remove validation error for cell if valid
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [validationErrors]
  )

  const columns = useMemo<MRTColumnDef<IExtendedUser>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'isGlobalAdmin',
        header: 'Global Admin',
        enableEditing: false,
        columnDefType: 'display',
        enableColumnOrdering: true,
        Cell: ({
          row,
          table,
        }: {
          row: MRTRow<IExtendedUser>
          table: MRTTableInstance<IExtendedUser>
        }) => {
          const { isGlobalAdmin } = row.original
          return isGlobalAdmin ? 'Yes' : 'No'
        },
      },
      {
        accessorKey: 'isAccountOwner',
        header: 'Account Owner',
        enableEditing: false,
        columnDefType: 'display',
        enableColumnOrdering: true,
        Cell: ({
          row,
          table,
        }: {
          row: MRTRow<IExtendedUser>
          table: MRTTableInstance<IExtendedUser>
        }) => {
          const { isAccountOwner } = row.original
          return isAccountOwner ? 'Yes' : 'No'
        },
      },
      {
        accessorKey: 'lastUpdated',
        header: 'Last Updated',
        columnDefType: 'display',
        enableEditing: false,
      },
      {
        accessorKey: 'lastSync',
        header: 'Last Sync',
        columnDefType: 'display',
        enableEditing: false,
      },
    ],
    [getCommonEditTextFieldProps]
  )

  function handleContinueEdit(): void {
    closeConfrimDialog()
    formik.setFieldValue('formikNextSelectedUserId', undefined)
    formik.setFieldValue('formikSelectedUserId', formikSelectedUserId)
  }

  function discardChanges() {
    formik.setFieldValue('formikSelectedUserId', formikNextSelectedUserId)

    // Get formikUsers from formik values
    const formikUsers: IExtendedUser[] = formik.values.formikUsers || []

    // Get formikNewUser from formik values
    const formikNewUser: IExtendedUser = formik.values.formikNewUser || ({} as IExtendedUser)
    const formikOriginalUser = formik.values.formikOriginalUser

    // Remove new user from formikUsers
    let newUsers = formikUsers
      .filter((user) => formikNewUser.id != user.id)
      .map((user) => {
        // Reinstate orignal role values
        if (user.id === formikOriginalUser?.id) {
          return formikOriginalUser
        }
        return user
      }) as IExtendedUser[]

    // Reset httpAction for user marked for deletion
    const userMarkedForDeletion = formik.getFieldProps('formikDeletedUser').value as IExtendedUser

    if (userMarkedForDeletion) {
      newUsers = newUsers.map((user) => {
        if (user.id === userMarkedForDeletion.id) {
          user.httpAction = undefined
          user.dirty = false
        }
        return user
      })
    }

    // Reset other values
    formik.setFieldValue('formikNewUser', undefined)
    formik.setFieldValue('formikEditedUser', undefined)

    // Save new roles to formik values
    formik.setFieldValue('formikUsers', newUsers)

    formik.setFieldValue(
      'formikSelectedUserId',
      formik.getFieldProps('formikNextSelectedUserId').value
    )
    formik.setFieldValue(
      'formikUsers',
      allUsers.map((user) => user)
    )

    formik.setFieldValue('formikDeletedUser', undefined)
    formik.setFieldValue('formikNextSelectedUserId', undefined)
    formik.setFieldValue('formIsDirty', false)
  }

  function handleDiscardChanges(): void {
    discardChanges()
    closeConfrimDialog()
  }

  const openConfrimDialog = () => formik.setFieldValue('openActionConfirmationDialog', true)

  const closeConfrimDialog = () => formik.setFieldValue('openActionConfirmationDialog', false)

  const confirmDialogOpen = formik.values.openActionConfirmationDialog === true

  const [addRoleModalOpen, setAddRoleModalOpen] = useState(false)
  const handleAddItem = () => {
    setAddRoleModalOpen(true)
  }

  const handleAddNewUser = (values: IExtendedUser) => {
    const newUser: IExtendedUser = {
      id: generateRandomId(),
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      tenantId,
      customerId,
      disabled: false,
      IdentityProviderIntegrationId: 0,
      isAccountOwner: false,
      isGlobalAdmin: false,
      lastSync: '',
      lastUpdated: '',
      userTypeId: values.userTypeId,
      phone: '',
      dirty: true,
      httpAction: 'POST',
    }
    const newUsers = [newUser].concat(data)
    formik.setFieldValue('formikUsers', newUsers)
    formik.setFieldValue('formikNewUser', newUser)
    formik.setFieldValue('formikSelectedUserId', newUser.id)
    setAddRoleModalOpen(false)
  }

  const formikSelectedUserId = formik.values.formikSelectedUserId

  const formikNextSelectedUserId = formik.values.formikNextSelectedUserId

  const handleRowSelectionChange = ({
    row,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IExtendedUser>
    table: MRTTableInstance<IExtendedUser>
  }) => {
    return {
      onClick: () => {
        const originalSelectedUser = formik.values.formikUsers?.find(
          (user: IExtendedUser) => user.id === Number(row.id)
        )

        formik.setFieldValue('originalSelectedUser', originalSelectedUser)

        if (!formikSelectedUserId) {
          formik.setFieldValue('formikSelectedUserId', Number(row.id))
          return
        }

        if (`${formikSelectedUserId}` !== row.id) {
          formik.setFieldValue('formikNextSelectedRoleId', Number(row.id))
          if (isFormDirty()) {
            openConfrimDialog()
            return
          }
          formik.setFieldValue('formikSelectedUserId', Number(row.id))
          return
        }
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          row.id === `${formik.values.formikSelectedUserId}` ? 'lightgrey' : 'initial',
      },
    }
  }

  const handleSaveRowEdits: MaterialReactTableProps<IExtendedUser>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const editedUser = data.find((user) => user.id === Number(row.id))
    if (!editedUser) {
      exitEditingMode()
      return
    }

    // Save off original role values
    const originalUser = { ...editedUser }
    formik.setFieldValue('formikOriginalUser', originalUser)

    editedUser.firstName = values.firstName
    editedUser.lastName = values.lastName
    editedUser.email = values.email
    editedUser.dirty = true

    const updatedUsers = data.map((role) => {
      if (role.id === Number(row.id)) {
        return editedUser
      }
      return role
    })

    formik.setFieldValue('formikUsers', updatedUsers)
    formik.setFieldValue(
      'formikEditedUser',
      updatedUsers.find((role) => role.id === Number(row.id))
    )

    exitEditingMode() // required to exit editing mode and close modal
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogMessageContent)}
        continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
        discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
        onContinueEdit={handleContinueEdit}
        onDiscardChanges={handleDiscardChanges}
      />
      <AddUserModal
        columns={columns.filter((column) =>
          ['firstName', 'lastName', 'email'].includes(column.accessorKey as string)
        )}
        onClose={() => setAddRoleModalOpen(false)}
        onSubmit={handleAddNewUser}
        open={addRoleModalOpen}
      />
      <MRTDataGrid
        heading={messages.usersTableHeading}
        columns={columns}
        data={data}
        handleAddItem={handleAddItem}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading: isFetchingUsers }}
        enableEditing
        enableStickyHeader
        enableStickyFooter
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => {
          const { externalId, httpAction, isAccountOwner } = row.original
          const markedForDeletion = httpAction === 'DELETE'
          const deletable = !isAccountOwner
          const editable = externalId === undefined
          const dataUsers = data.map((d) => d) as IExtendedUser[]

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement='left' title='Edit'>
                <IconButton
                  color={editable ? 'info' : undefined}
                  onClick={() => editable && table.setEditingRow(row)}
                  disabled={!editable}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement='right' title='Delete'>
                <IconButton
                  color={editable ? 'info' : undefined}
                  onClick={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    handleDelete(row, dataUsers, editable)
                  }}
                  disabled={!editable}
                >
                  {deletable || markedForDeletion ? (
                    markedForDeletion ? (
                      <UndoIcon color='info' />
                    ) : (
                      <Delete />
                    )
                  ) : null}
                </IconButton>
              </Tooltip>
            </Box>
          )
        }}
      />
    </>
  )
}
