import CloseIcon from '@mui/icons-material/Close'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Fab,
  Grid,
  IconButton,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import { useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { ILoad, IShipment } from '../../types'
import LoadSummaryHeader from '../Common/LoadSummaryHeader/LoadSummaryHeader'
import LoadInfo from './LoadInfo'
import LoadPalletDetail from './LoadPalletDetail'
import PhysicalProperties from './PhysicalProperties'
import TemperatureSetting from './TemperatureSetting'
import TransportDetail from './TransportDetail'
import messages from './messages'
import { ShipmentAccordion } from './styles'
import { ILoadPalletVector } from './types'

export interface ILoadProps {
  expanded?: boolean
  formik: FormikProps<IShipment>
  index: number
  load: ILoad
  handleAddPallet: (index: number) => void
  handleFormDropDownChanges: (e: SelectChangeEvent<number>, name: string, newValue: any) => void
  handleRemovePallet: (loadIndex: number, palletIndex: number) => void
  handleCopyLoads: (loadIndex: number, count: number) => void
  handleSwitchLoadChanges: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ) => void
  lastCreatedPallet: ILoadPalletVector | null
  disabled?: boolean
  disabledPast?: boolean
}

const Load = (props: ILoadProps): ReactElement => {
  const {
    formik,
    index,
    load,
    expanded,
    lastCreatedPallet,
    disabled,
    disabledPast,
    handleAddPallet,
    handleCopyLoads,
    handleFormDropDownChanges,
    handleRemovePallet,
    handleSwitchLoadChanges,
  } = props
  const { formatMessage } = useIntl()
  const [isCopyMode, setIsCopyMode] = useState(false)
  const [loadsForCopy, setLoadsForCopy] = useState(1)
  const [isMappedLoadWarning, setIsMappedLoadWarning] = useState(false)
  const handleRemoveLoad = (index: number): void => {
    const loads = [...formik.values.loads]
    const removedLoad = loads.splice(index, 1)
    if (
      removedLoad[0] &&
      removedLoad[0].assetRequestAllocations &&
      removedLoad[0].assetRequestAllocations.length > 0
    ) {
      setIsMappedLoadWarning(true)
      return
    }
    if (
      removedLoad[0] &&
      removedLoad[0].containerAllocations &&
      removedLoad[0].containerAllocations.length > 0
    ) {
      setIsMappedLoadWarning(true)
      return
    }
    if (loads.length === 0) {
      void formik.setFieldValue('usesContainer', false)
    }
    void formik.setFieldValue('loads', loads)
  }

  return (
    <>
      <ShipmentAccordion
        defaultExpanded={expanded}
        key={index}
        TransitionProps={{ unmountOnExit: true }}
        data-accordion={`load${index}`}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowRightIcon />}
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Grid container alignItems='center'>
            <Grid xs={12}>
              <Collapse in={isMappedLoadWarning}>
                <Alert severity='error' onClick={() => setIsMappedLoadWarning(false)}>
                  <AlertTitle>Error</AlertTitle>
                  This load cannot be deleted as it is currently mapped to pre-booked container(s)
                  or asset request(s). Please unmap it first if you wish to delete it.
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                {formatMessage(messages.load)} # {load.displayOrder}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <LoadSummaryHeader load={load} />
            </Grid>
            {!disabled && (
              <Grid item xs={3}>
                <Box display='flex' justifyContent='flex-end'>
                  {isCopyMode ? (
                    <Box display='flex' justifyContent='flex-end'>
                      <NumericFormat
                        customInput={TextField}
                        decimalScale={0}
                        label='How many?'
                        sx={{ paddingRight: '16px' }}
                        onClick={(e: any) => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                        onValueChange={(vals) => {
                          setLoadsForCopy(vals.floatValue ?? 0)
                        }}
                        value={loadsForCopy}
                        InputLabelProps={{ shrink: true }}
                        disabled={disabled}
                      />
                      <IconButton
                        size='large'
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleCopyLoads(index, loadsForCopy)
                          setLoadsForCopy(0)
                          setIsCopyMode(false)
                        }}
                      >
                        <DoneIcon color='success' />
                      </IconButton>
                      <IconButton
                        size='large'
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          setLoadsForCopy(0)
                          setIsCopyMode(false)
                        }}
                      >
                        <CloseIcon color='error' />
                      </IconButton>
                    </Box>
                  ) : (
                    <Fab
                      aria-label='copy'
                      color='primary'
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setIsCopyMode(true)
                      }}
                      size='small'
                      sx={{ margin: '0 2px' }}
                    >
                      <CopyIcon />
                    </Fab>
                  )}
                  <Fab
                    aria-label='remove'
                    color='info'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      handleRemoveLoad(index)
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <DeleteIcon />
                  </Fab>
                </Box>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <LoadInfo
              formik={formik}
              index={index}
              handleSelectLoadChanges={handleFormDropDownChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              load={load}
              disabled={disabled}
            />
            <TransportDetail
              formik={formik}
              index={index}
              handleSelectLoadChanges={handleFormDropDownChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              load={load}
              disabled={disabled}
              disabledPast={disabledPast}
            />
            <TemperatureSetting
              formik={formik}
              index={index}
              handleSelectLoadChanges={handleFormDropDownChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              load={load}
              path={`loads.${index}`}
              disabled={disabled}
            />
            <PhysicalProperties
              formik={formik}
              index={index}
              handleSelectLoadChanges={handleFormDropDownChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              load={load}
              disabled={disabled}
            />
            <LoadPalletDetail
              formik={formik}
              handleAddPallet={handleAddPallet}
              handleSelectLoadChanges={handleFormDropDownChanges}
              handleSwitchLoadChanges={handleSwitchLoadChanges}
              handleRemovePallet={handleRemovePallet}
              index={index}
              load={load}
              lastCreatedPallet={lastCreatedPallet}
              disabled={disabled}
              isExpanded={expanded}
            />
          </Grid>
        </AccordionDetails>
      </ShipmentAccordion>
    </>
  )
}

export default Load
