import { useIntl } from 'react-intl'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
} from 'material-react-table'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { IAsset, IAssetClass } from '../types'
import { messages as assetClassMessages } from './messages'
import { useMemo, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { useNotificationStack } from '../../../../Shipments/hooks/useNotificationStack'
import { useAssetClassMrtFormContext } from './hooks/useAssetClassMrtFormContext'
import { AddAssetClassMrtModal } from './Modals/AddAssetClassMrtModal'
import { EditAssetClassMrtModal } from './Modals/EditAssetClassMrtModal'
import { DeleteAssetClassMrtModal } from './Modals/DeleteAssetClassMrtModal'
import { deleteAssetClass } from './api/deleteAssetClass'
import { updateAssetClass } from './api/updateAssetClass'
import { createAssetClass } from './api/createAssetClass'
import messages from '../messages';
import { SubType } from '../../../../../types/common'
import { Enabled } from '../../../../../components/Enabled/Enabled'

interface IAssetClassProps {
  isLoading: boolean;
  tenantId: number;
}
export const AssetClassMRT = ({ tenantId, isLoading }: IAssetClassProps) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, submitForm } = useAssetClassMrtFormContext();
  const { assetClasses, assetCategoryId = -1, } = values;
  const columns = useMemo<MRTColumnDef<Partial<IAssetClass>>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: 'enabled',
        header: 'Enabled',
        columnDefType: 'boolean',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetClass>>
        }) => {
          return <Enabled isEnabled={row.original.enabled} />
        },
      },
    ] as MRTColumnDef<Partial<IAssetClass>>[]
  }, []);

  const columnsDef = useMemo(() => {
    return columns.reduce((acc, item) => {
      acc[item.id ?? ''] = item.header;
      return acc;
    }, {} as { [key: string]: string });
  }, [columns]);

  const { assetsClasses } = messages


  const handleRowSelectionChange = ({
    row,
  }: {
    row: MRTRow<Partial<IAssetClass>>
  }) => {
    return {
      onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
        const rowId = values.selectedRow === Number(row.id) ? 0 : Number(row.id);
        setFieldValue('selectedRow', rowId);
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          Number(row.id) === values.selectedRow ? 'lightgrey' : 'initial',
      },
    }
  }

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<MRTRow<Partial<IAssetClass>> | null>(null);
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetClass>> | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueue, enqueueFailureMsg } = useNotificationStack();

  const onAddSubmit = async (values: IAssetClass) => {
    setIsSubmitting(true);
    const result = await createAssetClass({ ...values, tenantId, assetCategoryId });

    if (result.success) {
      enqueue(formatMessage(messages.classCreatedSuccessfully));
      setCreateModalIsOpen(false);
    } else {
      enqueueFailureMsg(formatMessage(messages.classCreationFailed));
    }

    setIsSubmitting(false);
    submitForm();
  }

  const onDeleteSubmit = async (values: IAssetClass) => {
    setIsSubmitting(true);
    const result = await deleteAssetClass(values)

    if (result.success) {
      enqueue(formatMessage(messages.classDeletedSuccessfully));
      setDeleteRow(null);
    } else {
      enqueueFailureMsg(formatMessage(messages.classDeletionFailed));
    }

    setIsSubmitting(false);
    submitForm();
  }

  const onEditSubmit = async (values: IAssetClass) => {
    if (values.subType === SubType.SystemDefined) {
      await onAddSubmit({ ...values });
      setEditRow(null);
    } else if (values.isCloneOfSysmtemDefined) {
      await onDeleteSubmit(values);
      setEditRow(null);
    } else {
      setIsSubmitting(true);
      const result = await updateAssetClass(values)

      if (result.success) {
        enqueue(formatMessage(messages.classUpdatedSuccessfully));
        setEditRow(null);
      } else {
        enqueueFailureMsg(formatMessage(messages.classUpdateFailed));
      }

      setIsSubmitting(false);
      submitForm();
    }
  }

  return (
    <>
      {createModalIsOpen &&
        (<AddAssetClassMrtModal
          columnDef={columnsDef}
          onClose={() => setCreateModalIsOpen(false)}
          onSubmit={onAddSubmit}
          dialogTitle={formatMessage(assetClassMessages.addAssetClassTitle)}
          loading={isSubmitting}
        />)}
      {editRow &&
        (<EditAssetClassMrtModal
          columnDef={columnsDef}
          row={editRow}
          onClose={() => setEditRow(null)}
          onSubmit={onEditSubmit}
          dialogTitle={formatMessage(assetClassMessages.editAssetClassTitle)}
          loading={isSubmitting}
        />)}
      {deleteRow &&
        (<DeleteAssetClassMrtModal
          columnDef={columnsDef}
          row={deleteRow}
          onClose={() => setDeleteRow(null)}
          onSubmit={onDeleteSubmit}
          dialogTitle={formatMessage(assetClassMessages.deleteAssetClassTitle)}
          loading={isSubmitting}
        />)}
      <MRTDataGrid
        heading={assetsClasses}
        columns={columns}
        data={assetClasses as Partial<IAssetClass>[]}
        handleAddItem={() => setCreateModalIsOpen(true)}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading }}
        enableEditing
        renderRowActions={({ row }) => {
          const { tenantId, isCloneOfSysmtemDefined } =
            row.original as IAsset;

          const isUserDefined = !!tenantId;
          const editable = isUserDefined && !isCloneOfSysmtemDefined;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement='left' title='Edit'>
                <IconButton color='info' onClick={() => setEditRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {editable && (
                <Tooltip arrow placement='right' title='Delete'>
                  <IconButton
                    color='info'
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDeleteRow(row);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }}
      />
    </>
  )
}
