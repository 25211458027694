import { AxiosResponse } from 'axios'
import { shipmentsAuthorizedFetch } from '../../app/axios/omnialog'

export const fetchContactTypes = async (
  tenantId: number,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  const customer = customerId ? `customer/${customerId}/` : ''
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/${customer}contactType`)
}
