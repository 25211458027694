import CloseIcon from '@mui/icons-material/Close';
import { Divider, FormControlLabel, FormGroup, Grid, Switch, TextField, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { MRT_Row as MRTRow } from 'material-react-table';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { useIntl } from 'react-intl';
import { useAssetCategoriesByTransportModeIdQuery, useCreateAssetConfigurationMutation, useUpdateAssetConfigurationMutation } from '../../../../../../app/redux-fetch/apiQuery';
import ConfirmDialog from '../../../../../../components/ConfirmDialog/ConfirmDialog';
import FormDropdown, { DropdownItem } from '../../../../../../components/FormDropDown/FormDropdown';
import { useNotificationStack } from '../../../../../Shipments/hooks/useNotificationStack';
import { ITransportMode } from '../../../../../Shipments/types';
import * as tenantSettingsMessages from '../../../../messages';
import messages from '../../messages';
import { IAllocation, IAssetCategory, IAssetConfigPayload, IAssetConfiguration } from '../../types';
import { AssetConfigCard, AssetConfigCardProps } from '../Card/AssetConfigCard';
import { assetConfigurationCreateSchema, assetConfigurationUpdateSchema } from '../schemas/assetConfigurationSchema';
import LinearIndeterminate from '../../../../../../components/Progress/LinearIndeterminate';

interface AssetConfigurationModalProps {
    onClose: () => void
    columnDef: { [key: string]: string };
    isOpen: boolean;
    transportModes?: ITransportMode[];
    tenantId: number;
    row?: MRTRow<Partial<IAssetConfiguration>>;
}

const defaultItem = {
    assetCategoryId: 0,
    assetClassId: 0,
    assetTypeId: 0,
    sequence: 0,
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getRow = (row: AssetConfigurationModalProps['row'], tenantId: number): IAssetConfiguration => {
    if (row) {
        return {
            id: row.original.id,
            name: row.original.name,
            tenantId: row.original.tenantId,
            allocations: row.original.allocations,
            customerSelectable: row.original.customerSelectable,
            transportModeId: row.original.transportModeId,
        }
    }

    return {
        name: '',
        tenantId,
        transportModeId: 0,
        customerSelectable: false,
        allocations: [{ ...defaultItem }]
    }
}

export function AssetConfigurationModal({
    onClose,
    isOpen,
    columnDef,
    transportModes,
    tenantId,
    row,
}: AssetConfigurationModalProps) {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { enqueue, enqueueFailureMsg } = useNotificationStack();

    const config = getRow(row, tenantId);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [transportModeId, setTransportModeId] = useState<string | number>(config.transportModeId || 0);
    const [confirmTransportModeId, setConfirmTransportModeId] = useState<string | number>(config.transportModeId || 0);
    const [name, setName] = useState(config.name || '');
    const [customerSelectable, setCustomerSelectable] = useState(config.customerSelectable || false);
    const { data: assetCategoriesData = [] } = useAssetCategoriesByTransportModeIdQuery({ tenantId, transportModeId: transportModeId as number });
    const [allocations, setAllocations] = useState(config.allocations || []);
    const items: DropdownItem[] = (transportModes || []).map((item) => ({ id: item.id, name: item.name }));
    const assetCategories: DropdownItem[] = (assetCategoriesData as unknown as IAssetCategory[] || []).map((item) => ({ id: item.id, name: item.name }));
    const isEdit = Boolean(row);
    const schema = isEdit ? assetConfigurationUpdateSchema() : assetConfigurationCreateSchema();
    const isValid = schema.isValidSync({ name, tenantId, transportModeId, allocations, id: config.id });
    const [
        createAssetConfiguration,
        {
            isLoading: createIsLoading,
            isSuccess: createIsSuccess,
            data: createData,
            isError: createIsError
        }
    ] = useCreateAssetConfigurationMutation();

    useEffect(() => {
        if (createData) {
            if (createIsSuccess) {
                enqueue(formatMessage(messages.assetConfigurationCreateSuccessful));
                onClose();
            } else if (createIsError) {
                enqueueFailureMsg(formatMessage(messages.assetConfigurationCreateFailed));
            }
        }
    }, [createData, createIsSuccess, onClose]);

    const [
        updateAssetConfiguration,
        {
            isLoading: updateIsLoading,
            isSuccess: updateIsSuccess,
            isUninitialized: updateIsUninitialized,
            isError: updateIsError,
        }
    ] = useUpdateAssetConfigurationMutation();


    useEffect(() => {
        if (!updateIsUninitialized && updateIsSuccess) {
            if (updateIsSuccess) {
                enqueue(formatMessage(messages.assetConfigurationUpdateSuccessful));
                onClose();
            } else if (updateIsError) {
                enqueueFailureMsg(formatMessage(messages.assetConfigurationUpdateFailed));
            }
        }
    }, [updateIsSuccess, onClose]);

    const isLoading = createIsLoading || updateIsLoading;

    const onTransportModeChange = (_e: any, _name: string, newValue: number) => {
        if (allocations.some((c: IAllocation) => c.assetType?.assetCategoryId)) {
            setOpenConfirmDialog(true);
            setConfirmTransportModeId(newValue);
        } else {
            setTransportModeId(newValue);
            setConfirmTransportModeId(newValue);
        }
    }

    const onAddCard = () => {
        setAllocations((prev) => [...prev, { ...defaultItem, tenantId }]);
    }

    const onRemoveCard = (itemIndex: number) => {
        setAllocations((prev) => prev.filter((_item, index) => index !== itemIndex));
    }

    const onConfigChange: AssetConfigCardProps['onChange'] = (values) => {
        setAllocations((prev) => {
            const newItems = prev.map((item, index) => {
                if (values.itemIndex === index) {
                    return { ...values.allocation }
                }
                return { ...item };
            });
            return newItems;
        })
    }

    const onDragEnd = (result: DropResult) => {
        if (result.destination && result.source) {
            let items = allocations.filter((_, index) => index !== result.source.index);
            const item = allocations[result.source.index]

            if (result.destination.index === 0) {
                items.unshift(item);
            } else if (result.destination.index === allocations.length - 1) {
                items.push(allocations[result.source.index]);
            } else {
                items = [
                    ...items.slice(0, result.destination.index),
                    item,
                    ...items.slice(result.destination.index)
                ];
            }
            setAllocations(items);
        }
    };

    const handleContinueEdit = () => {
        setOpenConfirmDialog(false);
        setConfirmTransportModeId(transportModeId);
    }

    const handleDiscardChanges = () => {
        setOpenConfirmDialog(false);
        setTransportModeId(confirmTransportModeId);
        setAllocations([{ ...defaultItem }]);
    }

    const onCreate = () => {
        const body: IAssetConfigPayload = {
            tenantId,
            name,
            transportModeId: transportModeId as number,
            customerSelectable,
            allocations: allocations.map((item, index) => ({
                assetClassId: item.assetClassId,
                assetTypeId: item.assetTypeId,
                sequence: index + 1,
            })),
        }

        createAssetConfiguration({ tenantId, body });
    }

    const onUpdate = () => {
        const body: IAssetConfigPayload = {
            id: config.id,
            tenantId,
            name,
            transportModeId: transportModeId as number,
            customerSelectable,
            allocations: allocations.map((item, index) => ({
                id: item.id,
                assetClassId: item.assetClassId,
                assetTypeId: item.assetTypeId,
                sequence: index + 1,
                assetConfigurationId: item.assetConfigurationId,
            })),
        }

        updateAssetConfiguration({ tenantId, body });
    }

    return (
        <div>
            <ConfirmDialog
                open={openConfirmDialog}
                title={formatMessage(messages.confirmDialogTitle)}
                content={formatMessage(messages.confirmDialogMessageContent)}
                continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
                discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
                onContinueEdit={handleContinueEdit}
                onDiscardChanges={handleDiscardChanges}
            />
            <Dialog
                fullScreen
                open={isOpen}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative', background: theme.palette.secondary.main }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                            disabled={isLoading}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {formatMessage(isEdit ? messages.assetConfigurationEditModalTitle : messages.assetConfigurationAddModalTitle)}
                        </Typography>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            cancel
                        </Button>
                        <Button
                            autoFocus
                            color="inherit"
                            onClick={isEdit ? onUpdate : onCreate}
                            disabled={isLoading || !isValid}
                        >
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                {isLoading && <LinearIndeterminate />}
                <FormGroup aria-label="position" sx={{ padding: '20px', width: '25%' }} >
                    <TextField
                        label={columnDef['name']}
                        name="name"
                        onChange={({ target }) => setName(target.value)}
                        disabled={isLoading}
                        value={name}
                        defaultValue={name}
                    />
                    <FormDropdown
                        id='transportMode'
                        items={items}
                        label={formatMessage(tenantSettingsMessages.default.assetsTransportMode)}
                        onChange={onTransportModeChange}
                        sx={{ marginTop: '16px' }}
                        value={transportModeId}
                        defaultValue={transportModeId}
                        disabled={isLoading}
                    />
                    <FormControlLabel
                        sx={{ margin: '8px 0px 0px 0px', justifyContent: 'start' }}
                        value={customerSelectable}
                        labelPlacement="start"
                        control={
                            <Switch
                                color="primary"
                                checked={customerSelectable}
                                value={customerSelectable}
                                onChange={({ target }) => setCustomerSelectable(target.checked)}
                                disabled={isLoading}
                            />
                        }
                        label={columnDef['customerSelectable']}
                    />
                </FormGroup>
                <Divider />
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <Grid spacing={1}
                                container
                                sx={{
                                    background: 'rgb(243, 246, 249)',
                                    padding: '20px',
                                }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {allocations.map((allocation, index) => (
                                    <Draggable key={index} draggableId={String(index)} index={index}>
                                        {(providedChild) => (
                                            <Grid item
                                                ref={providedChild.innerRef}
                                                {...providedChild.draggableProps}
                                                {...providedChild.dragHandleProps}
                                            >
                                                <AssetConfigCard
                                                    tenantId={tenantId}
                                                    onChange={onConfigChange}
                                                    index={index}
                                                    allocation={allocation}
                                                    assetCategories={assetCategories}
                                                    onRemove={(allocations.length === 1 || isLoading) ? undefined : onRemoveCard}
                                                    onAdd={isLoading ? undefined : onAddCard}
                                                    disabled={isLoading}
                                                />
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Dialog>
        </div >
    );
}
