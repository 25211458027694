import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import {
  MRT_Cell as MRTCell,
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { IAsset, IAssetCategory } from '../types'
import { useAssetCategoryMrtFormContext } from './hooks/useAssetCategoryMrtFormContext'
import { AddAssetCategoryMrtModal } from './Modals/AddAssetCategoryMrtModal'
import { EditAssetCategoryMrtModal } from './Modals/EditAssetCategoryMrtModal'
import { DeleteAssetCategoryMrtModal } from './Modals/DeleteAssetCategoryMrtModal'
import { messages as assetCategoryMrtvMessage } from './messages';
import messages from '../messages';
import { createAssetCategory } from './api/createAssetCategory';
import { deleteAssetCategory } from './api/deleteAssetCategory';
import { useNotificationStack } from '../../../../Shipments/hooks/useNotificationStack';
import { updateAssetCategory } from './api/updateAssetCategory'
import { SubType } from '../../../../../types/common'
import { Enabled } from '../../../../../components/Enabled/Enabled'

interface IAssetCategoryProps {
  isLoading: boolean;
  tenantId: number;
  transportModeId: number;
  onCategoryChange: (value: number) => void;
}

export const AssetCategoryMRT = ({ tenantId, isLoading, transportModeId, onCategoryChange }: IAssetCategoryProps) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, submitForm } = useAssetCategoryMrtFormContext();

  const columns = useMemo<MRTColumnDef<Partial<IAssetCategory>>[]>(() => {
    return [
      {
        id: 'id',
        accessorKey: 'name',
        header: 'Name',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: 'enabled',
        header: 'Enabled',
        columnDefType: 'boolean',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetCategory>>
          table: MRTTableInstance<Partial<IAssetCategory>>
        }) => {
          return <Enabled isEnabled={row.original.enabled} />
        },
      },
    ] as MRTColumnDef<Partial<IAssetCategory>>[]
  }, [])

  const handleRowSelectionChange = ({
    row,
  }: {
    row: MRTRow<Partial<IAssetCategory>>
  }) => {
    return {
      onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
        const rowId = values.selectedRow === Number(row.id) ? 0 : Number(row.id);
        setFieldValue('selectedRow', rowId);
        onCategoryChange(rowId);
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          Number(row.id) === values.selectedRow ? 'lightgrey' : 'initial',
      },
    }
  }

  const columnsDef = useMemo(() => {
    return columns.reduce((acc, item) => {
      acc[item.id ?? ''] = item.header;
      return acc;
    }, {} as { [key: string]: string });
  }, [columns]);

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<MRTRow<Partial<IAssetCategory>> | null>(null);
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetCategory>> | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueue, enqueueFailureMsg } = useNotificationStack();

  const createRowActions = ({
    row,
  }: {
    cell: MRTCell<Partial<IAssetCategory>>
    row: MRTRow<Partial<IAssetCategory>>
    table: MRTTableInstance<Partial<IAssetCategory>>
  }) => {
    const { tenantId, isCloneOfSysmtemDefined } = row.original as IAsset;

    const isUserDefined = Boolean(tenantId);
    const editable = isUserDefined && !isCloneOfSysmtemDefined;
    return (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip arrow placement='left' title='Edit'>
          <IconButton color='info' onClick={() => setEditRow(row)}>
            <Edit />
          </IconButton>
        </Tooltip>
        {editable && (
          <Tooltip arrow placement='right' title='Delete'>
            <IconButton
              color='info'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteRow(row);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    )
  }

  const onAddSubmit = async (values: IAssetCategory) => {
    setIsSubmitting(true);

    const result = await createAssetCategory({ ...values, tenantId, transportModeId });
    if (result.success) {
      enqueue(formatMessage(messages.categoryCreatedSuccessfully));
      setCreateModalIsOpen(false);
    } else {
      enqueueFailureMsg(formatMessage(messages.categoryCreationFailed));
    }

    setIsSubmitting(false);
    submitForm();
  }

  const onDeleteSubmit = async (values: IAssetCategory) => {
    setIsSubmitting(true);
    const result = await deleteAssetCategory(values)

    if (result.success) {
      enqueue(formatMessage(messages.categoryDeletedSuccessfully));
      setDeleteRow(null);
    } else {
      enqueueFailureMsg(formatMessage(messages.categoryDeletionFailed));
    }

    setIsSubmitting(false);
    submitForm();
  }

  const onEditSubmit = async (values: IAssetCategory) => {
    if (values.subType === SubType.SystemDefined) {
      await onAddSubmit({ ...values, subType: SubType.UserDefined });
      setEditRow(null);
    } else if (values.isCloneOfSysmtemDefined) {
      await onDeleteSubmit({ ...values, subType: SubType.UserDefined });
      setEditRow(null);
    }
    else {
      setIsSubmitting(true);
      const result = await updateAssetCategory({ ...values, tenantId })
      if (result.success) {
        enqueue(formatMessage(messages.categoryUpdatedSuccessfully));
        setEditRow(null);
      } else {
        enqueueFailureMsg(formatMessage(messages.categoryUpdateFailed));
      }

      setIsSubmitting(false);
      submitForm();
    }
  }

  return (
    <>
      {createModalIsOpen &&
        (<AddAssetCategoryMrtModal
          columnDef={columnsDef}
          onClose={() => setCreateModalIsOpen(false)}
          onSubmit={onAddSubmit}
          dialogTitle={formatMessage(assetCategoryMrtvMessage.addAssetCategoryTitle)}
          loading={isSubmitting}
        />)}
      {editRow &&
        (<EditAssetCategoryMrtModal
          columnDef={columnsDef}
          row={editRow}
          onClose={() => setEditRow(null)}
          onSubmit={onEditSubmit}
          dialogTitle={formatMessage(assetCategoryMrtvMessage.editAssetCategoryTitle)}
          loading={isSubmitting}
        />)}
      {deleteRow &&
        (<DeleteAssetCategoryMrtModal
          columnDef={columnsDef}
          row={deleteRow}
          onClose={() => setDeleteRow(null)}
          onSubmit={onDeleteSubmit}
          dialogTitle={formatMessage(assetCategoryMrtvMessage.deleteAssetCategoryTitle)}
          loading={isSubmitting}
        />)}
      <MRTDataGrid
        heading={messages.assetsCategories}
        columns={columns}
        data={values.assetCategories as Partial<IAssetCategory>[]}
        handleAddItem={() => setCreateModalIsOpen(true)}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading }}
        enableEditing
        renderRowActions={createRowActions}
      />
    </>
  )
}
