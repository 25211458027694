import { alpha, styled } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import { drawerWidth } from '../Drawer/Drawer'
import { AppBarProps } from './types'

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, anchor }) => ({
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: alpha(theme.palette.secondary.main, 1),
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: alpha('#ffffff', 0.92),
    zIndex: -1,
  },
  justifyContent: 'center',
  width: '100%',
  height: '80px',
  paddingLeft: '96px',
  ...(anchor === 'right' && {
    paddingRight: '96px',
    paddingLeft: '0px',
  }),
  boxShadow: 'none',
  ...(open && anchor === 'left' && {
    paddingLeft: '0px',
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(open && anchor === 'right' && {
    paddingRight: `${drawerWidth}px`,
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))
