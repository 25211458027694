import { IAddress, KeyofAddress } from '../../Shipments/types'
import { isLocaleEquals } from '../../utils'
import { FormikCustomersSettings, ITenantCustomer } from './types'
interface IIsUnique {
  formik: FormikCustomersSettings
  id: number | undefined
  propToCheck: keyof ITenantCustomer
  valueToCheck: string
}

export const matchCustomer = (a: ITenantCustomer, b: ITenantCustomer) => {
  const result =
    isLocaleEquals(a.name, b.name) &&
    isLocaleEquals(a.fullAddress, b.fullAddress) &&
    a.defaultCurrencyId === b.defaultCurrencyId &&
    a.enabled === b.enabled

  return result
}

export const isUniqueCustomer = ({ formik, id, propToCheck, valueToCheck }: IIsUnique) => {
  const valueIsUnique =
    formik.values.allTenantCustomers
      ?.filter((customer) => customer.id !== id)
      .find((customer) => customer[propToCheck] === valueToCheck) === undefined

  return valueIsUnique
}

export const getStringFromAddress = (address: IAddress) => {
  const fullAddress = Object.keys(address)
    .map((key) => {
      if (address[key as KeyofAddress] === undefined) null
      return address[key as KeyofAddress]
    })
    .filter(Boolean)
    .join(', ')

  return fullAddress
}

export const getAddressFromString = (fullAddress = ''): IAddress => {
  const address = {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  }

  const addressArray = fullAddress.split(',')
  address.address1 = addressArray[0].trim()
  address.address2 = addressArray[1].trim()
  address.address3 = addressArray[2].trim()
  address.city = addressArray[3].trim()
  address.state = addressArray[4].trim()
  address.postalCode = addressArray[5].trim()
  address.country = addressArray[6].trim()

  return address
}
