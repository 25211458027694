import { FormikProps } from 'formik'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { ITransportJourney } from '../../types'
import Leg from './Leg'

export interface ILegsProps {
  formik: FormikProps<ITransportJourney>
  locations: ILocationByTenantCustomer[]
}

const Legs = ({ formik, locations }: ILegsProps) => {
  return (
    <>
      {formik.values.legs.map((leg, legIdx) => {
        return (
          <Leg
            key={`leg${legIdx}`}
            formik={formik}
            leg={leg}
            legIdx={legIdx}
            locations={locations}
          />
        )
      })}
    </>
  )
}

export default Legs
