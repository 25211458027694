import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { useGetLocationsQuery } from '../../../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../../../components/Spinner/SpinnerBlock'
import {
  fetchAllCountriesSelector,
  fetchAllCustomersByTenantSelector,
} from '../../../Shipments/selectors'
import {
  fetchAllCountriesThunk,
  fetchAllCustomersByTenantIdThunk,
  fetchLocationsByTenantOrCustomerThunk,
} from '../../../Shipments/slice'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { fetchTransportsLoadsSelector } from '../../selectors'
import { fetchTransportsEmployeeThunk, fetchTransportsLoadsThunk } from '../../slice'
import CreateJourneyForm from './CreateJourneyForm'

const CreateJourney = () => {
  const dispatch = useAppDispatch()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const loads = useAppSelector(fetchTransportsLoadsSelector.data) ?? []
  const isLoadFetching = useAppSelector(fetchTransportsLoadsSelector.isFetching)
  const isLoadFetched = useAppSelector(fetchTransportsLoadsSelector.isFinished)

  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const isCountriesFetching = useAppSelector(fetchAllCountriesSelector.isFetching)
  const isCountriesFetched = useAppSelector(fetchAllCountriesSelector.isFinished)
  const isCustomerFetching = useAppSelector(fetchAllCustomersByTenantSelector.isFetching)
  const isCustomerFetched = useAppSelector(fetchAllCustomersByTenantSelector.isFinished)
  const customers = useAppSelector(fetchAllCustomersByTenantSelector.data) ?? []

  const tenantId = loggedInUser?.tenantId

  const {
    data: locations = [],
    isSuccess: isLocationsFetched,
    isFetching: isLocationsFetching,
  } = useGetLocationsQuery({
    tenantId: tenantId ?? -1,
    includeCustomerLocations: true,
  })
  const isLoaded =
    !isLoadFetching &&
    isLoadFetched &&
    isLocationsFetched &&
    !isLocationsFetching &&
    !isCountriesFetching &&
    isCountriesFetched &&
    !isCustomerFetching &&
    isCustomerFetched

  useEffect(() => {
    if (tenantId) {
      dispatch(fetchTransportsLoadsThunk(tenantId))
      dispatch(fetchTransportsEmployeeThunk(tenantId))
      dispatch(fetchLocationsByTenantOrCustomerThunk(tenantId, undefined, true))
      dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
    }
  }, [tenantId])

  useEffect(() => {
    if (countries.length === 0) {
      dispatch(fetchAllCountriesThunk())
    }
  }, [countries])

  const getTransportLegLoads = () => {
    return loads.map((l) => {
      return {
        ...l,
        customerName: customers.find((c) => c.id === l.customerId)?.name,
        assetConfigurations: [],
      }
    })
  }

  if (!isLoaded) {
    return <SpinnerBlock />
  }

  return <CreateJourneyForm loads={getTransportLegLoads()} locations={locations} />
}

export default CreateJourney
