import { IAssetClass } from '../../types';

import { deleteAssetClass as deleteAsset } from '../../api';

interface Result {
    success: boolean;
}

export const deleteAssetClass = async (values: IAssetClass): Promise<Result> => {
      const { tenantId } = values;
      const deleteResult = { success: true };
      try {
        await deleteAsset(values, tenantId as number);
      } catch (error) {
        deleteResult.success = false;
      }
      return deleteResult;
}
