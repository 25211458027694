import { Edit } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { VisibilityState } from '@tanstack/react-table'
import {
  MRT_Cell as MRTCell,
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MaterialReactTableProps,
} from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../../app/hooks'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { fetchAllCountriesSelector } from '../../../../Shipments/selectors'
import { IAddress, ICurrency } from '../../../../Shipments/types'
import { HttpAction } from '../../../../enums'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { ILocation, ILoggedInCustomer } from '../../../../types'
import { generateRandomId, validateRequired } from '../../../../utils'
import messages from '../../messages'
import { FormikCustomersSettings, IDropdownOption, ITenantCustomer } from '../../types'
import { getStringFromAddress } from '../../utils'
import AddCustomerModal from '../AddCustomerModal/AddCustomerModal'

export interface ICustomerMRTProps {
  formik: FormikCustomersSettings
  isLoading: boolean
  isAddDisabled: boolean
}
export const CustomerMRT = ({ formik, isLoading, isAddDisabled }: ICustomerMRTProps) => {
  const { formatMessage } = useIntl()

  const loggedInCustomer = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInCustomer)


  const { tenantId } = loggedInCustomer

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string
  }>({})

  const allTenantCurrencies = formik.values.allTenantCurrencies || []
  const allCountries = useAppSelector(fetchAllCountriesSelector.data) || []

  const currencyOptions = useMemo<IDropdownOption[]>(() => {
    return allTenantCurrencies.map(
      (currency) =>
      ({
        value: currency.name,
        text: currency.iso3,
      } as IDropdownOption)
    )
  }, [allTenantCurrencies])

  const allCountryOptions = useMemo<IDropdownOption[]>(() => {
    return allCountries.map(
      (country) =>
      ({
        value: country.name,
        text: country.name,
      } as IDropdownOption)
    )
  }, [allCountries])

  const data = formik.values.allTenantCustomers || []

  const columnVisibility: VisibilityState = {
    ['location.address.address1']: false,
    ['location.address.address2']: false,
    ['location.address.address3']: false,
    ['location.address.city']: false,
    ['location.address.state']: false,
    ['location.address.postalCode']: false,
    ['location.name']: false,
  }

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRTCell<ITenantCustomer>
    ): MRTColumnDef<ITenantCustomer>['muiTableBodyCellEditTextFieldProps'] => {
      const columnsToValiate = [
        'name',
        'location.address.address1',
        'location.address.postalCode',
        'location.name',
        'defaultCurrencyName',
        'enabled',
      ]
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const columnId = cell.column.id as keyof ITenantCustomer

          if (!columnsToValiate.includes(columnId)) return

          const hasValue = validateRequired(event.target.value)

          const maxLength = 100

          const isValidLength = event.target.value.length <= maxLength

          const isValid = hasValue && isValidLength

          if (!isValid) {
            const propertyName = cell.column.columnDef.header

            const validationMessage = !hasValue
              ? formatMessage(messages.propertyValueRequired, { propertyName })
              : !isValidLength
                ? formatMessage(messages.propertyValueMaxLength, { propertyName, maxLength })
                : ''

            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationMessage,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [validationErrors]
  )

  const columns: MRTColumnDef<ITenantCustomer>[] = [
    {
      accessorKey: 'name',
      header: formatMessage(messages.name),
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'location.address.address1',
      header: formatMessage(messages.address1),
      accessorFn: (row) => row.location.address.address1 ?? null,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'location.address.address2',
      header: formatMessage(messages.address2),
      accessorFn: (row) => row.location.address.address2 ?? null,
    },
    {
      accessorKey: 'location.address.address3',
      header: formatMessage(messages.address3),
      accessorFn: (row) => row.location.address.address3 ?? null,
    },
    {
      accessorKey: 'location.address.city',
      header: formatMessage(messages.city),
      accessorFn: (row) => row.location.address.city ?? null,
    },
    {
      accessorKey: 'location.address.state',
      header: formatMessage(messages.state),
      accessorFn: (row) => row.location.address.state ?? null,
    },
    {
      accessorKey: 'location.address.postalCode',
      header: formatMessage(messages.postalCode),
      accessorFn: (row) => row.location.address.postalCode ?? null,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'location.name',
      header: formatMessage(messages.country),
      editVariant: 'select',
      editSelectOptions: allCountryOptions,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'fullAddress',
      header: formatMessage(messages.address),
      accessorFn: (row) => row.fullAddress ?? null,
      enableEditing: false,
    },
    {
      header: formatMessage(messages.defaultCurrency),
      accessorKey: 'defaultCurrencyName',
      editVariant: 'select',
      editSelectOptions: currencyOptions,
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
    {
      accessorKey: 'enabled',
      header: formatMessage(messages.enabled),
      editVariant: 'select',
      editSelectOptions: ['Yes', 'No'],
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      }),
    },
  ]

  const renderRowActions = ({
    row,
    table,
  }: {
    cell: MRTCell<ITenantCustomer>
    row: MRTRow<ITenantCustomer>
    table: MRTTableInstance<ITenantCustomer>
  }) => {
    return (
      <Tooltip arrow placement='left' title='Edit'>
        <IconButton color='info' disabled={false} onClick={() => table.setEditingRow(row)}>
          <Edit />
        </IconButton>
      </Tooltip>
    )
  }

  const handleEditingRowSave: MaterialReactTableProps<ITenantCustomer>['onEditingRowSave'] =
    async ({
      exitEditingMode,
      row,
      values,
    }: {
      exitEditingMode: () => void
      row: MRTRow<ITenantCustomer>
      values: ITenantCustomer
    }) => {
      if (Object.keys(validationErrors).length > 0) {
        return
      }

      const { original } = row
      const { id } = original as ITenantCustomer

      const defaultCurrencyId = allTenantCurrencies.find(
        (currency) => currency.name === values.defaultCurrencyName
      )?.id

      type KeyOfITenantCustomer = keyof ITenantCustomer

      const country = values['location.name' as KeyOfITenantCustomer]

      const address = {
        address1: values['location.address.address1' as KeyOfITenantCustomer],
        address2: values['location.address.address2' as KeyOfITenantCustomer],
        address3: values['location.address.address3' as KeyOfITenantCustomer],
        city: values['location.address.city' as KeyOfITenantCustomer],
        state: values['location.address.state' as KeyOfITenantCustomer],
        postalCode: values['location.address.postalCode' as KeyOfITenantCustomer],
        country,
      } as IAddress

      const fullAddress = getStringFromAddress(address)

      const updatedAllTenantCustomers = data.map((customer) => {
        if (customer.id === id) {
          return {
            ...customer,
            name: values.name,
            location: {
              ...customer.location,
              address: {
                ...customer.location.address,
                ...address,
              },
              name: country,
            },
            defaultTransportModeId: customer.location.defaultTransportModeId,
            fullAddress,
            defaultCurrencyId,
            defaultCurrencyName: values.defaultCurrencyName,
            ...{ disabled: values.enabled !== 'Yes' },
            enabled: values.enabled,
          }
        }
        return customer
      })

      formik.setFieldValue('allTenantCustomers', updatedAllTenantCustomers)
      exitEditingMode()
    }

  const handleEditRowCancel: MaterialReactTableProps<ITenantCustomer>['onEditingRowCancel'] = ({
    table,
  }: {
    row: MRTRow<ITenantCustomer>
    table: MRTTableInstance<ITenantCustomer>
  }) => {
    setValidationErrors({})

    table.setEditingRow(null)
  }

  const [addCurrencyModalOpen, setAddCurrencyModalOpen] = useState(false)

  const handleAddItem = () => setAddCurrencyModalOpen(true)

  const handleAddNewCustomer = async (values: Partial<ITenantCustomer> & IAddress) => {
    const { allTenantCustomers } = formik.values
    const {
      name,
      defaultCurrencyName,
      enabled,
      address1,
      address2,
      address3,
      city,
      state,
      postalCode,
      country,
      locationName
    } = values

    const fullAddress = getStringFromAddress({
      address1,
      address2,
      address3,
      city,
      state,
      postalCode,
    })

    const currency = allTenantCurrencies.find(
      (currency) => currency.name === defaultCurrencyName
    ) as ICurrency

    const defaultCurrencyId = currency.id


    const customerId = generateRandomId()
    const locationId = generateRandomId()
    const addressId = generateRandomId()
    const countryId = allCountries.find((ctry) => ctry.name === country)?.id

    const address = {
      id: addressId,
      countryId,
      address1: values.address1,
      address2: values.address2,
      address3: values.address3,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
    } as IAddress

    const location = {
      id: locationId,
      tenantId,
      customerId,
      locationTypeId: 0,
      addressId,
      address,
      defaultTransportModeId: 2,
      defaultCurrencyId,
      name: locationName,
      lat: 0,
      lng: 0,
      geofence: [],
    } as ILocation

    const newCustomer: Partial<ITenantCustomer> = {
      id: generateRandomId(),
      tenantId,
      locationId: 0,
      location,
      defaultCurrencyId,
      currency,
      name,
      disabled: enabled !== 'Yes',

      enabled,
      isNew: true,
      defaultCurrencyName,
      httpAction: HttpAction.POST,
      fullAddress,
    }

    const updatedData = [newCustomer, ...allTenantCustomers]
    formik.setFieldValue('allTenantCustomers', updatedData)
  }

  return (
    <>
      <AddCustomerModal
        open={addCurrencyModalOpen}
        onClose={() => setAddCurrencyModalOpen(false)}
        onSubmit={handleAddNewCustomer}
        currencyFormik={formik}
        currencyOptions={currencyOptions}
        countryOptions={allCountryOptions}
      />
      <MRTDataGrid
        data={data}
        heading={messages.tableHeader}
        columns={columns}
        state={{
          isLoading,
        }}
        enableEditing
        renderRowActions={renderRowActions}
        onEditingRowSave={handleEditingRowSave}
        onEditingRowCancel={handleEditRowCancel}
        handleAddItem={handleAddItem}
        isAddItemDisabled={isAddDisabled}
        muiTableBodyCellEditTextFieldProps={{ variant: 'outlined' }}
        initialState={{
          columnVisibility,
        }}
      />
    </>
  )
}

export default CustomerMRT
