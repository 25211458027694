import { Box, Grid, Tab, Tabs } from '@mui/material'
import { useIntl } from 'react-intl'
import messages from './messages'

interface MemberRoleTabPanelProps {
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
}
const MemberRoleTabPanel = ({ activeTab, setActiveTab }: MemberRoleTabPanelProps) => {
  const { formatMessage } = useIntl()

  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  return (
    <>
      <Grid
        position='sticky'
        sx={{
          // top: 118,
          backgroundColor: 'white',
          boxShadow: 'none',
          // zIndex: 1100,
          // marginBottom: '10px',
          // outline: '1px solid red',
        }}
        className='tab-appbar'
      >
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            centered
            variant='standard'
            scrollButtons={false}
            aria-label='Tenant settings scrollable tabs'
          >
            <Tab label={formatMessage(messages.memeberSettingsTab)} />
            <Tab label={formatMessage(messages.roleSettingTab)} />
          </Tabs>
        </Box>
      </Grid>
    </>
  )
}

export default MemberRoleTabPanel
