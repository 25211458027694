import { FormikProps } from 'formik'
import { useEffect } from 'react'
import { getFirstErrorKey } from './useScrollToFieldError'

function determineStepForError(errorFieldName: string, isVirtualLoadMode: boolean): number {
  if (errorFieldName.includes('loads')) {
    return isVirtualLoadMode ? 2 : 1
  }
  if (errorFieldName.includes('assetRequests')) {
    return 2
  }
  return 0
}

export function useNavigateToErrorStep(formik: FormikProps<any>, setStep: any) {
  const { isSubmitting, isValidating, errors } = formik
  const isVirtualLoadMode = !formik.values.loads.some((x: any) => x.isDefined)

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorFieldName = getFirstErrorKey(errors)
      if (errorFieldName) {
        const step = determineStepForError(errorFieldName, isVirtualLoadMode)
        setStep(step)
      }
    }
  }, [isSubmitting, isValidating, errors, setStep, isVirtualLoadMode])
}
