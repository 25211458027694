import { FormikCurrencySettings } from '../types'
import { ICurrencyRow } from './types'

interface IIsUnique {
  formik: FormikCurrencySettings
  id: number | undefined
  propToCheck: keyof ICurrencyRow
  valueToCheck: string
}

export const isUnique = ({ formik, id, propToCheck, valueToCheck }: IIsUnique) => {
  const valueIsUnique =
    formik.values.currencySettings
      .filter((curr) => curr.id !== id)
      .find((curr) => curr[propToCheck] === valueToCheck) === undefined

  return valueIsUnique
}
