import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tableHeader: {
    id: 'customerSettings.customer.tableHeader',
    description: 'Customer table header',
    defaultMessage: 'Customers',
  },
  name: {
    id: 'customerSettings.customer.name',
    description: 'Customer Settings name',
    defaultMessage: 'Name',
  },
  locationName: {
    id: 'customerSettings.customer.locationName',
    description: 'Customer Settings Location Name name',
    defaultMessage: 'Location Name',
  },
  address: {
    id: 'customerSettings.customer.address',
    description: 'Customer Settings address',
    defaultMessage: 'Address',
  },
  address1: {
    id: 'customerSettings.customer.address1',
    description: 'Customer Settings address1',
    defaultMessage: 'Address 1',
  },
  address2: {
    id: 'customerSettings.customer.address2',
    description: 'Customer Settings address2',
    defaultMessage: 'Address 2',
  },
  address3: {
    id: 'customerSettings.customer.address3',
    description: 'Customer Settings address3',
    defaultMessage: 'Address 3',
  },
  city: {
    id: 'customerSettings.customer.city',
    description: 'Customer Settings city',
    defaultMessage: 'City',
  },
  state: {
    id: 'customerSettings.customer.state',
    description: 'Customer Settings state',
    defaultMessage: 'State',
  },
  postalCode: {
    id: 'customerSettings.customer.postalCode',
    description: 'Customer Settings postalCode',
    defaultMessage: 'Postal Code',
  },
  country: {
    id: 'customerSettings.customer.country',
    description: 'Customer Settings country',
    defaultMessage: 'Country',
  },
  defaultCurrency: {
    id: 'customerSettings.customer.defaultCurrency',
    description: 'Customer Settings defaultCurrency',
    defaultMessage: 'Default Currency',
  },
  enabled: {
    id: 'customerSettings.customer.enabled',
    description: 'Customer Settings enabled',
    defaultMessage: 'Enabled',
  },

  // API message
  customerCreateSuccess: {
    id: 'customerSettings.customer.customerCreateSuccess',
    description: 'Customer Settings customerCreateSuccess',
    defaultMessage: 'Successfully created {customerName} customer',
  },
  customerCreateError: {
    id: 'customerSettings.customer.customerCreateError',
    description: 'Customer Settings customerCreateError',
    defaultMessage: 'Error creating {customerName} customer',
  },
  customerUpdateSuccess: {
    id: 'customerSettings.customer.customerUpdateSuccess',
    description: 'Customer Settings customerUpdateSuccess',
    defaultMessage: 'Successfully updated {customerName} customer',
  },
  customerUpdateError: {
    id: 'customerSettings.customer.customerUpdateError',
    description: 'Customer Settings customerUpdateError',
    defaultMessage: 'Error updating {customerName} customer',
  },
  customerDeleteSuccess: {
    id: 'customerSettings.customer.customerDeleteSuccess',
    description: 'Customer Settings customerDeleteSuccess',
    defaultMessage: 'Successfully deleted {customerName} customer',
  },
  customerDeleteError: {
    id: 'customerSettings.customer.customerDeleteError',
    description: 'Customer Settings customerDeleteError',
    defaultMessage: 'Error deleting {customerName} customer',
  },

  // Validation messages
  propertyValueRequired: {
    id: 'customerSettings.customer.propertyValueRequired',
    description: 'Customer value required',
    defaultMessage: 'Customer {propertyName} is required',
  },
  propertyValueMustBeUnique: {
    id: 'customerSettings.customer.propertyValueMustBeUnique',
    description: 'Customer value must be unique',
    defaultMessage: 'Customer {propertyName} must be unique',
  },
  propertyValueMaxLength: {
    id: 'customerSettings.customer.propertyValueMaxLength',
    description: 'Customer value max length',
    defaultMessage: 'Customer {propertyName} must be max {length} characters',
  },

  addNewCustomerModalTitle: {
    id: 'customerSettings.customer.addNewCustomerModalTitle',
    description: 'Customer Settings addNewCustomerModalTitle',
    defaultMessage: 'Add New Customer',
  },
})

export default messages
