import { Box, TextField } from '@mui/material'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { fetchAllCountriesSelector } from '../../Shipments/selectors'
import messages from './messages'
import { getAddressError, getError } from './utils'
import { SectionLabelTypography } from '../../../components/Typographies/styles'

type AddressDetailsProps = {
    formik: any
    disabled?: boolean
    type: 'create' | 'view' | 'edit'
    updateMarker?: any

}

const AddressDetails = ({ formik, disabled = false, type, updateMarker }: AddressDetailsProps): ReactElement<any, any> => {
    const { formatMessage } = useIntl()
    const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
    const view = type === 'view'
    if (updateMarker) {
        updateMarker({ lat: formik.values.lat, lng: formik.values.lng })
    }
    return (
        <Box sx={{ width: '85%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.address1)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.address1'
                    name='address.address1'
                    value={formik.values.address.address1}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.address1 && Boolean(formik.errors.address?.address1)}
                    helperText={getAddressError('address1', formik)}
                />
            </Box>
            <Box display='flex' columnGap={2} sx={{ mb: '16px' }}>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.address2'
                    name='address.address2'
                    label={view && !formik.values.address2 ? '' : formatMessage(messages.address2)}
                    value={formik.values.address.address2}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.address2 && Boolean(formik.errors.address?.address2)}
                    helperText={getAddressError('address2', formik)}
                />
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.address3'
                    name='address.address3'
                    label={view && !formik.values.address.address3 ? '' : formatMessage(messages.address3)}
                    value={formik.values.address.address3}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.address3 && Boolean(formik.errors.address?.address3)}
                    helperText={getAddressError('address3', formik)}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.city)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.city'
                    name='address.city'
                    value={formik.values.address.city}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.city && Boolean(formik.errors.address?.city)}
                    helperText={getAddressError('city', formik)}
                />
            </Box>
            <Box display='flex' columnGap={2} sx={{ mb: '16px' }}>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.state'
                    name='address.state'
                    label={formatMessage(messages.state)}
                    value={formik.values.address.state}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.state && Boolean(formik.errors.address?.state)}
                    helperText={getAddressError('state', formik)}
                />
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='address.postalCode'
                    name='address.postalCode'
                    label={formatMessage(messages.postCode)}
                    value={formik.values.address.postalCode}
                    onChange={formik.handleChange}
                    error={formik.touched.address?.postalCode && Boolean(formik.errors.address?.postalCode)}
                    helperText={getAddressError('postCode', formik)}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.country)}<RequiredAsterisk /></SectionLabelTypography>
                <FormDropdown
                    id='address.countryId'
                    disabled={disabled}
                    onChange={(e, name, newValue) => {
                        formik.setFieldValue(name, newValue)
                    }}
                    value={formik.values.address?.countryId}
                    items={countries}
                    handleOnBlur={formik.handleBlur}
                    error={
                        (formik.touched.address?.countryId as any) && Boolean(formik.errors.address?.countryId)
                    }
                />
            </Box>
            <Box display='flex' columnGap={2} marginBottom={5}>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='lat'
                    name='lat'
                    label={formatMessage(messages.lat)}
                    value={formik.values.lat}
                    onChange={formik.handleChange}
                    type='number'
                    error={formik.touched.lat && Boolean(formik.errors.lat)}
                    helperText={getError('lat', formik)}
                    InputLabelProps={{
                        shrink: !!formik.values.lat,
                    }}
                />
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='lng'
                    name='lng'
                    label={formatMessage(messages.lng)}
                    value={formik.values.lng}
                    onChange={formik.handleChange}
                    type='number'
                    error={formik.touched.lng && Boolean(formik.errors.lng)}
                    helperText={getError('lng', formik)}
                    InputLabelProps={{
                        shrink: !!formik.values.lng,
                    }}
                />
            </Box>
        </Box>
    )
}

export default AddressDetails