import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../app/axios/omnialog'
import { ILoggedInCustomer } from '../types'
import { ITenantCustomer } from './Customer/types'

export const putCustomerDefaultCurrency = async (
  customer: ILoggedInCustomer
): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.put('/customer/default-currency', customer)
}

export const putCustomer = async (customer: ITenantCustomer): Promise<AxiosResponse<any, any>> => {
  const { tenantId } = customer
  return await usersAuthorizedFetch.put(`/tenant/${tenantId}/customer`, customer)
}

export const postCustomer = async (customer: ITenantCustomer): Promise<AxiosResponse<any, any>> => {
  const { tenantId } = customer
  return await usersAuthorizedFetch.post(`/tenant/${tenantId}/customer`, customer)
}
