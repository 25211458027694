import logger from '../../../../app/middleware/log'
import { fetchCommonSettingsDataThunk, makeFetchSlice } from '../../../../app/redux-fetch'
import { AppDispatch } from '../../../../app/store/store'
import { fetchAllSecurityPrincipals } from './api'
import { COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME } from './consts'

export const fetchAllSecurityPrincipalsSlice = makeFetchSlice(
  COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME
)

export const fetchAllSecurityPrincipalsReducers = fetchAllSecurityPrincipalsSlice.reducer
export const fetchAllSecurityPrincipalsActions = fetchAllSecurityPrincipalsSlice.actions

export const fetchAllSecurityPrincipalsThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCommonSettingsDataThunk({
        dispatch,
        actions: fetchAllSecurityPrincipalsActions,
        asyncFunc: () => fetchAllSecurityPrincipals(),
      })
    } catch (error) {
      logger.error('🚀 Error fetching user group by customer id', error)
    }
  }
}
