import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_RowSelectionState as MRTRowSelectionState,
  MRT_TableInstance as MRTTableInstance,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { HttpAction } from '../../../../enums'
import { IExtendedRole, IRole } from '../../UserRoleAllocation/Role/types'
import { FormikUserGroupSettings, ISelectedMemberRoleIdsByUserGroupId } from '../../types'
import messages from './messages'

interface IAddRoleMRTProps {
  formik: FormikUserGroupSettings
  isLoading: boolean
}

export const AddRoleMRT = ({ formik, isLoading }: IAddRoleMRTProps) => {
  const allRoles = formik.values.formikRoles
  const allGroups = formik.values.formikUserGroupRolesByGroupId
  const selectedUserGroupId = formik.values.formikSelectedUserGroupId as number

  const selectedGroupRoles = (allGroups.find((group) => group.groupId === selectedUserGroupId)
    ?.roles || []) as IExtendedRole[]


  const filteredRoles = allRoles
    .filter(
      (role) =>
        !selectedGroupRoles.find(
          (groupRole) => groupRole.id === role.id && groupRole.httpAction !== HttpAction.DELETE
        )
    )
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  const columns = useMemo<MRTColumnDef<IRole>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ],
    [formik]
  )

  const handleRowSelectionChange = ({
    row,
    table,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IRole>
    table: MRTTableInstance<IRole>
  }) => {
    return {
      onChange: (event: any) => handleChangeSelection(event, row),
      onClick: (event: any) => handleChangeSelection(event, row),
      sx: { cursor: 'pointer' },
    }
  }

  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const handleChangeSelection = (event: React.MouseEvent<HTMLElement>, row: MRTRow<IRole>) => {
    event.preventDefault()
    event.stopPropagation()

    const { id: originalRowId } = row.original

    setRowSelection((prevState) => {
      const newRowSelection: MRTRowSelectionState = {
        ...prevState,
        [originalRowId]: !prevState[originalRowId],
      }
      const newRowSelectionModel = Object.keys(newRowSelection).map((key) => Number(key))

      const selectedMemberRoleIdsByUserGroupId: ISelectedMemberRoleIdsByUserGroupId = {
        selectedUserGroupId,
        selectedRoleIds: newRowSelectionModel.filter((id) => newRowSelection[id] === true),
      }

      formik.setFieldValue(
        'formikSelectedMemberRoleIdsByGroupId',
        selectedMemberRoleIdsByUserGroupId
      )

      return newRowSelection
    })
  }

  return (
    <>
      <MRTDataGrid
        heading={messages.roleTableHeading}
        columns={columns}
        data={filteredRoles}
        state={{ rowSelection, isLoading }}
        getRowId={(row) => `${row.id}`}
        enableEditing={false}
        enableMultiRowSelection={true}
        enableRowSelection={true}
        enableSelectAll={true}
        muiTableBodyRowProps={handleRowSelectionChange}
      />
    </>
  )
}
