import { Formik, Form } from 'formik';
import { PropsWithChildren, useState } from 'react';
import { IAssetSettingsValues } from '../types';
import { AssetCategoryMRT } from './AssetCategoryMRT2';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { 
  fetchAllCategoriesByTransportModeSelector,
  fetchSortedAssetCategoriesByTransportModeSelector,
} from '../selectors';
import { fetchAllAssetCategoriesByTransportModeThunk } from '../slice';
import { ILoggedInUser } from '../../../../types';
import { fetchLoggedInUserSelector } from '../../../../selectors';
import FormDropdown from '../../../../../components/FormDropDown/FormDropdown';
import { messages } from './messages';
import { useIntl } from 'react-intl';
import { fetchAllTransportModesSelector } from '../../../../Shipments/selectors'

type AssetCategoryMrtFormProps = Pick<IAssetSettingsValues, 'assetTypes' > & 
{ 
  onCategoryChange: (value: number | undefined) => void;
};

export function AssetCategoryMrtForm({ onCategoryChange }: PropsWithChildren<AssetCategoryMrtFormProps>) {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser);
  const [transportModeId, setTransportModeId] = useState<number>(0);
  const transportModes = useAppSelector(fetchAllTransportModesSelector.data) ?? [];
  const isFetchingTransportModes = useAppSelector(fetchAllTransportModesSelector.isFetching);
  const assetCategories = useAppSelector(fetchSortedAssetCategoriesByTransportModeSelector);
  const isFetchingAssetCategories = useAppSelector(fetchAllCategoriesByTransportModeSelector.isFetching);

  const onTransportModeChange = (_e: any, _name: string, newValue: number) => {
    setTransportModeId(newValue);
    onCategoryChange(undefined);
    dispatch(fetchAllAssetCategoriesByTransportModeThunk(tenantId, newValue));
  }

  return (
      <>
        <FormDropdown
          id='transportMode'
          items={transportModes}
          label={formatMessage(messages.assetsTransportMode)}
          onChange={onTransportModeChange}
          sx={{ margin: '10px 0' }}
          error={false}
          value={transportModeId}
        />
        {Boolean(transportModeId) && 
          (<Formik
            initialValues={{ selectRow: 0, assetCategories }}
            enableReinitialize
            onSubmit={() => {
                dispatch(fetchAllAssetCategoriesByTransportModeThunk(tenantId, transportModeId));
                onCategoryChange(undefined);
            }}
          >
            <Form>
              <AssetCategoryMRT 
                isLoading={isFetchingTransportModes || isFetchingAssetCategories} 
                tenantId={tenantId} 
                transportModeId={transportModeId}
                onCategoryChange={onCategoryChange}
              />
            </Form>
          </Formik>) 
        }
    </>
  );
};

