import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  SnackbarOrigin,
  Step,
  StepButton,
  Stepper,
  type SelectChangeEvent,
} from '@mui/material'
import { FormikErrors, setNestedObjectValues, useFormik } from 'formik'
import { cloneDeep } from 'lodash/fp'
import React, { useCallback, useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector, useScrollToFormikFieldError } from '../../../../app/hooks'
import { acceptShipment, postDraftShipment, submitShipment } from '../../api'

import { useSnackbar } from 'notistack'
import logger from '../../../../app/middleware/log'
import { useTrackingNumberTemplateQuery } from '../../../../app/redux-fetch/apiQuery'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { default as RoutesEnum, default as rootEnum } from '../../../../components/Routes/rootEnum'
import SpinnerBlock from '../../../../components/Spinner/SpinnerBlock'
import { fetchAllAssetCategoriesByTenantIdSelector } from '../../../TenantSettings/Components/AssetSettings/selectors'
import { fetchAssetCategoriesByTenantIdThunk } from '../../../TenantSettings/Components/AssetSettings/slice'
import { fetchTransportAssetConfigurationThunk } from '../../../Transport/slice'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { NotiStackOptions } from '../../../utils'
import { useNotificationStack } from '../../hooks/useNotificationStack'
import {
  fetchAllCountriesSelector,
  fetchAllCurrenciesSelector,
  fetchAllCustomersByTenantSelector,
  fetchAllLocationsByTenantCustomerSelector,
  fetchAllMeasureUnitsSelector,
  fetchAllTemperatureRangesSelector,
  fetchAllTransportModesSelector,
} from '../../selectors'
import {
  fetchAllCountriesThunk,
  fetchAllCustomersByTenantIdThunk,
  fetchAllPalletTypesThunk,
  fetchAllTemperatureRangeThunk,
  fetchAllTransportModesThunk,
  fetchAllUnitsThunk,
  fetchCurrenciesByCustomerIdThunk,
  fetchLocationsByTenantOrCustomerThunk,
} from '../../slice'
import { ApiErrorMsgsType, IShipment } from '../../types'
import { sortByDisplayOrder, sortById } from '../../utils'
import ConditionalRender from '../EditShipment/Components/ConditionalRender'
import ShipmentContainersOrAssetRequestsStep from './ShipmentContainersOrAssetRequestsStep'
import ShipmentLoadStep from './ShipmentLoadStep'
import ShipmentSummary from './ShipmentSummary'
import ShipmentSummaryStep from './ShipmentSummaryStep'
import { getShipmentValidationSchema } from './ShipmentValidationSchema'
import messages from './messages'
import { type ILoadPalletVector } from './types'


const CreateShipmentForm = (): ReactElement<any, any> => {

  // Localisation support.
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { enqueueMsg } = useNotificationStack()

  const { enqueueSnackbar } = useSnackbar()

  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }
  // Initialise stepper.
  const [activeStep, setActiveStep] = React.useState(0)

  const CreateShipmentSteps = [
    formatMessage(messages.summaryStepName),
    formatMessage(messages.createLoadsStepName),
    formatMessage(messages.assignContainersOrAssetRequestStepName),
    formatMessage(messages.confirmShipment),
  ]

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  // Initialise state.
  const [lastCreatedLoadIndex, setLastCreatedLoadIndex] = useState<number | null>(null)
  const [lastCreatedPallet, setLastCreatedPallet] = useState<ILoadPalletVector | null>(null)

  const isCountriesLoaded = useAppSelector(fetchAllCountriesSelector.isFinished)
  const isUnitsLoaded = useAppSelector(fetchAllMeasureUnitsSelector.isFinished)
  const isTemperatureRangesLoaded = useAppSelector(fetchAllTemperatureRangesSelector.isFinished)
  const isPalletTypesLoaded = useAppSelector(fetchAllMeasureUnitsSelector.isFinished)
  const isTransportModeLoaded = useAppSelector(fetchAllTransportModesSelector.isFinished)
  const isAssetCategoriesLoaded = useAppSelector(
    fetchAllAssetCategoriesByTenantIdSelector.isFinished
  )
  const isCustomerLoaded = useAppSelector(fetchAllCustomersByTenantSelector.isFinished)
  const isLocationLoaded = useAppSelector(fetchAllLocationsByTenantCustomerSelector.isFinished)
  const isCurrenciesLoaded = useAppSelector(fetchAllCurrenciesSelector.isFinished)

  const isCountriesFetching = useAppSelector(fetchAllCountriesSelector.isFetching)
  const isUnitsFetching = useAppSelector(fetchAllMeasureUnitsSelector.isFetching)
  const isTemperatureFetching = useAppSelector(fetchAllTemperatureRangesSelector.isFetching)
  const isPalletTypeFetching = useAppSelector(fetchAllMeasureUnitsSelector.isFetching)
  const isTransportModeFetching = useAppSelector(fetchAllTransportModesSelector.isFetching)
  const isAssetCategoriesFetching = useAppSelector(
    fetchAllAssetCategoriesByTenantIdSelector.isFetching
  )
  const isCustomerFetching = useAppSelector(fetchAllCustomersByTenantSelector.isFetching)
  const isCurrenciesFetching = useAppSelector(fetchAllCurrenciesSelector.isFetching)
  const { data: template, isFetching: isTemplateFetching, isSuccess: isTemplateLoaded, refetch } = useTrackingNumberTemplateQuery({ tenantId });

  const isFetching =
    isCountriesFetching ||
    isUnitsFetching ||
    isTemperatureFetching ||
    isPalletTypeFetching ||
    isPalletTypeFetching ||
    isTransportModeFetching ||
    isAssetCategoriesFetching ||
    isTemplateFetching ||
    isCustomerFetching ||
    (customerId ? isCurrenciesFetching : false)

  const isLoaded =
    !isFetching &&
    isCountriesLoaded &&
    isUnitsLoaded &&
    isTemperatureRangesLoaded &&
    isPalletTypesLoaded &&
    isTransportModeLoaded &&
    isCustomerLoaded &&
    isLocationLoaded &&
    isTemplateLoaded &&
    isAssetCategoriesLoaded &&
    (customerId ? isCurrenciesLoaded : true)

  const isCustomerUser = !!tenantId && !!customerId
  const isTenantUser = !!tenantId && !customerId

  const fetchData = useCallback(async () => {
    dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllUnitsThunk())
    dispatch(fetchAllTemperatureRangeThunk())
    dispatch(fetchAllPalletTypesThunk())
    dispatch(fetchAllTransportModesThunk())
    dispatch(fetchAssetCategoriesByTenantIdThunk(tenantId))
    dispatch(fetchLocationsByTenantOrCustomerThunk(tenantId, customerId))
    dispatch(fetchTransportAssetConfigurationThunk(tenantId))

    if (customerId) dispatch(fetchCurrenciesByCustomerIdThunk(customerId, true))
  }, [dispatch])

  const [isDraft, setIsDraft] = useState(true)
  const [isAccept, setIsAccept] = useState(false)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const initialValues = () => {
    const initialValues = {
      tenantId: tenantId,
      customerId: !customerId ? 0 : customerId,
      currencyId: 0,
      shipperId: 0,
      trackingNumber: '',
      customerReferenceNumber: '',
      description: '',
      value: 0,
      loads: [],
      usesContainer: false,
      assetRequests: [],
      containerDetails: [],
      acceptTermsAndCondition: false,
      acceptWarrnings: true,
    }

    return initialValues
  }
  const isTrackingNumberSetup = useCallback(() => !!template, [template])

  const formik = useFormik<IShipment>({
    initialValues: initialValues(),
    validationSchema: getShipmentValidationSchema(formatMessage),
    onSubmit: async (values) => {

      if (isDraft) {
        await postDraftShipment(tenantId, values)
          .then((data) => {
            enqueueSnackbar(formatMessage(messages.shipmentCreateSuccess), NotiStackOptions.success)
            navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(data.data.data.id))}#${activeStep}`)
          })
          .catch((e: any) => {
            enqueueMsg(e.response.data as ApiErrorMsgsType)
          })
      } else {
        await postDraftShipment(tenantId, values)
          .then(async (data) => {
            const id = data.data.data.id
            if (!isAccept) {
              await submitShipment(tenantId, values, id, customerId)
                .then(() => {
                  enqueueSnackbar('Shipment submitted successfully', {
                    variant: 'success',
                    anchorOrigin,
                  })
                  navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(id))}`)
                  setIsSavingDraft(false)
                })
                .catch((e: any) => {
                  enqueueMsg(e.response.data as ApiErrorMsgsType)
                  setIsSavingDraft(false)
                })
            }
            else {
              await submitShipment(tenantId, values, id, customerId)
                .then(async () => {
                  await acceptShipment(tenantId, values, id)
                    .then(() => {
                      enqueueSnackbar('Shipment accepted successfully', {
                        variant: 'success',
                        anchorOrigin,
                      })
                      navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(id))}`)
                      setIsSavingDraft(false)
                      setIsAccept(false)
                    })
                    .catch((e: any) => {
                      logger.warn('shipment submitting error', e.response?.data?.data?.errors)
                      enqueueMsg(e.response.data as ApiErrorMsgsType)
                      setIsSavingDraft(false)
                      setIsAccept(false)
                    })
                })
                .catch((e: any) => {
                  enqueueMsg(e.response.data as ApiErrorMsgsType)
                  setIsSavingDraft(false)
                  setIsAccept(false)
                })
            }
          })
          .catch((e: any) => {
            logger.warn('shipment saving error', e.response?.data?.data?.errors)
            enqueueMsg(e.response.data as ApiErrorMsgsType)
          })
      }
    },
    onReset: (values, { resetForm }) => {
      resetForm()
    },
  })

  useScrollToFormikFieldError(formik)

  const handleSubmit = () => {
    formik.validateForm().then((errors: FormikErrors<IShipment>) => {
      formik.submitForm().finally(() => {
        setIsSavingDraft(false)
        formik.setTouched(setNestedObjectValues(errors, true))
      })
    })
  }
  const handleNext = (): void => {
    formik
      .validateForm()
      .then((errors: FormikErrors<IShipment>) => {
        formik.setTouched(setNestedObjectValues(errors, true))
        if (
          activeStep === 2 &&
          formik.values.loads.some((l) => l.isDefined) &&
          formik.values.loads.some((l) => !l.isDefined)
        ) {
          return
        }
        if (
          activeStep <= 1 &&
          !formik.values.loads.some((l) => l.isDefined) &&
          errors.loads &&
          Object.keys(errors).length === 1
        ) {
          setActiveStep(activeStep + 1)
          return
        }

        if (
          activeStep < CreateShipmentSteps.length - 1 &&
          (Object.keys(errors).length === 0 || isNaN(Object.keys(errors).length))
        ) {
          setActiveStep(activeStep + 1)
        }
      })
      .catch(() => {
        logger.error('handleBack error')
      })
  }

  const handleBack = (): void => {
    formik
      .validateForm()
      .then((errors: FormikErrors<IShipment>) => {
        formik.setTouched(setNestedObjectValues(errors, true))
        if (activeStep === 1 && !formik.values.loads.some((l) => l.isDefined)) {
          setActiveStep(activeStep - 1)
          return
        }

        if (
          activeStep > 0 &&
          (Object.keys(errors).length === 0 || isNaN(Object.keys(errors).length))
        ) {
          if (activeStep > 0) {
            setActiveStep(activeStep - 1)
          }
        }
      })
      .catch(() => {
        logger.error('handleBack error')
      })
  }

  const handleAddLoad = (): number => {
    setLastCreatedLoadIndex(formik.values.loads.length)
    const lastLoad = formik.values.loads.sort(sortById).slice(-1)[0]
    const lastId = lastLoad?.id ?? 0
    const lastDisplayLoad = formik.values.loads
      .filter((l) => l.isDefined)
      .sort(sortByDisplayOrder)
      .slice(-1)[0]
    const lastDisplayOrder = lastDisplayLoad?.displayOrder ?? 0
    if (formik.values.assetRequests.length === 0) {
      void formik.setFieldValue('usesContainer', true)
    }
    const initialLoadPaylaod = {
      id: lastId - 1,
      value: 0,
      palletDetails: [],
      isDefined: true,
      shipmentId: formik.values.id,
      displayOrder: lastDisplayOrder + 1,
    }
    void formik.setFieldValue('loads', [...formik.values.loads, initialLoadPaylaod])
    return initialLoadPaylaod.id
  }

  const handleAddPallet = (index: number): void => {
    const lastPalletVector: ILoadPalletVector = {
      loadIndex: index,
      palletIndex: formik.values.loads[index].palletDetails?.length ?? 0,
    }
    setLastCreatedPallet(lastPalletVector)
    void formik.setFieldValue(`loads.${index}.palletDetails`, [
      ...(formik.values.loads[index].palletDetails ?? []),
      {},
    ])
  }

  const handleCopyLoads = (loadIndex: number, count: number): void => {
    let lastId = formik.values.loads.sort(sortById).slice(-1)[0]?.id - 1
    let lastDisplayLoad = formik.values.loads
      .filter((l) => l.isDefined)
      .sort(sortByDisplayOrder)
      .slice(-1)[0]?.displayOrder
    const newLoads = []
    for (let i = 0; i < count; i++) {
      lastDisplayLoad++
      const copyLoad = cloneDeep(formik.values.loads[loadIndex])
      copyLoad.id = lastId
      copyLoad.displayOrder = lastDisplayLoad
      copyLoad.assetRequestAllocations = []
      copyLoad.containerAllocations = []
      newLoads.push(copyLoad)
      lastId--
    }
    void formik.setFieldValue('loads', [...formik.values.loads, ...newLoads])
  }

  const handleFormDropDownChanges = (
    e: SelectChangeEvent<number>,
    name: string,
    newValue: any
  ): void => {
    formik.validateField(name)
    void formik.setFieldValue(name, newValue, true)
  }

  const handleRemoveAssetRequests = (index: number): void => {
    const assetRequests = [...formik.values.assetRequests]
    const remAssetRequest = assetRequests.splice(index, 1)[0]
    if (remAssetRequest.loadsId)
      for (const loadId of remAssetRequest.loadsId) {
        const loadIndex = formik.values.loads.findIndex((x) => x.id === loadId)
        const loadContainerAllocations = formik.values.loads[
          loadIndex
        ].assetRequestAllocations.filter((ar) => ar.assetRequestId !== remAssetRequest.id)
        void formik.setFieldValue(
          `loads.${loadIndex}.assetRequestAllocations`,
          loadContainerAllocations
        )
      }
    void formik.setFieldValue('assetRequests', assetRequests)
  }

  const handleRemovePallet = (loadIndex: number, palletIndex: number): void => {
    const load = formik.values.loads[loadIndex]
    const newPallets = [...(load.palletDetails ?? [])]
    newPallets.splice(palletIndex, 1)
    void formik.setFieldValue(`loads.${loadIndex}.palletDetails`, newPallets)
  }

  const handleSwitchLoadChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ): void => {
    const { name } = e.target
    void formik.setFieldValue(name, value)
  }

  const renderContainersOrAssetRequestsStep = (): ReactElement<any, any> => {
    return (
      <ShipmentContainersOrAssetRequestsStep
        formik={formik}
        handleRemoveAssetRequest={handleRemoveAssetRequests}
        handleSelectLoadChanges={handleFormDropDownChanges}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        handleFormDropDownChanges={handleFormDropDownChanges}
      />
    )
  }

  const renderFinalSummaryStep = (): ReactElement<any, any> => {
    return (
      <ShipmentSummary
        formik={formik}
        isSameValue={isSameValue}
        isSameLoadsCurrency={isSameLoadsCurrency}
        isSameWithShipmentCurrency={isSameWithShipmentCurrency}
        isTrackingNumberSetup={isTrackingNumberSetup()}
      />
    )
  }

  const renderLoadStep = (): ReactElement<any, any> => {
    return (
      <ShipmentLoadStep
        formik={formik}
        handleAddLoad={handleAddLoad}
        handleAddPallet={handleAddPallet}
        handleCopyLoads={handleCopyLoads}
        handleFormDropDownChanges={handleFormDropDownChanges}
        handleRemovePallet={handleRemovePallet}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        lastCreatedLoadIndex={lastCreatedLoadIndex}
        lastCreatedPallet={lastCreatedPallet}
      />
    )
  }

  const renderSummaryStep = (): ReactElement<any, any> => {
    return (
      <ShipmentSummaryStep formik={formik} handleFormDropDownChanges={handleFormDropDownChanges} />
    )
  }

  const isPage2 =
    formik.values.assetRequests &&
    formik.values.assetRequests.length === 0 &&
    formik.values.containerDetails &&
    formik.values.containerDetails.length === 0

  const loadsValue = formik.values.loads.reduce((acc, curr) => {
    return (acc += curr.value ? curr.value : 0)
  }, 0)

  const isSameValue = isPage2 ? loadsValue === +formik.values.value : true

  const isSameLoadsCurrency =
    formik.values.loads && formik.values.loads.length > 0
      ? formik.values.loads.every((l) => l.currencyId === formik.values.loads[0].currencyId)
      : true

  const isSameWithShipmentCurrency =
    formik.values.loads && formik.values.loads.length > 0 && isSameLoadsCurrency
      ? formik.values.loads[0].currencyId === formik.values.currencyId
      : true

  const haveLoads = formik.values.loads.length > 0

  const isSubmitDisable = () => {
    return (
      !(
        haveLoads &&
        formik.isValid &&
        formik.values.acceptTermsAndCondition &&
        (formik.values.acceptWarrnings || isSameLoadsCurrency) &&
        isSameWithShipmentCurrency &&
        isSameValue
      ) ||
      isSavingDraft ||
      formik.isSubmitting
      || !isTrackingNumberSetup()
    )
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handleBackToShipment = useCallback(async () => {
    if (!formik.dirty) return navigate(rootEnum.SHIPMENTS)

    setOpenConfirmDialog(true)
  }, [formik.dirty, navigate])

  const handleContinueEdit = () => setOpenConfirmDialog(false)

  const handleDiscardChanges = () => {
    setOpenConfirmDialog(false)
    navigate(rootEnum.SHIPMENTS)
  }

  return (
    <Grid container justifyContent='center' pt='10px'>
      <Grid item xs={12} minWidth='800px'>
        <Stepper activeStep={activeStep}>
          {CreateShipmentSteps.map((label) => (
            <Step key={label}>
              <StepButton sx={{ cursor: 'default' }} color='inherit'>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} minWidth='800px' pt='20px'>
        <Grid container>
          <Grid item xs={2} sx={{ paddingRight: '16px' }}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {formatMessage(messages.buttonBack)}
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button variant='contained' onClick={handleBackToShipment}>
              {formatMessage(messages.buttonBackToShipmentst)}
            </Button>
          </Grid>
          <Grid item xs>
            <Grid container justifyContent='flex-end'>
              <Grid item sx={{ paddingRight: '16px' }}>
                <ConditionalRender condition={isCustomerUser}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      setIsDraft(true)
                      handleSubmit()
                    }}
                    fullWidth
                  >
                    Save Draft
                  </Button>
                </ConditionalRender>
              </Grid>
              <Grid item>
                {activeStep !== 3 ? (
                  <Grid item>
                    <Button color='primary' variant='contained' fullWidth onClick={handleNext}>
                      {formatMessage(messages.buttonNext)}
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid container spacing={1}>
                      <Grid item >
                        <ConditionalRender condition={isTenantUser}>
                          <Button
                            color='primary'
                            variant='contained'
                            disabled={isSubmitDisable()}
                            onClick={() => {
                              setIsDraft(false)
                              setIsAccept(true)
                              handleSubmit()
                            }}
                            fullWidth
                          >
                            {formatMessage(messages.buttonSubmitAndAccept)}

                          </Button>
                        </ConditionalRender>
                      </Grid>
                      <Grid item>
                        <Button
                          color='primary'
                          variant='contained'
                          fullWidth
                          disabled={isSubmitDisable()}
                          onClick={() => {
                            setIsDraft(false)
                            setIsAccept(false)
                            handleSubmit()
                          }}
                        >
                          {formatMessage(messages.buttonSubmit)}
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} minHeight='400px' minWidth='800px' pt='20px'>
        {!isLoaded ? (
          <SpinnerBlock />
        ) : (
          <Box>
            {!isTrackingNumberSetup() && (
              <Grid item xs={12} sx={{ pb: '16px' }}>
                <Alert severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  You must setup your tracking number template before you can submit a shipment.
                </Alert>
              </Grid>
            )}
            {activeStep === 0 && renderSummaryStep()}
            {activeStep === 1 && renderLoadStep()}
            {activeStep === 2 && renderContainersOrAssetRequestsStep()}
            {activeStep === 3 && renderFinalSummaryStep()}
          </Box>
        )}
      </Grid>
      <ConfirmDialog
        open={openConfirmDialog}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogMessageContent)}
        continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
        discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
        onContinueEdit={handleContinueEdit}
        onDiscardChanges={handleDiscardChanges}
      />
    </Grid>
  )
}

export default CreateShipmentForm
