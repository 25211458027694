import { Box, TextField } from '@mui/material'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import { useGetCustomersQuery } from '../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../app/types'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { IHsHeading, IHsSubheading } from '../types'
import { useProductCategoryFormContext } from './hooks/useProductCategoryFormContext'
import messages from './messages'

type MainInfoProps = {
  hsHeadingItems: IHsHeading[]
  hsSubheadingItems: IHsSubheading[]
  isDisabled?: boolean
  isEdit?: boolean
}

const MainInfo = ({
  hsHeadingItems,
  hsSubheadingItems,
  isDisabled = false,
  isEdit = false,
}: MainInfoProps) => {
  const { formatMessage } = useIntl()
  const { values, setFieldValue, errors, touched } = useProductCategoryFormContext()

  const handleTextFieldChange = ({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue(target.id, target.value)
  }
  const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
    setFieldValue(name, newValue)
  }
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: customers } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)

  const isCustomerUser = loggedInUser?.customerId && loggedInUser?.tenantId
  const mapTpDropDownItems = (items: IHsHeading[] | IHsSubheading[]) => {
    return items.map((item) => {
      if ('subheadingCode' in item) {
        const code = item.subheadingCode
        const modifiedCode = `${code.slice(0, -2)} ${code.slice(-2)} - ${item.description}`
        return {
          id: item.id,
          name: modifiedCode,
        }
      } else {
        return {
          id: item.id,
          name: `${item.headingCode} - ${item.description}`,
        }
      }
    })
  }

  const { name, hsHeadingId, hsSubheadingId, customerId } = values

  const getSubheadingItems = useCallback(
    (headingId?: number) => {
      if (headingId) return hsSubheadingItems.filter((item) => item.hsHeadingId === headingId)
      return []
    },
    [hsSubheadingItems]
  )

  return (
    <Box sx={{ width: '50%' }}>
      <Box sx={{ mb: '16px' }}>
        <SectionTitleTypography>{formatMessage(messages.mainInfoTitle)}</SectionTitleTypography>
      </Box>
      {!isCustomerUser && (
        <Box sx={{ mb: '8px' }}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.customerLbl)}
            <RequiredAsterisk />
          </SectionLabelTypography>
          <FormDropdown
            id='customerId'
            items={customers as IDropDownItem[]}
            onChange={handleFormDropDownChange}
            value={customerId}
            disabled={true}
            defaultValue={customerId}
            error={touched.customerId && !!errors.customerId}
            errorText={touched.customerId && errors.customerId}
          />
        </Box>
      )}
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.name)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='name'
          value={name}
          defaultValue={name}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.hsHeading)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='hsHeadingId'
          items={mapTpDropDownItems(hsHeadingItems)}
          onChange={(e: any, name: any, newValue: any) => {
            handleFormDropDownChange(e, name, newValue)
            setFieldValue('hsSubheadingId', undefined)
          }}
          value={hsHeadingId}
          disabled={isDisabled || isEdit || values.hsLocalTariffs.length > 0}
          defaultValue={hsHeadingId}
          error={touched.hsHeadingId && !!errors.hsHeadingId}
          errorText={touched.hsHeadingId && errors.hsHeadingId}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.hsSubheading)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='hsSubheadingId'
          items={mapTpDropDownItems(getSubheadingItems(hsHeadingId))}
          onChange={handleFormDropDownChange}
          value={hsSubheadingId}
          disabled={isDisabled || isEdit || values.hsLocalTariffs.length > 0}
          defaultValue={hsSubheadingId}
          error={touched.hsSubheadingId && !!errors.hsSubheadingId}
          errorText={touched.hsSubheadingId && errors.hsSubheadingId}
        />
      </Box>
    </Box>
  )
}

export default MainInfo
