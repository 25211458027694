
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

interface DatePickerProps {
  disabled?: boolean;
  label: string;
  errorText?: string;
  name: string;
  value?: Date | null;
  disablePast?: boolean;
  onChange?: (date?: Date) => void;
  maxDate?: Date;
  minDate?: Date;
}


export const DatePicker = ({
  disabled,
  label,
  value = null,
  name,
  disablePast = true,
  onChange,
  errorText,
  minDate,
  maxDate,
}: DatePickerProps) => {
  return (
    <DesktopDatePicker
      disablePast={disablePast}
      disabled={disabled}
      inputFormat='DD/MM/YYYY'
      label={label}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(date: any) => {
        if (date?.$d !== 'Invalid Date') {
          const localDate = date?.toDate();
          if (onChange) {
            onChange(localDate);
          }
        }
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          id={name}
          name={name}
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={value}
          error={Boolean(errorText)}
          helperText={errorText}
          disabled={disabled}
        />
      )}
    />
  )
}
