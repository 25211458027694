import { ApiErrorMsgsType } from '../types';

export const getApiErrorMsgs = ({ data }: ApiErrorMsgsType): string [] => {
    if(!data?.errors){
        return [];
    }

    return Object.values(data.errors).reduce((acc, item) => {
        acc.push(...item);
        return acc;
    }, []);
}