import { IAssetType } from '../../types';
import { postAssetType } from '../../api';

interface Result {
    id: number;
    success: boolean;
}

export const createAssetType = async (assetType: IAssetType): Promise<Result> => {
      const { tenantId } = assetType;
      const createResult = { id: 0, success: true };
      try {
        const payload = { ...assetType, tenantId, id: undefined } as unknown as IAssetType;
        const resp = await postAssetType(payload, tenantId as number);
        createResult.id = resp.data.id; 
      } catch (error) {
        createResult.success = false;
      }

      return createResult;
  }
