import { Edit } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MaterialReactTableProps,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import { HttpAction } from '../../../enums'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { isFormDirty } from '../RoleSettings'
import { AddRoleModal } from './Components/AddRoleModal'
import messages from './messages'
import { IExtendedRole, IRole, IRoleProps } from './types'

export const Role = ({ formik, isLoading }: IRoleProps) => {
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { tenantId, customerId } = loggedInUser

  const data: IRole[] = formik.values.formikRoles || []

  const columns = useMemo<MRTColumnDef<IRole>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ]
  }, [])

  const formikSelectedRoleId = formik.values.formikSelectedRoleId

  const formikNextSelectedRoleId = formik.values.formikNextSelectedRoleId

  const handleRowSelectionChange = ({
    row,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IRole>
    table: MRTTableInstance<IRole>
  }) => {
    return {
      onClick: () => {
        const originalSelectedRoleSecurityPrincipalIds: number[] =
          formik.values.formikRoles
            ?.find((role) => role.id === Number(row.id))
            ?.securityPrincipals?.map((securityPrincipal) => securityPrincipal.id) || []

        formik.setFieldValue(
          'originalSelectedSecurityPrincipals',
          originalSelectedRoleSecurityPrincipalIds
        )

        if (!formikSelectedRoleId) {
          formik.setFieldValue('formikSelectedRoleId', Number(row.id))
          return
        }
        if (`${formikSelectedRoleId}` !== row.id) {
          formik.setFieldValue('formikNextSelectedRoleId', Number(row.id))
          if (isFormDirty(formik) || formikSelectedRoleId === -1) {
            openConfrimDialog()
            return
          }
          formik.setFieldValue('formikSelectedRoleId', Number(row.id))
          return
        }
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          row.id === `${formik.values.formikSelectedRoleId}` ? 'lightgrey' : 'initial',
      },
    }
  }

  const openConfrimDialog = () => formik.setFieldValue('openActionConfirmationDialog', true)

  const closeConfrimDialog = () => formik.setFieldValue('openActionConfirmationDialog', false)

  const confirmDialogOpen = formik.values.openActionConfirmationDialog === true

  const handleContinueEdit = () => {
    closeConfrimDialog()
    formik.setFieldValue('formikNextSelectedRoleId', undefined)
    formik.setFieldValue('formikSelectedRoleId', formikSelectedRoleId)
  }

  const handleDiscardChanges = () => {
    closeConfrimDialog()
    formik.setFieldValue('formikSelectedRoleId', formikNextSelectedRoleId)

    // Get formikRoles from formik values
    const formikRoles: IRole[] = formik.values.formikRoles || []

    // Get formikNewRoles from formik values
    const formikNewRoles: IRole[] = formik.values.formikNewRoles || []
    const formikOriginalRole = formik.values.formikOriginalRole

    // Remove new roles from formikRoles
    const newRoles = formikRoles
      .filter((role) => !formikNewRoles.map((newRole) => newRole.id).includes(role.id))
      .map((role) => {
        // Reinstate orignal role values
        if (role.id === formikOriginalRole?.id) {
          return formikOriginalRole
        }
        return role
      })

    // Reset other values
    formik.setFieldValue('formikNewRoles', [])
    formik.setFieldValue('formikEditedRole', undefined)

    // Save new roles to formik values
    formik.setFieldValue('formikRoles', newRoles)
  }

  const [addRoleModalOpen, setAddRoleModalOpen] = useState(false)
  const handleAddItem = () => {
    setAddRoleModalOpen(true)
  }

  const handleAddNewRole = (values: IRole) => {
    const newRole: IExtendedRole = {
      id: -1,
      name: values.name,
      tenantId,
      customerId,
      description: values.description,
      lastUpdated: '',
      securityPrincipals: [],
      securityPrincipalAllocations: [],
      httpAction: HttpAction.POST,
    }
    const newRoles = [newRole, ...data]
    formik.setFieldValue('formikRoles', newRoles)
    formik.setFieldValue('formikNewRoles', [newRole])
    formik.setFieldValue('formikSelectedRoleId', newRole.id)
    formik.setFieldValue('newSelectedSecurityPrincipals', [])
    formik.setFieldValue('originalSelectedSecurityPrincipals', [])
    setAddRoleModalOpen(false)
  }

  const handleSaveRowEdits: MaterialReactTableProps<IRole>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    const editedRole = data.find((role) => role.id === Number(row.id))
    if (!editedRole) {
      exitEditingMode()
      return
    }

    // Save off original role values
    const originalRole = { ...editedRole }
    formik.setFieldValue('formikOriginalRole', originalRole)

    editedRole.name = values.name
    editedRole.description = values.description

    const updatedRoles = data.map((role) => {
      if (role.id === Number(row.id)) {
        return editedRole
      }
      return role
    })

    formik.setFieldValue('formikRoles', updatedRoles)
    formik.setFieldValue(
      'formikEditedRole',
      updatedRoles.find((role) => role.id === Number(row.id))
    )

    exitEditingMode() // required to exit editing mode and close modal
  }

  return (
    <>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogMessageContent)}
        continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
        discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
        onContinueEdit={handleContinueEdit}
        onDiscardChanges={handleDiscardChanges}
      />
      <AddRoleModal
        columns={columns}
        onClose={() => setAddRoleModalOpen(false)}
        onSubmit={handleAddNewRole}
        open={addRoleModalOpen}
      />
      <MRTDataGrid
        heading={messages.roleTableHeading}
        columns={columns}
        data={data}
        handleAddItem={handleAddItem}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading }}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        renderRowActions={({ row, table }) => {
          return (
            <Tooltip arrow placement='left' title='Edit'>
              <IconButton color='info' onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
          )
        }}
      />
    </>
  )
}
