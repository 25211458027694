import { Box } from '@mui/material'
import MaterialReactTable, { MaterialReactTableProps } from 'material-react-table'
import { MessageDescriptor, useIntl } from 'react-intl'
import { AddItemAction } from './AddItemAction'
import { DeleteItemAction } from './DeleteItemAction'

export interface IDataGridProps<TData extends Record<string, any>>
  extends MaterialReactTableProps<TData> {
  contianerStyles?: React.CSSProperties
  heading?: MessageDescriptor
  headingComponent?: React.ReactElement
  handleAddItem?: () => void
  handleBulkDelete?: () => void
  selectedRowCount?: number
  isAddItemDisabled?: boolean
}

// eslint-disable-next-line
export const MRTDataGrid = <TData extends Record<string, any> = {}>(
  props: IDataGridProps<TData>
) => {
  const { formatMessage } = useIntl()

  const styles = {
    boxStyles: {
      borderRadius: '3px',
      padding: '0 10px 10px 10px',
      marginBottom: '20px',
    },
  }

  const {
    heading,
    contianerStyles,
    headingComponent,
    handleAddItem,
    handleBulkDelete,
    enableRowSelection,
    isAddItemDisabled,
    enableDensityToggle = true,
    state,
    ...rest
  } = props

  const rowSelection = state?.rowSelection || {}
  const selectedRowCount = Object.keys(rowSelection).filter((key) => rowSelection[key]).length
  return (
    <Box sx={styles.boxStyles}>
      <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <h3 style={{ padding: '5px', display: 'inline-flex', margin: '10px 10px 10px 0' }}>
          {heading && formatMessage(heading)}
        </h3>
        <AddItemAction
          show={handleAddItem !== undefined}
          onAddItem={handleAddItem}
          isDisabled={isAddItemDisabled}
        />
        <DeleteItemAction
          show={!!enableRowSelection && handleBulkDelete !== undefined}
          onDeleteItem={handleBulkDelete}
          disabled={selectedRowCount === 0}
        />
        {headingComponent}
      </Box>
      <Box style={contianerStyles} sx={{ flexGrow: 1 }}>
        <MaterialReactTable
          initialState={{ density: 'compact' }}
          {...{
            ...rest,
            enableRowSelection,
            enableDensityToggle,
            state: { ...state },
          }}
        />
      </Box>
    </Box>
  )
}

export default MRTDataGrid
