import { IAssetClass } from '../../types';

import { updateAssetClass as update } from '../../api';

interface Result {
    isSystemDefined: boolean;
    success: boolean;
}

export const updateAssetClass = async (
    values: IAssetClass
): Promise<Result> => {
      const { tenantId } = values;
      const updateResult = { isSystemDefined: false, success: true };
      try {
        const payload = { ...values, tenantId } as IAssetClass
        await update(payload, tenantId as number)
      } catch (error) {
        updateResult.isSystemDefined = false;
      }

      return updateResult;
  }
