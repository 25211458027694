import {
    Box,
    Button,
    ButtonTypeMap,
    CircularProgress
} from '@mui/material';
  
  interface SubmitButtonProps {
    onClick: () => void
    btnText: string;
    disabled?: boolean;
    loading?: boolean;
    btnColor?: ButtonTypeMap['props']['color'];
  }

  export function SubmitButton({
    onClick,
    btnText,
    disabled,
    loading,
    btnColor = 'secondary'
  }: SubmitButtonProps){
  
    return (
          <Box sx={{ position: 'relative' }}>
            <Button 
                onClick={onClick} 
                color={btnColor} 
                variant='contained' 
                disabled={disabled || loading}
            >
              {btnText}
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position:'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
    )
  }
  