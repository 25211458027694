import { Form, Formik } from 'formik';
import { PropsWithChildren, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { fetchLoggedInUserSelector } from '../../../../selectors';
import { ILoggedInUser } from '../../../../types';
import {
  fetchAssetTypesByCategorySelector,
  fetchSortedAssetTypesByCategorySelector,
  fetchSortedUserTypesByTenantSelector,
  fetchUserTypesSelector,
} from '../selectors';
import { fetchAssetTypesByCategoryThunk, fetchUserTypeThunk } from '../slice';
import { IAssetSettingsValues } from '../types';
import { AssetTypeMRT } from './AssetTypeMRTV2';
import isEmpty from 'lodash/isEmpty';

type AssetTypeMrtFormProps = Pick<IAssetSettingsValues, 'assetTypes'> & { assetCategoryId?: number };

export function AssetTypeMrtForm({ assetCategoryId }: PropsWithChildren<AssetTypeMrtFormProps>) {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser);
  const isFetchingTypes = useAppSelector(fetchAssetTypesByCategorySelector.isFetching);
  const assetTypes = useAppSelector(fetchSortedAssetTypesByCategorySelector);
  const isFetchingUserTypes = useAppSelector(fetchUserTypesSelector.isFetching);
  const userTypes = useAppSelector(fetchSortedUserTypesByTenantSelector);
  const dispatch = useAppDispatch();


  useEffect(() => {
    if (assetCategoryId) {
      dispatch(fetchAssetTypesByCategoryThunk(tenantId, assetCategoryId))
      dispatch(fetchUserTypeThunk())
    }
  }, [tenantId, assetCategoryId]);

  return (
    <Formik
      initialValues={{ assetTypes, selectRow: 0, assetCategoryId, userTypes }}
      enableReinitialize
      onSubmit={() => {
        dispatch(fetchAssetTypesByCategoryThunk(tenantId, assetCategoryId as number));
      }}
    >
      {Boolean(assetCategoryId) && !isEmpty(userTypes) && (<Form>
        <AssetTypeMRT
          isLoading={isFetchingTypes || isFetchingUserTypes}
          tenantId={tenantId}
        />
      </Form>)
      }
    </Formik>
  );
};

