import { Grid } from '@mui/material'
import { type ReactElement } from 'react'
// eslint-disable-next-line
import { useAppSelector } from '../../../app/hooks'
import {
  useGetJourneysByTenantIdQuery,
  useGetLocationsQuery,
} from '../../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import JourneyTable from './JourneyTable'

const Journey = (): ReactElement<any, any> => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId

  const { data: allLocations = [], isSuccess: isLocationsLoaded } = useGetLocationsQuery({
    tenantId,
  })
  const { data: journeys, isSuccess: isJourneysFetched } = useGetJourneysByTenantIdQuery({
    tenantId,
  })

  const isLoaded = isJourneysFetched
  if (!isLoaded || !isLocationsLoaded) {
    return <SpinnerBlock />
  }

  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={12}>
        <JourneyTable allLocations={allLocations} transportLegs={journeys} />
      </Grid>
    </Grid>
  )
}

export default Journey
