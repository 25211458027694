import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_RowSelectionState as MRTRowSelectionState,
  MRT_TableInstance as MRTTableInstance,
} from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from '../../../../../app/hooks'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { HttpAction } from '../../../../enums'
import { fetchAllCustomerSettingsUsersSelector } from '../../../UserSettings/User/selectors'
import { IExtendedUser, IUser } from '../../../UserSettings/User/types'
import AddMoreDialog from '../../Components/AddMoreDialog'
import { AddUserMRT } from '../../Components/AddUsers/AddUserMRT'
import { IExtendedUserGroup, IUserGroup } from '../../UserGroups/types'
import {
  FormikUserGroupSettings,
  ISelectedItemIdsByUserGroupId,
  IUserGroupMemberByUserGroupId,
} from '../../types'
import messages from './messages'

interface IMemberMRTProps {
  formik: FormikUserGroupSettings
}
export const MemberMRT = ({ formik }: IMemberMRTProps) => {
  const isFetchingUsers = useAppSelector(fetchAllCustomerSettingsUsersSelector.isFetching)

  const selectedUserGroupId = formik.values.formikSelectedUserGroupId as number
  const allUserGroupMembersByGroupId = formik.values.formikUserGroupMembersByGroupId
  const itemsMarkedForDeletion = formik.values.formikUsersLastMarkedForDeletion
  const allUsers = formik.values.formikUsers
  const allUserGroups = formik.values.formikUserGroups

  const formikUserGroupBeingEdited = formik.values.formikUserGroupBeingEdited
  const editingInProgress = formikUserGroupBeingEdited !== undefined

  const existingSelectedItemIdsByUserGroupId = formik.values.formikSelectedMemberIdsByUserGroupId

  const selectedItemIdsByUserGroupId =
    existingSelectedItemIdsByUserGroupId.find(
      (selectedItem) => selectedItem.selectedUserGroupId === selectedUserGroupId
    ) || ({} as ISelectedItemIdsByUserGroupId)

  const formikSelectedItemIds = selectedItemIdsByUserGroupId.selectedItemIds || []

  const formikSelectedItems = allUsers.filter((user) =>
    (formikSelectedItemIds as number[])?.includes(user.id)
  )

  const data = useMemo<IUser[]>(() => {
    // Filter out all users marked for deletion in the selected user group
    const selectedUserGroupMembersByGroupId = allUserGroupMembersByGroupId.find(
      (groupMember) => groupMember.groupId === selectedUserGroupId
    )

    if (!selectedUserGroupMembersByGroupId) return []

    const selectedUserGroupMembers = (selectedUserGroupMembersByGroupId.members ||
      []) as IExtendedUser[]

    if (selectedUserGroupMembers.length === 0) return []

    const selectedUserGroupMembersExceptMarkedForDeletion = selectedUserGroupMembers.filter(
      (user) => user.httpAction !== HttpAction.DELETE
    ) as IUser[]

    return selectedUserGroupMembersExceptMarkedForDeletion
  }, [
    allUserGroupMembersByGroupId,
    selectedUserGroupId,
    itemsMarkedForDeletion,
    existingSelectedItemIdsByUserGroupId,
  ])

  const columns = useMemo<MRTColumnDef<IUser>[]>(() => {
    return [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
    ]
  }, [formik, existingSelectedItemIdsByUserGroupId])

  useEffect(() => {
    const newRowSelection: {
      [x: string]: boolean
    } = existingSelectedItemIdsByUserGroupId.reduce((acc, selectedItem) => {
      if (selectedItem.selectedUserGroupId === selectedUserGroupId) {
        selectedItem.selectedItemIds.forEach((selectedItemId) => {
          acc[selectedItemId] = true
        })
      }
      return acc
    }, {} as MRTRowSelectionState)

    setRowSelection(newRowSelection)
  }, [existingSelectedItemIdsByUserGroupId])

  const handleAddMoreUser = () => selectedUserGroupId !== undefined && showAddUserDialog()

  const handleBulkDelete = () => {
    const assets = allUserGroups
    const editedItem = assets.find((userGroup) => userGroup.id === selectedUserGroupId)

    // Save off original members
    if (!editingInProgress) {
      const originalUserGroup = allUserGroups.find(
        (userGroup) => userGroup.id === selectedUserGroupId
      )

      const originalUserGroupMemberByGroupId = allUserGroupMembersByGroupId.find(
        (groupMember) => groupMember.groupId === selectedUserGroupId
      )

      formik.values.formikOriginalUserGroups = allUserGroups
      formik.values.formikOriginalUserGroup = originalUserGroup
      formik.values.formikOriginalUserGroupMemberByGroupId = originalUserGroupMemberByGroupId
    }

    // Update all items
    // Mark selected items to be deleted by setting http action
    const itemsToDeleteWithHttpAction = formikSelectedItems.map((item) => ({
      ...item,
      httpAction: HttpAction.DELETE,
    }))

    // Update group items
    // Get the group members for the selected group
    const groupMembers = allUserGroupMembersByGroupId.find(
      (groupMember) => groupMember.groupId === selectedUserGroupId
    )

    // Find all group members except the ones marked for deletion
    const groupMembersExceptMarkedForDeletion =
      groupMembers?.members.filter(
        (groupMember) =>
          !itemsToDeleteWithHttpAction.find((itemToDelete) => itemToDelete.id === groupMember.id)
      ) || []

    // Merge all group members except the ones marked for deletion with the ones marked for deletion
    const allGroupMembersWithDeletedItems = [
      ...groupMembersExceptMarkedForDeletion,
      ...itemsToDeleteWithHttpAction,
    ].sort((a, b) => a.id - b.id)

    // Filter out the group members for the selected group
    const allUserGroupMembersExceptSelectedGroup = allUserGroupMembersByGroupId.filter(
      (groupMember) => groupMember.groupId !== selectedUserGroupId
    )

    // Merge the group members for the selected group with the updated group members
    const allUserGroupMembersWithDeletedItems = [
      ...allUserGroupMembersExceptSelectedGroup,
      {
        groupId: selectedUserGroupId,
        members: allGroupMembersWithDeletedItems,
      },
    ].sort((a, b) => a.groupId - b.groupId)

    // Set the new group members in formik
    formik.setFieldValue('formikUserGroupMembersByGroupId', allUserGroupMembersWithDeletedItems)

    // Set timestamp for last marked for deletion in formik to force re-render rows
    formik.setFieldValue('formikUsersLastMarkedForDeletion', Date.now())

    // Mark the user group as dirty
    const updatedUserGroups = formik.values.formikUserGroups.map((userGroup: IExtendedUserGroup) =>
      userGroup.id === selectedUserGroupId
        ? { ...userGroup, dirty: true, httpAction: HttpAction.PUT }
        : userGroup
    )

    formik.values.formikUserGroups = updatedUserGroups as IUserGroup[]

    setRowSelection({})
  }

  const handleChangeSelection = (event: React.MouseEvent<HTMLElement>, row: MRTRow<IUser>) => {
    event.preventDefault()
    event.stopPropagation()

    const { id } = row.original
    const originalRowId = `${id}`

    setRowSelection((prevState) => {
      const newRowSelection = { ...prevState, [originalRowId]: !prevState[originalRowId] }
      let newRowSelectionModel = Object.keys(newRowSelection).map((key) => {
        if (newRowSelection[key]) {
          return Number(key)
        } else {
          return null
        }
      }) as any[]

      newRowSelectionModel = newRowSelectionModel.filter((elements) => {
        return elements !== null
      })

      const existingSelectedItemsByUserGroupId: ISelectedItemIdsByUserGroupId[] =
        formik.getFieldProps('formikSelectedMemberIdsByUserGroupId').value || []

      const selectedItemsByUserGroupId =
        existingSelectedItemsByUserGroupId.find(
          (selectedItem) => selectedItem.selectedUserGroupId === selectedUserGroupId
        ) || ({} as ISelectedItemIdsByUserGroupId)

      selectedItemsByUserGroupId.selectedUserGroupId = selectedUserGroupId
      selectedItemsByUserGroupId.selectedItemIds = newRowSelectionModel

      let newSelectedItemsByGroupId = existingSelectedItemsByUserGroupId
        .map((selectedItem) =>
          selectedItem.selectedUserGroupId === selectedUserGroupId
            ? selectedItemsByUserGroupId
            : selectedItem
        )
        .filter((selectedItem) => selectedItem.selectedItemIds.length > 0)

      if (
        newSelectedItemsByGroupId.length === 0 &&
        selectedItemsByUserGroupId.selectedItemIds.length > 0
      )
        newSelectedItemsByGroupId = [selectedItemsByUserGroupId]

      formik.setFieldValue('formikSelectedMemberIdsByUserGroupId', newSelectedItemsByGroupId)

      return newRowSelection
    })
  }

  const showAddUserDialog = (show = true) => formik.setFieldValue('formikShowAddUserDialog', show)

  const handleRowSelectionChange = ({
    row,
    table,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IUser>
    table: MRTTableInstance<IUser>
  }) => {
    return {
      onChange: (event: any) => handleChangeSelection(event, row),
      onClick: (event: any) => handleChangeSelection(event, row),
      sx: { cursor: 'pointer' },
    }
  }

  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const handleAddUserSelection = () => {
    showAddUserDialog(false)

    const assets = allUserGroups
    const editedItem = assets.find((userGroup) => userGroup.id === selectedUserGroupId)

    const selectedUserIdsByGroupId = formik.values.formikSelectedUserIdsByGroupId

    if (!editedItem) return

    const foundIndex = assets.findIndex(
      (item) => item.id === editedItem.id && item.name === editedItem.name
    )

    // 1/3 Save off original value
    if (!editingInProgress) {
      const originalItem = { ...assets[foundIndex] }
      formik.values.formikOriginalUserGroup = originalItem
      formik.values.formikOriginalUserGroups = allUserGroups
    }

    const selectedUsers = allUsers.filter((user) =>
      (selectedUserIdsByGroupId?.selectedUserIds as number[])?.includes(user.id)
    )

    if (!editingInProgress) {
      const originalUserGroupMemberByGroupId = allUserGroupMembersByGroupId.find(
        (groupMember) => groupMember.groupId === selectedUserGroupId
      )

      formik.values.formikOriginalUserGroupMemberByGroupId = originalUserGroupMemberByGroupId
    }

    const existingGroupMembers =
      allUserGroupMembersByGroupId.find(
        (groupMember) => groupMember.groupId === selectedUserGroupId
      )?.members || []

    // Mark selected items to be added by setting http action
    const filteredSelectedUsersWithHttpAction = selectedUsers.map((item) => ({
      ...item,
      httpAction: HttpAction.POST,
    }))

    const existingAndFilteredSelectedUsers = [
      ...existingGroupMembers,
      ...filteredSelectedUsersWithHttpAction,
    ].sort((a, b) => a.id - b.id)

    const newAllUserGroupMembersByGroupId: IUserGroupMemberByUserGroupId = {
      groupId: selectedUserGroupId,
      members: existingAndFilteredSelectedUsers,
    }

    const newAllUserGroupMembers = allUserGroupMembersByGroupId.map((groupMember) =>
      groupMember.groupId === selectedUserGroupId ? newAllUserGroupMembersByGroupId : groupMember
    )

    formik.setFieldValue('formikUserGroupMembersByGroupId', newAllUserGroupMembers)

    // Mark the user group as dirty and set http action
    const updatedUserGroups = formik.values.formikUserGroups.map((userGroup: IExtendedUserGroup) =>
      userGroup.id === selectedUserGroupId
        ? { ...userGroup, dirty: true, httpAction: userGroup.httpAction || HttpAction.PUT }
        : userGroup
    )

    const updatedUserGroup = updatedUserGroups.find(
      (userGroup) => userGroup.id === selectedUserGroupId
    )

    formik.values.formikUserGroups = updatedUserGroups as IUserGroup[]
    formik.values.formikUserGroupBeingEdited = updatedUserGroup as IUserGroup
  }

  const handleDiscardUserSelection = () => {
    showAddUserDialog(false)
    formik.setFieldValue('formikSelectedUserIdsByGroupId', undefined)
  }

  return (
    <>
      <AddMoreDialog
        open={(formik.getFieldProps('formikShowAddUserDialog').value as boolean) === true}
        onAdd={handleAddUserSelection}
        onDiscard={handleDiscardUserSelection}
        title='Add User'
      >
        <AddUserMRT formik={formik} isLoading={isFetchingUsers} />
      </AddMoreDialog>
      <MRTDataGrid
        heading={messages.memeberSettingsTableHeading}
        columns={columns}
        data={data}
        enableMultiRowSelection={true}
        enableRowSelection={true}
        enableSelectAll={true}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{
          rowSelection, // pass our managed row selection state to the table to use
        }}
        // enables all rows to be selected but disables individual row selection
        // onRowSelectionChange={setRowSelection}
        getRowId={(row) => `${row.id}`}
        handleAddItem={handleAddMoreUser}
        handleBulkDelete={handleBulkDelete}
      />
    </>
  )
}
