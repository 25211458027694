import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
} from 'material-react-table';
import { useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../app/hooks';
import { useGetAssetConfigurationsQuery, useGetTransportModeQuery } from '../../../../../app/redux-fetch/apiQuery';
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid';
import { fetchLoggedInUserSelector } from '../../../../selectors';
import { ILoggedInUser } from '../../../../types';
import messages from '../messages';
import { IAssetConfiguration } from '../types';
import { AssetConfigurationModal } from './modals/AssetConfigurationModal';
import { DeleteAssetConfigurationModal } from './modals/DeleteAssetConfigurationModal';
import { Enabled } from '../../../../../components/Enabled/Enabled';

export const AssetConfigurations = () => {
  const columns = useMemo<MRTColumnDef<Partial<IAssetConfiguration>>[]>(() => {
    return [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'customerSelectable',
        header: 'Customer Selectable',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetConfiguration>>
        }) => {
          return <Enabled isEnabled={row.original.customerSelectable} />
        },
      },
    ] as MRTColumnDef<Partial<IAssetConfiguration>>[]
  }, []);

  const columnsDef = useMemo(() => {
    return columns.reduce((acc, item) => {
      acc[item.id ?? ''] = item.header;
      return acc;
    }, {} as { [key: string]: string });
  }, [columns]);

  const handleRowSelectionChange = ({
    row,
  }: {
    row: MRTRow<Partial<IAssetConfiguration>>
  }) => {
    return {
      onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          Number(row.id) === 0 ? 'lightgrey' : 'initial',
      },
    }
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteRow, setDeleteRow] = useState<MRTRow<Partial<IAssetConfiguration>> | null>(null);
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetConfiguration>> | undefined>(undefined);
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser);
  const { data: transportModes } = useGetTransportModeQuery();
  const { data: assetConfigurations = [] } = useGetAssetConfigurationsQuery({ tenantId });

  return (
    <>
      {deleteRow && <DeleteAssetConfigurationModal
        columnDef={columnsDef}
        onClose={() => setDeleteRow(null)}
        row={deleteRow}
      />}
      {modalIsOpen && <AssetConfigurationModal
        columnDef={columnsDef}
        onClose={() => {
          setEditRow(undefined);
          setModalIsOpen(false);
        }}
        isOpen={modalIsOpen}
        transportModes={transportModes}
        tenantId={tenantId}
        row={editRow}
      />
      }
      <MRTDataGrid
        heading={messages.assetConfigurationTitle}
        columns={columns}
        data={assetConfigurations}
        handleAddItem={() => {
          setModalIsOpen(true)
        }}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading: false }}
        enableEditing
        renderRowActions={({ row }) => {
          const { tenantId, isCloneOfSysmtemDefined } =
            row.original as IAssetConfiguration;

          const isUserDefined = !!tenantId;
          const editable = isUserDefined && !isCloneOfSysmtemDefined;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement='left' title='Edit'>
                <IconButton color='info' onClick={() => {
                  setEditRow(row);
                  setModalIsOpen(true)
                }}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {editable && (
                <Tooltip arrow placement='right' title='Delete'>
                  <IconButton
                    color='info'
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setDeleteRow(row);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }}
      />
    </>
  )
}

