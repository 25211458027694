import { ILoad } from '../Shipments/types'
import { ICarrier } from './../Shipments/types'

export interface ITransport {
  id?: number
  pickUpLocation: string
  pickUpDate: string
  dropOffLocation: string
  dropOffDate: string
  numberOfLoads: null | number
  isComplete: boolean
}
export interface ITransportRoutes {
  id: string
  loads: IJourneyLegRoutesLoad[]
  pickupId?: number
  dropoffId?: number
  routeName?: string
  pickupName?: string
  dropOffName?: string
  description?: string
}

export interface ITransportJourney {
  tenantId: number
  carrierId: number // TODO: Hardcode value is 10001
  transportDetail?: any // TODO: omit for now
  isApproved?: boolean // TODO: need to have approve endpoint as I can see
  assetConfigurationAllocations?: ITransportAssetConfigurationAllocations[]
  assetEmployeeAllocations: ITransportEmployeeAllocations[]
  assetSealAllocations: ITransportAssetSealAllocations[]
  loads: IJourneyLegLoad[]
  routes: IJourneyRoute[]
  legs: ILeg[]
  assetConfigurations: ITransportAssetConfiguration[]
  assetLoadAllocations?: any[]
}

export interface ILeg {
  uId: string
  carrierId?: number
  carrierWaybillMaster?: string
  routes: IJourneyRoute[]
}

export interface IJourneyRoute {
  id: string
  loads: IJourneyLegRoutesLoad[]
  locations: IJourneyLegRoutesLocation[]
}

export interface IJourneyLegRoutesLoad {
  id: number
  pickupRouteLocationId?: number
  dropoffRouteLocationId?: number
  pickupNotifyPartyId?: number
  deliveryNotifyPartyId?: number
  consigneeId?: number
  pickupLocationId?: number
  name?: string
  isDefined?: boolean
}

export interface ITransportLoads {
  id: number
  routeId: number
  description?: string
}

export interface IJourneyLegLoad extends ILoad {
  isSelected: boolean
  trackingNumber?: string
  customerId: number
  pickupLocationName?: string
  pickupDate?: string
  deliveryDate?: string
  setPoint?: number
  quantity: number
  description: string
  temperatureRange?: string
  consignee?: string
  productName?: string
  pickupNotifyPartyId?: number
  deliveryNotifyPartyId?: number
  pickupRouteLocationId?: number
  dropoffRouteLocationId?: number
  consigneeId?: number
  customerName?: string
  pickupLocationId?: number
  isDefined: boolean
}

export interface IJourneyRouteLocationLoad extends IJourneyLegLoad {
  uId: string
  isPickup: boolean
  isDropoff: boolean
}

export interface ITransportEmployeeAllocations {
  assetId: number
  employeeId: number
}

export interface ITransportAssetSealAllocations {
  assetId: number
  sealNumber: string
}

export interface ITransportAssetConfigurationAllocations {
  assetConfigurationId: number
  roadFreightManifest: string // Example 'TestManifest'
}

export interface IAssetLoadAllocations {
  assetId: number
  loadId: number
  roadFreightWaybill: string // TODO
}

export interface IJourneyLegRoutesLocation {
  locationId: number
  name: string
  contactId?: number
  sequence: number
  scheduledDate: Date
}

export interface IJourneyLegAssetLoadAllocation {
  assetId: number
  loadId: number
}

export enum DestionationAction {
  Nothing = 0,
  Pickup = 1,
  Dropoff = 2,
}

// Transport types

// TODO: Should be moved to separate folder during cleanup
export interface ICreateTransportForm {
  route: ITransportRoute
  assetConfigurations: ITransportAssetConfiguration[]
}

export interface ITransportRoute {
  id: string
  loads: ITransportRouteLoad[]
  locations: IJourneyLegRoutesLocation[]
}

export enum AssetTypes {
  Head = 1,
  Trail = 2,
  Truck = 3,
}

export enum TransportationMode {
  Air = 1,
  Sea,
  Road,
  Rail,
}

export interface ITransportRouteLoad {
  id: number
  loadId: number
  description: string
  weight: string
  grossWeight: string
  weightUnit: string
  temperatureUnit: string
  containerNumber: string[]
  setPoint: string
  assetConfigurationName: string
}

// export interface IJourneyRoute {
//   id: string
//   loads: IJourneyLegRoutesLoad[]
//   locations: IJourneyLegRoutesLocation[]
//   transportJobs: ITransportJob[]
// }

// export interface ITransportJourney {
//   tenantId: number
//   carrierId: number // TODO: Hardcode value is 10001
//   carrierWaybillMaster: string // TODO: Hardcode value TestWaybill
//   transportDetail?: any // TODO: omit for now
//   isApproved?: boolean // TODO: need to have approve endpoint as I can see
//   assetConfigurationAllocations?: ITransportAssetConfigurationAllocations[]
//   assetEmployeeAllocations: ITransportEmployeeAllocations[]
//   assetSealAllocations: ITransportAssetSealAllocations[]
//   loads: IJourneyLegLoad[]
//   routes: IJourneyRoute[]
//   legs: IUpdateJourneyLeg[]
//   assetConfigurations: ITransportAssetConfiguration[]
//   assetLoadAllocations?: any[]
// }

export interface IJourneyLegRoutesLoad {
  id: number
  pickupRouteLocationId?: number
  dropoffRouteLocationId?: number
  consigneeId?: number
  pickupLocationId?: number
  name?: string
  isDefined?: boolean
}

export interface ITransportAssetConfiguration {
  id: number
  assetConfigurationId?: number
  name: string
  transportModeId: number
  allocations: ITransportAsset[]
}

export interface ITransportAssetType {
  id: number
  name: string
  isMotorized: boolean
  requiresDriver: boolean
  sealable: boolean
  carriesPayload: boolean
  userTypeAllocations: IUserTypeAllocations[]
}

export interface IUserTypeAllocations {
  userTypeId: number
}

export interface ITransportAsset {
  uid?: string
  id?: number
  identification: string
  brandId?: number
  brandName?: string
  model?: string
  name?: string
  type?: string
  employeesId?: number[]
  employes: ITransportAssetEmployees[]
  seals?: string[]
  routes?: number[]
  loadIds?: number[]
  sequence?: number
  assetTypeId?: number
  assetType?: ITransportAssetType
  assetTypeName?: string
  assetClassId?: number
  detail: IAssetDetail
  userTypeAllocations: IUserTypeAllocation[]
}
export interface ITransportAssetEmployees {
  id: number
  userTypeId?: number
  isDriver: boolean
}
export interface IUserTypeAllocation {
  id: number
  assetTypeId: number
  userTypeId: number
}

export interface IAsset {
  id: number
  tenantId: number
  assetClassId: number
  assetTypeId: number
  identification: string
  detail: IAssetDetail
  portPasses: any[] // Specify the correct type here if you know it
}

export interface IAssetDetail {
  id: number
  assetId: number
  brandId: number
  manufacturerId: number
  coolingUnitId: number
  model: string
  productionYear: string // You might want to consider using Date type if these are Date instances
  numberOfDoors: number
  chassisNumber: string
  registrationNumber: string
  registrationDate: string // Same as the productionYear, consider using Date
  registrationExpiry: string // Same as the productionYear, consider using Date
  insuranceExpiry: string // Same as the productionYear, consider using Date
  operationCardExpiry: string // Same as the productionYear, consider using Date
  mvpiExpiry: string // Same as the productionYear, consider using Date
  maintenanceEndDate: string // Same as the productionYear, consider using Date
  isOwned: boolean
}

// Asset group
export interface IAssetGroupUserAllocation {
  id?: number
  assetGroupAssetAllocationId?: number
  userId: number
}

export interface IAssetGroupSealAllocation {
  id?: number
  assetGroupAssetAllocationId?: number
  sealNumber: string
}

export interface IAssetGroupLoadAllocation {
  id?: number
  assetGroupAssetAllocationId?: number
  loadId: number
}

export interface IAssetGroupAssetAllocation {
  id?: number
  assetGroupId?: number
  assetId: number
  userAllocations: IAssetGroupUserAllocation[]
  sealAllocations: IAssetGroupSealAllocation[]
  loadAllocations: IAssetGroupLoadAllocation[]
}

export interface IAssetGroupAllocationDetails {
  id?: number
  tenantId?: number
  assetAllocations: IAssetGroupAssetAllocation[]
}

export interface IJobLoadAllocation {
  id?: number
  transportJobId?: number
  loadId?: number
  containerDetailId?: number
  carrierWaybill?: string
  containerSealNumber?: string
}

export interface ITransportJob {
  id?: number
  routeId?: number
  assetGroupId?: number
  roadFreightManifest?: string
  loadAllocations?: IJobLoadAllocation[]
}
export interface IUpdateJourneyLegRouteDestinationLoadAction {
  id?: number
  routeDestinationId?: number
  loadId?: number
  destinationActionTypeId?: number
  notifyPartyId?: number
}

export interface IUpdateJourneyLegRouteDestination {
  id?: number
  routeId?: number
  locationId?: number
  scheduledDate?: string
  sequence?: number
  loadActions?: IUpdateJourneyLegRouteDestinationLoadAction[]
}

export interface IUpdateJourneyLegRoute {
  uId: string
  id?: number
  legId?: number
  subcontractedTenantId?: number
  subSubcontractedTenantId?: number
  displayOrder?: number
  transportJobs?: ITransportJob[]
  destinations?: IUpdateJourneyLegRouteDestination[]
  loads: IJourneyLegRoutesLoad[]
  locations: IJourneyLegRoutesLocation[]
}

export interface IUpdateJourneyLeg {
  id?: number
  journeyId?: number
  carrierId?: number
  carrier?: ICarrier
  carrierWaybillMaster?: string
  sequence?: number
  routes: IUpdateJourneyLegRoute[]
}

export interface IUpdateJourney {
  id?: number
  uId?: number
  tenantId?: number
  isApproved?: boolean
  legs?: IUpdateJourneyLeg[]
}
