import RemoveIcon from '@mui/icons-material/Remove'
import { Box, Fab, Grid, Paper, TextField, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import type { FormikProps } from 'formik'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { handleDateChanges } from '../../../Shipments/utils'
import { IJourneyLegRoutesLocation, IJourneyRoute, ITransportJourney } from '../../types'
import LocationLoad from './LocationLoad'
import { messages } from './messages'

interface IRouteLocation {
  formik: FormikProps<ITransportJourney>
  route: IJourneyRoute
  routeIdx: number
  locationIdx: number
  location: IJourneyLegRoutesLocation
  legIdx: number
  loadRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>
  locations: ILocationByTenantCustomer[]
}

const RouteLocation = ({
  formik,
  routeIdx: rIdx,
  locationIdx: lIdx,
  location: l,
  route,
  legIdx,
  loadRefs,
  locations,
}: IRouteLocation) => {
  const routeLoads = route.loads
  const { formatMessage } = useIntl()

  const handleChangeLocation = (e: any, name: string, newValue: any) => {
    const newLocation = locations.find((l) => l.id === newValue)
    routeLoads.forEach((rl, rlIdx) => {
      const load = rl
      if (load.pickupRouteLocationId === l.locationId) {
        load.pickupRouteLocationId = newValue
        formik.setFieldValue(
          `legs.${legIdx}.routes.${rIdx}.loads.${rlIdx}.pickupRouteLocationId`,
          newValue
        )
      }
      if (load.dropoffRouteLocationId === l.locationId) {
        load.dropoffRouteLocationId = newValue
        formik.setFieldValue(
          `legs.${legIdx}.routes.${rIdx}.loads.${rlIdx}.dropoffRouteLocationId`,
          newValue
        )
      }
    })
    formik.setFieldValue(`${name}.locationId`, newValue)
    formik.setFieldValue(`${name}.name`, newLocation?.name)
    formik.setFieldValue(`${name}.contactId`, undefined)
  }

  const getLocations = () => {
    const usedLocationIds = route.locations.map((rl) => rl.locationId)
    return locations.filter(
      (loc) => !usedLocationIds.some((uloc) => uloc === loc.id) || l.locationId === loc.id
    )
  }

  const getLocationTitle = (lIdx: number) => {
    let title = ''
    if (lIdx === 0) {
      title = formatMessage(messages.start)
    } else if (lIdx === formik.values.legs[legIdx].routes[rIdx].locations.length - 1) {
      title = formatMessage(messages.end)
    } else {
      title = formatMessage(messages.stop, { stopNumber: lIdx })
    }
    return title
  }
  const handleRemoveLocation = useCallback(() => {
    const currLocations = formik.values.legs[legIdx].routes[rIdx].locations
    const loadIdPickupIndxs: number[] = []
    const loadIdDropoffIndxs: number[] = []
    routeLoads.forEach((load, loadIdx) => {
      if (load.dropoffRouteLocationId === l.locationId) {
        loadIdDropoffIndxs.push(loadIdx)
      }
      if (load.pickupRouteLocationId === l.locationId) {
        loadIdPickupIndxs.push(loadIdx)
      }
    })
    loadIdPickupIndxs.forEach((loadIdx) => {
      formik.setFieldValue(
        `legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.pickupRouteLocationId`,
        undefined
      )
    })
    loadIdDropoffIndxs.forEach((loadIdx) => {
      formik.setFieldValue(
        `legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.dropoffRouteLocationId`,
        undefined
      )
    })
    currLocations.splice(lIdx, 1)
    formik.setFieldValue(`legs.${legIdx}.routes.${rIdx}.locations`, currLocations)
  }, [routeLoads, formik.values.legs[legIdx].routes[rIdx].locations])

  const getScheduleDateValue = (): dayjs.Dayjs | null => {
    return formik.values.legs[legIdx].routes[rIdx].locations[lIdx].scheduledDate != null
      ? dayjs(formik.values.legs[legIdx].routes[rIdx].locations[lIdx].scheduledDate)
      : null
  }
  return (
    <>
      <Paper
        id={`routes.${rIdx}.locations.${lIdx}`}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '6px',
          minWidth: '378px',
          maxWidth: '378px',
        }}
      >
        <Grid container sx={{ p: '12px' }}>
          <Grid item xs={6}>
            <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
              <Typography sx={{ pr: '16px' }}>{getLocationTitle(lIdx)}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              margin: '-10px 0 19px 0',
            }}
          >
            <Box display='flex' justifyContent='flex-end'>
              <Fab
                aria-label='remove'
                color='info'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleRemoveLocation()
                }}
                size='small'
                sx={{ margin: '0 2px' }}
              >
                <RemoveIcon />
              </Fab>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              mb: '21px',
            }}
          >
            <FormDropdown
              id={`legs.${legIdx}.routes.${rIdx}.locations.${lIdx}`}
              label={formatMessage(messages.selectLocation)}
              onChange={handleChangeLocation}
              value={l.locationId}
              items={getLocations()}
              handleOnBlur={formik.handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              margin: '-10px 0 19px 0',
            }}
          >
            <DesktopDatePicker
              disablePast={true}
              inputFormat='DD/MM/YYYY'
              label={''}
              value={getScheduleDateValue()}
              onChange={(e: any) => {
                handleDateChanges(
                  e,
                  `legs.${legIdx}.routes.${rIdx}.locations.${lIdx}.scheduledDate`,
                  formik
                )
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  id={`legs.${legIdx}.routes.${rIdx}.locations.${lIdx}.scheduledDate`}
                  name={`legs.${legIdx}.routes.${rIdx}.locations.${lIdx}.scheduledDate`}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  onBlur={formik.handleBlur}
                />
              )}
            />
          </Grid>
          <Grid />
          <Grid item xs={12}>
            <LocationLoad
              loadRefs={loadRefs}
              loads={routeLoads}
              location={l}
              formik={formik}
              rIdx={rIdx}
              legIdx={legIdx}
              locations={locations}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default RouteLocation
