import { Delete, Edit, Undo } from '@mui/icons-material'
import { FormControl, IconButton, Switch, Tooltip } from '@mui/material'
import {
  MRT_Cell as MRTCell,
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MaterialReactTableProps,
} from 'material-react-table'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import { SubType } from '../../../../types/common'
import { HttpAction } from '../../../enums'
import { fetchLoggedInCustomerSelector } from '../../../selectors'
import { ILoggedInCustomer } from '../../../types'
import { NotiStackOptions, generateRandomId, validateRequired } from '../../../utils'
import { compareCurrencyAllProps } from '../../utils'
import messages from '../messages'
import { FormikCurrencySettings } from '../types'
import AddCurrencyModal from './Components/AddCurrencyModal'
import { ICurrencyRow } from './types'
import { isUnique } from './utils'

export interface ICurrMRT {
  formik: FormikCurrencySettings
  isLoading: boolean
}

export const CurrencyMRT = ({ formik, isLoading }: ICurrMRT) => {
  const { formatMessage } = useIntl()

  let data = formik.values.currencySettings
  data = data.sort(function(x, y) {
    return (x.enabled === y.enabled)? 0 : x.enabled? -1 : 1;
});

  const loggedInCustomer =
    useAppSelector(fetchLoggedInCustomerSelector.data) || ({} as ILoggedInCustomer)
  const { id: customerId, defaultCurrencyId } = loggedInCustomer

  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string
  }>({})

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRTCell<ICurrencyRow>
    ): MRTColumnDef<ICurrencyRow>['muiTableBodyCellEditTextFieldProps'] => {
      const columnsToValiate = ['symbol', 'localisedSymbol']
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const columnId = cell.column.id as keyof ICurrencyRow

          if (!columnsToValiate.includes(columnId)) return

          const hasValue = validateRequired(event.target.value)

          const isUniqueValue =
            hasValue &&
            isUnique({
              formik,
              id: cell.row.original.id,
              valueToCheck: event.target.value,
              propToCheck: columnId,
            })

          const maxLength = 10

          const isValidLenght = event.target.value.length <= maxLength

          const isValid = hasValue && isValidLenght && isUniqueValue

          if (!isValid) {
            const propertyName = cell.column.columnDef.header

            const validationMessage = !hasValue
              ? formatMessage(messages.propertyValueRequired, { propertyName })
              : !isValidLenght
              ? formatMessage(messages.propertyValueMaxLength, { propertyName, maxLength })
              : !isUniqueValue
              ? formatMessage(messages.propertyValueMustBeUnique, { propertyName })
              : ''

            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationMessage,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [validationErrors]
  )

  const getIso3EditTextFieldProps = useCallback(
    (
      cell: MRTCell<ICurrencyRow>
    ): MRTColumnDef<ICurrencyRow>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const columnId = cell.column.id as keyof ICurrencyRow
          const value = event.target.value?.trim()

          const hasValue = validateRequired(value)
          const isUniqueValue =
            hasValue &&
            isUnique({
              formik,
              id: cell.row.original.id,
              valueToCheck: value,
              propToCheck: columnId,
            })

          const exactLength = 3
          const isValidLenght = value.length === exactLength

          const aplhaOnlyRegexPattern = /^[a-zA-Z]+$/
          const isAlhpa = aplhaOnlyRegexPattern.test(value)
          const isValid = hasValue && isValidLenght && isUniqueValue && isAlhpa

          if (!isValid) {
            const propertyName = cell.column.columnDef.header
            const validationMessage = !hasValue
              ? formatMessage(messages.propertyValueRequired, { propertyName })
              : !isAlhpa
              ? formatMessage(messages.propertyValueMustContainAlphabetOnly, { propertyName })
              : !isValidLenght
              ? formatMessage(messages.propertyValueMustBeExactLength, {
                  propertyName,
                  exactLength,
                })
              : !isUniqueValue
              ? formatMessage(messages.propertyValueMustBeUnique, { propertyName })
              : ''

            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationMessage,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [validationErrors]
  )

  const getCurrencyNameEditTextFieldProps = useCallback(
    (
      cell: MRTCell<ICurrencyRow>
    ): MRTColumnDef<ICurrencyRow>['muiTableBodyCellEditTextFieldProps'] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const columnId = cell.column.id as keyof ICurrencyRow
          const value = event.target.value?.trim()

          const hasValue = validateRequired(value)

          const isUniqueValue =
            hasValue &&
            isUnique({
              formik,
              id: cell.row.original.id,
              valueToCheck: value,
              propToCheck: columnId,
            })

          const maxLength = 100

          const isValidLenght = value.length <= maxLength

          const isValid = hasValue && isValidLenght && isUniqueValue

          if (!isValid) {
            const propertyName = cell.column.columnDef.header
            const validationMessage = !hasValue
              ? formatMessage(messages.propertyValueRequired, { propertyName })
              : !isValidLenght
              ? formatMessage(messages.propertyValueMaxLength, { propertyName, maxLength })
              : !isUniqueValue
              ? formatMessage(messages.propertyValueMustBeUnique, { propertyName })
              : ''

            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationMessage,
            })
          } else {
            delete validationErrors[cell.id]
            setValidationErrors({
              ...validationErrors,
            })
          }
        },
      }
    },
    [validationErrors]
  )

  const currencyColumns = useMemo<MRTColumnDef<ICurrencyRow>[]>(() => {
    const columns: MRTColumnDef<ICurrencyRow>[] = [
      {
        accessorKey: 'iso3',
        header: formatMessage(messages.currencyIso3ColumnHeading),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getIso3EditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'name',
        header: formatMessage(messages.currencyNameColumnHeading),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCurrencyNameEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'symbol',
        header: formatMessage(messages.currencySymbolColumnHeading),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'localisedSymbol',
        header: formatMessage(messages.currencyLocalisedSymbolColumnHeading),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'enabled',
        editVariant: 'select',
        editSelectOptions: ['true', 'false'],
        accessorFn: (originalRow) => (originalRow.enabled ? 'true' : 'false'),
        header: formatMessage(messages.currencyEnabledColumnHeading),
        Cell: ({
          row,
          table,
        }: {
          row: MRTRow<ICurrencyRow>
          table: MRTTableInstance<ICurrencyRow>
        }) => {
          const { original } = row
          const { isDefalutCurrency } = original
          return (
            <FormControl>
              <Switch
                size='small'
                name='enabled'
                checked={original.enabled}
                disabled={isDefalutCurrency}
                onChange={(event) => {
                  const { checked } = event.target
                  const { id, subType, customerId } = original

                  const fieldProp = 'currencySettings'

                  const currencies: ICurrencyRow[] = table
                    .getCoreRowModel()
                    .rows.map((row) => row.original)

                  const currency = { ...currencies.find((curr) => curr.id === id) } as ICurrencyRow

                  if (id === defaultCurrencyId && !checked) {
                    enqueueSnackbar(
                      formatMessage(messages.defaultCurrencyCannotBeDisabled),
                      NotiStackOptions.error
                    )
                    return
                  }

                  const originalCurrency =
                    formik.values.originalCurrencySettings.find(
                      (curr) => curr.id === currency.id
                    ) || ({} as ICurrencyRow)

                  const isEnabledAndSystemDefined = checked && subType === SubType.SystemDefined
                  const isEnabledAndUserDefined = checked && subType === SubType.UserDefined
                  const isDisabledAndUserDefined = !checked && subType === SubType.UserDefined
                  const isDisabledAndSystemDefined = !checked && subType === SubType.SystemDefined

                  currency.enabled = checked
                  currency.canDelete = !checked
                  currency.customerId = customerId

                  if (isEnabledAndSystemDefined) {
                    currency.httpAction = HttpAction.POST
                    currency.canDelete = false
                  }

                  if (isEnabledAndUserDefined) {
                    currency.httpAction = originalCurrency.httpAction
                  }

                  if (isDisabledAndUserDefined) {
                    currency.httpAction = HttpAction.DELETE
                  }

                  if (isDisabledAndSystemDefined) {
                    const currencyInUse = originalCurrency.enabled === true
                    currency.httpAction = currencyInUse
                      ? HttpAction.PUT
                      : originalCurrency.httpAction
                  }

                  const currencySettings = currencies.map((curr) => {
                    if (curr.id === currency.id) {
                      if (!originalCurrency) return currency
                      const match = compareCurrencyAllProps(currency, originalCurrency)
                      return match ? originalCurrency : currency
                    }
                    return curr
                  })

                  formik.setFieldValue(fieldProp, currencySettings)
                }}
              />
            </FormControl>
          )
        },
      },
    ]

    return columns
  }, [getCommonEditTextFieldProps, data, validationErrors])

  const handleEditingRowSave: MaterialReactTableProps<ICurrencyRow>['onEditingRowSave'] = async ({
    exitEditingMode,
    row,
    values,
  }) => {
    if (Object.keys(validationErrors).length > 0) {
      return
    }

    const currencySettings = data.map((currency) => {
      if (currency.id === row.original.id) {
        return {
          ...currency,
          ...values,
          dirty: true,
          ...{ enabled: values.enabled === 'true' },
        }
      }
      return currency
    })

    formik.setFieldValue('currencySettings', currencySettings)
    exitEditingMode()
  }

  const handleEditRowCancel: MaterialReactTableProps<ICurrencyRow>['onEditingRowCancel'] = ({
    table,
  }: {
    row: MRTRow<ICurrencyRow>
    table: MRTTableInstance<ICurrencyRow>
  }) => {
    setValidationErrors({})
    table.setEditingRow(null)
  }

  const handleDelete = ({
    row,
    table,
  }: {
    row: MRTRow<ICurrencyRow>
    table: MRTTableInstance<ICurrencyRow>
  }) => {
    const { original } = row
    const { id, httpAction, isNew } = original

    const currencies = table.getCoreRowModel().rows.map((row) => row.original)

    // If new, then remove from the list
    if (httpAction === HttpAction.POST && isNew) {
      const updatedData = currencies.filter((currency) => currency.id !== id)
      formik.setFieldValue('currencySettings', updatedData)
      return
    }

    // If marked for deletion, then undo delete
    if (httpAction === HttpAction.DELETE) {
      const updatedData = currencies.map((curr) => {
        if (curr.id === id) {
          return {
            ...curr,
            httpAction: HttpAction.NONE,
          }
        }
        return curr
      })
      formik.setFieldValue('currencySettings', updatedData)
      return
    }

    // If existing, then mark for delete
    const updatedData = currencies.map((currency) => {
      if (currency.id === id) {
        return {
          ...currency,
          httpAction: HttpAction.DELETE,
        }
      }
      return currency
    })

    formik.setFieldValue('currencySettings', updatedData)
  }

  const renderRowActions = ({
    row,
    table,
  }: {
    cell: MRTCell<ICurrencyRow>
    row: MRTRow<ICurrencyRow>
    table: MRTTableInstance<ICurrencyRow>
  }) => {
    const { customerId, httpAction, isDefalutCurrency, isCloneOfSysmtemDefined } =
      row.original as ICurrencyRow
    const editable = customerId !== undefined && !isCloneOfSysmtemDefined
    const markedForDeletion = httpAction === HttpAction.DELETE

    if (!editable) return null

    return (
      <>
        <Tooltip arrow placement='left' title='Edit'>
          <IconButton
            color='info'
            disabled={isDefalutCurrency || markedForDeletion}
            onClick={() => table.setEditingRow(row)}
          >
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip arrow placement='right' title={!markedForDeletion ? 'Delete' : 'Undo'}>
          <IconButton
            color='info'
            disabled={isDefalutCurrency}
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleDelete({ row, table })
            }}
          >
            {!markedForDeletion ? <Delete /> : <Undo color='info' />}
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [addCurrencyModalOpen, setAddCurrencyModalOpen] = useState(false)

  const handleAddItem = () => setAddCurrencyModalOpen(true)

  const handleAddNewCurrency = (values: ICurrencyRow) => {
    const { currencySettings } = formik.values
    const { name, iso3, symbol, localisedSymbol } = values
    const newCurrency: ICurrencyRow = {
      id: generateRandomId(),
      name,
      iso3,
      symbol,
      localisedSymbol,
      enabled: true,

      customerId,
      dirty: true,
      isNew: true,
      canDelete: true,
      entity: 'currency',
      httpAction: HttpAction.POST,
      subType: SubType.UserDefined,
      isPureUserDefined: true,
      isDefalutCurrency: false,
      isMotorized: false
    }

    const updatedData = [newCurrency, ...currencySettings]
    formik.setFieldValue('currencySettings', updatedData)
  }

  return (
    <>
      <AddCurrencyModal
        open={addCurrencyModalOpen}
        onClose={() => setAddCurrencyModalOpen(false)}
        onSubmit={handleAddNewCurrency}
        currencyFormik={formik}
      />
      <MRTDataGrid
        heading={messages.currencyTableHeading}
        columns={currencyColumns}
        data={data}
        state={{
          isLoading: formik.isSubmitting || isLoading,
        }}
        getRowId={(row) => `${row.id}`}
        enableEditing
        onEditingRowSave={handleEditingRowSave}
        onEditingRowCancel={handleEditRowCancel}
        renderRowActions={renderRowActions}
        handleAddItem={handleAddItem}
        muiTableBodyCellEditTextFieldProps={{ variant: 'outlined' }}
      />
    </>
  )
}

export default CurrencyMRT
