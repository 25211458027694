import { defineMessages } from 'react-intl'

const messages = defineMessages({
  memeberSettingsTab: {
    id: 'userRoleAllocation.tabs.memeberSettingsTab',
    description: 'Memeber settings tab',
    defaultMessage: 'Members',
  },
  roleSettingTab: {
    id: 'userRoleAllocation.tabs.roleSettingTab',
    description: 'Role settings tab',
    defaultMessage: 'Roles',
  },
})

export default messages
