import { combineReducers } from '@reduxjs/toolkit'
import { fetchAllRolesReducers } from './UserGroupSettings/UserRoleAllocation/Role/slice'
import { COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME } from '../CommonSettings/UserGroupSettings/UserRoleAllocation/Role/consts'
import { COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME } from './RoleSettings/SecurityPrincipal/consts'
import { fetchAllSecurityPrincipalsReducers } from './RoleSettings/SecurityPrincipal/slices'

export const commonSettingsReducers = combineReducers({
  [COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME]: fetchAllRolesReducers,
  [COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME]: fetchAllSecurityPrincipalsReducers,
})
