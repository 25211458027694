import { FormikProps } from 'formik'
import { useEffect, useRef } from 'react'

export const getFirstErrorKey = (errorObject: any): string | undefined => {
  if (!errorObject || typeof errorObject !== 'object') return

  for (const key in errorObject) {
    if (typeof errorObject[key] === 'string') {
      return key
    }
    if (Array.isArray(errorObject[key])) {
      for (let i = 0; i < errorObject[key].length; i++) {
        if (typeof errorObject[key][i] === 'object') {
          const nestedError = getFirstErrorKey(errorObject[key][i])
          if (nestedError) {
            return `${key}.${i}.${nestedError}`
          }
        }
      }
    } else if (typeof errorObject[key] === 'object') {
      const nestedError = getFirstErrorKey(errorObject[key])
      if (nestedError) {
        return `${key}.${nestedError}`
      }
    }
  }
}
const handleAccordionExpansion = (firstErrorKey: string, isVirtualLoadMode: boolean) => {
  const keyType = firstErrorKey.includes('loads') ? 'loads' : 'assetRequests'
  const parts = firstErrorKey.split('.')
  const indexPosition = parts.indexOf(keyType) + 1
  const index = parts[indexPosition]
  const accordionSelector = `.MuiAccordion-root[data-accordion="${
    isVirtualLoadMode || keyType === 'assetRequests' ? 'assetRequest' : 'load'
  }${index}"] .MuiAccordionSummary-root`

  const accordionSummary = document.querySelector(accordionSelector)

  accordionSummary &&
    accordionSummary.getAttribute('aria-expanded') === 'false' &&
    (accordionSummary as HTMLElement).click()
}

export const useScrollToFormikFieldError = (formik: FormikProps<any>, isRendered = true) => {
  const { errors, isSubmitting, isValidating, submitCount } = formik

  const errorElementRef = useRef<Element | null>()
  const isVirtualLoadMode = !(formik.values.loads.filter((x: any) => x.isDefined).length > 0)

  useEffect(() => {
    if (isSubmitting && !isValidating && isRendered) {
      const firstErrorKey = getFirstErrorKey(errors)

      if (firstErrorKey) {
        const selector = `[id="${firstErrorKey}"]`
        const errorElement = document.querySelector(selector)
        if (firstErrorKey.includes('loads') || firstErrorKey.includes('assetRequests')) {
          handleAccordionExpansion(firstErrorKey, isVirtualLoadMode)
        }
        if (errorElement) {
          errorElementRef.current = errorElement
          errorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        } else if (window.document.readyState === 'complete') {
          setTimeout(() => {
            const errorElement = document.querySelector(selector)
            if (errorElement) {
              errorElementRef.current = errorElement
              errorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          }, 1000)
        }
      }
    }
  }, [errors, isSubmitting, isValidating, submitCount, isRendered])

  return
}

export default useScrollToFormikFieldError
