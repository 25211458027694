import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  assetsClasses: {
    id: 'tenantSettings.assetClass.title',
    description: 'Assets classes title',
    defaultMessage: 'Asset Classes',
  },
  confirmDialogTitle: {
    id: 'tenantSettings.assetClass.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogDiscardSelection: {
    id: 'tenantSettings.assetClass.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'tenantSettings.assetClass.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogMessageContent: {
    id: 'tenantSettings.assetClass.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
  addAssetClassTitle: {
    id: 'tenantSettings.assetClass.addAssetClassTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Add Asset Class',
  },
  editAssetClassTitle: {
    id: 'tenantSettings.assetClass.editAssetClassTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Edit Asset Class',
  },
  deleteAssetClassTitle: {
    id: 'tenantSettings.assetClass.deleteAssetClassTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Delete Asset Class',
  },
})
