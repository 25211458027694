import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { MRT_Row as MRTRow } from 'material-react-table';
import { IAssetClass } from '../../types';
import { FieldInputProps } from 'formik';
import { useAssetClassMrtFormContext } from '../hooks/useAssetClassMrtFormContext';
import { SubmitButton } from '../../../../../../components/Buttons/SubmitButton';

interface DeleteAssetClassMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetClass) => void
  dialogTitle: string
  row: MRTRow<Partial<IAssetClass>>;
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function DeleteAssetClassMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  row,
  columnDef,
  loading,
}: DeleteAssetClassMrtModalProps) {

  const fieldName = `assetClasses.${row.index}`;
  const { getFieldProps } = useAssetClassMrtFormContext()
  const field = getFieldProps(fieldName) as FieldInputProps<IAssetClass>;

  const isSystemDefined = false;


  const submit = () => {
    onSubmit(field.value);
  }

  const disabled = true;

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          <TextField
            label={columnDef['name']}
            name="name"
            value={field.value.name}
            defaultValue={field.value.name}
            disabled={disabled}
          />
          <FormGroup aria-label="position">
            <FormControlLabel
              value={field.value.enabled}
              control={
                <Switch
                  color="primary"
                  checked={field.value.enabled}
                  value={field.value.enabled}
                  disabled={disabled}
                />
              }
              label={columnDef['enabled']}
            />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
        <SubmitButton
          btnText='Delete'
          btnColor='error'
          onClick={submit}
          disabled={isSystemDefined}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
