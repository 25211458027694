import logger from '../../../app/middleware/log'
import { fetchCustomerSettingsDataThunk, makeFetchSlice } from '../../../app/redux-fetch'
import { AppDispatch } from '../../../app/store/store'
import { fetchCurrenciesByCustomerId } from '../../Shipments/api'
import { CUSTOMER_SETTINGS_REDUCER } from '../consts'

const fetchAllCustomerSettingsCurrenciesSlice = makeFetchSlice(CUSTOMER_SETTINGS_REDUCER)

export const fetchAllCustomerSettingsCurrenciesReducers =
  fetchAllCustomerSettingsCurrenciesSlice.reducer
export const fetchAllCustomerSettingsCurrenciesActions =
  fetchAllCustomerSettingsCurrenciesSlice.actions

export const fetchAllCustomerSettingsCurrenciesThunk = (
  customerId: number,
  hideDisabled = false
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCustomerSettingsDataThunk({
        dispatch,
        actions: fetchAllCustomerSettingsCurrenciesActions,
        asyncFunc: () => fetchCurrenciesByCustomerId(customerId, hideDisabled),
      })
    } catch (error) {
      logger.error('Error fetching currencies', error)
    }
  }
}
