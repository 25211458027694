import { Box } from '@mui/material'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import { fetchAllTransportModesSelector, fetchSortedSystemCurrenciesSelector } from '../../Shipments/selectors'
import messages from './messages'
import { SectionTitleTypography, SectionLabelTypography } from '../../../components/Typographies/styles'

type DefaultValuesProps = {
    formik: any
    disabled?: boolean
}



export const DefaultValues = ({ formik, disabled = false }: DefaultValuesProps): ReactElement<any, any> => {
    const transportModes = useAppSelector(fetchAllTransportModesSelector.data) ?? []
    const { formatMessage } = useIntl()
    const currencies = useAppSelector(fetchSortedSystemCurrenciesSelector)

    return (
        <Box sx={{ mb: '16px', width: '75%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography >{formatMessage(messages.defaultValues)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.transportMode)}</SectionLabelTypography>
                <FormDropdown
                    disabled={disabled}
                    id='defaultTransportModeId'
                    onChange={(e, name, newValue) => formik.setFieldValue(name, newValue)}
                    value={formik.values.defaultTransportModeId}
                    items={transportModes}
                    handleOnBlur={formik.handleBlur}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.currency)}</SectionLabelTypography>
                <FormDropdown
                    disabled={disabled}
                    sx={{ width: '50%' }}
                    id='defaultCurrencyId'
                    onChange={(e, name, newValue) => formik.setFieldValue(name, newValue)}
                    value={formik.values.defaultCurrencyId}
                    items={currencies}
                    handleOnBlur={formik.handleBlur}
                />
            </Box>
        </Box>
    )
}

export default DefaultValues