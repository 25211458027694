import { defineMessages } from 'react-intl'

const messages = defineMessages({
  roleSettingTableHeading: {
    id: 'userRoleAllocation.tabs.roleSettingTableHeading',
    description: 'Role settings table heading',
    defaultMessage: 'Roles',
  },
})

export default messages
