import { fetchCommonSettingsDataThunk, makeFetchSlice } from '../../../../../app/redux-fetch'
import { COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME } from './consts'
import { AppDispatch } from '../../../../../app/store/store'
import { fetchAllRoles } from './api'
import logger from '../../../../../app/middleware/log'

export const fetchAllRolesSlice = makeFetchSlice(COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME)

export const fetchAllRolesReducers = fetchAllRolesSlice.reducer
export const fetchAllRolesActions = fetchAllRolesSlice.actions

export const fetchAllCommonSettingsRolesThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCommonSettingsDataThunk({
        dispatch,
        actions: fetchAllRolesActions,
        asyncFunc: () => fetchAllRoles({ tenantId, customerId }),
      })
    } catch (error) {
      logger.error('🚀 Error fetching user group by customer id', error)
    }
  }
}
