/* eslint-disable react/prop-types */
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import MaterialReactTable, { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'

import { useMemo, type ReactElement } from 'react'
import { IJourneyLegLoad, ITransportJourney } from '../../types'

interface ICreateTransportStepProps {
  formik: FormikProps<ITransportJourney>
}

const JourneyDisabledLoadTable = ({
  formik,
}: ICreateTransportStepProps): ReactElement<any, any> => {
  const columns = useMemo<MRTColumnDef<IJourneyLegLoad>[]>(() => {
    return [
      {
        accessorFn: (row) => row.trackingNumber,
        header: 'Tracking Number',
      },
      {
        accessorFn: (row) => row.customerName,
        header: 'Customer',
      },
      {
        accessorFn: (row) => row.pickupLocationName,
        header: 'Pickup Location',
      },
      {
        accessorFn: (row) => (row.pickupDate ? dayjs(row.pickupDate).format('DD/MM/YYYY') : ''),
        header: 'Pickup Date',
      },
      {
        accessorFn: (row) => row.consignee,
        header: 'Consignee',
      },
      {
        accessorFn: (row) => (row.deliveryDate ? dayjs(row.deliveryDate).format('DD/MM/YYYY') : ''),
        header: 'Delivery Date',
      },
      {
        accessorFn: (row) =>
          row.containerDetails && row.containerDetails.length > 0
            ? row.containerDetails.reduce((acc: any, curr: any) => {
                return `${acc} ${curr.containerNumber}`
              }, '')
            : '',
        header: 'Containter No.',
      },
      {
        accessorFn: (row) => (!row.isDefined ? 'Asset Request' : row.description),
        header: 'Description',
      },
      {
        accessorFn: (row) => row.temperatureRange,
        header: 'Temp. Range',
      },
    ]
  }, [formik.values])
  return (
    <MaterialReactTable
      columns={columns}
      data={formik.values.loads.filter((x) => x.isSelected)}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  )
}

export default JourneyDisabledLoadTable
