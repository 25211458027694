import { defineMessages } from 'react-intl'

const messages = defineMessages({
  usersTableHeading: {
    id: 'customerSettings.users.usersTableHeading',
    description: 'Users settings table heading',
    defaultMessage: 'Users',
  },
  usersTableUserAlreadyExists: {
    id: 'customerSettings.users.usersTableUserAlreadyExists',
    description: 'User already exists message',
    defaultMessage: 'User with those credentials already exists',
  },
  usersTableUserDelete: {
    id: 'customerSettings.users.usersTableUserDelete',
    description: 'Delete button text',
    defaultMessage: 'Delete',
  },
  usersTableUserUndo: {
    id: 'customerSettings.users.usersTableUserUndo',
    description: 'Undo button text',
    defaultMessage: 'Undo',
  },
  usersTableFormHasErrors: {
    id: 'customerSettings.users.usersTableFormHasErrors',
    description: 'User form has errors message',
    defaultMessage: 'Form has errors, please fix errors and try again',
  },
  usersTableUserCreatedSuccessfully: {
    id: 'customerSettings.users.usersTableUserCreatedSuccessfully',
    description: 'User created successfully message',
    defaultMessage: 'User created successfully',
  },
  usersTableUserUpdatedSuccessfully: {
    id: 'customerSettings.users.usersTableUserUpdatedSuccessfully',
    description: 'User updated successfully message',
    defaultMessage: 'User updated successfully',
  },
  usersTableUserDeletedSuccessfully: {
    id: 'customerSettings.users.usersTableUserDeletedSuccessfully',
    description: 'User deleted successfully message',
    defaultMessage: 'User deleted successfully',
  },
  usersTableUserCreationFailed: {
    id: 'customerSettings.users.usersTableUserCreationFailed',
    description: 'User creation failed message',
    defaultMessage: 'Failed to create user',
  },
  usersTableUserUpdateFailed: {
    id: 'customerSettings.users.usersTableUserUpdateFailed',
    description: 'User update failed message',
    defaultMessage: 'Failed to updated user',
  },
  usersTableUserDeleteFailed: {
    id: 'customerSettings.users.usersTableUserDeleteFailed',
    description: 'User delete failed message',
    defaultMessage: 'Failed to delete user',
  },
  userConfirmDialogTitle: {
    id: 'userGroupSettings.confirmDialog.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  userConfirmDialogDiscardSelection: {
    id: 'userGroupSettings.confirmDialog.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  userConfirmDialogContinueEditing: {
    id: 'userGroupSettings.confirmDialog.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  userConfirmDialogMessageContent: {
    id: 'userGroupSettings.confirmDialog.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
  confirmDialogTitle: {
    id: 'roleSettings.role.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogDiscardSelection: {
    id: 'roleSettings.role.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'roleSettings.role.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogMessageContent: {
    id: 'roleSettings.role.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
})

export default messages
