import { Box } from '@mui/material'
import MaterialReactTable, { MaterialReactTableProps } from 'material-react-table'

interface IDataGridProps<TData extends Record<string, any>> extends MaterialReactTableProps<TData> {
  contianerStyles?: React.CSSProperties
  leftHeadingComponent?: React.ReactElement
  rightHeadingComponent?: React.ReactElement
}

// eslint-disable-next-line
export const MRTDataGridV2 = <TData extends Record<string, any> = {}>(
  props: IDataGridProps<TData>
) => {
  const styles = {
    boxStyles: {
      borderRadius: '3px',
      padding: '0 10px 10px 10px',
      marginBottom: '20px',
    },
  }

  const {
    contianerStyles,
    leftHeadingComponent,
    rightHeadingComponent,
    enableRowSelection,
    enableDensityToggle = true,
    state,
    ...rest
  } = props

  return (
    <Box sx={styles.boxStyles}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingBottom: '48px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{leftHeadingComponent}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>{rightHeadingComponent}</Box>
      </Box>
      <Box style={contianerStyles} sx={{ flexGrow: 1 }}>
        <MaterialReactTable
          initialState={{
            density: 'compact',
            sorting: [
              {
                id: 'name',
                desc: false,
              },
            ],
          }}
          {...{
            ...rest,
            enableRowSelection,
            enableDensityToggle,
            state: { ...state },
          }}
        />
      </Box>
    </Box>
  )
}

export default MRTDataGridV2
