import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import dayjs from 'dayjs'
import { ILoad } from '../../../types'
import { messages } from './messages'
import { LoadSummaryHeaderBox } from './styles'

interface ILoadSummaryHeaderProps {
  load: ILoad
}

const LoadSummaryHeader: FunctionComponent<ILoadSummaryHeaderProps> = ({ load }) => {
  const { formatMessage } = useIntl()
  const qty = load.palletDetails ? load.palletDetails.reduce((sum, c) => {
    return sum + (c.quantity ? c.quantity : 0)
  }, 0) : 0
  return (

    <LoadSummaryHeaderBox sx={{ display: 'flex', flexDirection: 'row' }}>
      <LoadSummaryHeaderBox>
        {formatMessage(messages.quantity, { qty: load.quantity })}{' '}
      </LoadSummaryHeaderBox>
      <LoadSummaryHeaderBox>
        {formatMessage(messages.description, { desc: load.description })}{' '}
      </LoadSummaryHeaderBox>
      <LoadSummaryHeaderBox>
        {formatMessage(messages.palletsQuantity, { count: qty })}
      </LoadSummaryHeaderBox>
      {load.transportDetail?.pickupDate && (
        <LoadSummaryHeaderBox>
          {formatMessage(messages.pickupDate, {
            date: dayjs(load.transportDetail?.pickupDate).format('DD/MM/YYYY'),
          })}
        </LoadSummaryHeaderBox>
      )}
    </LoadSummaryHeaderBox>
  )
}

export default LoadSummaryHeader
