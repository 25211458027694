import { ApiResult, IAssetCategory } from '../../types';
import { postAssetCategory } from '../../api';


export const createAssetCategory = async (values: IAssetCategory): Promise<ApiResult> => {
      const { tenantId } = values;
      const createResult: ApiResult = { success: true };
      try {
        const payload = { ...values, id: undefined } as unknown as IAssetCategory;
        const resp = await postAssetCategory(payload, tenantId as number);
        createResult.id = resp.data.id as number; 
      } catch (error) {
        createResult.success = false;
      }

      return createResult;
  }
