import AddIcon from '@mui/icons-material/Add'
import { AccordionSummary, Box, Fab, Grid, Typography } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { IShipment } from '../../types'
import AssignmentContainer from './AssignmentContainer'

import messages from './messages'
import { ContainerAccordion } from './styles'

interface IAssignmentContainerPaneProps {
  formik: FormikProps<IShipment>
  disabled?: boolean
  viewMode?: boolean
}

const AssignmentContainerPane = (props: IAssignmentContainerPaneProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { formik, disabled, viewMode } = props

  const handleAddContainer = (): void => {
    const lastId = formik.values.containerDetails.slice(-1)[0]?.id ?? 0
    void formik.setFieldValue('containerDetails', [
      ...formik.values.containerDetails,
      { id: lastId - 1, isGenSetRequired: true },
    ])
  }
  return (
    <>
      {formik.values.containerDetails.length > 0 &&
        formik.values.containerDetails.map((container, containerIndex) => {
          return (
            <AssignmentContainer
              key={containerIndex}
              containerIndex={containerIndex}
              formik={formik}
              container={container}
              disabled={disabled}
              viewMode={viewMode}
            />
          )
        })}
      {!disabled && (
        <ContainerAccordion expanded={false}>
          <AccordionSummary>
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={6} alignItems='center'>
                <Box>
                  <Typography>{formatMessage(messages.newContainer)}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} justifyContent='flex-end'>
                <Box display='flex' justifyContent='flex-end'>
                  <Fab
                    aria-label='add'
                    color='primary'
                    onClick={() => {
                      handleAddContainer()
                    }}
                    size='small'
                    sx={{ margin: '0 2px' }}
                  >
                    <AddIcon />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
        </ContainerAccordion>
      )}
    </>
  )
}

export default AssignmentContainerPane
