import { useFormik } from 'formik'
import { SnackbarOrigin, enqueueSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import MultiGridLayout from '../../../components/MultiGridLayout/MultiGridLayout'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { fetchAllCustomerSettingsSortedUsersSelector } from '../UserSettings/User/selectors'
import { IExtendedUser } from '../UserSettings/User/types'
import ControlBar from './Components/ControlBar/ControlBar'
import {
  IUserGroupPayload,
  deleteUserGroupByCustomerId,
  postUserGroupByCustomerId,
  putUserGroupByCustomerId,
} from './UserGroups/api'
import userGroupMessages from './UserGroups/messages'
import {
  fetchAllCustomerSettingsSortedUserGroupsSelector,
  fetchAllCustomerSettingsUserGroupsSelector,
} from './UserGroups/selectors'
import {
  fetchAllCustomerSettingsUserGroupByCustomerIdActions,
  fetchAllCustomerSettingsUserGroupsThunk,
} from './UserGroups/slice'
import { IExtendedUserGroup } from './UserGroups/types'
import { fetchAllCommonSettingsRolesSortedRolesSelector } from './UserRoleAllocation/Role/selectors'
import {
  fetchAllCommonSettingsRolesThunk,
  fetchAllRolesActions,
} from './UserRoleAllocation/Role/slice'
import { IExtendedRole } from './UserRoleAllocation/Role/types'
import UserRoleAllocation from './UserRoleAllocation/UserRoleAllocation'
import {
  FormikUserGroupSettings,
  IUserGroupFormValues,
  IUserGroupMemberByUserGroupId,
  IUserGroupRoleByUserGroupId,
} from './types'
import { UserGroupMRT } from '../UserGroup/UserGroupMRT'
import {
  fetchAllCustomerSettingsUserByCustomeIdActions,
  fetchAllCustomerSettingsUserThunk,
} from '../UserSettings/User/slice'
import { HttpAction } from '../../enums'
import { IRoleAllocation, IUserAllocation } from './UserRoleAllocation/types'

const UserGroupSettings = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId

  const isFetchingUserGroups = useAppSelector(fetchAllCustomerSettingsUserGroupsSelector.isFetching)

  const allUsers = useAppSelector(fetchAllCustomerSettingsSortedUsersSelector)
  const allRoles = useAppSelector(fetchAllCommonSettingsRolesSortedRolesSelector)
  const allUserGroups = useAppSelector(fetchAllCustomerSettingsSortedUserGroupsSelector)

  useEffect(() => {
    void dispatch(fetchAllCustomerSettingsUserGroupsThunk(tenantId, customerId))
    void dispatch(fetchAllCustomerSettingsUserThunk(tenantId, customerId))
    void dispatch(fetchAllCommonSettingsRolesThunk(tenantId, customerId))
  }, [
    fetchAllCustomerSettingsUserGroupsThunk,
    fetchAllCustomerSettingsUserThunk,
    fetchAllCommonSettingsRolesThunk,
  ])

  useEffect(() => {
    return () => {
      void dispatch(fetchAllCustomerSettingsUserGroupByCustomerIdActions.clear())
      void dispatch(fetchAllCustomerSettingsUserByCustomeIdActions.clear())
      void dispatch(fetchAllRolesActions.clear())
    }
  }, [dispatch, fetchAllCustomerSettingsUserGroupByCustomerIdActions.clear])

  const initialValues = useMemo<
    FormikUserGroupSettings['initialValues']
  >((): IUserGroupFormValues => {
    const allUserGroupMembers: IUserGroupMemberByUserGroupId[] = allUserGroups.map((userGroup) => ({
      members: userGroup.users,
      groupId: userGroup.id,
      membersEditable: userGroup.externalId === undefined,
    }))
    const allUserGroupRoles: IUserGroupRoleByUserGroupId[] = allUserGroups.map((userGroup) => ({
      roles: userGroup.roles,
      groupId: userGroup.id,
    }))
    const allUserGrroupUserAllocations = allUserGroups
      .map((userGroup) => userGroup.userAllocations)
      .flat()
    const allUserGrroupRoleAllocations = allUserGroups
      .map((userGroup) => userGroup.roleAllocations)
      .flat()

    return {
      formikUsers: allUsers,
      formikRoles: allRoles,
      formikUserGroups: allUserGroups,
      formikUserGroupMembersByGroupId: allUserGroupMembers,
      formikUserGroupRolesByGroupId: allUserGroupRoles,
      formikUserGroupUserAllocations: allUserGrroupUserAllocations,
      formikUserGroupRoleAllocations: allUserGrroupRoleAllocations,

      formikSelectedUserGroupId: undefined,

      formikSelectedUserIdsByGroupId: undefined,

      formikSelectedMemberRoleIdsByGroupId: undefined,

      formikSelectedMemberIdsByUserGroupId: [],
      formikSelectedRoleIdsByUserGroupId: [],

      formIsDirty: false,
      formHasErrors: false,
      formSubmissionSuccessful: false,
      formSubmissionFailure: false,
    }
  }, [allUsers, allRoles, allUserGroups])

  const submitForm = async () => {
    const selectedGroupId = formik.values.formikSelectedUserGroupId
    const currentUserGroup = formik.values.formikUserGroups.find((x) => x.id === selectedGroupId)
    formik.setSubmitting(true)

    const anchorOrigin: SnackbarOrigin = {
      vertical: 'top',
      horizontal: 'center',
    }

    if (currentUserGroup) {
      const groupsToCreate = formik.values.formikUserGroups.filter(
        (group: IExtendedUserGroup) => group.httpAction === HttpAction.POST && group.dirty
      )

      const groupsToDelete = formik.values.formikUserGroups.filter(
        (group: IExtendedUserGroup) => group.httpAction === HttpAction.DELETE && group.dirty
      )

      const groupsToUpdate = formik.values.formikUserGroups.filter(
        (group: IExtendedUserGroup) =>
          group.dirty &&
          (group.httpAction === HttpAction.PUT ||
            ![HttpAction.POST, HttpAction.DELETE].includes(group.httpAction as HttpAction))
      )

      for (const groupToCreate of groupsToCreate) {
        const payload: IUserGroupPayload = {
          tenantId,
          customerId,
          userGroup: {
            ...groupToCreate,
            id: undefined,
            userAllocations: getUserAllocations(groupToCreate.id),
            roleAllocations: getRolesAllocations(groupToCreate.id),
          },
        }

        try {
          await postUserGroupByCustomerId(payload)

          enqueueSnackbar(
            formatMessage(userGroupMessages.userGroupTableUserGroupCreatedSuccessfully),
            {
              variant: 'success',
              anchorOrigin,
            }
          )
        } catch (error) {
          enqueueSnackbar(formatMessage(userGroupMessages.userGroupTableUserGroupCreationFailed), {
            variant: 'error',
            anchorOrigin,
          })
        }
      }

      for (const groupToDelete of groupsToDelete) {
        try {
          await deleteUserGroupByCustomerId({
            tenantId: groupToDelete.tenantId,
            customerId: groupToDelete.customerId,
            userGroup: groupToDelete,
          })
          enqueueSnackbar(
            formatMessage(userGroupMessages.userGroupTableUserGroupDeletedSuccessfully),
            {
              variant: 'success',
              anchorOrigin,
            }
          )
        } catch (error) {
          enqueueSnackbar(formatMessage(userGroupMessages.userGroupTableUserGroupDeleteFailed), {
            variant: 'error',
            anchorOrigin,
          })
        }
      }

      for (const groupToUpdate of groupsToUpdate) {
        const payload: IUserGroupPayload = {
          tenantId,
          customerId,
          userGroup: {
            ...groupToUpdate,
            userAllocations: getUserAllocations(groupToUpdate.id),
            roleAllocations: getRolesAllocations(groupToUpdate.id),
          },
        }

        try {
          await putUserGroupByCustomerId(payload)

          enqueueSnackbar(
            formatMessage(userGroupMessages.userGroupTableUserGroupUpdatedSuccessfully),
            {
              variant: 'success',
              anchorOrigin,
            }
          )
        } catch (error) {
          enqueueSnackbar(formatMessage(userGroupMessages.userGroupTableUserGroupUpdateFailed), {
            variant: 'error',
            anchorOrigin,
          })
        }
      }
    }

    function getUserAllocations(selectedGroupId: number) {
      const userPayload = formik.values.formikUserGroupMembersByGroupId.find(
        (g) => g.groupId === selectedGroupId
      )
      if (userPayload) {
        const newUsers =
          userPayload?.members.filter(
            (u) =>
              (u as IExtendedUser)?.httpAction &&
              (u as IExtendedUser)?.httpAction !== HttpAction.DELETE
          ) ?? []
        const deleteUserAllocation =
          userPayload?.members.filter(
            (u) =>
              (u as IExtendedUser)?.httpAction &&
              (u as IExtendedUser)?.httpAction === HttpAction.DELETE
          ) ?? []

        let userAllocation =
          currentUserGroup?.userAllocations.filter(
            (ua) => !deleteUserAllocation.some((dua) => dua.id === ua.userId)
          ) || []
        const newUserAllocation = newUsers.map(
          (x) => ({ userGroupId: selectedGroupId, userId: x.id } as IUserAllocation)
        )
        userAllocation = [...userAllocation, ...newUserAllocation]
        return userAllocation
      }
      return []
    }

    function getRolesAllocations(selectedGroupId: number) {
      const rolePayload = formik.values.formikUserGroupRolesByGroupId.find(
        (g) => g.groupId === selectedGroupId
      )
      if (rolePayload) {
        const newRoles =
          rolePayload?.roles.filter(
            (u) =>
              (u as IExtendedUser)?.httpAction &&
              (u as IExtendedUser)?.httpAction !== HttpAction.DELETE
          ) ?? []
        const deleteRoleAllocation =
          rolePayload?.roles.filter(
            (r) =>
              (r as IExtendedUser)?.httpAction &&
              (r as IExtendedUser)?.httpAction === HttpAction.DELETE
          ) ?? []

        let roleAllocation =
          currentUserGroup?.roleAllocations.filter(
            (ra) => !deleteRoleAllocation.some((dra) => dra.id === ra.roleId)
          ) || []
        const newRoleAllocation = newRoles.map(
          (x) => ({ userGroupId: selectedGroupId, roleId: x.id } as IRoleAllocation)
        )
        roleAllocation = [...roleAllocation, ...newRoleAllocation]
        return roleAllocation
      }
      return []
    }

    // Clear all selections
    formik.values.formikSelectedUserGroupId = undefined
    formik.values.formikSelectedUserIdsByGroupId = undefined
    formik.values.formikSelectedMemberRoleIdsByGroupId = undefined
    formik.values.formikSelectedRoleIdsByUserGroupId = []
    formik.values.formikSelectedMemberIdsByUserGroupId = []

    dispatch(fetchAllCustomerSettingsUserGroupsThunk(tenantId, customerId))
    dispatch(fetchAllCustomerSettingsUserThunk(tenantId, customerId))
    dispatch(fetchAllCommonSettingsRolesThunk(tenantId, customerId))

    formik.setSubmitting(false)
    return
  }

  const formik = useFormik<IUserGroupFormValues>({
    initialValues: initialValues,
    onSubmit: submitForm,
    enableReinitialize: true,
  })

  const handleSubmit = (event: any) => formik.handleSubmit(event)

  const [activeTab, setActiveTab] = useState(0)

  return (
    <>
      <ControlBar
        isFormDirty={() => isFormDirty(formik)}
        handleSubmit={handleSubmit}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <MultiGridLayout>
        <UserGroupMRT formik={formik} isLoading={isFetchingUserGroups} />
        <UserRoleAllocation activeTab={activeTab} formik={formik} />
      </MultiGridLayout>
    </>
  )
}

export default UserGroupSettings

export const isFormDirty = (formik: FormikUserGroupSettings) => {
  const {
    formikSelectedUserGroupId,

    // Existing members/roles
    formikUserGroups,
    formikUserGroupMembersByGroupId,
    formikUserGroupRolesByGroupId,
  } = formik.values as IUserGroupFormValues

  const userGroupTouched =
    (formikUserGroups as IExtendedUserGroup[])?.find((user) => user.dirty) !== undefined

  const selectedUserGroupMembersByGroupId = (formikUserGroupMembersByGroupId.find(
    (member) => member.groupId === formikSelectedUserGroupId
  )?.members || []) as IExtendedUser[]
  const selectedUserGroupRolesByGroupId = (formikUserGroupRolesByGroupId.find(
    (role) => role.groupId === formikSelectedUserGroupId
  )?.roles || []) as IExtendedRole[]

  const userGroupMembersChanged =
    selectedUserGroupMembersByGroupId.find((member) => member.httpAction !== undefined) !==
    undefined

  const userGroupRolesChanged =
    selectedUserGroupRolesByGroupId.find((member) => member.httpAction !== undefined) !== undefined
  return userGroupTouched || userGroupMembersChanged || userGroupRolesChanged
}
