import { FormikProps } from 'formik'
import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { Box, Typography } from '@mui/material'
import { useAppSelector } from '../../../../../app/hooks'
import { TableColumn, TableItem } from '../../../../../components/Headers/styles'
import { TableTitleTypography } from '../../../../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../../../../selectors'
import { fetchAllCustomersByTenantSelector, fetchAllShippersSelector, fetchSortedCurrenciesSelector } from '../../../selectors'
import { IShipment } from '../../../types'
import { getStatus } from '../../ViewShipment/utils'
import { messages } from './messages'

interface IShipmentSummaryHeaderProps {
  formik: FormikProps<IShipment>
  pb?: string
}

const ShipmentSummaryHeader: FunctionComponent<IShipmentSummaryHeaderProps> = ({ formik, pb }) => {
  const { formatMessage } = useIntl()
  const currencies = useAppSelector(fetchSortedCurrenciesSelector)
  const shippers = useAppSelector(fetchAllShippersSelector)
  const shipper = shippers.find((x) => x.id === formik.values.shipperId)
  const currency = currencies.find((x) => x.id === formik.values.currencyId)
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId
  const customers = useAppSelector(fetchAllCustomersByTenantSelector.data) ?? []
  const shipment = formik.values

  return (

    <Box display="flex" flexDirection="column" sx={{ pt: '4px', pb: pb ? pb : '36px' }} >
      <Box display="flex">
        <TableTitleTypography sx={{ fontSize: '24px' }}>Summary</TableTitleTypography>
      </Box>
      <Box display="flex">
        <TableColumn>
          <Typography variant="body1">Status</Typography>
        </TableColumn>
        {isTenantUser && (
          <TableColumn>
            <Typography variant="body1">Customer</Typography>
          </TableColumn>)}
        <TableColumn>
          <Typography variant="body1">Customer Reference Number</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant="body1">{`${formatMessage(messages.shipper)}`}</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant="body1">Cargo Description</Typography>
        </TableColumn>
        <TableColumn>
          <Typography variant="body1">Declared Value</Typography>
        </TableColumn>
      </Box>
      <Box display="flex">
        <TableItem><Typography>{getStatus(formatMessage, shipment)}</Typography></TableItem>
        {isTenantUser && (<TableItem><Typography>{customers.find(c => c.id === formik.values.customerId)?.name}</Typography></TableItem>)}
        <TableItem><Typography>{shipment?.customerReferenceNumber}</Typography></TableItem>
        <TableItem><Typography>{`${shipper?.name ?? ''}`}</Typography></TableItem>
        <TableItem><Typography>{shipment?.description}</Typography></TableItem>
        <TableItem><Typography>{`${currency ? `${formik.values.value.toFixed(2)} ${currency.symbol} ` : ''
          }`}</Typography></TableItem>
      </Box>
    </Box >
  )
}

export default ShipmentSummaryHeader
