import { useEffect, useMemo, useState } from 'react'
import messages from './messages'
import MRTDataGrid from '../../../../components/MRTDataGrid/MRTDataGrid'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table'
import { IExtendedSecurityPrincipal, ISecurityPrincipal, ISecurityPrincipalProps } from './types'
import { SecurityPrincipalEnum } from '../../../../app/types'

export const SecurityPrincipal = ({ formik, isLoading }: ISecurityPrincipalProps) => {
  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const formikSecurityPrincipals: ISecurityPrincipal[] =
    formik.values.formikSecurityPrincipals || []

  const formikSelectedRoleId = formik.values.formikSelectedRoleId

  // Get all security principals ids of the selected role
  const originalSelectedRoleSecurityPrincipalIds: number[] =
    formik.getFieldProps('originalSelectedSecurityPrincipals').value || []

  const data: IExtendedSecurityPrincipal[] = formikSelectedRoleId
    ? formikSecurityPrincipals.map((securityPrincipal) => {
        return {
          ...securityPrincipal,
          autoSelect: originalSelectedRoleSecurityPrincipalIds.includes(securityPrincipal.id),
        }
      })
    : []

  const columns = useMemo<MRTColumnDef<ISecurityPrincipal>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
    ]
  }, [])

  const handleChangeSelection = (
    event: React.MouseEvent<HTMLElement>,
    row: MRTRow<ISecurityPrincipal>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    const { id: originalRowId, prerequisites = [] } = row.original

    setRowSelection((prevState) => {
      const AddUser = SecurityPrincipalEnum['Add User']
      const EditUser = SecurityPrincipalEnum['Edit User']
      const ViewUser = SecurityPrincipalEnum['View User']

      // You can Add or Edit a user, if only you can View the user,
      // so if ViewUser is not selected, then AddUser and EditUser should not be selected either
      if (originalRowId === ViewUser && prevState[ViewUser] === true) {
        return {
          [SecurityPrincipalEnum['Add User']]: false,
          [SecurityPrincipalEnum['Edit User']]: false,
          [SecurityPrincipalEnum['View User']]: false,
        }
      }

      const newRowSelection: MRTRowSelectionState = {
        ...prevState,
        [originalRowId]: !prevState[originalRowId],
      }
      prerequisites.forEach((prerequisite) => (newRowSelection[prerequisite.id] = true))

      // If EditUser is not selected, then AddUser should not be selected
      if (originalRowId === EditUser && prevState[EditUser] === true) {
        newRowSelection[AddUser] = false
      }

      return newRowSelection
    })
  }

  const handleRowSelectionChange = ({
    row,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<ISecurityPrincipal>
    table: MRTTableInstance<ISecurityPrincipal>
  }) => {
    return {
      onChange: (event: any) => handleChangeSelection(event, row),
      onClick: (event: any) => handleChangeSelection(event, row),
      sx: { cursor: 'pointer' },
    }
  }

  useEffect(() => {
    if (!formikSelectedRoleId) return

    setRowSelection(() => {
      const newRowSelection = originalSelectedRoleSecurityPrincipalIds.reduce((acc, id) => {
        return {
          ...acc,
          [id]: true,
        }
      }, {})
      return newRowSelection
    })
  }, [formikSelectedRoleId])

  useEffect(() => {
    const newSelectedSecurityPrincipals = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map((key) => Number(key))

    formik.setFieldValue('newSelectedSecurityPrincipals', newSelectedSecurityPrincipals)
  }, [rowSelection])

  return (
    <>
      <MRTDataGrid
        heading={messages.tableHeading}
        columns={columns}
        data={data}
        enableMultiRowSelection={true}
        enableRowSelection={true}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        // onRowSelectionChange={setRowSelection} // connect internal row selection state to your own
        state={{ rowSelection, isLoading }} // pass our managed row selection state to the table to use
      />
    </>
  )
}
