import { Button, Grid, Typography } from '@mui/material';
import { useCallback, type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { fetchLoggedInUserSelector } from '../../../features/selectors';
import AssetForm from './AssetForm';
import messages from './messages';

const CreateAsset = (): ReactElement<any, any> => {
    const { formatMessage } = useIntl()
    const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)

    return (
        <Grid container spacing={2} pt={4}>
            <Grid item xs={12}>
                <Typography variant='h3' align='left' dir='rtl'>
                    {formatMessage(messages.addAsset)}
                </Typography>
            </Grid>
            <Grid display='flex' flexDirection='row' gap={4} width='100%'>
                <Grid display='flex' flexDirection='column' width='50%'>
                    <AssetForm
                        loggedInUser={loggedInUser}
                        type="create"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateAsset
