import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'
import { getProperty } from '../../../../../app/utils'
import messages from '../../messages'
import { FormikCurrencySettings } from '../../types'
import { ICurrencyRow } from '../types'
import { isUnique } from '../utils'

interface IAddCurrencyModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: ICurrencyRow) => void
  currencyFormik: FormikCurrencySettings
}

export const AddCurrencyModal = ({
  open,
  onClose,
  onSubmit,
  currencyFormik,
}: IAddCurrencyModalProps) => {
  const { formatMessage } = useIntl()
  const formik = useFormik<Partial<ICurrencyRow>>({
    initialValues: {
      id: undefined,
      name: '',
      iso3: '',
      symbol: '',
      localisedSymbol: '',
    },
    validationSchema: getAddNewCurrencyValidationSchema,
    onSubmit: async (values) => {
      onSubmit(values as ICurrencyRow)
      onClose()
    },
  })

  const isUniqueValue = function isUniqueValue(value: string | undefined, context: any) {
    return isUnique({
      formik: currencyFormik,
      id: -1,
      propToCheck: context.path,
      valueToCheck: value as string,
    })
  }

  function getAddNewCurrencyValidationSchema() {
    return yup.object({
      id: yup.number().optional(),
      iso3: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'ISO3' }))
        .max(
          3,
          formatMessage(messages.propertyValueMustBeExactLength, {
            propertyName: 'ISO3',
            exactLength: 3,
          })
        )
        .test({
          name: 'isUniqueValue',
          message: formatMessage(messages.propertyValueMustBeUnique, { propertyName: 'ISO3' }),
          test: isUniqueValue,
        }),
      name: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'Name' }))
        .max(
          100,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'Name', maxLength: 100 })
        )
        .test({
          name: 'isUniqueValue',
          message: formatMessage(messages.propertyValueMustBeUnique, { propertyName: 'Name' }),
          test: isUniqueValue,
        }),
      symbol: yup
        .string()
        .required(formatMessage(messages.propertyValueRequired, { propertyName: 'Symbol' }))
        .max(
          10,
          formatMessage(messages.propertyValueMaxLength, { propertyName: 'Symbol', maxLength: 10 })
        ),
      localisedSymbol: yup
        .string()
        .required(
          formatMessage(messages.propertyValueRequired, { propertyName: 'Localised Symbol' })
        )
        .max(
          10,
          formatMessage(messages.propertyValueMaxLength, {
            propertyName: 'Localised Symbol',
            maxLength: 10,
          })
        ),
    })
  }

  const getError = (propertyName: string): boolean => {
    const touched = getProperty(formik.touched, propertyName)
    const errorMessage = getProperty(formik.errors as unknown, propertyName)
    return touched && (errorMessage as any)
  }

  const handleCancelAddNewCurrency = () => {
    formik.resetForm()
    onClose()
  }

  const handleSubmit = () => formik.handleSubmit()

  useEffect(() => formik.resetForm(), [open])

  return (
    <Dialog
      open={open}
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>Add New Currency</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            <>
              <TextField
                id='iso3'
                name='iso3'
                key='iso3'
                label='ISO3'
                value={formik.values.iso3}
                onChange={formik.handleChange}
                error={formik.touched.iso3 && Boolean(formik.errors.iso3)}
                helperText={getError('iso3')}
              />

              <TextField
                id='name'
                name='name'
                key='name'
                label='Name'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={getError('name')}
              />

              <TextField
                id='symbol'
                name='symbol'
                key='symbol'
                label='Symbol'
                value={formik.values.symbol}
                onChange={formik.handleChange}
                error={formik.touched.symbol && Boolean(formik.errors.symbol)}
                helperText={getError('symbol')}
              />

              <TextField
                id='localisedSymbol'
                name='localisedSymbol'
                key='localisedSymbol'
                label='Localised Symbol'
                value={formik.values.localisedSymbol}
                onChange={formik.handleChange}
                error={formik.touched.localisedSymbol && Boolean(formik.errors.localisedSymbol)}
                helperText={getError('localisedSymbol')}
              />
            </>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={handleCancelAddNewCurrency}>Cancel</Button>
        <Button color='secondary' onClick={handleSubmit} variant='contained'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddCurrencyModal
