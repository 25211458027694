import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  load: {
    id: 'routeLocation.load',
    defaultMessage: 'Load',
    description: 'Label for the "Load" column in the table',
  },
  pickup: {
    id: 'routeLocation.pickup',
    defaultMessage: 'Pickup',
    description: 'Label for the "Pickup" column in the table',
  },
  dropoff: {
    id: 'routeLocation.dropoff',
    defaultMessage: 'Dropoff',
    description: 'Label for the "Dropoff" column in the table',
  },
  noLoads: {
    id: 'routeLocation.noLoads',
    defaultMessage: 'No loads',
    description: 'Label for the "No loads" column in the table',
  },
  contact: {
    id: 'routeLocation.contact',
    defaultMessage: 'Contact',
    description: 'Label for the "Contact" column in the table',
  },
  removeLoadFromRoute: {
    id: 'routeLocation.removeLoadFromRoute',
    defaultMessage: 'Remove load from route',
    description: 'Label for the "Remove load from route" menu item',
  },
  selectLocation: {
    id: 'routeLocation.selectLocation',
    defaultMessage: 'Select location',
    description: 'Label for the location selection dropdown',
  },
  selectContact: {
    id: 'routeLocation.selectContact',
    defaultMessage: 'Select Contact',
    description: 'Label for the contact selection dropdown',
  },
  start: {
    id: 'routeLocation.start',
    defaultMessage: 'Start',
    description: 'Label for the start location of the route',
  },
  end: {
    id: 'routeLocation.end',
    defaultMessage: 'End',
    description: 'Label for the end location of the route',
  },
  stop: {
    id: 'routeLocation.stop',
    defaultMessage: 'Stop #{stopNumber}',
    description:
      'Label for a stop location of the route. "{stopNumber}" will be replaced with the actual stop number',
  },
  remove: {
    id: 'routeLocation.remove',
    defaultMessage: 'Remove',
    description: 'Label for the "Remove" icon button',
  },
  legCarrierLabel: {
    id: 'leg.legCarrierLabel',
    defaultMessage: 'Carrier',
    description: 'Label for the "Carrier" labeldropdown',
  },
  legCarrierWaybillMaster: {
    id: 'leg.legCarrierWaybillMaster',
    defaultMessage: 'Carrier Waybill Master',
    description: 'Label for the "Carrier Waybill Master" labeldropdown',
  },
  legTitle: {
    id: 'leg.legTitle',
    defaultMessage: 'Leg',
    description: 'Label for the "Leg" in the accordion',
  },
  addRoute: {
    id: 'routeLocation.addRouteTitle',
    defaultMessage: 'Add Route',
    description: 'Add Route Title',
  },
  defaultRouteName: {
    id: 'routeLocation.defaultRouteName',
    defaultMessage: 'Route',
    description: 'Default Route Name',
  },
})
