import { getProperty } from '../../../app/utils'

export const getError = (propertyName: string, formik: any): boolean => {
  const touched = getProperty(formik.touched, propertyName)
  const errorMessage = getProperty(formik.errors as unknown, propertyName)
  return touched && (errorMessage as any)
}

export const getAddressError = (propertyName: string, formik: any): boolean => {
  const touched =
    formik.touched && formik.touched.address && getProperty(formik.touched.address, propertyName)
  const errorMessage =
    formik.errors &&
    formik.errors.address &&
    getProperty(formik.errors.address as unknown, propertyName)
  return touched && (errorMessage as any)
}
