import { defineMessages } from 'react-intl'

const messages = defineMessages({
  userGroupTableHeading: {
    id: 'customerSettings.userGroups.userGroupTableHeading',
    description: 'User group settings table heading',
    defaultMessage: 'User Groups',
  },
  userGroupTableUserAlreadyExists: {
    id: 'customerSettings.userGroups.userGroupTableUserAlreadyExists',
    description: 'User already exists message',
    defaultMessage: 'User group with those attributes already exists',
  },
  userGroupTableUserDelete: {
    id: 'customerSettings.userGroups.userGroupTableUserDelete',
    description: 'Delete button text',
    defaultMessage: 'Delete',
  },
  userGroupTableUserUndo: {
    id: 'customerSettings.userGroups.userGroupTableUserUndo',
    description: 'Undo button text',
    defaultMessage: 'Undo',
  },
  userGroupTableFormHasErrors: {
    id: 'customerSettings.usersGroups.userGroupTableFormHasErrors',
    description: 'User Group form has errors message',
    defaultMessage: 'Form has errors, please fix errors and try again',
  },
  userGroupTableUserGroupCreatedSuccessfully: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupCreatedSuccessfully',
    description: 'User Group created successfully message',
    defaultMessage: 'User group created successfully',
  },
  userGroupTableUserGroupUpdatedSuccessfully: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupUpdatedSuccessfully',
    description: 'User Group updated successfully message',
    defaultMessage: 'User group updated successfully',
  },
  userGroupTableUserGroupDeletedSuccessfully: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupDeletedSuccessfully',
    description: 'User Group deleted successfully message',
    defaultMessage: 'User group deleted successfully',
  },
  userGroupTableUserGroupCreationFailed: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupCreationFailed',
    description: 'User Group creation failed message',
    defaultMessage: 'Failed to create user group',
  },
  userGroupTableUserGroupUpdateFailed: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupUpdateFailed',
    description: 'User Group update failed message',
    defaultMessage: 'Failed to update user group',
  },
  userGroupTableUserGroupDeleteFailed: {
    id: 'customerSettings.usersGroups.userGroupTableUserGroupDeleteFailed',
    description: 'User Group delete failed message',
    defaultMessage: 'Failed to delete user group',
  },
  addUserGroupTitle: {
    id: 'customerSettings.usersGroups.addUserGroupTitle',
    description: 'Add User Group title',
    defaultMessage: 'Add User Group',
  },
  confirmDialogTitle: {
    id: 'commonSettings.userGroups.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogDiscardSelection: {
    id: 'commonSettings.userGroups.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'commonSettings.userGroups.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogMessageContent: {
    id: 'commonSettings.userGroups.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
})

export default messages
