import logger from '../../../../app/middleware/log'
import { IJourneyLegLoad, ITransportJourney } from '../../types'

export const validateLoadInLegs = (journey: ITransportJourney): boolean => {
  const loadsInUse = journey.loads
  for (const loadInUse of loadsInUse) {
    const legsWithSpecifiedLoads = journey.legs.filter((l) =>
      l.routes.some((r) => r.loads.some((rl) => rl.id === loadInUse.id))
    )
    if (legsWithSpecifiedLoads.length > 1) {
      let previousLastDestionation
      for (const leg of legsWithSpecifiedLoads) {
        for (const route of leg.routes) {
          const routeLoad = route.loads.find((rl) => rl.id === loadInUse.id)
          if (routeLoad) {
            if (
              previousLastDestionation &&
              routeLoad.pickupRouteLocationId !== previousLastDestionation
            ) {
              return false
            }
            if (!routeLoad.dropoffRouteLocationId) {
              return false
            }
            previousLastDestionation = routeLoad.dropoffRouteLocationId
          }
        }
      }
    }
  }
  return true
}

export const findFirstAndLastOccasionForLoad = (
  journey: ITransportJourney,
  load: IJourneyLegLoad
): {
  first: { legIndex: number; routeIndex: number; loadIndex: number }
  last: { legIndex: number; routeIndex: number; loadIndex: number }
} | null => {
  let firstOccasion = null
  let lastOccasion = null

  for (let legIndex = 0; legIndex < journey.legs.length; legIndex++) {
    const currentLeg = journey.legs[legIndex]

    for (let routeIndex = 0; routeIndex < currentLeg.routes.length; routeIndex++) {
      const currentRoute = currentLeg.routes[routeIndex]

      for (let loadIndex = 0; loadIndex < currentRoute.loads.length; loadIndex++) {
        const routeLoad = currentRoute.loads[loadIndex]

        if (routeLoad.id === load.id) {
          // Check for the first occasion
          if (!firstOccasion && routeLoad.pickupRouteLocationId === load.pickupLocationId) {
            firstOccasion = { legIndex, routeIndex, loadIndex }
          } else if (!firstOccasion && routeLoad.pickupRouteLocationId !== load.pickupLocationId) {
            // The first pickup destination in a Journey must be the Load's pickup location
            return null
          }
          // Always update the last occasion if it matches the consigneeId, so that the last match is stored
          if (routeLoad.dropoffRouteLocationId === load.consigneeId) {
            lastOccasion = { legIndex, routeIndex, loadIndex }
          }
        }
      }
    }
  }

  // Check if we found both first and last occasions
  if (firstOccasion && lastOccasion) {
    return {
      first: firstOccasion,
      last: lastOccasion,
    }
  } else {
    return null // Not found or conditions not met
  }
}

export const checkLoadsMismatch = (journey: ITransportJourney): boolean => {
  const loadsInUse = journey.loads.filter((x) => x.isSelected)
  for (const load of loadsInUse) {
    const occasions = findFirstAndLastOccasionForLoad(journey, load)

    if (occasions) {
      logger.log(`Load ID: ${load.id}`)
      logger.log(
        'First (pickup) - Leg Index:',
        occasions.first.legIndex,
        'Route Index:',
        occasions.first.routeIndex,
        'Load Index:',
        occasions.first.loadIndex
      )
      logger.log(
        'Last (dropoff) - Leg Index:',
        occasions.last.legIndex,
        'Route Index:',
        occasions.last.routeIndex,
        'Load Index:',
        occasions.last.loadIndex
      )
      logger.log('-------------------------------------')
    } else {
      logger.log(`Load ID ${load.id} did not have matches or was not found.`)
      return false
    }
  }
  return true
}

export const validateLoadsInLegRoutes = (journey: ITransportJourney): number[] => {
  const invalidLoadIds: number[] = []
  for (const leg of journey.legs) {
    for (const route of leg.routes) {
      for (const routeLoad of route.loads) {
        const hasPickup = route.locations.some(
          (location) => location.locationId === routeLoad.pickupRouteLocationId
        )
        const hasDropoff = route.locations.some(
          (location) => location.locationId === routeLoad.dropoffRouteLocationId
        )
        if (!hasPickup || !hasDropoff) {
          invalidLoadIds.push(routeLoad.id)
        }
      }
    }
  }

  return invalidLoadIds
}
