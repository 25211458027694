import { defineMessages } from 'react-intl'

const messages = defineMessages({
  roleTableHeading: {
    id: 'commonSettings.role.roleTableHeading',
    description: 'Role settings table heading',
    defaultMessage: 'Roles',
  },
})

export default messages
