import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { AxiosError, AxiosInstance } from 'axios'
import {
  ITrackingNumberParameterType,
  ITrackingNumberTemplate,
} from '../../features/CommonSettings/TrackingNumber/types'
import {
  IHsHeading,
  IHsLocalTariff,
  IHsSubheading,
  IProduct,
  IProductCategory,
} from '../../features/Products/types'
import { ShipmentNote } from '../../features/Shipments/components/Notes/types'
import {
  IAssetCategory,
  ICarrier,
  ILocationByTenantCustomer,
  IShipment,
  ITransportMode,
} from '../../features/Shipments/types'
import {
  IAssetConfigPayload,
  IAssetConfiguration,
} from '../../features/TenantSettings/Components/AssetSettings/types'
import { IBranding } from '../../features/TenantSettings/types'
import { IUnassginedRoute } from '../../features/Transport/components/Routes/types'
import {
  IAssetGroupAllocationDetails,
  ITransportRouteLoad,
  IUpdateJourney,
} from '../../features/Transport/types'
import { TransportJob } from '../../features/TransportJob/types'
import { IAssetBrand, IAssetManagement, IAssetMeasureUnit } from '../../features/types'
import { OMNILOG_SHIPMENTS_API_URL } from '../axios/const'
import {
  assetsAuthorizedFetch,
  shipmentsAuthorizedFetch,
  usersAuthorizedFetch,
} from '../axios/omnialog'

async function fetchData(
  domain: 'Asset' | 'Shipment' | 'User',
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  url: string,
  body?: any
) {
  let authorizedFetch: AxiosInstance

  if (domain === 'Asset') {
    authorizedFetch = assetsAuthorizedFetch
  } else if (domain === 'Shipment') {
    authorizedFetch = shipmentsAuthorizedFetch
  } else if (domain === 'User') {
    authorizedFetch = usersAuthorizedFetch
  } else {
    throw new Error('Invalid domain')
  }

  let result
  if (method === 'GET') {
    result = await authorizedFetch.get(url)
  } else if (method === 'POST') {
    result = await authorizedFetch.post(url, body)
  } else if (method === 'PUT') {
    result = await authorizedFetch.put(url, body)
  } else if (method === 'DELETE') {
    result = await authorizedFetch.delete(url)
  } else {
    throw new Error('Invalid method')
  }

  return { data: result.data.data }
}

const apiAxiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method: 'GET' | 'POST' | 'PUT' | 'DELETE'
      domain: 'Asset' | 'Shipment' | 'User'
      body?: any
    },
    unknown,
    unknown
  > =>
  async ({ url, method, domain, body }) => {
    try {
      return await fetchData(domain, method, url, body)
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const apiSlice = createApi({
  baseQuery: apiAxiosBaseQuery({
    baseUrl: OMNILOG_SHIPMENTS_API_URL,
  }),
  tagTypes: [
    'Shipments',
    'Carrier',
    'Assets',
    'Loads',
    'Locations',
    'Journeys',
    'Journey',
    'AssetGroup',
    'TrackingNumberParameterTypes',
    'TrackingNumberTemplate',
    'TransportMode',
    'AssetTypes',
    'AssetConfigurations',
    'MeasureUnits',
    'Brands',
    'Customers',
    'Branding',
    'Categories',
    'SubHsHeadings',
    'HsHeadings',
    'Tariffs',
    'Products',
    'Transport Jobs',
    'Shipment Notes',
  ],
  endpoints(build) {
    return {
      shipmentById: build.query<
        IShipment,
        { shipmentId: number; tenantId: number; customerId?: number }
      >({
        query: ({ shipmentId, tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}shipment/${shipmentId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { shipmentId }) => [{ type: 'Shipments', shipmentId }],
      }),
      trackingNumberParamsTypes: build.query<ITrackingNumberParameterType[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/trackingnumber/parameters`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'TrackingNumberParameterTypes', tenantId },
        ],
      }),
      trackingNumberTemplate: build.query<ITrackingNumberTemplate, { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/trackingnumber/template`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'TrackingNumberTemplate', tenantId },
        ],
      }),
      createTrackingNumberTemplate: build.mutation<
        any,
        { tenantId: number; body: ITrackingNumberTemplate }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/trackingnumber/template`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
      }),
      assetCategoriesByTransportModeId: build.query<
        IAssetCategory,
        { tenantId: number; transportModeId: number }
      >({
        query: ({ tenantId, transportModeId }) => {
          return {
            url: `/tenant/${tenantId}/transport-mode/${transportModeId}/asset-category?hideGlobals=true`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { transportModeId }) => [{ type: 'Assets', transportModeId }],
      }),
      // useGetTransportModeQuery
      getTransportMode: build.query<ITransportMode[], void>({
        query: () => {
          return {
            url: '/get-all-transport-modes',
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error) => [{ type: 'TransportMode', id: 'any' }],
      }),
      // useGetAssetTypesByCategoryQuery
      getAssetTypesByCategory: build.query<
        IAssetCategory,
        { tenantId: number; assetCategoryId: number }
      >({
        query: ({ tenantId, assetCategoryId }) => {
          return {
            url: `/tenant/${tenantId}/asset-category/${assetCategoryId}/asset-type`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { assetCategoryId }) => [{ type: 'Assets', assetCategoryId }],
      }),
      // useGetAssetClassesByCategoryQuery
      getAssetClassesByCategory: build.query<
        IAssetCategory,
        { tenantId: number; assetCategoryId: number }
      >({
        query: ({ tenantId, assetCategoryId }) => {
          return {
            url: `/tenant/${tenantId}/asset-category/${assetCategoryId}/asset-class`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { assetCategoryId }) => [{ type: 'Assets', assetCategoryId }],
      }),
      // useGetAssetConfigurationsQuery
      getAssetConfigurations: build.query<IAssetCategory[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/asset-configuration`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'AssetConfigurations', id: tenantId },
        ],
      }),
      // useGetAssetConfigurationByIdQuery
      getAssetConfigurationById: build.query<
        IAssetCategory,
        { tenantId: number; assetConfigurationId: number }
      >({
        query: ({ tenantId, assetConfigurationId }) => {
          return {
            url: `/tenant/${tenantId}/asset-configuration/${assetConfigurationId}`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { tenantId }) => [
          { type: 'AssetConfigurations', id: tenantId },
        ],
      }),
      // useCreateAssetConfigurationMutation
      createAssetConfiguration: build.mutation<
        any,
        { tenantId: number; body: IAssetConfigPayload }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset-configuration`,
          method: 'POST',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: (_result, _error, { tenantId }) => {
          return [{ type: 'AssetConfigurations', id: tenantId }]
        },
      }),
      // useDeleteAssetConfigurationMutation
      deleteAssetConfiguration: build.mutation<
        any,
        { tenantId: number; assetConfigurationId: number }
      >({
        query: ({ tenantId, assetConfigurationId }) => ({
          url: `/tenant/${tenantId}/asset-configuration/${assetConfigurationId}`,
          method: 'DELETE',
          domain: 'Asset',
        }),
        invalidatesTags: (_result, _error, { tenantId }) => {
          return [{ type: 'AssetConfigurations', id: tenantId }]
        },
      }),
      // useUpdateAssetConfigurationMutation
      updateAssetConfiguration: build.mutation<
        any,
        { tenantId: number; body: IAssetConfiguration }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset-configuration`,
          method: 'PUT',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: (_result, _error, { tenantId }) => {
          return [{ type: 'AssetConfigurations', id: tenantId }]
        },
      }),
      locationById: build.query<
        ILocationByTenantCustomer,
        { locationId: number; tenantId: number; customerId?: number }
      >({
        query: ({ locationId, tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}location/${locationId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Locations', locationId }],
      }),
      loadsByRouteId: build.query<ITransportRouteLoad[], { routeId: number; tenantId: number }>({
        query: ({ routeId, tenantId }) => {
          return {
            url: `/tenant/${tenantId}/route/${routeId}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Loads', locationId }],
      }),
      getUnassignedRoutes: build.query<IUnassginedRoute[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/route`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [
          { type: 'Loads', locationId },
          { type: 'Loads', id: 'Unassigned Routes' },
        ],
      }),
      getJourneysByTenantId: build.query<IUpdateJourney[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/journey`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Journeys', locationId }],
      }),
      getJourneyByTenantId: build.query<IUpdateJourney, { id: number; tenantId: number }>({
        query: ({ id, tenantId }) => {
          return {
            url: `/tenant/${tenantId}/journey/${id}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: (result, error, locationId) => [{ type: 'Journey', locationId }],
      }),
      createAssetGroup: build.mutation<
        any,
        { tenantId: number; body: IAssetGroupAllocationDetails }
      >({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset-group`,
          method: 'POST',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: [
          { type: 'AssetGroup', id: 'LIST' },
          { type: 'Loads', id: 'Unassigned Routes' },
        ],
      }),
      updateJourney: build.mutation<
        any,
        { tenantId: number; journeyId: number; body: IUpdateJourney }
      >({
        query: ({ tenantId, journeyId, body }) => ({
          url: `/tenant/${tenantId}/journey/${journeyId}`,
          method: 'PUT',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (journeyId) => [{ type: 'Journey', id: journeyId }],
      }),
      approveJourney: build.mutation<any, { tenantId: number; journeyId: number }>({
        query: ({ tenantId, journeyId }) => ({
          url: `/tenant/${tenantId}/journey/${journeyId}/approve`,
          method: 'PUT',
          domain: 'Shipment',
        }),
        invalidatesTags: (journeyId) => [
          { type: 'Journey', id: journeyId },
          { type: 'Transport Jobs', id: 'Transport Jobs' },
        ],
      }),
      getCarriers: build.query<ICarrier[], number>({
        query: (tenantId: number) => ({
          url: `tenant/${tenantId}/carrier`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Carrier', id: 'Carrier' }],
      }),
      getLocations: build.query<
        ILocationByTenantCustomer[],
        { tenantId: number; includeCustomerLocations?: boolean; customerId?: number }
      >({
        query: ({ tenantId, includeCustomerLocations, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          const includeCustomerLocationsQuery = includeCustomerLocations
            ? '?includeCustomerLocations=true'
            : ''
          return {
            url: `/tenant/${tenantId}/${customer}location${includeCustomerLocationsQuery}`,
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: () => [{ type: 'Locations', id: 'Locations' }],
      }),
      // useGetAssetsQuery
      getAssets: build.query<IAssetManagement[], { tenantId: number }>({
        query: ({ tenantId }) => {
          return {
            url: `/tenant/${tenantId}/asset`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
      }),
      // useGetAssetQuery
      getAsset: build.query<IAssetManagement, { tenantId: number; assetId: number }>({
        query: ({ tenantId, assetId }) => {
          return {
            url: `/tenant/${tenantId}/asset/${assetId}`,
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: (result, error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
      }),
      // useUpdateAssetMutation
      updateAsset: build.mutation<any, { tenantId: number; body: IAssetManagement }>({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset`,
          method: 'PUT',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
      }),
      // useCreateAssetMutation
      createAsset: build.mutation<any, { tenantId: number; body: IAssetManagement }>({
        query: ({ tenantId, body }) => ({
          url: `/tenant/${tenantId}/asset`,
          method: 'POST',
          domain: 'Asset',
          body: body,
        }),
        invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
      }),
      // useDeleteAssetMutation
      deleteAsset: build.mutation<any, { tenantId: number; assetId: number }>({
        query: ({ tenantId, assetId }) => ({
          url: `/tenant/${tenantId}/asset/${assetId}`,
          method: 'DELETE',
          domain: 'Asset',
        }),
        invalidatesTags: (_result, _error, { tenantId }) => [{ type: 'Assets', id: tenantId }],
      }),
      // useGetMeasureUnitsQuery
      getMeasureUnits: build.query<IAssetMeasureUnit[], void>({
        query: () => {
          return {
            url: '/get-all-measure-units',
            method: 'GET',
            domain: 'Shipment',
          }
        },
        providesTags: () => [{ type: 'MeasureUnits' }],
      }),
      // useGetBrandQuery
      getBrand: build.query<IAssetBrand[], void>({
        query: () => {
          return {
            url: '/brand',
            method: 'GET',
            domain: 'Asset',
          }
        },
        providesTags: () => [{ type: 'Brands' }],
      }),
      // Get customers by tenantId
      getCustomers: build.query<{ id: number; name: string }[], number>({
        query: (tenantId: number) => ({
          url: `/tenant/${tenantId}/customer`,
          method: 'GET',
          domain: 'User',
        }),
        providesTags: () => [{ type: 'Customers', id: 'Customers' }],
      }),
      setDefaultBranding: build.mutation<IBranding, number>({
        query: (tenantId: number) => ({
          url: `/tenant/${tenantId}/branding/reset`,
          method: 'POST',
          domain: 'User',
        }),
        invalidatesTags: () => [{ type: 'Branding', id: 'Branding' }],
      }),
      deleteLocation: build.mutation<void, { locationId: number; tenantId: number }>({
        query: ({ locationId, tenantId }) => ({
          url: `tenant/${tenantId}/location/${locationId}`,
          method: 'DELETE',
          domain: 'Shipment',
        }),
        invalidatesTags: () => [{ type: 'Locations', id: 'Locations' }],
      }),
      // Products
      getProducts: build.query<IProduct[], number>({
        query: (customerId: number) => ({
          url: `/customer/${customerId}/product`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Products', id: 'Products' }],
      }),
      getProductById: build.query<IProduct, { customerId: number; productId: number }>({
        query: ({ customerId, productId }) => ({
          url: `/customer/${customerId}/product/${productId}`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Products', id: 'Products' }],
      }),
      createProduct: build.mutation<{ id: number }, { customerId: number; body: IProduct }>({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Products', id: 'Products' }],
      }),
      updateProduct: build.mutation<{ id: number }, { customerId: number; body: IProduct }>({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product`,
          method: 'PUT',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Products', id: 'Products' }],
      }),
      getProductCategories: build.query<IProductCategory[], number>({
        query: (customerId: number) => ({
          url: `/customer/${customerId}/product/category`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Categories', id: 'Categories' }],
      }),

      getAllSubHsHeadings: build.query<IHsSubheading[], void>({
        query: () => ({
          url: '/subheading',
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'SubHsHeadings', id: 'SubHsHeadings' }],
      }),
      getProductCategoryById: build.query<
        IProductCategory,
        { customerId: number; categoryId: number }
      >({
        query: ({ customerId, categoryId }) => ({
          url: `/customer/${customerId}/product/category/${categoryId}`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: (result, error, { customerId, categoryId }) => [
          { type: 'Categories', id: categoryId },
        ],
      }),
      getAllHsHeadings: build.query<IHsHeading[], void>({
        query: () => ({
          url: '/hsheading',
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'HsHeadings', id: 'HsHeadings' }],
      }),
      createProductCategory: build.mutation<
        { id: number },
        { customerId: number; body: IProductCategory }
      >({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product/category`,
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [{ type: 'Categories', id: 'Categories' }],
      }),
      updateProductCategory: build.mutation<
        { id: number },
        { customerId: number; body: IProductCategory }
      >({
        query: ({ customerId, body }) => ({
          url: `/customer/${customerId}/product//category`,
          method: 'PUT',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: (result, error, { body }) => [
          { type: 'Categories', id: body.id },
          { type: 'Categories', id: 'Categories' },
        ],
      }),
      createHsLocalTariff: build.mutation<{ id: number }, { body: IHsLocalTariff }>({
        query: ({ body }) => ({
          url: '/product/category/tariffs/local',
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
        invalidatesTags: () => [{ type: 'Tariffs', id: 'LocalTariffs' }],
      }),
      // Job //
      getTransportJobs: build.query<TransportJob[], number>({
        query: (tenantId) => ({
          url: `/tenant/${tenantId}/transport-job`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Transport Jobs', id: 'Transport Jobs' }],
      }),
      // Job //
      // Notes //
      getNotesById: build.query<ShipmentNote[], number>({
        query: (id: number) => ({
          url: `shipment/${id}/note`,
          method: 'GET',
          domain: 'Shipment',
        }),
        providesTags: () => [{ type: 'Shipment Notes', id: 'Shipment Notes' }],
      }),
      addNote: build.mutation<any, { body: ShipmentNote }>({
        query: ({ body }) => ({
          url: 'shipment/note',
          method: 'POST',
          domain: 'Shipment',
          body: body,
        }),
      }),
      // Notes //
    }
  },
})

export const {
  useShipmentByIdQuery,
  useLocationByIdQuery,
  useAssetCategoriesByTransportModeIdQuery,
  useLoadsByRouteIdQuery,
  useGetJourneysByTenantIdQuery,
  useGetJourneyByTenantIdQuery,
  useGetUnassignedRoutesQuery,
  useCreateAssetGroupMutation,
  useUpdateJourneyMutation,
  useTrackingNumberParamsTypesQuery,
  useTrackingNumberTemplateQuery,
  useCreateTrackingNumberTemplateMutation,
  useGetCarriersQuery,
  useGetTransportModeQuery,
  useApproveJourneyMutation,
  useGetLocationsQuery,
  // assets configurations //
  useGetAssetTypesByCategoryQuery,
  useGetAssetClassesByCategoryQuery,
  useGetAssetConfigurationsQuery,
  useGetAssetConfigurationByIdQuery,
  useCreateAssetConfigurationMutation,
  useDeleteAssetConfigurationMutation,
  useUpdateAssetConfigurationMutation,
  // assets configurations //
  // assets //
  useGetAssetsQuery,
  useGetAssetQuery,
  useGetBrandQuery,
  useGetMeasureUnitsQuery,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  // Users //
  useGetCustomersQuery,
  useSetDefaultBrandingMutation,
  // Locations //
  useDeleteLocationMutation,
  // Products //
  useGetProductsQuery,
  useGetProductByIdQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductCategoriesQuery,
  useGetAllHsHeadingsQuery,
  useGetAllSubHsHeadingsQuery,
  useGetProductCategoryByIdQuery,
  useCreateProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useCreateHsLocalTariffMutation,
  // Products //
  // Transport Jobs //
  useGetTransportJobsQuery,
  // Transport Jobs //
  // Notes //
  useGetNotesByIdQuery,
  useAddNoteMutation,
  // Notes //
  endpoints: apiEndpoints,
} = apiSlice
