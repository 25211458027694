import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Typography,
} from '@mui/material'
import { FormikProps } from 'formik'
import { useRef, useState } from 'react'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { IJourneyRoute, ITransportJourney } from '../../types'
import RouteLocation from './RouteLocation'
import { RouteAccordion } from './style'
export interface IRouteProps {
  formik: FormikProps<ITransportJourney>
  route: IJourneyRoute
  rIdx: number
  legIdx: number
  locations: ILocationByTenantCustomer[]
}

const Route = ({ formik, route, rIdx, legIdx, locations }: IRouteProps) => {
  const [useAddLoad, setUseAddLoad] = useState(true)
  const [isExpanded, setIsExpanded] = useState(false)
  const loadRefs = useRef<React.RefObject<HTMLDivElement>[]>([])

  const getLoadsForAdd = () => {
    const usedLoadIds: number[] = []
    formik.values.legs[legIdx].routes.forEach((r) => {
      r.loads.forEach((rl) => {
        usedLoadIds.push(rl.id)
      })
    })
    return formik.values.loads
      .filter((ad) => ad.isSelected && !usedLoadIds.some((ulId) => ulId === ad.id))
      .map((l) => {
        return {
          id: l.id,
          name: l.description ?? '',
        }
      })
  }
  const getRouteTitle = () => {
    if (route.locations.length === 1) {
      const startLocation = route.locations[0].name
      return `Route #${rIdx + 1} - ${startLocation}`
    }
    if (route.locations.length === 2) {
      const startLocation = route.locations[0].name
      const endLocation = route.locations[1].name
      return `Route #${rIdx + 1} - ${startLocation} > ${endLocation}`
    }
    if (route.locations.length > 2) {
      const startLocation = route.locations[0].name
      const endLocation = route.locations[route.locations.length - 1].name
      return `Route #${rIdx + 1} - ${startLocation} > ${endLocation} (${
        route.locations.length - 2
      } stops)`
    }
    return `Route #${rIdx + 1}`
  }

  const getLocations = () => {
    const usedLocationIds = route.locations.map((rl) => rl.locationId)
    return locations.filter((loc) => !usedLocationIds.some((uloc) => uloc === loc.id))
  }

  const handleAddLocations = (rIdx: number) => {
    const sequence = formik.values.legs[legIdx].routes[rIdx].locations.length + 1
    const currLocations = getLocations()
    const newLocation = {
      name: currLocations[0].name,
      loadIds: [],
      sequence: sequence,
    }
    formik.setFieldValue(`legs.${legIdx}.routes.${rIdx}.locations`, [
      ...formik.values.legs[legIdx].routes[rIdx].locations,
      newLocation,
    ])
  }
  const handleAddLoad = (e: any, name: string, newValue: any) => {
    e.preventDefault()
    e.stopPropagation()
    const load = formik.values.loads.find((l) => l.id === newValue)
    formik.setFieldValue(`legs.${legIdx}.routes.${rIdx}.loads`, [
      ...route.loads,
      {
        id: load?.id,
        name: load?.description,
        pickupLocationId: load?.pickupLocationId,
        consigneeId: load?.consigneeId,
        isDefined: load?.isDefined,
      },
    ])
    setUseAddLoad(true)
  }

  const handleRemoveRoute = (routeIdx: number) => {
    const routes = formik.values.legs[legIdx].routes
    const loadIdx: number[] = []
    formik.values.loads.forEach((l, lIdx) => {
      if (l.routeId === formik.values.legs[legIdx].routes[routeIdx].id) {
        loadIdx.push(lIdx)
      }
    })
    loadIdx.forEach((lIdx) => {
      const load = formik.values.loads[lIdx]
      load.routeId = undefined
      formik.setFieldValue(`loads.${lIdx}`, load)
    })
    routes.splice(routeIdx, 1)
    formik.setFieldValue(`legs.${legIdx}.routes`, routes)
  }
  const getAccordionDetails = () => {
    if (!isExpanded) {
      return <></>
    } else {
      if (useAddLoad) {
        return (
          <Button
            color='primary'
            disabled={formik.isSubmitting}
            variant='contained'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setUseAddLoad(false)
            }}
          >
            Add load
          </Button>
        )
      } else {
        return (
          <FormDropdown
            sx={{ backgroundColor: 'white', width: '200px' }}
            id={`routes.${rIdx}`}
            label={'Add load'}
            onChange={handleAddLoad}
            handleOnBlur={(e: any) => {
              formik.handleBlur(e)
              setUseAddLoad(true)
            }}
            value={-1}
            noOptionsText={'No unallocated loads'}
            items={getLoadsForAdd()}
            size='small'
          />
        )
      }
    }
  }
  return (
    <>
      <RouteAccordion
        sx={{
          backgroundColor: 'rgba(0,160,228,0.1)',
          '& .Mui-expanded': {
            marginBottom: '0px',
          },
        }}
        expanded={isExpanded}
        onChange={(e: any, expanded: boolean) => {
          setIsExpanded(expanded)
        }}
      >
        <AccordionSummary>
          <Grid container>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-start' alignItems={'center'}>
                <Typography sx={{ pr: '16px' }}>{getRouteTitle()}</Typography>
                {getAccordionDetails()}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='flex-end'>
                <Fab
                  aria-label='remove'
                  color='info'
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleRemoveRoute(rIdx)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <DeleteIcon />
                </Fab>
              </Box>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Container
            disableGutters={true}
            maxWidth={false}
            sx={{
              display: 'flex',
              overflow: 'auto',
              columnGap: '128px',
              padding: '16px 0px 16px 0px',
            }}
          >
            {route.locations.map((l, lIdx) => {
              return (
                <>
                  <RouteLocation
                    key={`{RouteLocation${rIdx}${lIdx}`}
                    formik={formik}
                    location={l}
                    locationIdx={lIdx}
                    route={route}
                    routeIdx={rIdx}
                    legIdx={legIdx}
                    loadRefs={loadRefs}
                    locations={locations}
                  />
                  <Box
                    key={`pbox${lIdx}`}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginRight: '-124px',
                      marginLeft: '-124px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      maxHeight: '400px',
                    }}
                  >
                    <Box key={`Box${rIdx}${lIdx}`} sx={{ width: '30px', height: '20px' }}>
                      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 350 100'>
                        <defs>
                          <marker
                            id='arrowhead'
                            markerWidth='10'
                            markerHeight='7'
                            refX='0'
                            refY='3.5'
                            orient='auto'
                          >
                            <polygon points='0 0, 10 3.5, 0 7' />
                          </marker>
                        </defs>
                        <line
                          x1='0'
                          y1='50'
                          x2='200'
                          y2='50'
                          color='rgb(33, 58, 124)'
                          stroke='#000'
                          strokeWidth='16'
                          markerEnd='url(#arrowhead)'
                        />
                      </svg>
                    </Box>
                  </Box>
                </>
              )
            })}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                minWidth: `${route.locations.length === 0 ? '32px' : '32px'}`,
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: `${route.locations.length === 0 ? '64px' : '400px'}`,
              }}
            >
              <Box sx={{ width: '32px', height: '32px' }}>
                <Fab
                  aria-label='add'
                  color='primary'
                  onClick={() => {
                    handleAddLocations(rIdx)
                  }}
                  size='small'
                  sx={{ margin: '0 2px' }}
                >
                  <AddIcon />
                </Fab>
              </Box>
            </Box>
          </Container>
        </AccordionDetails>
      </RouteAccordion>
    </>
  )
}

export default Route
