import { AppBar, Box, Button } from '@mui/material'

interface IControlBar {
  formik: any
  isFormDirty: () => boolean
  onSubmit: (event: any) => void
}

const ControlBar = ({ formik, isFormDirty, onSubmit }: IControlBar) => {
  return (
    <AppBar
      position='sticky'
      sx={{
        top: 130,
        backgroundColor: '#fff',
        boxShadow: 'none',
      }}
      className='assets-tab-controls-appbar'
    >
      <Box sx={{ padding: '10px', marginBottom: '20px' }}>
        <Button
          disabled={formik.values.formIsDirty !== true && isFormDirty() !== true}
          sx={{ margin: '20px 0' }}
          onClick={onSubmit}
          variant='contained'
        >
          {'Save settings'}
        </Button>
      </Box>
    </AppBar>
  )
}

export default ControlBar
