import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  title: {
    id: 'assetsManagement.title',
    description: 'Assets Management title',
    defaultMessage: 'Assets',
  },
  cancelAssetBtn: {
    id: 'assetsManagement.cancelAssetBtn',
    description: 'cancel asset button',
    defaultMessage: 'Cancel',
  },
  deleteAssetBtn: {
    id: 'assetsManagement.deleteAssetBtn',
    description: 'Delete asset button',
    defaultMessage: 'Delete',
  },
  createAssetBtn: {
    id: 'assetsManagement.createAssetBtn',
    description: 'Create asset button',
    defaultMessage: 'Create Assest',
  },
  saveAssetBtn: {
    id: 'assetsManagement.saveAssetBtn',
    description: 'Save asset button',
    defaultMessage: 'Save',
  },
  addModalTitle: {
    id: 'assetsManagement.addModalTitle',
    description: 'Add Asset',
    defaultMessage: 'Add Asset',
  },
  deleteModalTitle: {
    id: 'assetsManagement.deleteModalTitle',
    description: 'Delete Asset',
    defaultMessage: 'Delete Asset',
  },
  editModalTitle: {
    id: 'assetsManagement.editModalTitle',
    description: 'Edit Asset',
    defaultMessage: 'Edit Asset',
  },
  deleteSuccessful: {
    id: 'assetsManagement.deleteSuccessful',
    description: 'Asset deleted successfully',
    defaultMessage: 'Asset deleted successfully',
  },
  deleteFailed: {
    id: 'assetsManagement.deleteFailed',
    description: 'Asset delete failed',
    defaultMessage: 'Asset delete failed',
  },
  updateFailed: {
    id: 'assetsManagement.updateFailed',
    description: 'Asset update failed',
    defaultMessage: 'Asset update failed',
  },
  updateSuccessful: {
    id: 'assetsManagement.updateSuccessful',
    description: 'Asset update successfully',
    defaultMessage: 'Asset update successfully',
  },
  createFailed: {
    id: 'assetsManagement.createFailed',
    description: 'Asset creation failed',
    defaultMessage: 'Asset creation failed',
  },
  createSuccessful: {
    id: 'assetsManagement.createSuccessful',
    description: 'Asset created successfully',
    defaultMessage: 'Asset created successfully',
  },
  transportMode: {
    id: 'assetsManagement.transportMode',
    description: 'Assets transport mode dropdown title',
    defaultMessage: 'Transport mode',
  },
  assetsCategory: {
    id: 'assetsManagement.assetsCategories',
    description: 'Asset categories dropdown title',
    defaultMessage: 'Asset Category',
  },
  assetsClasses: {
    id: 'assetsManagement.assetsClasses',
    description: 'Asset class dropdown title',
    defaultMessage: 'Asset Class',
  },
  assetsTypes: {
    id: 'assetsManagement.assetsTypes',
    description: 'Assets type dropdown title',
    defaultMessage: 'Asset Type',
  },
  assetsDetails: {
    id: 'assetsManagement.assetsDetails',
    description: 'Details',
    defaultMessage: 'Details',
  },
  assetsDetailsBrand: {
    id: 'assetsManagement.assetsDetails.brand',
    description: 'Brand',
    defaultMessage: 'Brand',
  },
  assetsDetailsModel: {
    id: 'assetsManagement.assetsDetails.model',
    description: 'Model',
    defaultMessage: 'Model',
  },
  assetsDetailsNumberOfDoors: {
    id: 'assetsManagement.assetsDetails.numberOfDoors',
    description: 'Number of Doors',
    defaultMessage: 'Number of Doors',
  },
  assetsDetailsRegistrationNumber: {
    id: 'assetsManagement.assetsDetails.registrationNumber',
    description: 'Registration Number',
    defaultMessage: 'Registration Number',
  },
  assetsDetailsIdentification: {
    id: 'assetsManagement.assetsDetails.identification',
    description: 'Identification',
    defaultMessage: 'Fleet Number',
  },
  assetsDetailsChassisNumber: {
    id: 'assetsManagement.assetsDetails.chassisNumber',
    description: 'Chassis Number',
    defaultMessage: 'Chassis Number',
  },
  assetsDetailsIsOwned: {
    id: 'assetsManagement.assetsDetails.isOwned',
    description: 'Is Owned',
    defaultMessage: 'Is Owned',
  },
  assetsDetailsProductionYear: {
    id: 'assetsManagement.assetsDetails.productionYear',
    description: 'Production Year',
    defaultMessage: 'Production Year',
  },
  assetsDetailsRegistrationDate: {
    id: 'assetsManagement.assetsDetails.registrationDate',
    description: 'Registration Date',
    defaultMessage: 'Registration Date',
  },
  assetsDetailsRegistrationExpiry: {
    id: 'assetsManagement.assetsDetails.registrationExpiry',
    description: 'Registration Expiry',
    defaultMessage: 'Registration Expiry',
  },
  assetsDetailsInsuranceExpiry: {
    id: 'assetsManagement.assetsDetails.insuranceExpiry',
    description: 'Insurance Expiry',
    defaultMessage: 'Insurance Expiry',
  },
  assetsDetailsOperationCardExpiry: {
    id: 'assetsManagement.assetsDetails.operationCardExpiry',
    description: 'Operation Card Expiry',
    defaultMessage: 'Operation Card Expiry',
  },
  assetsDetailsMvpiExpiry: {
    id: 'assetsManagement.assetsDetails.mvpiExpiry',
    description: 'Mvpi Expiry',
    defaultMessage: 'Mvpi Expiry',
  },
  assetsDetailsMaintenanceEndDate: {
    id: 'assetsManagement.assetsDetails.maintenanceEndDate',
    description: 'Maintenance End Date',
    defaultMessage: 'Maintenance End Date',
  },
  assetsPhysicalProperties: {
    id: 'assetsManagement.assetsPhysicalProperties',
    description: 'Physical properties',
    defaultMessage: 'Physical properties',
  },
  assetsPortPass: {
    id: 'assetsManagement.assetsPortPass',
    description: 'Port Pass',
    defaultMessage: 'Port Pass',
  },
  assetsPortName: {
    id: 'assetsManagement.assetsPortName',
    description: 'Port name',
    defaultMessage: 'Port name',
  },
  assetsPortPassIssueDate: {
    id: 'assetsManagement.assetsPortPassIssueDate',
    description: 'Issue Date',
    defaultMessage: 'Issue Date',
  },
  assetsPortPassExpiryDate: {
    id: 'assetsManagement.assetsPortPassExpiryDate',
    description: 'Expiry Date',
    defaultMessage: 'Expiry Date',
  },
  assetsClearLoadingHeight: {
    id: 'assetsManagement.assetsClearLoadingHeight',
    description: 'Clear Loading Height',
    defaultMessage: 'Clear Loading Height',
  },
  assetsGrossWeight: {
    id: 'assetsManagement.assetsGrossWeight',
    description: 'Gross Weight',
    defaultMessage: 'Gross Weight',
  },
  assetsLengthMeasureUnitId: {
    id: 'assetsManagement.assetsLengthMeasureUnitId',
    description: 'Length Measure Unit Id',
    defaultMessage: 'Length Measure Unit Id',
  },
  assetsInternalVolume: {
    id: 'assetsManagement.assetsInternalVolume',
    description: 'Internal Volume',
    defaultMessage: 'Internal Volume',
  },
  assetsInternalHeight: {
    id: 'assetsManagement.assetsInternalHeight',
    description: 'Internal Height',
    defaultMessage: 'Internal Height',
  },
  assetsInternalLength: {
    id: 'assetsManagement.assetsInternalLength',
    description: 'Internal Length',
    defaultMessage: 'Internal Length',
  },
  assetsInternalWidth: {
    id: 'assetsManagement.assetsInternalWidth',
    description: 'Internal Width',
    defaultMessage: 'Internal Width',
  },
  assetsKerbWieght: {
    id: 'assetsManagement.assetsKerbWieght',
    description: 'Kerb Weight',
    defaultMessage: 'Kerb Weight',
  },
  assetsPermissibleLoad: {
    id: 'assetsManagement.assetsPermissibleLoad',
    description: 'Permissible Load',
    defaultMessage: 'Permissible Load',
  },
  assetsRadius: {
    id: 'assetsManagement.assetsRadius',
    description: 'Radius',
    defaultMessage: 'Radius',
  },
  assetsWeightMeasureUnitId: {
    id: 'assetsManagement.assetsWeightMeasureUnitId',
    description: 'Weight Measure Unit Id',
    defaultMessage: 'Weight Measure Unit Id',
  },
  assetsVolumeMeasureUnitId: {
    id: 'assetsManagement.assetsVolumeMeasureUnitId',
    description: 'Volume Measure Unit Id',
    defaultMessage: 'Volume Measure Unit Id',
  },
  unitOfDensity: {
    id: 'createShipment.unitOfDensity',
    description: 'Unit of Density label',
    defaultMessage: 'Unit of Density',
  },
  unitOfHeight: {
    id: 'createShipment.unitOfHeight',
    description: 'Unit of Height label',
    defaultMessage: 'Unit of Height',
  },
  unitOfLength: {
    id: 'createShipment.unitOfLength',
    description: 'Unit of Length label',
    defaultMessage: 'Unit of Length',
  },
  unitOfVolume: {
    id: 'createShipment.unitOfVolume',
    description: 'Unit of Volume label',
    defaultMessage: 'Unit of Volume',
  },
  unitOfWeight: {
    id: 'createShipment.unitOfWeight',
    description: 'Unit of Weight label',
    defaultMessage: 'Unit of Weight',
  },
  confirmDialogDiscardSelection: {
    id: 'tenantSettings.assetCategory.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'tenantSettings.assetCategory.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogTitle: {
    id: 'tenantSettings.assetCategory.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogMessageContent: {
    id: 'tenantSettings.assetCategory.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
  // end
})
