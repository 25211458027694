import CloseIcon from '@mui/icons-material/Close';
import { Divider, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { Form, Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import { MRT_Row as MRTRow } from 'material-react-table';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNotificationStack } from '../../../app/hooks/useNotificationStack';
import { useCreateAssetMutation, useGetAssetQuery, useUpdateAssetMutation } from '../../../app/redux-fetch/apiQuery';
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog';
import LinearIndeterminate from '../../../components/Progress/LinearIndeterminate';
import { IAssetManagement } from '../../types';
import { PhysicalPropertiesGrid } from '../PhysicalProperties/PhysicalProperties';
import { PortPasses } from '../PortPasses/PortPasses';
import { AssetAttributeGroup } from '../groups/AssetAttributeGroup';
import { ButtonGroup } from '../groups/ButtonGroup';
import { DetailDates } from '../groups/DetailDates';
import { messages } from '../messages';
import { assetManagementCreateSchema, assetManagementUpdateSchema } from '../schemas/assetManagementSchema';

interface AssetConfigurationModalProps {
    onClose: () => void
    isOpen: boolean;
    tenantId: number;
    row?: MRTRow<Partial<IAssetManagement>>;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getData = (data: IAssetManagement | undefined, tenantId: number): IAssetManagement => {
    if (data) {
        return data;
    }

    return {
        tenantId,
        transportModeId: 0,
        assetCategoryId: 0,
        detail: {
            isOwned: false
        },
        physicalProperties: {},
    }
}

export function AssetManagementModal({
    onClose,
    isOpen,
    tenantId,
    row,
}: AssetConfigurationModalProps) {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { enqueueFailure, enqueueSuccess } = useNotificationStack();
    const { data, isFetching: isFetchingAsset } = useGetAssetQuery({ tenantId, assetId: Number(row?.id) })
    const asset = getData(data, tenantId);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [transportModeId, setTransportModeId] = useState(asset.transportModeId);
    const [confirmTransportModeId, setConfirmTransportModeId] = useState(asset.transportModeId);
    const isEdit = Boolean(row);
    const schema = isEdit ? assetManagementUpdateSchema() : assetManagementCreateSchema();

    const [
        createAssetConfiguration,
        {
            isLoading: createIsLoading,
            isSuccess: createIsSuccess,
            data: createData,
            isError: createIsError
        }
    ] = useCreateAssetMutation();

    useEffect(() => {
        if (createData) {
            if (createIsSuccess) {
                enqueueSuccess(messages.createSuccessful);
                onClose();
            } else if (createIsError) {
                enqueueFailure(messages.createFailed);
            }
        }
    }, [createIsSuccess, createData]);

    const [
        updateAssetConfiguration,
        {
            isLoading: updateIsLoading,
            isSuccess: updateIsSuccess,
            isUninitialized: updateIsUninitialized,
            isError: updateIsError,
        }
    ] = useUpdateAssetMutation();


    useEffect(() => {
        if (!updateIsUninitialized && updateIsSuccess) {
            if (updateIsSuccess) {
                enqueueSuccess(messages.updateSuccessful);
                onClose();
            } else if (updateIsError) {
                enqueueFailure(messages.updateFailed);
            }
        }
    }, [updateIsSuccess, onClose]);

    const isLoading = createIsLoading || updateIsLoading || isFetchingAsset;

    const handleContinueEdit = () => {
        setOpenConfirmDialog(false);
        setConfirmTransportModeId(transportModeId);
    }

    const handleDiscardChanges = () => {
        setOpenConfirmDialog(false);
        setTransportModeId(confirmTransportModeId);
    }

    const onCreate = (values: IAssetManagement) => {
        createAssetConfiguration({
            tenantId,
            body: {
                ...values,
                tenantId,
            }
        });
    }

    const onUpdate = (values: IAssetManagement) => {
        updateAssetConfiguration({
            tenantId,
            body: {
                ...values,
                tenantId,
            }
        });
    }

    return (
        <div>
            <ConfirmDialog
                open={openConfirmDialog}
                title={formatMessage(messages.confirmDialogTitle)}
                content={formatMessage(messages.confirmDialogMessageContent)}
                continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
                discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
                onContinueEdit={handleContinueEdit}
                onDiscardChanges={handleDiscardChanges}
            />
            <Dialog
                fullScreen
                open={isOpen}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                {isLoading && <LinearIndeterminate />}
                <Formik
                    initialValues={{ ...asset }}
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={() => {
                        // to do
                    }}
                >
                    <Form>
                        <AppBar sx={{ position: 'relative', background: theme.palette.secondary.main }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={onClose}
                                    aria-label="close"
                                    disabled={isLoading}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    {formatMessage(isEdit ? messages.editModalTitle : messages.addModalTitle)}
                                </Typography>
                                <ButtonGroup
                                    disabled={isLoading}
                                    schema={schema}
                                    onClose={onClose}
                                    onSave={isEdit ? onUpdate : onCreate} />
                            </Toolbar>
                        </AppBar>
                        <AssetAttributeGroup disabled={isLoading} />
                        <Divider />
                        <DetailDates disabled={isLoading} />
                        <Divider />
                        <PhysicalPropertiesGrid disabled={isLoading} />
                        <Divider />
                        <PortPasses disabled={isLoading} />
                    </Form>
                </Formik>
            </Dialog>
        </div >
    );
}
