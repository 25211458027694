import { createSelector } from '@reduxjs/toolkit'
import { COMMON_SETTINGS_REDUCER } from '../../../consts'
import { COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME } from './consts'
import { IExtendedRole, IRole } from './types'
import { compareRole, isPureUserDefined } from './utils'
import { SubType } from '../../../../../types/common'
import { makeTypedFetchSelectors } from '../../../../../app/redux-fetch'
import { getSubType } from '../../../../utils'

export const fetchAllCommonSettingsRolesSelector = makeTypedFetchSelectors<IRole[]>(
  COMMON_SETTINGS_REDUCER,
  COMMON_SETTINGS_ALL_ROLES_REDUCER_NAME
)

export const fetchAllCommonSettingsRolesSortedRolesSelector = createSelector(
  fetchAllCommonSettingsRolesSelector.data,
  (roles: IRole[] | null) => {
    if (!roles) return []
    const allRoles = roles.map((role) => ({
      ...role,
      entity: 'role',
      dirty: false,
      subType: getSubType(role.customerId),
      isCloneOfSysmtemDefined: false,
      isPureUserDefined: false,
      canDelete: false,
      httpAction: undefined,
    }))

    // Only pure user defined currencies can be deleted
    allRoles.forEach((c) => {
      if (c.subType !== SubType.UserDefined) return
      c.canDelete = true
    })

    type SortedUserGrups = typeof allRoles

    const duplicateSystemDefinedRoles = allRoles
      .map((c) => c)
      .reduce((acc, curr, index, array) => {
        const found = array.filter((a) => compareRole(a, curr))
        if (found && found.length > 1) {
          const systemDefined = found.find((f) => f.subType === SubType.SystemDefined)
          if (systemDefined) {
            const exists = acc.find((a) => compareRole(a, systemDefined))
            if (exists) return acc
            acc.push(systemDefined)
            return acc
          }
        }
        return acc
      }, [] as SortedUserGrups)

    // Only pure user defined currencies can be deleted
    allRoles.forEach((c) => {
      if (c.subType === SubType.SystemDefined) return
      const exists = duplicateSystemDefinedRoles.find((a) => compareRole(a, c))
      if (exists) {
        c.isCloneOfSysmtemDefined = true
        c.canDelete = false
      }
    })

    const rolesSortedByName = allRoles
      .filter((c) => !duplicateSystemDefinedRoles.includes(c))
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    const pureUserDefinedRoles = rolesSortedByName
      .filter((user) => isPureUserDefined(user as IExtendedRole))
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    pureUserDefinedRoles.forEach((c) => (c.isPureUserDefined = true))

    const allOtherRoles = rolesSortedByName.filter(
      (user) => !isPureUserDefined(user as IExtendedRole)
    )

    const rolesSortedByPureUserDefined = pureUserDefinedRoles.concat(allOtherRoles)

    return rolesSortedByPureUserDefined as IRole[]
  }
)
