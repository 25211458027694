import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'tenantSettings.title',
    description: 'Tenant Settings title',
    defaultMessage: 'Tenant Settings',
  },
  brandingSettingsTab: {
    id: 'tenantSettings.tabs.brandingSettings',
    description: 'Branding tab',
    defaultMessage: 'Branding',
  },
  assetsSettingTab: {
    id: 'tenantSettings.tabs.assetSettings',
    description: 'Assets configuration tab',
    defaultMessage: 'Asset settings',
  },
  assetsCategories: {
    id: 'tenantSettings.asset.categories',
    description: 'Assets categories title',
    defaultMessage: 'Asset Categories',
  },
  assetsClasses: {
    id: 'tenantSettings.asset.classes',
    description: 'Assets classes title',
    defaultMessage: 'Asset Classes',
  },
  assetsTypes: {
    id: 'tenantSettings.asset.types',
    description: 'Assets types title',
    defaultMessage: 'Asset Types',
  },
  assetsDataGridNameHeader: {
    id: 'tenantSettings.asset.nameHeader',
    description: 'Assets data grid column name header',
    defaultMessage: 'Name',
  },
  assetsDataGridEnabledHeader: {
    id: 'tenantSettings.asset.enabledHeader',
    description: 'Assets data grid clolumn enabled header',
    defaultMessage: 'Enabled',
  },
  assetsDataGridCustomerSelectableHeader: {
    id: 'tenantSettings.asset.customerSelectableHeader',
    description: 'Assets data grid clolumn customer selectable header',
    defaultMessage: 'Customer selectable',
  },
  assetsDataGridDeleteHeader: {
    id: 'tenantSettings.asset.deleteHeader',
    description: 'Assets data grid clolumn delete header',
    defaultMessage: 'Delete',
  },
  assetsSaveSettings: {
    id: 'tenantSettings.asset.saveSettings',
    description: 'Assets save settings button',
    defaultMessage: 'Save settings',
  },
  colorPrimary: {
    id: 'tenantSettings.colorPrimary',
    description: 'Primary Color',
    defaultMessage: 'Primary Palette Color',
  },
  colorSecondary: {
    id: 'tenantSettings.colorSecondary',
    description: 'Secondary Color',
    defaultMessage: 'Secondary Palette Color',
  },
  colorContrastText: {
    id: 'tenantSettings.colorContrastText',
    description: 'Contrast Text Color',
    defaultMessage: 'Contrast Text Color',
  },
  colorText: {
    id: 'tenantSettings.colorText',
    description: 'Text Color',
    defaultMessage: 'Text Color',
  },
  saveBranding: {
    id: 'tenantSettings.saveBranding',
    description: 'Save Branding',
    defaultMessage: 'Save',
  },
  categoryCreatedSuccessfully: {
    id: 'tenantSettings.categoryCreatedSuccessfully',
    description: 'Category created successfully',
    defaultMessage: 'Asset Category created successfully',
  },
  categoryUpdatedSuccessfully: {
    id: 'tenantSettings.categoryUpdatedSuccessfully',
    description: 'Category updated successfully',
    defaultMessage: 'Asset Category updated successfully',
  },
  categoryDeletedSuccessfully: {
    id: 'tenantSettings.categoryDeletedSuccessfully',
    description: 'Category deleted successfully',
    defaultMessage: 'Asset Category deleted successfully',
  },

  categoryEnabledSuccessfully: {
    id: 'tenantSettings.categoryEnabledSuccessfully',
    description: 'Category enabled successfully',
    defaultMessage: 'Asset Category enabled successfully',
  },
  categoryDisabledSuccessfully: {
    id: 'tenantSettings.categoryDisabledSuccessfully',
    description: 'Category disabled successfully',
    defaultMessage: 'Asset Category disabled successfully',
  },
  categoryEnabledFailed: {
    id: 'tenantSettings.categoryEnabledFailed',
    description: 'Category enabled Failed',
    defaultMessage: 'Failed to enable Asset Category',
  },
  categoryDisabledFailed: {
    id: 'tenantSettings.categoryDisabledFailed',
    description: 'Category disabled Failed',
    defaultMessage: 'Failed to disable Asset Category',
  },
  classCreatedSuccessfully: {
    id: 'tenantSettings.classCreatedSuccessfully',
    description: 'Class created successfully',
    defaultMessage: 'Asset Class created successfully',
  },
  classEnabledSuccessfully: {
    id: 'tenantSettings.classEnabledSuccessfully',
    description: 'Class enabled successfully',
    defaultMessage: 'Asset Class enabled successfully',
  },
  classDisabledSuccessfully: {
    id: 'tenantSettings.classDisabledSuccessfully',
    description: 'Class disabled successfully',
    defaultMessage: 'Asset Class disabled successfully',
  },
  classEnabledFailed: {
    id: 'tenantSettings.classEnabledFailed',
    description: 'Class enabled failed',
    defaultMessage: 'Failed to enable Asset Class',
  },
  classDisabledFailed: {
    id: 'tenantSettings.classDisabledFailed',
    description: 'Class disabled failed',
    defaultMessage: 'Failed to disable Asset Class',
  },
  classUpdatedSuccessfully: {
    id: 'tenantSettings.classUpdatedSuccessfully',
    description: 'Class updated successfully',
    defaultMessage: 'Asset Class updated successfully',
  },
  classDeletedSuccessfully: {
    id: 'tenantSettings.classDeletedSuccessfully',
    description: 'Class deleted successfully',
    defaultMessage: 'Asset Class deleted successfully',
  },
  typeCreatedSuccessfully: {
    id: 'tenantSettings.typeCreatedSuccessfully',
    description: 'Type created successfully',
    defaultMessage: 'Asset Type created successfully',
  },
  typeEnabledSuccessfully: {
    id: 'tenantSettings.typeEnabledSuccessfully',
    description: 'Type enabled successfully',
    defaultMessage: 'Asset Type enabled successfully',
  },
  typeDisabledSuccessfully: {
    id: 'tenantSettings.typeDisabledSuccessfully',
    description: 'Type disabled successfully',
    defaultMessage: 'Asset Type disabled successfully',
  },
  typeEnabledFailed: {
    id: 'tenantSettings.typeEnabledFailed',
    description: 'Type enabled failed',
    defaultMessage: 'Failed to enable Asset Type',
  },
  typeDisabledFailed: {
    id: 'tenantSettings.typeDisabledFailed',
    description: 'Type disabled Failed',
    defaultMessage: 'Failed to disable Asset Type',
  },
  typeUpdatedSuccessfully: {
    id: 'tenantSettings.typeUpdatedSuccessfully',
    description: 'Type updated successfully',
    defaultMessage: 'Asset Type updated successfully',
  },
  typeDeletedSuccessfully: {
    id: 'tenantSettings.typeDeletedSuccessfully',
    description: 'Type deleted successfully',
    defaultMessage: 'Asset Type deleted successfully',
  },
  categoryCreationFailed: {
    id: 'tenantSettings.categoryCreationFailed',
    description: 'Category creation failed',
    defaultMessage: 'Asset Category creation failed',
  },
  categoryUpdateFailed: {
    id: 'tenantSettings.categoryUpdateFailed',
    description: 'Category update failed',
    defaultMessage: 'Asset Category update failed',
  },
  categoryDeletionFailed: {
    id: 'tenantSettings.categoryDeletionFailed',
    description: 'Category delete failed',
    defaultMessage: 'Asset Category delete failed',
  },
  classCreationFailed: {
    id: 'tenantSettings.classCreationFailed',
    description: 'Class creation failed',
    defaultMessage: 'Asset Class creation failed',
  },
  classUpdateFailed: {
    id: 'tenantSettings.classUpdateFailed',
    description: 'Class update failed',
    defaultMessage: 'Asset Class update failed',
  },
  classDeletionFailed: {
    id: 'tenantSettings.classDeletionFailed',
    description: 'Class delete failed',
    defaultMessage: 'Asset Class delete failed',
  },
  typeCreationFailed: {
    id: 'tenantSettings.typeCreationFailed',
    description: 'Type creation failed',
    defaultMessage: 'Asset Type creation failed',
  },
  typeUpdateFailed: {
    id: 'tenantSettings.typeUpdateFailed',
    description: 'Type update failed',
    defaultMessage: 'Asset Type update failed',
  },
  typeDeletionFailed: {
    id: 'tenantSettings.typeDeletionFailed',
    description: 'Type delete failed',
    defaultMessage: 'Asset Type delete failed',
  },
  assetConfigurations: {
    id: 'tenantSettings.assetConfigurationsTab',
    description: 'Asset configurations',
    defaultMessage: 'Asset configurations',
  },
  assetConfigurationTitle: {
    id: 'tenantSettings.assetConfigurationTitle',
    description: 'Configurations',
    defaultMessage: 'Configurations',
  },
  assetConfigurationAddModalTitle: {
    id: 'tenantSettings.assetConfigurationAddModalTitle',
    description: 'Add configuration',
    defaultMessage: 'Add configuration',
  },
  assetConfigurationDeleteModalTitle: {
    id: 'tenantSettings.assetConfigurationDeleteModalTitle',
    description: 'Delete configuration',
    defaultMessage: 'Delete configuration',
  },
  assetConfigurationEditModalTitle: {
    id: 'tenantSettings.assetConfigurationEditModalTitle',
    description: 'Edit configuration',
    defaultMessage: 'Edit configuration',
  },
  confirmDialogDiscardSelection: {
    id: 'tenantSettings.assetCategory.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'tenantSettings.assetCategory.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogTitle: {
    id: 'tenantSettings.assetCategory.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogMessageContent: {
    id: 'tenantSettings.assetCategory.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
  assetConfigurationDeleteSuccessful: {
    id: 'tenantSettings.assetConfigurationDeleteSuccessful',
    description: 'Asset configuration deleted successfully',
    defaultMessage: 'Asset configuration deleted successfully',
  },
  assetConfigurationDeleteFailed: {
    id: 'tenantSettings.assetConfigurationDeleteFailed',
    description: 'Asset configuration delete failed',
    defaultMessage: 'Asset configuration delete failed',
  },
  assetConfigurationUpdateFailed: {
    id: 'tenantSettings.assetConfigurationUpdateFailed',
    description: 'Asset configuration update failed',
    defaultMessage: 'Asset configuration update failed',
  },
  assetConfigurationUpdateSuccessful: {
    id: 'tenantSettings.assetConfigurationUpdateSuccessful',
    description: 'Asset configuration update successfully',
    defaultMessage: 'Asset configuration update successfully',
  },
  assetConfigurationCreateFailed: {
    id: 'tenantSettings.assetConfigurationCreateFailed',
    description: 'Asset configuration creation failed',
    defaultMessage: 'Asset configuration creation failed',
  },
  assetConfigurationCreateSuccessful: {
    id: 'tenantSettings.assetConfigurationCreateSuccessful',
    description: 'Asset configuration created successfully',
    defaultMessage: 'Asset configuration created successfully',
  },
})

export default messages
