export interface IDropDownItem {
  id: number
  name: string
}

export enum SecurityPrincipalEnum {
  'Add User' = 3,
  'Edit User' = 1,
  'Delete User' = 4,
  'View User' = 2,
}

export type SecurityPrincipalKeys = keyof typeof SecurityPrincipalEnum
