import type { FormikProps } from 'formik'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { ILeg, ITransportJourney } from '../../types'
import Route from './Route'

export interface IRouteProps {
  formik: FormikProps<ITransportJourney>
  leg: ILeg
  legIdx: number
  locations: ILocationByTenantCustomer[]
}

const Routes = ({ formik, leg, legIdx, locations }: IRouteProps) => {
  return (
    <>
      {leg.routes.map((route, rIdx) => {
        return (
          <Route
            key={rIdx}
            formik={formik}
            route={route}
            rIdx={rIdx}
            legIdx={legIdx}
            locations={locations}
          />
        )
      })}
    </>
  )
}

export default Routes
