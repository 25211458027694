import { Alert, AlertTitle } from '@mui/material'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useTrackingNumberTemplateQuery } from '../../../app/redux-fetch/apiQuery'
import ControlBar from '../../../components/ControlBar/ControlBar'
import {
  fetchAllCountriesSelector,
  fetchAllCustomersByTenantSelector,
  fetchAllTenantCurrenciesSelector,
} from '../../Shipments/selectors'
import {
  fetchAllCountriesThunk,
  fetchAllCurrenciesByTenantIdActions,
  fetchAllCurrenciesByTenantIdThunk,
  fetchAllCustomersByTenantIdActions,
  fetchAllCustomersByTenantIdThunk,
} from '../../Shipments/slice'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ICustomer, ILoggedInCustomer } from '../../types'
import { NotiStackOptions } from '../../utils'
import { postCustomer, putCustomer } from '../api'
import CustomerMRT from './Components/CustomerMRT/CustomerMRT'
import messages from './messages'
import { FormikCustomersSettings, ICustomerFormValues, ITenantCustomer } from './types'
import { getStringFromAddress, matchCustomer } from './utils'

export const Customer = () => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInCustomer)
  const { tenantId } = loggedInUser
  const { data: template, isFetching: isTemplateFetching, isSuccess: isTemplateLoaded, refetch } = useTrackingNumberTemplateQuery({ tenantId });

  const loadingAllTenantCustomers = useAppSelector(fetchAllCustomersByTenantSelector.isFetching)
  useEffect(() => {
    if (!tenantId) return
    void dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
  }, [dispatch, tenantId])

  const loadingAllTenantCurrencies = useAppSelector(fetchAllTenantCurrenciesSelector.isFetching)
  useEffect(() => {
    if (!tenantId) return
    void dispatch(fetchAllCurrenciesByTenantIdThunk(tenantId))
  }, [dispatch, tenantId])

  const loadingAllCountries = useAppSelector(fetchAllCountriesSelector.isFetching)
  useEffect(() => {
    dispatch(fetchAllCountriesThunk())
  }, [dispatch])

  const isTrackingNumberSetup = useCallback(() => !!template, [template])

  const isLoading = loadingAllTenantCustomers || loadingAllTenantCurrencies || loadingAllCountries || isTemplateFetching || !isTemplateLoaded

  const allCustomers = useAppSelector(fetchAllCustomersByTenantSelector.data) as ICustomer[]

  const allTenantCurrencies = useAppSelector(fetchAllTenantCurrenciesSelector.data)

  const allTenantCustomers = allCustomers?.map(
    (customer) =>
    ({
      ...customer,
      defaultCurrencyName: customer.currency.name,
      fullAddress: getStringFromAddress({
        ...customer.location.address,
        country: customer.location.name,
      }),
      enabled: customer.disabled ? 'No' : 'Yes',
      disabled: customer.disabled,
    } as ITenantCustomer)
  )

  const formik = useFormik<FormikCustomersSettings['initialValues']>({
    initialValues: {
      allTenantCustomers,
      originalAllTenantCustomers: allTenantCustomers,
      allTenantCurrencies,
    },
    onSubmit: (values) => submitForm(values),
    enableReinitialize: true,
  })

  const submitForm = async (values: ICustomerFormValues) => {
    const { allTenantCustomers, originalAllTenantCustomers } = values

    const modifiedCustomers = getModifiedCustomers(allTenantCustomers, originalAllTenantCustomers)

    const newCustomers = getNewCustomers(
      allTenantCustomers,
      originalAllTenantCustomers
    ) as Partial<ITenantCustomer>[]

    const newCustomersPayloads = newCustomers?.map((customer) => ({
      tenantId: customer.tenantId,
      location: {
        tenantId: customer.tenantId,
        locationTypeId: 255,
        address: {
          countryId: customer?.location?.address.countryId,
          address1: customer?.location?.address.address1,
          address2: customer?.location?.address.address2,
          address3: customer?.location?.address.address3,
          city: customer?.location?.address.city,
          state: customer?.location?.address.state,
          postalCode: customer?.location?.address.postalCode,
        },
        name: customer?.location?.name,
      },
      defaultCurrencyId: customer.defaultCurrencyId,
      name: customer.name,
      disabled: customer.disabled,
    })) as unknown as Partial<ITenantCustomer>[]

    const modifiedCustomersPayloads = modifiedCustomers?.map((customer) => ({
      id: customer.id,
      tenantId: customer.tenantId,
      locationId: customer.locationId,
      location: {
        id: customer.location.id,
        tenantId: customer.tenantId,
        customerId: customer.id,
        locationTypeId: 255,
        addressId: customer.location.address.id,
        address: {
          id: customer.location.address.id,
          countryId: customer?.location?.address.countryId,
          address1: customer?.location?.address.address1,
          address2: customer?.location?.address.address2,
          address3: customer?.location?.address.address3,
          city: customer?.location?.address.city,
          state: customer?.location?.address.state,
          postalCode: customer?.location?.address.postalCode,
        },
        defaultTransportModeId: customer.location.defaultTransportModeId,
        defaultCurrencyId: customer.defaultCurrencyId,
        name: customer?.location?.name,
      },
      defaultCurrencyId: customer.defaultCurrencyId,
      name: customer.name,
      disabled: customer.disabled,
    })) as unknown as Partial<ITenantCustomer>[]

    modifiedCustomersPayloads?.forEach(async (customer) => {
      try {
        await putCustomer(customer as ITenantCustomer)
        enqueueSnackbar(
          formatMessage(messages.customerUpdateSuccess, { customerName: customer.name }),
          NotiStackOptions.success
        )
      } catch (error) {
        enqueueSnackbar(
          formatMessage(messages.customerUpdateError, { customerName: customer.name }),
          NotiStackOptions.error
        )
      }
    })

    newCustomersPayloads.forEach(async (customer) => {
      try {
        await postCustomer(customer as ITenantCustomer)
        enqueueSnackbar(
          formatMessage(messages.customerCreateSuccess, { customerName: customer.name }),
          NotiStackOptions.success
        )
      } catch (error) {
        enqueueSnackbar(
          formatMessage(messages.customerCreateError, { customerName: customer.name }),
          NotiStackOptions.error
        )
      }
    })

    setTimeout(() => {
      void dispatch(fetchAllCustomersByTenantIdActions.clear())
      void dispatch(fetchAllCustomersByTenantIdThunk(tenantId))
    }, 300)
  }

  useEffect(() => {
    return () => {
      formik.resetForm()
      void dispatch(fetchAllCustomersByTenantIdActions.clear())
      void dispatch(fetchAllCurrenciesByTenantIdActions.clear())
    }
  }, [dispatch])

  return (
    <>
      {!isTrackingNumberSetup() && (<Alert severity='error'>
        <AlertTitle>Error</AlertTitle>
        You must setup your tracking number template before you can add customers.
      </Alert>)}
      <ControlBar
        formik={formik}
        isFormDirty={() => isFormDirty(formik) || isTrackingNumberSetup()}
        onSubmit={formik.handleSubmit}
        sx={{ top: '64px' }}
      />
      <form onSubmit={formik.handleSubmit}>
        <CustomerMRT formik={formik} isLoading={isLoading} isAddDisabled={!isTrackingNumberSetup()} />
      </form>
    </>
  )
}

export default Customer

export const isFormDirty = (formik: FormikCustomersSettings) => {
  const { allTenantCustomers, originalAllTenantCustomers } = formik.values

  const customerModified = compare(allTenantCustomers, originalAllTenantCustomers)

  const newCustomers = getNewCustomers(allTenantCustomers, originalAllTenantCustomers)

  const hasNewCustomers = newCustomers?.length > 0

  return customerModified || hasNewCustomers
}

export function compare(
  allTenantCustomers: ITenantCustomer[],
  originalAllTenantCustomers: readonly ITenantCustomer[]
) {
  if (allTenantCustomers?.length !== originalAllTenantCustomers?.length) return false

  const foundMismatch =
    allTenantCustomers?.find((customer) => {
      const foundInOriginalCustomer = originalAllTenantCustomers?.find(
        (originalCurrency) => originalCurrency.id === customer.id
      )

      if (!foundInOriginalCustomer) return true

      const customerMatch = matchCustomer(customer, foundInOriginalCustomer)
      return !customerMatch
    }) !== undefined

  return foundMismatch
}

export function getModifiedCustomers(
  allTenantCustomers: ITenantCustomer[],
  originalAllTenantCustomers: readonly ITenantCustomer[]
): ITenantCustomer[] {
  const modifiedCustomers = allTenantCustomers?.reduce((acc, customer) => {
    const originalCustomer = originalAllTenantCustomers?.find((c) => c.id === customer.id)

    if (!originalCustomer) return acc

    const match = matchCustomer(customer, originalCustomer)
    if (!match) {
      acc.push(customer)
    }

    return acc
  }, [] as ITenantCustomer[])

  return modifiedCustomers
}

export function getNewCustomers(
  allTenantCustomers: ITenantCustomer[],
  originalAllTenantCustomers: readonly ITenantCustomer[]
) {
  const newCustomers = allTenantCustomers?.reduce((acc, customer) => {
    const originalCustomer = originalAllTenantCustomers?.find((c) => c.id === customer.id)

    if (!originalCustomer) {
      acc.push(customer)
    }

    return acc
  }, [] as ITenantCustomer[])

  return newCustomers
}
