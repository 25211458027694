import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { FormikTouched } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { useAppSelector } from '../../../../app/hooks'
import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import ValueUnitControl from '../../../../components/ValueUnitControl/ValueUnitControl'
import { fetchAllLengthUnitsSelector, fetchAllPalletTypesSelector } from '../../selectors'
import { ILoad, ILoadPalletDetail } from '../../types'
import messages from './messages'
import { PalletAccordion } from './styles'
import { type ILoadDetailsProps, type ILoadPalletVector } from './types'

export interface PalletDetailsProps extends ILoadDetailsProps {
  handleAddPallet: (index: number) => void
  handleRemovePallet: (loadIndex: number, palletIndex: number) => void
  lastCreatedPallet: ILoadPalletVector | null
  isExpanded?: boolean
}

const LoadPalletDetail = (props: PalletDetailsProps): ReactElement => {
  const {
    formik,
    index: loadIndex,
    load,
    handleAddPallet,
    handleRemovePallet,
    handleSwitchLoadChanges,
    lastCreatedPallet,
    disabled,
    isExpanded
  } = props
  const { formatMessage } = useIntl()

  const lengthUnitItems = useAppSelector(fetchAllLengthUnitsSelector)
  const palletTypesItems = useAppSelector(fetchAllPalletTypesSelector.data) ?? []

  const getError = (propertyName: string, index: number): boolean => {
    const palletDetails =
      formik.errors.loads && (formik.errors.loads[loadIndex] as unknown as ILoad)?.palletDetails
    const touchedPallets = formik.touched.loads?.[loadIndex]?.palletDetails as
      | Array<FormikTouched<ILoadPalletDetail>>
      | undefined
    const touched =
      touchedPallets && touchedPallets[index] && getProperty(touchedPallets[index], propertyName)
    const errorMessage =
      palletDetails && palletDetails[index] && getProperty(palletDetails[index], propertyName)
    // eslint-disable-next-line
    return touched && (errorMessage as any)
  }
  return (
    <>
      <Grid item xs={12}>
        <Divider
          color='rgba (0, 0, 0, 0.125)'
          sx={{ backgroundColor: 'transparent' }}
          textAlign='left'
        >
          {formatMessage(messages.palletDetails)}
        </Divider>
      </Grid>
      {(load.palletDetails?.length ?? 0) > 0 &&
        load.palletDetails?.map((pallet, palletIndex) => {
          const expanded =
            lastCreatedPallet != null
              ? lastCreatedPallet.loadIndex === loadIndex &&
              lastCreatedPallet.palletIndex === palletIndex
              : false || isExpanded
          return (
            <Grid item key={palletIndex} xs={12}>
              <PalletAccordion
                defaultExpanded={expanded}
                key={palletIndex}
                TransitionProps={{ unmountOnExit: true }}
              >
                <AccordionSummary
                  expandIcon={<KeyboardArrowRightIcon />}
                  sx={{ flexDirection: 'row-reverse' }}
                >
                  <Grid container alignItems='center'>
                    <Grid item xs={6}>
                      <Typography>
                        {formatMessage(messages.pallet)} # {palletIndex + 1}
                      </Typography>
                    </Grid>
                    {!disabled && (
                      <Grid item xs={6}>
                        <Box display='flex' justifyContent='flex-end'>
                          <Fab
                            aria-label='remove'
                            color='info'
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              handleRemovePallet(loadIndex, palletIndex)
                            }}
                            size='small'
                            sx={{ margin: '0 2px' }}
                          >
                            <DeleteIcon />
                          </Fab>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={4}>
                    <Grid item xs={2}>
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator={true}
                        fullWidth={true}
                        decimalScale={3}
                        id={`loads.${loadIndex}.palletDetails.${palletIndex}.quantity`}
                        name={`loads.${loadIndex}.palletDetails.${palletIndex}.quantity`}
                        label={formatMessage(messages.palletQuantity)}
                        onValueChange={(vals) =>
                          formik.setFieldValue(
                            `loads.${loadIndex}.palletDetails.${palletIndex}.quantity`,
                            vals.floatValue
                          )
                        }
                        value={pallet.quantity}
                        onBlur={formik.handleBlur}
                        helperText={getError('quantity', palletIndex)}
                        error={Boolean(getError('quantity', palletIndex))}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 11 }}
                        allowNegative={false}
                        disabled={disabled}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormDropdown
                        id={`loads.${loadIndex}.palletDetails.${palletIndex}.palletTypeId`}
                        label={formatMessage(messages.palletType)}
                        onChange={(event, name, newValue) => {
                          formik.setFieldValue(
                            `loads.${loadIndex}.palletDetails.${palletIndex}.palletTypeId`,
                            newValue
                          )
                        }}
                        value={pallet.palletTypeId}
                        error={getError('palletTypeId', palletIndex)}
                        errorText={getError('palletTypeId', palletIndex)}
                        handleOnBlur={formik.handleBlur}
                        items={palletTypesItems}
                        disabled={disabled}
                      />
                    </Grid>
                    <ValueUnitControl
                      unitId={`loads.${loadIndex}.palletDetails.${palletIndex}.heightUnitId`}
                      valueId={`loads.${loadIndex}.palletDetails.${palletIndex}.height`}
                      handleOnBlur={formik.handleBlur}
                      errorUnit={getError('heightUnitId', palletIndex)}
                      errorValue={getError('height', palletIndex)}
                      handleChangeOfUnit={(name, newValue) => {
                        formik.setFieldValue(
                          `loads.${loadIndex}.palletDetails.${palletIndex}.heightUnitId`,
                          newValue
                        )
                      }}
                      handleChangeOfValue={(name, newValue) => {
                        formik.setFieldValue(
                          `loads.${loadIndex}.palletDetails.${palletIndex}.height`,
                          newValue
                        )
                      }}
                      labelUnit={formatMessage(messages.unitOfHeight)}
                      labelValue={formatMessage(messages.height)}
                      name={`volumeAndUnitControl_pallet_height_${palletIndex}`}
                      selectedUnitId={pallet.heightUnitId}
                      unitList={lengthUnitItems}
                      value={pallet.height}
                      disabled={disabled}
                    />
                    <Grid item xs={3}>
                      <FormControlLabel
                        disabled={disabled}
                        control={
                          <Switch
                            size='small'
                            id={`loads.${loadIndex}.palletDetails.${palletIndex}.isStackable`}
                            checked={pallet.isStackable}
                            name={`loads.${loadIndex}.palletDetails.${palletIndex}.isStackable`}
                            onChange={(e, value) => {
                              handleSwitchLoadChanges(e, value)
                            }}
                          />
                        }
                        label={formatMessage(messages.palletStackable)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider
                        color='rgba (0, 0, 0, 0.125)'
                        sx={{
                          backgroundColor: 'transparent',
                          margin: '0px 16px',
                        }}
                        textAlign='left'
                      ></Divider>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </PalletAccordion>
            </Grid>
          )
        })}
      {!disabled && (
        <Grid item xs={12}>
          <PalletAccordion expanded={false}>
            <AccordionSummary>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item xs={6} alignItems='center'>
                  <Box>
                    <Typography>{formatMessage(messages.newPalette)}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} justifyContent='flex-end'>
                  <Box display='flex' justifyContent='flex-end'>
                    <Fab
                      aria-label='add'
                      color='primary'
                      onClick={() => {
                        handleAddPallet(loadIndex)
                      }}
                      size='small'
                      sx={{ margin: '0 2px' }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
          </PalletAccordion>
        </Grid>
      )}
    </>
  )
}

export default LoadPalletDetail
