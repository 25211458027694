import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_RowSelectionState as MRTRowSelectionState,
  MRT_TableInstance as MRTTableInstance,
} from 'material-react-table'
import { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../../app/hooks'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { HttpAction } from '../../../../enums'
import AddMoreDialog from '../../Components/AddMoreDialog'
import { AddRoleMRT } from '../../Components/AddRole/AddRoleMRT'
import { IExtendedUserGroup, IUserGroup } from '../../UserGroups/types'
import {
  FormikUserGroupSettings,
  ISelectedItemIdsByUserGroupId,
  ISelectedMemberRoleIdsByUserGroupId,
  IUserGroupRoleByUserGroupId,
} from '../../types'
import messages from './messages'
import { fetchAllCommonSettingsRolesSelector } from './selectors'
import { IExtendedRole, IRole } from './types'

interface IRoleMRTProps {
  formik: FormikUserGroupSettings
}
export const RoleMRT = ({ formik }: IRoleMRTProps) => {
  const allRoles = formik.values.formikRoles
  const allUserGroups = formik.values.formikUserGroups
  const allUserGroupRolesByGroupId = formik.values.formikUserGroupRolesByGroupId

  const selectedUserGroupId = formik.values.formikSelectedUserGroupId as number
  const existingSelectedItemsByUserGroupId = formik.values.formikSelectedRoleIdsByUserGroupId

  const formikUserGroupBeingEdited = formik.values.formikUserGroupBeingEdited
  const editingInProgress = formikUserGroupBeingEdited !== undefined

  const selectedItemsByUserGroupId =
    existingSelectedItemsByUserGroupId.find(
      (selectedItem) => selectedItem.selectedUserGroupId === selectedUserGroupId
    ) || ({} as ISelectedItemIdsByUserGroupId)

  const formikSelectedRoleIds = selectedItemsByUserGroupId.selectedItemIds || []
  const formikSelectedItems = allRoles.filter((role) => formikSelectedRoleIds.includes(role.id))

  const itemsMarkedForDeletion = formik.values.formikRolesLastMarkedForDeletion

  const isFetchingRoles = useAppSelector(fetchAllCommonSettingsRolesSelector.isFetching)

  const columns = useMemo<MRTColumnDef<IRole>[]>(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
    ]
  }, [formik])

  const data = useMemo<IRole[]>(() => {
    // Filter out all users roles marked for deletion in the selected user group
    const selectedUserGroupRolesByGroupId = allUserGroupRolesByGroupId.find(
      (groupMember) => groupMember.groupId === selectedUserGroupId
    )

    const selectedUserGroupRoles = (selectedUserGroupRolesByGroupId?.roles || []) as IExtendedRole[]

    const selectedUserGroupRolesExceptMarkedForDeletion = selectedUserGroupRoles.filter(
      (user) => user.httpAction !== HttpAction.DELETE
    ) as IRole[]

    return selectedUserGroupRolesExceptMarkedForDeletion
  }, [allUserGroupRolesByGroupId, selectedUserGroupId, itemsMarkedForDeletion])

  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const handleChangeSelection = (event: React.MouseEvent<HTMLElement>, row: MRTRow<IRole>) => {
    event.preventDefault()
    event.stopPropagation()

    const { id: originalRowId } = row.original

    setRowSelection((prevState) => {
      const newStateWithAllItems: MRTRowSelectionState = {
        ...prevState,
        [originalRowId]: !prevState[originalRowId],
      }

      // Shake off deselected items
      const newRowSelection = Object.entries(newStateWithAllItems).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(value === true ? { [key]: value } : undefined),
        }),
        {}
      )

      const newRowSelectionModel = Object.keys(newRowSelection).map((key) => Number(key))

      const existingSelectedItemsByUserGroupId: ISelectedItemIdsByUserGroupId[] =
        formik.getFieldProps('formikSelectedRoleIdsByUserGroupId').value || []

      const selectedItemsByUserGroupId =
        existingSelectedItemsByUserGroupId.find(
          (selectedItem) => selectedItem.selectedUserGroupId === selectedUserGroupId
        ) || ({} as ISelectedItemIdsByUserGroupId)

      selectedItemsByUserGroupId.selectedUserGroupId = selectedUserGroupId
      selectedItemsByUserGroupId.selectedItemIds = newRowSelectionModel

      let newSelectedItemsByGroupId = existingSelectedItemsByUserGroupId
        .map((selectedItem) =>
          selectedItem.selectedUserGroupId === selectedUserGroupId
            ? selectedItemsByUserGroupId
            : selectedItem
        )
        .filter((selectedItem) => selectedItem.selectedItemIds.length > 0)

      if (
        newSelectedItemsByGroupId.length === 0 &&
        selectedItemsByUserGroupId.selectedItemIds.length > 0
      )
        newSelectedItemsByGroupId = [selectedItemsByUserGroupId]

      formik.setFieldValue('formikSelectedRoleIdsByUserGroupId', newSelectedItemsByGroupId)

      return newRowSelection
    })
  }

  const showAddRoleDialog = (show = true) => formik.setFieldValue('formikShowAddRoleDialog', show)

  const handleAddMoreRole = () => selectedUserGroupId !== undefined && showAddRoleDialog()

  const handleBulkDelete = () => {
    // Save off original members
    if (!editingInProgress) {
      const originalUserGroup = allUserGroups.find(
        (userGroup) => userGroup.id === selectedUserGroupId
      )

      const originalUserGroupRoleByGroupId = allUserGroupRolesByGroupId.find(
        (groupRole) => groupRole.groupId === selectedUserGroupId
      )

      formik.values.formikOriginalUserGroups = allUserGroups
      formik.values.formikOriginalUserGroup = originalUserGroup
      formik.values.formikOriginalUserGroupRoleByGroupId = originalUserGroupRoleByGroupId
    }

    // Update all items
    // Mark selected items to be deleted by setting http action
    const itemsToDeleteWithHttpAction = formikSelectedItems.map((item) => ({
      ...item,
      httpAction: HttpAction.DELETE,
    }))

    // Update group items
    // Get the group roles for the selected group
    const group = allUserGroupRolesByGroupId.find((group) => group.groupId === selectedUserGroupId)

    // Find all group roles except the ones marked for deletion
    const groupRolesExceptMarkedForDeletion =
      group?.roles.filter(
        (role) => !itemsToDeleteWithHttpAction.find((itemToDelete) => itemToDelete.id === role.id)
      ) || []

    // Merge all group roles except the ones marked for deletion with the ones marked for deletion
    const allGroupRolesWithDeletedItems = [
      ...groupRolesExceptMarkedForDeletion,
      ...itemsToDeleteWithHttpAction,
    ].sort((a, b) => a.id - b.id)

    // Filter out the group roles for the selected group
    const allUserGroupRolesExceptSelectedGroup = allUserGroupRolesByGroupId.filter(
      (groupRole) => groupRole.groupId !== selectedUserGroupId
    )

    // Merge the group members for the selected group with the updated group members
    const allUserGroupRolesWithDeletedItems: IUserGroupRoleByUserGroupId[] = [
      ...allUserGroupRolesExceptSelectedGroup,
      {
        groupId: selectedUserGroupId,
        roles: allGroupRolesWithDeletedItems,
      },
    ].sort((a, b) => a.groupId - b.groupId)

    // Set the new group members in formik
    formik.setFieldValue('formikUserGroupRolesByGroupId', allUserGroupRolesWithDeletedItems)

    // Set timestamp for last marked for deletion in formik to force re-render rows
    formik.setFieldValue('formikRolesLastMarkedForDeletion', Date.now())

    // Mark the user group as dirty
    const updatedUserGroups = formik.values.formikUserGroups.map((userGroup: IExtendedUserGroup) =>
      userGroup.id === selectedUserGroupId
        ? { ...userGroup, dirty: true, httpAction: HttpAction.PUT }
        : userGroup
    )

    formik.values.formikUserGroups = updatedUserGroups as IUserGroup[]

    setRowSelection({})
  }

  const handleRowSelectionChange = ({
    row,
    table,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IRole>
    table: MRTTableInstance<IRole>
  }) => {
    return {
      onChange: (event: any) => handleChangeSelection(event, row),
      onClick: (event: any) => handleChangeSelection(event, row),
      sx: { cursor: 'pointer' },
    }
  }

  const handleAddRoleSelection = () => {
    showAddRoleDialog(false)
    const assets = allUserGroups
    const editedItem = assets.find((userGroup) => userGroup.id === selectedUserGroupId)

    if (!editedItem) return

    const foundIndex = assets.findIndex(
      (item) => item.id === editedItem.id && item.name === editedItem.name
    )

    // 1/3 Save off original value
    if (!editingInProgress) {
      const originalItem = { ...assets[foundIndex] }
      formik.values.formikOriginalUserGroup = originalItem
      formik.values.formikOriginalUserGroups = allUserGroups
    }

    const selectedMemberRoleIdsByGroupId =
      formik.values.formikSelectedMemberRoleIdsByGroupId ||
      ({} as ISelectedMemberRoleIdsByUserGroupId)

    const selectedRoles = allRoles.filter((role) =>
      (selectedMemberRoleIdsByGroupId.selectedRoleIds as number[])?.includes(role.id)
    )

    if (!editingInProgress) {
      const originalUserGroupRoleByGroupId = allUserGroupRolesByGroupId.find(
        (groupMember) => groupMember.groupId === selectedUserGroupId
      )

      formik.values.formikOriginalUserGroupRoleByGroupId = originalUserGroupRoleByGroupId
    }

    const existingRoles =
      allUserGroupRolesByGroupId.find((groupMember) => groupMember.groupId === selectedUserGroupId)
        ?.roles || []

    const filteredSelectedRoles = selectedRoles.filter(
      (role) => !existingRoles.find((groupMember) => groupMember.id === role.id)
    )

    // Mark selected items to be added by setting http action
    const filteredSelectedUsersWithHttpAction = filteredSelectedRoles.map((item) => ({
      ...item,
      httpAction: HttpAction.POST,
    }))

    const existingAndFilteredSelectedRoles = [
      ...existingRoles,
      ...filteredSelectedUsersWithHttpAction,
    ].sort((a, b) => a.id - b.id)

    const newAllUserGroupRolesByGroupId: IUserGroupRoleByUserGroupId = {
      groupId: selectedUserGroupId,
      roles: existingAndFilteredSelectedRoles,
    }

    const newAllUserGroupRoles = allUserGroupRolesByGroupId.map((groupMember) =>
      groupMember.groupId === selectedUserGroupId ? newAllUserGroupRolesByGroupId : groupMember
    )

    formik.setFieldValue('formikUserGroupRolesByGroupId', newAllUserGroupRoles)

    // Mark the user group as dirty and set http action
    const updatedUserGroups = formik.values.formikUserGroups.map((userGroup: IExtendedUserGroup) =>
      userGroup.id === selectedUserGroupId
        ? { ...userGroup, dirty: true, httpAction: userGroup.httpAction || HttpAction.PUT }
        : userGroup
    )

    const updatedUserGroup = updatedUserGroups.find(
      (userGroup) => userGroup.id === selectedUserGroupId
    )

    formik.values.formikUserGroups = updatedUserGroups as IUserGroup[]
    formik.values.formikUserGroupBeingEdited = updatedUserGroup as IUserGroup
  }

  const handleDiscardRoleSelection = () => {
    showAddRoleDialog(false)
    formik.setFieldValue('formikSelectedMemberRoleIdsByGroupId', undefined)
  }

  return (
    <>
      <AddMoreDialog
        open={(formik.getFieldProps('formikShowAddRoleDialog').value as boolean) === true}
        onAdd={handleAddRoleSelection}
        onDiscard={handleDiscardRoleSelection}
        title='Add Role'
      >
        <AddRoleMRT formik={formik} isLoading={isFetchingRoles} />
      </AddMoreDialog>
      <MRTDataGrid
        heading={messages.roleSettingTableHeading}
        columns={columns}
        data={data}
        enableMultiRowSelection={true}
        enableRowSelection={true}
        enableSelectAll={true}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{
          rowSelection, // pass our managed row selection state to the table to use
          isLoading: isFetchingRoles,
        }}
        // enables all rows to be selected but disables individual row selection
        // onRowSelectionChange={setRowSelection}
        getRowId={(row) => `${row.id}`}
        handleAddItem={handleAddMoreRole}
        handleBulkDelete={handleBulkDelete}
      />
    </>
  )
}
