import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { useGetAssetClassesByCategoryQuery, useGetAssetTypesByCategoryQuery } from '../../../../../../app/redux-fetch/apiQuery';
import FormDropdown, { DropdownItem } from '../../../../../../components/FormDropDown/FormDropdown';
import { IAssetCategory, IAssetClass, IAllocation } from '../../types';
import { AddItemAction } from '../buttons/AddItemAction';
import { RemoveItemAction } from '../buttons/RemoveItemAction';
import messages from '../../../../messages';
import { useIntl } from 'react-intl';

const mapToDropdownItems =
    (items: IAssetCategory[] | IAssetClass[]): DropdownItem[] =>
        items.map(({ id, name }) => ({ id, name }));

export interface AssetConfigCardProps {
    onChange?: (values: { itemIndex: number, allocation: IAllocation; }) => void;
    onRemove?: (itemIndex: number) => void;
    onAdd?: () => void;
    assetCategories: DropdownItem[];
    allocation: IAllocation;
    index: number;
    tenantId: number;
    disabled?: boolean;
}

export function AssetConfigCard({
    onRemove,
    onAdd,
    assetCategories,
    allocation,
    index,
    onChange,
    tenantId,
    disabled
}: AssetConfigCardProps) {
    const { formatMessage } = useIntl();
    const { assetType, assetTypeId, assetClassId } = allocation;
    const assetCategoryId = assetType?.assetCategoryId || 0;

    const handleOnChange = (_e: any, name: string, newValue: number) => {
        if (onChange) {
            if (name === 'assetCategory') {
                onChange({
                    itemIndex: index,
                    allocation: {
                        ...allocation,
                        assetType: {
                            ...allocation.assetType,
                            assetCategoryId: newValue,
                        },
                        assetClassId: 0,
                        assetTypeId: 0,
                    }
                })
            }

            if (name === 'assetTypes') {
                onChange({ itemIndex: index, allocation: { ...allocation, assetTypeId: newValue } })
            }

            if (name === 'assetClass') {
                onChange({ itemIndex: index, allocation: { ...allocation, assetClassId: newValue } })
            }
        }
    }

    const { data: assetTypesData = [] } = useGetAssetTypesByCategoryQuery({ tenantId, assetCategoryId });
    const assetTypes: DropdownItem[] = mapToDropdownItems(assetTypesData as unknown as IAssetCategory[] || []);
    const { data: assetClassesData = [] } = useGetAssetClassesByCategoryQuery({ tenantId, assetCategoryId });
    const assetClasses: DropdownItem[] = mapToDropdownItems(assetClassesData as unknown as IAssetClass[] || []);

    return (
        <Card sx={{ minWidth: 275, maxHeight: 300, position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 3, right: 0 }}>
                {onRemove && <RemoveItemAction onClick={() => onRemove(index)} />}
                {onAdd && <AddItemAction onClick={onAdd} />}
            </Box>
            <CardContent>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                    {index + 1}
                </Typography>
                <FormGroup aria-label="position" sx={{ display: 'flex' }}>
                    <FormDropdown
                        id='assetCategory'
                        items={assetCategories}
                        label={formatMessage(messages.assetsCategories)}
                        onChange={handleOnChange}
                        sx={{ margin: '10px 0' }}
                        error={false}
                        value={assetCategoryId}
                        defaultValue={assetCategoryId}
                        disabled={disabled || isEmpty(assetCategories)}
                    />
                    <FormDropdown
                        id='assetTypes'
                        items={assetTypes}
                        label={formatMessage(messages.assetsTypes)}
                        onChange={handleOnChange}
                        sx={{ margin: '10px 0' }}
                        error={false}
                        value={assetTypeId}
                        disabled={disabled || isEmpty(assetTypes)}
                        defaultValue={assetTypeId}
                    />
                    <FormDropdown
                        id='assetClass'
                        disabled={disabled || isEmpty(assetClasses)}
                        items={assetClasses}
                        label={formatMessage(messages.assetsClasses)}
                        onChange={handleOnChange}
                        sx={{ margin: '10px 0' }}
                        error={false}
                        value={assetClassId}
                        defaultValue={assetClassId}
                    />
                </FormGroup>
            </CardContent>
        </Card>
    );
}
