import { Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useGetMeasureUnitsQuery } from '../../../app/redux-fetch/apiQuery';
import { DropdownItem } from '../../../components/FormDropDown/FormDropdown';
import { TableSubTitleTypography } from '../../../components/Typographies/TableTitleTypography';
import { FormGrid } from '../FormGrid/FormGrid';
import { FormGridItem } from '../FormGrid/FormGridItem';
import { ValueUnitControl } from '../ValueUnitControl/ValueUnitControl';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';
import { messages } from '../messages';

interface PhysicalPropertiesGridProps {
    disabled?: boolean;
}

interface Items {
    densityUnitItems: DropdownItem[];
    lengthUnitItems: DropdownItem[];
    weightUnitItems: DropdownItem[];
    volumeUnitItems: DropdownItem[];
}

export function PhysicalPropertiesGrid({
    disabled,
}: PhysicalPropertiesGridProps) {
    const { data: measureUnits = [] } = useGetMeasureUnitsQuery();
    const { formatMessage } = useIntl();

    const { values, setFieldValue } = useAssetManagementFormContext();
    const { physicalProperties } = values;
    const isDisabled = disabled || isEmpty(measureUnits);

    const items = useMemo(() => {
        return measureUnits.reduce((acc, item) => {
            const { measureTypeId, id, units } = item;
            switch (measureTypeId) {
                case 1:
                    acc.weightUnitItems.push({ id, name: units });
                    break;
                case 2:
                    acc.volumeUnitItems.push({ id, name: units });
                    break;
                case 4:
                    acc.lengthUnitItems.push({ id, name: units });
                    break;
                case 6:
                    acc.densityUnitItems.push({ id, name: units });
                    break;
                default:
                    break;
            }
            return acc;
        }, {
            densityUnitItems: [],
            lengthUnitItems: [],
            weightUnitItems: [],
            volumeUnitItems: [],
        } as Items)
    }, [measureUnits]);

    return (
        <>
            <Grid item xs={12} sx={{ ml: '16px', mt: '12px' }}>
                <TableSubTitleTypography>Physical Properties</TableSubTitleTypography>
            </Grid>
            <FormGrid>

                <FormGridItem>
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='lengthMeasureUnitId'
                        valueId='internalLength'
                        unitList={items.lengthUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfLength)}
                        labelValue={formatMessage(messages.assetsInternalLength)}
                        selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
                        value={Number(physicalProperties?.internalLength)}
                    />
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='lengthMeasureUnitId'
                        valueId='internalWidth'
                        unitList={items.lengthUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfLength)}
                        labelValue={formatMessage(messages.assetsInternalWidth)}
                        selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
                        value={Number(physicalProperties?.internalWidth)}
                    />
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='lengthMeasureUnitId'
                        valueId='internalHeight'
                        unitList={items.lengthUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfLength)}
                        labelValue={formatMessage(messages.assetsInternalHeight)}
                        selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
                        value={Number(physicalProperties?.internalHeight)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='lengthMeasureUnitId'
                        valueId='clearLoadingHeight'
                        unitList={items.lengthUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfLength)}
                        labelValue={formatMessage(messages.assetsClearLoadingHeight)}
                        selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
                        value={Number(physicalProperties?.clearLoadingHeight)}
                    />
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='lengthMeasureUnitId'
                        valueId='radius'
                        unitList={items.lengthUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfLength)}
                        labelValue={formatMessage(messages.assetsRadius)}
                        selectedUnitId={Number(physicalProperties?.lengthMeasureUnitId)}
                        value={Number(physicalProperties?.radius)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='weightMeasureUnitId'
                        valueId='grossWeight'
                        unitList={items.weightUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfWeight)}
                        labelValue={formatMessage(messages.assetsGrossWeight)}
                        selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
                        value={Number(physicalProperties?.grossWeight)}
                    />
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='weightMeasureUnitId'
                        valueId='kerbWieght'
                        unitList={items.weightUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfWeight)}
                        labelValue={formatMessage(messages.assetsKerbWieght)}
                        selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
                        value={Number(physicalProperties?.kerbWieght)}
                    />
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='weightMeasureUnitId'
                        valueId='permissibleLoad'
                        unitList={items.weightUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfWeight)}
                        labelValue={formatMessage(messages.assetsPermissibleLoad)}
                        selectedUnitId={Number(physicalProperties?.weightMeasureUnitId)}
                        value={Number(physicalProperties?.permissibleLoad)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <ValueUnitControl
                        disabled={isDisabled}
                        unitId='volumeMeasureUnitId'
                        valueId='internalVolume'
                        unitList={items.volumeUnitItems}
                        handleChangeOfUnit={(name: string, newValue: string) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        handleChangeOfValue={(name: string, newValue: string | number | undefined) => {
                            setFieldValue(`physicalProperties.${name}`, Number(newValue))
                        }}
                        labelUnit={formatMessage(messages.unitOfVolume)}
                        labelValue={formatMessage(messages.assetsInternalVolume)}
                        selectedUnitId={Number(physicalProperties?.volumeMeasureUnitId)}
                        value={Number(physicalProperties?.internalVolume)}
                    />
                </FormGridItem>
            </FormGrid></>
    );
};