import { Box } from '@mui/material'
import { UppercaseTypography } from '../Typographies/UpperTypography'
import { StyledListItemButton } from './styles'

export const CategoryButton = ({
  icon,
  label,
  action,
  isSelected,
}: {
  icon: React.ReactNode
  label: string
  action: () => void
  isSelected: boolean
}) => {
  return (
    <StyledListItemButton onClick={action} isSelected={isSelected}>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        height='100%'
        sx={{ overflow: 'hidden' }}
      >
        {icon}
        <UppercaseTypography>{label}</UppercaseTypography>
      </Box>
    </StyledListItemButton>
  )
}
