import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { cloneDeep } from 'lodash/fp'

import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from '../../../../app/hooks'
import { useApproveJourneyMutation } from '../../../../app/redux-fetch/apiQuery'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { saveDraftJourney } from '../../api'
import {
  DestionationAction,
  IJourneyLegLoad,
  IJourneyLegRoutesLoad,
  IJourneyRoute,
  ITransportJourney,
} from '../../types'
import Legs from '../Common/Legs'
import { messages } from '../messages'
import JourneyLoadTable from './CreateJourneyLoadTable'
import JourneyDisabledLoadTable from './CreateJourneyLoadTableDisabled'
import { checkLoadsMismatch, validateLoadInLegs, validateLoadsInLegRoutes } from './utils'
import rootEnum from '../../../../components/Routes/rootEnum'

export interface ICreateTransportLegFormProps {
  loads: IJourneyLegLoad[]
  locations: ILocationByTenantCustomer[]
}

const CreateJourneyForm = (props: ICreateTransportLegFormProps): ReactElement<any, any> => {
  const { loads, locations } = props
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isAddMultiModal, setIsAddMultiModal] = useState(false)
  const [startMultiLocationId, setStartMultiLocationId] = useState<number | undefined>()
  const [endMultiLocationId, setEndMultiLocationId] = useState<number | undefined>()
  const [approveJorney] = useApproveJourneyMutation()

  const navigate = useNavigate()

  const { formatMessage } = useIntl()

  const initialValues = (): ITransportJourney => {
    const initialValues: ITransportJourney = {
      tenantId: loggedInUser?.tenantId ?? -1,
      carrierId: 10001,
      loads: loads.map((item) => ({ ...item, id: item.loadId, isSelected: false })),
      assetConfigurationAllocations: [],
      assetEmployeeAllocations: [],
      assetSealAllocations: [],
      routes: [],
      assetConfigurations: [],
      legs: [],
    }
    return initialValues
  }

  const formik = useFormik<ITransportJourney>({
    initialValues: initialValues(),
    enableReinitialize: false,
    onSubmit: async (values) => {
      let wrongRouteIdx = 1
      const isInvalidLocationCount = values.legs.some((tl) =>
        tl.routes.some((tlr, tlrIdx) => {
          if (tlr.locations.length < 2) {
            wrongRouteIdx += tlrIdx
            return true
          }
          return false
        })
      )
      if (isInvalidLocationCount) {
        enqueueSnackbar(formatMessage(messages.invalidRouteLocationCount, { wrongRouteIdx }), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }

      const legs = values.legs.map((tl, tIdx) => {
        const routes = tl.routes.map((route) => {
          return {
            transportDetail: {
              pickupLocationId: route.locations.length > 0 ? route.locations[0].locationId : -1,
              deliveryLocationId:
                route.locations.length > 0
                  ? route.locations[route.locations.length - 1].locationId
                  : -1,
              pickupDate: '2023-07-10T12:30:32.935Z', // TODO: What is the time there
              deliveryDate: '2023-07-10T12:30:32.935Z', // TODO: What is the time there
              isCrossBorder: true, // TODO: Calculate in BE
            },
            subcontractedTenantId: 0,
            subSubcontractedTenantId: 0,
            assetGroupAllocations: [],
            destinations: route.locations.map((rloc) => {
              const loadActions = route.loads.map((rl) => {
                let actionId = DestionationAction.Nothing // Do nothing
                let notifyPartyId
                if (rl.pickupRouteLocationId === rloc.locationId) {
                  actionId = DestionationAction.Pickup // Pick up
                  notifyPartyId = rl.pickupNotifyPartyId
                }
                if (rl.dropoffRouteLocationId === rloc.locationId) {
                  actionId = DestionationAction.Dropoff // Drop off
                  notifyPartyId = rl.deliveryNotifyPartyId
                }
                return {
                  loadId: rl.id,
                  destinationActionTypeId: actionId,
                  notifyPartyId: notifyPartyId,
                }
              })
              return {
                locationId: rloc.locationId,
                sequence: rloc.sequence,
                scheduledDate: rloc.scheduledDate,
                loadActions: loadActions,
              }
            }),
          }
        })

        return {
          carrierId: tl.carrierId,
          carrierWaybillMaster: tl.carrierWaybillMaster,
          routes: routes,
          sequence: tIdx + 1,
        }
      })
      const loads: IJourneyLegRoutesLoad[] = []
      values.legs.forEach((tl) =>
        tl.routes.forEach((tlr) => tlr.loads.forEach((l) => loads.push(l)))
      )

      const isPickupOrDroppoffLocationNotMissing = checkLoadsMismatch(values)
      const isPickupOrDroppoffLocationWrongForLegs = validateLoadInLegs(values)
      const pickupDropoffErrorLoads = validateLoadsInLegRoutes(values)
      if (pickupDropoffErrorLoads.length > 0) {
        enqueueSnackbar(formatMessage(messages.loadPickupDropoffValidation), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }
      if (!isPickupOrDroppoffLocationWrongForLegs) {
        enqueueSnackbar(formatMessage(messages.loadPickupDeliveryLegsValidation), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }

      if (!isPickupOrDroppoffLocationNotMissing) {
        enqueueSnackbar(formatMessage(messages.loadPickupDeliveryValidation), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        return
      }

      const newPayload = {
        tenantId: loggedInUser?.tenantId,
        legs: legs,
      }
      if (!isSubmit) {
        await saveDraftJourney(loggedInUser?.tenantId ?? -1, newPayload)
          .then(() => {
            enqueueSnackbar(formatMessage(messages.journeySavedSuccessfully), {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
            navigate(rootEnum.JOURNEY)
          })
          .catch(() => {
            enqueueSnackbar(formatMessage(messages.journeyNotSaved), {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
          })
          .finally(() => {
            setIsSubmit(false)
          })
      } else {
        await saveDraftJourney(loggedInUser?.tenantId ?? -1, newPayload)
          .then(async (res: any) => {
            await approveJorney({
              tenantId: loggedInUser?.tenantId ?? -1,
              journeyId: res.data.data.id,
            })
              .then(() => {
                enqueueSnackbar(formatMessage(messages.journeyApprovedSuccessfully), {
                  variant: 'success',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                })
                navigate(rootEnum.JOURNEY)
              })
              .catch(() => {
                enqueueSnackbar(formatMessage(messages.journeyNotApproved), {
                  variant: 'error',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                })
              })
              .finally(() => {
                setIsSubmit(false)
              })
          })
          .catch(() => {
            enqueueSnackbar(formatMessage(messages.journeyNotSaved), {
              variant: 'error',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
            })
            setIsSubmit(false)
          })
      }
    },
  })

  const someChecked = Object.entries(formik.values)
    .filter((item: any) => item[0] !== 'assetConfigurations')
    .some((item) => item[1])

  const hadleAddTransportLeg = () => {
    const currentLegs = formik.values.legs
    const selectedLoads = formik.values.loads.filter((x) => x.isSelected)
    const newRoutes: IJourneyRoute[] = []
    const routeId = uuidv4()

    const isMixed =
      selectedLoads.some((sl) => sl.isDefined) && selectedLoads.some((sl) => !sl.isDefined)

    if (isMixed) {
      enqueueSnackbar(formatMessage(messages.cannotCombineLoadsAndAssets), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }

    if (selectedLoads.length > 0 && currentLegs.length === 0) {
      selectedLoads.forEach((x) => {
        if (x.pickupLocationId && x.consigneeId) {
          let newRoute = newRoutes.find(
            (nr) =>
              nr.locations.some((l) => l.locationId === x.pickupLocationId) &&
              nr.locations.some((l) => l.locationId === x.consigneeId)
          )
          if (!newRoute) {
            const currStartLocation = locations.find((loc) => loc.id === x.pickupLocationId)
            const currEndLocation = locations.find((loc) => loc.id === x.consigneeId)
            newRoute = {
              id: routeId,
              loads: [
                {
                  id: x.id,
                  pickupRouteLocationId: x.pickupLocationId,
                  dropoffRouteLocationId: x.consigneeId,
                  name: x.description,
                  pickupLocationId: x.pickupLocationId,
                  consigneeId: x.consigneeId,
                  isDefined: x.isDefined,
                  pickupNotifyPartyId: currStartLocation?.contacts?.find(
                    (c) => c.id === x.pickupNotifyPartyId
                  )?.id,
                  deliveryNotifyPartyId: currEndLocation?.contacts?.find(
                    (c) => c.id === x.deliveryNotifyPartyId
                  )?.id,
                },
              ],
              locations: [
                {
                  name: currStartLocation?.name ?? '',
                  sequence: 1,
                  contactId: x.pickupNotifyPartyId,
                  locationId: currStartLocation?.id ?? -1,
                  scheduledDate: new Date(),
                },
                {
                  name: currEndLocation?.name ?? '',
                  sequence: 2,
                  contactId: x.dropOffNotifyPartyId,
                  locationId: currEndLocation?.id ?? -1,
                  scheduledDate: new Date(),
                },
              ],
            }
            newRoutes.push(newRoute)
          } else {
            newRoute.loads = [
              ...newRoute.loads,
              {
                id: x.id,
                pickupRouteLocationId: x.pickupLocationId,
                dropoffRouteLocationId: x.consigneeId,
                name: x.description,
                pickupLocationId: x.pickupLocationId,
                consigneeId: x.consigneeId,
                isDefined: x.isDefined,
              },
            ]
          }
        }
      })
    }

    const newLegLeg = []
    if (isAddMultiModal) {
      const startLocation = locations.find((loc) => loc.id === startMultiLocationId)
      const endLocation = locations.find((loc) => loc.id === endMultiLocationId)
      const intermediateRoute: IJourneyRoute = {
        id: uuidv4(),
        loads: selectedLoads.map((x) => {
          return {
            id: x.id,
            pickupRouteLocationId: x.pickupLocationId,
            dropoffRouteLocationId: x.consigneeId,
            name: x.description,
            pickupLocationId: x.pickupLocationId,
            consigneeId: x.consigneeId,
            isDefined: x.isDefined,
          }
        }),
        locations: [
          {
            locationId: startLocation?.id ?? -1,
            sequence: 1,
            name: startLocation?.name ?? '',
            scheduledDate: new Date(),
          },
          {
            locationId: endLocation?.id ?? -1,
            sequence: 2,
            name: endLocation?.name ?? '',
            scheduledDate: new Date(),
          },
        ],
      }
      const startLeg = {
        routes: cloneDeep(newRoutes),
      }
      const endLeg = {
        routes: cloneDeep(newRoutes),
      }
      const intermediateLeg = {
        routes: [intermediateRoute],
      }
      startLeg.routes.forEach((r) => {
        r.loads.forEach((l) => {
          l.dropoffRouteLocationId = startLocation?.id ?? -1
        })
        r.locations[1].locationId = startLocation?.id ?? -1
        r.locations[1].name = startLocation?.name ?? ''
      })
      intermediateLeg.routes.forEach((r) => {
        r.loads.forEach((l) => {
          l.dropoffRouteLocationId = endLocation?.id ?? -1
          l.pickupRouteLocationId = startLocation?.id ?? -1
        })
        r.locations[0].locationId = startLocation?.id ?? -1
        r.locations[0].name = startLocation?.name ?? ''
        r.locations[1].locationId = endLocation?.id ?? -1
        r.locations[1].name = endLocation?.name ?? ''
      })
      endLeg.routes.forEach((r) => {
        r.loads.forEach((l) => {
          l.pickupRouteLocationId = endLocation?.id ?? -1
        })
        r.locations[0].locationId = endLocation?.id ?? -1
        r.locations[0].name = endLocation?.name ?? ''
      })
      newLegLeg.push(startLeg)
      newLegLeg.push(intermediateLeg)
      newLegLeg.push(endLeg)
    } else {
      newLegLeg.push({
        routes: newRoutes,
      })
    }
    if (selectedLoads.length !== 0 || confirm(formatMessage(messages.noSelectedLoadsWarning))) {
      formik.setFieldValue('legs', [...currentLegs, ...newLegLeg])
    }
  }
  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={6}>
        <Typography variant='h5'>
          {formik.values.legs.length === 0
            ? formatMessage(messages.shipmentUnscheduled)
            : formatMessage(messages.createNewJourney)}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {formik.values.legs.length !== 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 3,
              }}
            >
              <Button
                color='primary'
                disabled={formik.isSubmitting}
                variant='contained'
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                {formatMessage(messages.saveDraft)}
              </Button>
              <Button
                color='primary'
                disabled={formik.isSubmitting}
                variant='contained'
                onClick={() => {
                  setIsSubmit(true)
                  formik.handleSubmit()
                }}
              >
                {formatMessage(messages.approveForTransport)}
              </Button>
            </Box>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '3px',
            padding: '10px',
          }}
        >
          <Grid container justifyContent='center' rowSpacing={2}>
            <Grid item xs={6}>
              <Typography variant='h5'>
                {formik.values.legs.length === 0 ? '' : formatMessage(messages.selectedLoads)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 3,
                }}
              >
                {isAddMultiModal ? (
                  <>
                    <FormDropdown
                      id={'start'}
                      sx={{ width: '250px' }}
                      items={locations}
                      label={formatMessage(messages.leg2OriginLabel)} // Add the leg #2 origin label message
                      onChange={function (event: any, name: string, value: any): void {
                        setStartMultiLocationId(value)
                      }}
                      value={startMultiLocationId}
                    />
                    <FormDropdown
                      id={'end'}
                      sx={{ width: '250px' }}
                      items={locations}
                      label={formatMessage(messages.leg2DestinationLabel)} // Add the leg #2 destination label message
                      onChange={function (event: any, name: string, value: any): void {
                        setEndMultiLocationId(value)
                      }}
                      value={endMultiLocationId}
                    />
                    <IconButton
                      size='large'
                      onClick={() => {
                        setIsAddMultiModal(false)
                        hadleAddTransportLeg()
                      }}
                    >
                      <DoneIcon color='success' />
                    </IconButton>
                    <IconButton
                      size='large'
                      onClick={() => {
                        setIsAddMultiModal(false)
                        setStartMultiLocationId(-1)
                        setEndMultiLocationId(-1)
                      }}
                    >
                      <CloseIcon color='error' />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <Button
                      color='primary'
                      disabled={!someChecked}
                      variant='contained'
                      onClick={hadleAddTransportLeg}
                    >
                      {formik.values.legs.length === 0
                        ? formatMessage(messages.createJourney)
                        : formatMessage(messages.addTransportLeg)}{' '}
                    </Button>
                    {formik.values.legs.length === 0 && (
                      <Button
                        color='primary'
                        disabled={!someChecked}
                        variant='contained'
                        onClick={() => {
                          setIsAddMultiModal(true)
                        }}
                      >
                        {formatMessage(messages.createMultiModalJourney)}
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {formik.values.legs.length === 0 ? (
                <JourneyLoadTable formik={formik} />
              ) : (
                <JourneyDisabledLoadTable formik={formik} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
      {formik.values.legs.length > 0 && (
        <Grid item xs={12}>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.125)',
              borderRadius: '3px',
              padding: '10px',
            }}
          >
            <Legs formik={formik} locations={locations} />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default CreateJourneyForm
