import { styled } from '@mui/system'

export const SpinnerCenter = styled('div')({
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const SpinnerModalCenter = styled('div')({
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '70%',
  transform: 'translate(-50%, -50%)',
})
