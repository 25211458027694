import { defineMessages } from 'react-intl'

const messages = defineMessages({
  roleTableHeading: {
    id: 'tenantSettings.role.roleTableHeading',
    description: 'Role settings table',
    defaultMessage: 'Roles',
  },
  confirmDialogTitle: {
    id: 'roleSettings.role.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogDiscardSelection: {
    id: 'roleSettings.role.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'roleSettings.role.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogMessageContent: {
    id: 'roleSettings.role.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
})

export default messages
