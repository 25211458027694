import { Button, Grid } from '@mui/material'
import { useFormik } from 'formik'
import { useCallback, useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import logger from '../../../app/middleware/log'
import { IDropDownItem } from '../../../app/types'
import { getAddressObject } from '../../../app/utils'
import PolygonMap from '../../../components/PolygonMap/PolygonMap'
import { fetchLoggedInUserSelector } from '../../../features/selectors'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import ContactTable from '../../Contacts/ContactTable'
import { updateLocation } from '../../Shipments/api'
import { useNotificationStack } from '../../Shipments/hooks/useNotificationStack'
import { fetchAllCountriesSelector } from '../../Shipments/selectors'
import { ApiErrorMsgsType, ILocationByTenantCustomer, IMarker } from '../../Shipments/types'
import { ILoggedInUser } from '../../types'
import AddressDetails from './AddressDetails'
import DefaultValues from './DefaultValues'
import MainInfo from './MainInfo'
import SubmitButton from './SubmitButton'
import messages from './messages'
import { getLocationValidationSchema } from './validation'
import { SectionTitleTypography } from '../../../components/Typographies/styles'

export interface IEditLocationProps {
  location?: ILocationByTenantCustomer
  customers?: IDropDownItem[]
}

const EditLocationForm = ({ location, customers }: IEditLocationProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { enqueueMsg } = useNotificationStack()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const { onClose, setBottomComponent } = useSideModalContext()

  const [polygons, setPolygons] = useState<any>([])

  const customerId = loggedInUser.customerId
  const tenantId = loggedInUser.tenantId
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  const [marker, setMarker] = useState<IMarker>()

  const initialValues = useCallback((): ILocationByTenantCustomer => {
    const initialValues = {
      id: location?.id ?? -1,
      name: location?.name ?? '',
      address: {
        id: location?.address?.id ?? -1,
        countryId: location?.address?.countryId ?? 1,
        address1: location?.address?.address1 ?? '',
        address2: location?.address?.address2 ?? '',
        address3: location?.address?.address3 ?? '',
        city: location?.address?.city ?? '',
        state: location?.address?.state ?? '',
        postalCode: location?.address?.postalCode ?? '',
      },
      lat: convertLatLng(location?.lat),
      lng: convertLatLng(location?.lng),
      defaultTransportModeId: location?.defaultTransportModeId,
      locationTypeId: 255, // TODO: Hardcoded for now as user defined
      defaultCurrencyId: location?.defaultCurrencyId,
      tenantId: location?.tenantId,
      customerId: location?.customerId,
      contacts: location?.contacts,
    }

    return initialValues
  }, [location, location?.address])

  const convertLatLng = (input: number | undefined) => {
    return !input || input == 0 ? undefined : input
  }

  const formik = useFormik<ILocationByTenantCustomer>({
    initialValues: initialValues(),
    enableReinitialize: true,
    validationSchema: getLocationValidationSchema(formatMessage),
    onSubmit: async (values: ILocationByTenantCustomer) => {
      try {
        values.lat = marker?.lat
        values.lng = marker?.lng
        values.geofence = polygons
        await updateLocation(values, tenantId, customerId)
        onClose()
      } catch (e: any) {
        logger.error(e)
        enqueueMsg(e.response.data as ApiErrorMsgsType)
      }
    },
  })

  const onHandleDeletePolygon = () => {
    setPolygons([])
  }
  const onHandleDeleteMarker = () => {
    formik.setFieldValue('lat', '')
    formik.setFieldValue('lng', '')
    setMarker(undefined)
  }

  const getMarker = () => {
    if (location?.lat && location?.lng) {
      const marker = { lat: location?.lat, lng: location?.lng }
      return marker
    }
    return undefined
  }

  useEffect(() => {
    setMarker(getMarker())
    location && setPolygons(location.geofence)
  }, [location])

  const updateMarker = (coords: any) => {
    if (coords.lat != marker?.lat || coords.lng != marker?.lng) {
      setMarker(!coords.lat || !coords.lng ? undefined : coords)
      if (!polygons || polygons.length == 0) {
        // TODO: pan & zoom to new co-ords
        // panTo(coords)
        // setZoom(13)
      }
    }
  }

  useEffect(() => {
    setBottomComponent && setBottomComponent(<SubmitButton create={false} formik={formik} />)
    return () => {
      setBottomComponent && setBottomComponent(null)
    }
  }, [])

  const handleGeocoder = (geoCoderResult: any) => {
    const parsedAddress = getAddressObject(geoCoderResult.address_components)
    if (parsedAddress?.country) {
      const parsedCountry = countries.find((c) => c.iso2 === parsedAddress?.country)
      if (parsedCountry) {
        formik.setFieldValue('address.countryId', parsedCountry.id)
      }
    } else {
      formik.setFieldValue('address.countryId', 0)
    }
    if (parsedAddress?.city) {
      formik.setFieldValue('address.city', parsedAddress?.city)
    } else {
      formik.setFieldValue('address.city', '')
    }
    if (parsedAddress?.postalCode) {
      formik.setFieldValue('address.postalCode', parsedAddress?.postalCode)
    } else {
      formik.setFieldValue('address.postalCode', '')
    }
    if (parsedAddress?.region) {
      formik.setFieldValue('address.state', parsedAddress?.region)
    } else {
      formik.setFieldValue('address.state', '')
    }
    formik.setFieldValue('address.address1', geoCoderResult.formatted_address)
    formik.setFieldValue('lat', geoCoderResult.geometry.location.lat())
    formik.setFieldValue('lng', geoCoderResult.geometry.location.lng())
  }

  return (
    <Grid container spacing={2} sx={{ p: '48px' }}>
      <Grid display='flex' flexDirection='row' gap={4} width='100%'>
        <Grid display='flex' flexDirection='column' width='50%'>
          <MainInfo type={'edit'} formik={formik} updateMarker={updateMarker} customers={customers} isTenantUser={Boolean(isTenantUser)} />
          <DefaultValues formik={formik} />
          <ContactTable formik={formik} />
        </Grid>
        <Grid display='flex' flexDirection='column' width='50%'>
          <Grid container item xs={12} gap={2} justifyContent="space-between" sx={{ mb: '16px' }}>
            <Grid item>
              <SectionTitleTypography >{formatMessage(messages.addressDetails)}</SectionTitleTypography>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                color='secondary'
                disabled={polygons && polygons.length === 0}
                onClick={onHandleDeletePolygon}
                sx={{ marginRight: '12px' }}
              >
                {formatMessage(messages.deletePolygon)}
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                disabled={!marker?.lat && !marker?.lng}
                onClick={onHandleDeleteMarker}
              >
                {formatMessage(messages.deleteMarker)}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <PolygonMap
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
              defaultCenter={{
                lat: 24.638517161652892,
                lng: 45.84234953431573,
                zoom: 5,
              }}
              marker={marker}
              height={239}
              width='85%'
              polygons={polygons}
              onChangePolygons={setPolygons}
              onChangeMarker={setMarker}
              handleGeocode={handleGeocoder}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressDetails formik={formik} type='edit' updateMarker={updateMarker} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditLocationForm
