import { useMemo, useState } from 'react'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
  MRT_RowSelectionState as MRTRowSelectionState,
} from 'material-react-table'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { HttpAction } from '../../../../enums'
import { IExtendedUser, IUser } from '../../../UserSettings/User/types'
import { FormikUserGroupSettings, ISelectedUserIdsByUserGroupId } from '../../types'
import messages from './messages'

interface IAddUserMRTProps {
  formik: FormikUserGroupSettings
  isLoading: boolean
}

export const AddUserMRT = ({ formik, isLoading }: IAddUserMRTProps) => {
  const allUsers = formik.values.formikUsers
  const allGroups = formik.values.formikUserGroupMembersByGroupId
  const selectedUserGroupId = formik.values.formikSelectedUserGroupId as number

  const selectedGroupUsers = (allGroups.find((group) => group.groupId === selectedUserGroupId)
    ?.members || []) as IExtendedUser[]

  const filteredUsers = allUsers
    .filter(
      (user) =>
        !selectedGroupUsers.find(
          (groupUser) => groupUser.id === user.id && groupUser.httpAction !== HttpAction.DELETE
        )
    )
    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

  const columns = useMemo<MRTColumnDef<IUser>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'isGlobalAdmin',
        header: 'Global Admin',
        enableEditing: false,
        columnDefType: 'display',
        enableColumnOrdering: true,
        Cell: ({ row, table }: { row: MRTRow<IUser>; table: MRTTableInstance<IUser> }) => {
          const { isGlobalAdmin } = row.original
          return isGlobalAdmin ? 'Yes' : 'No'
        },
      },
      {
        accessorKey: 'isAccountOwner',
        header: 'Account Owner',
        enableEditing: false,
        columnDefType: 'display',
        enableColumnOrdering: true,
        Cell: ({ row, table }: { row: MRTRow<IUser>; table: MRTTableInstance<IUser> }) => {
          const { isAccountOwner } = row.original
          return isAccountOwner ? 'Yes' : 'No'
        },
      },
    ],
    [formik]
  )

  const handleRowSelectionChange = ({
    row,
    table,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<IUser>
    table: MRTTableInstance<IUser>
  }) => {
    return {
      onChange: (event: any) => handleChangeSelection(event, row),
      onClick: (event: any) => handleChangeSelection(event, row),
      sx: { cursor: 'pointer' },
    }
  }

  const [rowSelection, setRowSelection] = useState<MRTRowSelectionState>({})

  const handleChangeSelection = (event: React.MouseEvent<HTMLElement>, row: MRTRow<IUser>) => {
    event.preventDefault()
    event.stopPropagation()

    const { id: originalRowId } = row.original

    setRowSelection((prevState) => {
      const newRowSelection: MRTRowSelectionState = {
        ...prevState,
        [originalRowId]: !prevState[originalRowId],
      }
      const newRowSelectionModel = Object.keys(newRowSelection).map((key) => Number(key))

      const selectedUserIdsByGroupId: ISelectedUserIdsByUserGroupId = {
        selectedUserGroupId,
        selectedUserIds: newRowSelectionModel.filter((id) => newRowSelection[id] === true),
      }

      formik.setFieldValue('formikSelectedUserIdsByGroupId', selectedUserIdsByGroupId)
      return newRowSelection
    })
  }

  return (
    <>
      <MRTDataGrid
        heading={messages.usersTableHeading}
        columns={columns}
        data={filteredUsers}
        state={{ rowSelection, isLoading }}
        getRowId={(row) => `${row.id}`}
        enableEditing={false}
        enableMultiRowSelection={true}
        enableRowSelection={true}
        enableSelectAll={true}
        muiTableBodyRowProps={handleRowSelectionChange}
      />
    </>
  )
}
