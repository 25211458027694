/* eslint-disable */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addLocation: {
    id: 'locations.addLocation',
    description: 'Create new location',
    defaultMessage: 'Create new location',
  },
  editLocation: {
    id: 'locations.editLocation',
    description: 'Edit location',
    defaultMessage: 'Edit location',
  },
  mainInfo: {
    id: 'locations.mainInfo',
    description: 'Main Information Label',
    defaultMessage: 'Main Information',
  },
  addressDetails: {
    id: 'locations.addressDetails',
    description: 'Address details label',
    defaultMessage: 'Address Details',
  },
  defaultValues: {
    id: 'locations.defaultValues',
    description: 'Default Values Label',
    defaultMessage: 'Default Values',
  },
  location: {
    id: 'locations.location',
    description: 'Location',
    defaultMessage: 'Location',
  },
  backToLocationstBtn: {
    id: 'locations.backToLocationstBtn',
    description: 'Back To Locations',
    defaultMessage: 'Back To Locations',
  },
  allLocations: {
    id: 'locations.allLocations',
    description: 'All Locations',
    defaultMessage: 'Locations',
  },
  sendCoordinates: {
    id: 'locations.sendCoordinates',
    description: 'Send Coordinates',
    defaultMessage: 'Send Coordinates',
  },
  deletePolygon: {
    id: 'locations.deletePolygon',
    description: 'Delete Selected Zone',
    defaultMessage: 'Delete Selected Zone',
  },
  deleteMarker: {
    id: 'locations.deleteMarker',
    description: 'Delete Marker',
    defaultMessage: 'Delete Marker',
  },
  transportMode: {
    id: 'locations.transportMode',
    description: 'Default Transport Mode',
    defaultMessage: 'Default Transport Mode',
  },
  country: {
    id: 'locations.country',
    description: 'Country',
    defaultMessage: 'Country',
  },
  currency: {
    id: 'locations.currency',
    description: 'Default Currency',
    defaultMessage: 'Default Currency',
  },
  name: {
    id: 'locations.name',
    description: 'Name',
    defaultMessage: 'Location Name',
  },
  customerLabel: {
    id: 'locations.customerLabel',
    description: 'Customer Label',
    defaultMessage: 'Customer',
  },
  customerOptionalLabel: {
    id: 'locations.customerOptionalLabel',
    description: 'Customer Optional Label',
    defaultMessage: 'Select if this is a customer location',
  },
  locationTypeLable: {
    id: 'locations.locationTypeLable',
    description: 'Location Type',
    defaultMessage: 'Location Type',
  },
  address: {
    id: 'locations.address',
    description: 'Address ',
    defaultMessage: 'Address ',
  },
  address1: {
    id: 'locations.address1',
    description: 'Address 1',
    defaultMessage: 'Address 1',
  },
  address2: {
    id: 'locations.address2',
    description: 'Address 2',
    defaultMessage: 'Address 2',
  },
  address3: {
    id: 'locations.address3',
    description: 'Address 3',
    defaultMessage: 'Address 3',
  },
  city: {
    id: 'locations.city',
    description: 'City',
    defaultMessage: 'City',
  },
  state: {
    id: 'locations.state',
    description: 'State',
    defaultMessage: 'State',
  },
  postCode: {
    id: 'locations.postCode',
    description: 'Postal Code',
    defaultMessage: 'Postal Code',
  },
  lat: {
    id: 'locations.lat',
    description: 'Latitude',
    defaultMessage: 'Latitude',
  },
  lng: {
    id: 'locations.lng',
    description: 'Longitude',
    defaultMessage: 'Longitude',
  },
  create: {
    id: 'locations.create',
    description: 'Create button',
    defaultMessage: 'Create',
  },
  update: {
    id: 'locations.update',
    description: 'Update',
    defaultMessage: 'Update',
  },
  cancel: {
    id: 'locations.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'locations.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete',
  },
  countryValidation: {
    id: 'locations.countryValidation',
    description: 'Country is required',
    defaultMessage: 'Country is required',
  },
  nameValidation: {
    id: 'locations.nameValidation',
    description: 'Name is required',
    defaultMessage: 'Name is required',
  },
  address1Validation: {
    id: 'locations.address1Validation',
    description: 'Address is required',
    defaultMessage: 'Address is required',
  },
  cityValidation: {
    id: 'locations.cityValidation',
    description: 'City is required',
    defaultMessage: 'City is required',
  },
  stateValidation: {
    id: 'locations.stateValidation',
    description: 'State is required',
    defaultMessage: 'State is required',
  },
  postCodeValidation: {
    id: 'locations.postCodeValidation',
    description: 'Postal Code is required',
    defaultMessage: 'Postal Code is required',
  },
  createLocationTitle: {
    id: 'locations.createLocationTitle',
    description: 'Create Location Modal title',
    defaultMessage: 'Create Location',
  },
  editLocationTitle: {
    id: 'locations.editLocationTitle',
    description: 'Edit Location Title',
    defaultMessage: 'Edit Location',
  },
  viewLocationTitle: {
    id: 'locations.viewLocationTitle',
    description: 'Edit Location Title',
    defaultMessage: 'View Location',
  },
  showAllLocations: {
    id: 'locations.showAllLocations',
    description: 'Show All Locations',
    defaultMessage: 'Show All Locations',
  },
  viewButtonLbl: {
    id: 'locations.viewButtonLbl',
    description: 'View link label',
    defaultMessage: 'View',
  },
  editButtonLbl: {
    id: 'locations.editButtonLbl',
    description: 'Edit link label',
    defaultMessage: 'Edit',
  },
})

export default messages
