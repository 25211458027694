import { SubType } from '../../../../types/common'
import { isLocaleEquals } from '../../../utils'
import { IExtendedRole, IRole } from './types'

export const compareRole = (a: IRole, b: IRole) => {
  const result = isLocaleEquals(a.name, b.name) && isLocaleEquals(a.description, b.description)
  return result
}

export const isPureUserDefined = (role: IExtendedRole) =>
  SubType.UserDefined === role.subType && !role.isCloneOfSysmtemDefined
