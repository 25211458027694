import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../../app/redux-fetch'
import { SubType } from '../../../types/common'
import { HttpAction } from '../../enums'
import { CUSTOMER_SETTINGS_REDUCER } from '../consts'
import { getSubType, isPureUserDefined, matchCurrencies } from '../utils'
import { ICurrencyRow } from './Currency/types'
import { CUSTOMER_SETTINGS_ALL_CURRENCIES_REDUCER_NAME } from './consts'
import { ICurrency } from './types'

export const fetchAllCustomerSettingsCurrenciesSelector = makeTypedFetchSelectors<ICurrency[]>(
  CUSTOMER_SETTINGS_REDUCER,
  CUSTOMER_SETTINGS_ALL_CURRENCIES_REDUCER_NAME
)

export const fetchAllCustomerSettingsSortedCurrenciesSelector = (defaultCurrencyId?: number) =>
  createSelector(
    fetchAllCustomerSettingsCurrenciesSelector.data,
    (currencies: ICurrency[] | null) => {
      if (!currencies?.length) return []
      const allCurrencies = currencies.map((currency) => ({
        ...currency,
        entity: 'currency',
        dirty: false,
        subType: getSubType(currency.customerId),
        isCloneOfSysmtemDefined: false,
        isPureUserDefined: false,
        canDelete: false,
        isDefalutCurrency: currency.id === defaultCurrencyId ? true : false,
        httpAction: HttpAction.NONE,
      })) as ICurrencyRow[]

      // Only pure user defined currencies can be deleted
      allCurrencies.forEach((c) => {
        if (c.subType === SubType.UserDefined && c.enabled) {
          c.canDelete = true
        }
      })

      type SortedCurrencies = typeof allCurrencies

      const duplicateSystemDefinedCurrencies = allCurrencies
        .map((c) => c)
        .reduce((acc: SortedCurrencies, curr, index, array) => {
          const found = array.filter((a) => matchCurrencies(a, curr))
          if (found && found.length > 1) {
            const systemDefined = found.find((f) => f.subType === SubType.SystemDefined)
            if (systemDefined) {
              const exists = acc.find((a) => matchCurrencies(a, systemDefined))
              if (exists) return acc
              acc.push(systemDefined)
              return acc
            }
          }
          return acc
        }, [] as SortedCurrencies)

      // Only pure user defined currencies can be deleted
      allCurrencies.forEach((c) => {
        if (c.subType === SubType.SystemDefined) return
        const exists = duplicateSystemDefinedCurrencies.find((a) => matchCurrencies(a, c))
        if (exists) {
          c.isCloneOfSysmtemDefined = true
          c.canDelete = false
        }
      })

      const currenciesSortedByName = allCurrencies
        .filter((c) => !duplicateSystemDefinedCurrencies.includes(c))
        .sort((a, b) => (a.name > b.name ? 1 : -1))

      const pureUserDefinedCurrencies = currenciesSortedByName
        .filter((currency) => isPureUserDefined(currency))
        .sort((a, b) => (a.name > b.name ? 1 : -1))

      pureUserDefinedCurrencies.forEach((c) => (c.isPureUserDefined = true))

      const allOtherCurrencies = currenciesSortedByName.filter(
        (currency) => !isPureUserDefined(currency)
      )

      const sortedCurrencies = pureUserDefinedCurrencies.concat(allOtherCurrencies)

      return sortedCurrencies as ICurrencyRow[]
    }
  )
