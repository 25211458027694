import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/dist/query'
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import Barcode from 'react-barcode'
import { useIntl } from 'react-intl'
import QRCode from 'react-qr-code'
import { useCreateTrackingNumberTemplateMutation } from '../../../app/redux-fetch/apiQuery'
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import MaskedInputComponent from './MaskedInputComponent'
import SerialNumberInput from './SerialNumberInput'
import messages from './messages'
import { ITrackingNumberParameterType, ITrackingNumberTemplate } from './types'

export interface ITrackingNumberFormProps {
  value: ITrackingNumberTemplate
  tenantId: number
  paramsTypes: ITrackingNumberParameterType[]
  isDisabled: boolean
  refetch: () => QueryActionCreatorResult<
    QueryDefinition<
      {
        tenantId: number
      },
      BaseQueryFn<
        {
          url: string
          method: string
          domain: string
          body?: any
        },
        unknown,
        unknown
      >,
      | 'Shipments'
      | 'Assets'
      | 'Loads'
      | 'Locations'
      | 'Journeys'
      | 'Journey'
      | 'AssetGroup'
      | 'TrackingNumberParameterTypes'
      | 'TrackingNumberTemplate',
      ITrackingNumberTemplate,
      'api'
    >
  >
}
const TrackingNumberForm = (props: ITrackingNumberFormProps) => {
  const { value, isDisabled, paramsTypes, tenantId, refetch } = props
  const { formatMessage } = useIntl()

  const [createTemplate] = useCreateTrackingNumberTemplateMutation()
  const formik = useFormik<ITrackingNumberTemplate>({
    initialValues: value,
    enableReinitialize: true,
    onSubmit: async (values) => {
      values.name = 'Template'
      values.separator = '-'
      values.tenantId = tenantId
      const serialNumber = values.trackingNumberParameters.find((tnp) => tnp.typeId === 1)
      if (serialNumber && !serialNumber.value) {
        serialNumber.value = '0'
      }
      if (values.id) {
        throw Error('Tracking number can not be updated')
      } else {
        await createTemplate({ tenantId: tenantId, body: values })
      }
      refetch()
    },
  })

  const getTrackingNumberTypes = (showAll?: boolean) => {
    let result = paramsTypes.filter((pt) => pt.id !== 1)
    if (showAll) {
      result = paramsTypes
    }
    return result
  }

  const handleChange = (id: any, idx: number) => {
    formik.setFieldValue(`trackingNumberParameters.${idx}.typeId`, id)
  }
  const isSerialNumberSelected = () => {
    return !formik.values.trackingNumberParameters.some((x) => x.typeId === 1)
  }
  const getTrackingNumberExample = useCallback(() => {
    const result = formik.values.trackingNumberParameters.reduce((acc, curr) => {
      if (curr.typeId === 4) {
        return `${acc} ${new Date().getFullYear()}`
      }
      if (curr.typeId === 5) {
        return `${acc} ${('0' + (new Date().getMonth() + 1)).slice(-2)}`
      }
      if (curr.typeId === 6) {
        return `${acc} ${('0' + new Date().getDate()).slice(-2)}`
      }

      return `${acc} ${curr.value ?? ''}`
    }, '')
    return result.trim()
  }, [formik.values.trackingNumberParameters])
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false)

  return (
    <>
      <ConfirmDialog
        open={isConfigDialogOpen}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogContent)}
        continueButtonText={formatMessage(messages.confirmDialogYes)}
        discardButtonText={formatMessage(messages.confirmDialogNo)}
        onContinueEdit={() => {
          formik.submitForm()
        }}
        onDiscardChanges={() => {
          setIsConfigDialogOpen(false)
        }}
      />
      <Grid container gap={2} sx={{ pt: '32px' }}>
        {formik.values.trackingNumberParameters.map((x, idx) => {
          return (
            <Grid xs={1.7} key={idx}>
              <FormDropdown
                items={getTrackingNumberTypes(x.typeId === 1 || isSerialNumberSelected())}
                label={paramsTypes.find((t) => t.id === x.id)?.name ?? ''}
                id={x.typeId.toString()}
                disabled={isDisabled}
                onChange={(e, id, newValue) => {
                  handleChange(newValue, idx)
                }}
                value={x.typeId}
                sx={{
                  paddingBottom: '16px',
                }}
              />
              {x.typeId === 1 && !isDisabled && (
                <SerialNumberInput
                  customInput={TextField}
                  disabled={isDisabled}
                  maxLength={30}
                  inputProps={{ maxLength: 30 }}
                  label={paramsTypes.find((t) => t.id === x.id)?.name ?? ''}
                  name={`trackingNumberParameters.${idx}.value`}
                  id={`trackingNumberParameters.${idx}.value`}
                  onBlur={formik.handleBlur}
                  onValueChange={(vals: any) => {
                    formik.setFieldValue(
                      `trackingNumberParameters.${idx}.value`,
                      vals.floatValue
                        ? vals.floatValue.toString().padStart(8, '0')
                        : ''.toString().padStart(8, '0')
                    )
                  }}
                  value={
                    x.value ? x.value.toString().padStart(8, '0') : ''.toString().padStart(8, '0')
                  }
                  fullWidth
                />
              )}
              {x.typeId === 1 && isDisabled && (
                <TextField
                  disabled={isDisabled}
                  label={paramsTypes.find((t) => t.id === x.id)?.name ?? ''}
                  name={`trackingNumberParameters.${idx}.value`}
                  id={`trackingNumberParameters.${idx}.value`}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={x.value}
                  InputProps={{
                    inputComponent: MaskedInputComponent as any,
                  }}
                  fullWidth
                />
              )}

              {x.typeId === 3 && (
                <TextField
                  fullWidth
                  disabled={isDisabled}
                  inputProps={{ maxLength: 4 }}
                  label={paramsTypes.find((t) => t.id === x.id)?.name ?? ''}
                  name={`trackingNumberParameters.${idx}.value`}
                  id={`trackingNumberParameters.${idx}.value`}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={x.value}
                />
              )}
            </Grid>
          )
        })}
        {!isDisabled && (
          <Grid xs={2}>
            <Button
              color='primary'
              variant='contained'
              fullWidth
              disabled={formik.isSubmitting}
              onClick={() => {
                setIsConfigDialogOpen(true)
              }}
            >
              {formatMessage(messages.save)}
            </Button>
          </Grid>
        )}
        <Grid xs={10}></Grid>
        {getTrackingNumberExample() && getTrackingNumberExample().length > 0 && (
          <>
            <Grid xs={6}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Typography variant='h6'>{formatMessage(messages.barcode)}</Typography>
                <Barcode value={getTrackingNumberExample()} />
              </Box>
            </Grid>
            <Grid xs={3}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Typography variant='h6' sx={{ paddingBottom: '10px' }}>
                  {formatMessage(messages.qrCode)}
                </Typography>
                <QRCode value={getTrackingNumberExample()} style={{ paddingBottom: '10px' }} />
                <Typography>{getTrackingNumberExample()}</Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default TrackingNumberForm
