import { IconButton, Tooltip } from '@mui/material'
import { useMemo, type ReactElement } from 'react'
// eslint-disable-next-line
import { Delete, Edit } from '@mui/icons-material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import logger from '../../../app/middleware/log'
import MRTDataGrid from '../../../components/MRTDataGrid/MRTDataGrid'
import { messages } from './messages'

const JourneyTable = ({ transportLegs }: any): ReactElement<any, any> => {
  // assetLoadAllocations.route
  const columns = useMemo<MRTColumnDef<any>[]>(() => {
    return [
      {
        accessorFn: (row) => {
          return row.legs && row.legs.length > 0
            ? row.legs.reduce((acc: any, curr: any) => {
              if (!curr?.carrier?.name) return acc
              return `${acc ? `${acc}, ` : ''}${curr.carrier.name}`
            }, '')
            : ''
        },
        header: 'Carrier(s)',
        size: 80,
        maxSize: 20,
      },
    ]
  }, [])

  return (
    <MRTDataGrid
      heading={messages.journeysTableTitle}
      columns={columns as any}
      data={transportLegs || []}
      getRowId={(row: any) => row.id}
      enableEditing
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          size: 20,
          maxSize: 20,
        },
      }}
      renderRowActions={({ row, table }) => {
        return (
          <>
            <Tooltip arrow placement='left' title='Edit'>
              <IconButton
                color='info'
              // onClick={() => navigate(`/transport/${row.original.id}/edit`)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement='right' title='Delete'>
              <IconButton color='info' onClick={() => logger.log('Delete')}>
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      }}
    />
  )
}

export default JourneyTable
