import { Box, Link, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import {
  useGetCustomersQuery,
  useGetProductCategoriesQuery,
} from '../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../app/types'
import AddButton from '../../../components/Buttons/AddButton'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import MRTDataGridv2 from '../../../components/MRTDataGrid/MRTDataGridv2'
import SideModalWrapper from '../../AssetsManagement/modals/SideModalWrapper'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { useProductTabContext } from '../ProductTabContext'
import { IProductCategory } from '../types'
import CreateProductCategoryModal from './CreateProductCategoryModal'
import ProductCategoryModal from './ProductCategoryModal'
import messages from './messages'

const ProductCategoryTable = () => {
  const { formatMessage } = useIntl()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const isCustomerUser = loggedInUser.customerId !== undefined

  const { customerId: sharedCustomerId, setCustomerId } = useProductTabContext()
  const { data: customers } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)

  const {
    data: productCategories,
    error,
    isLoading,
  } = useGetProductCategoriesQuery(sharedCustomerId)

  const columns = useMemo<MRTColumnDef<IProductCategory>[]>(() => {
    return [
      {
        accessorFn: (row) => {
          return <Typography sx={{ fontWeight: 700 }}>{row.name}</Typography>
        },
        header: formatMessage(messages.name),
      },
      {
        accessorFn: (row) => {
          const code = row.hsSubheading?.subheadingCode ?? ''
          const modifiedCode = `${code.slice(0, -2)} ${code.slice(-2)}`
          return modifiedCode
        },
        header: formatMessage(messages.hsCode),
      },
    ]
  }, [productCategories])

  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [selectedProductCategoryId, setSelectedProductCategoryId] = useState(-1)
  return (
    <>
      <SideModalWrapper
        open={createOpen}
        key={'create'}
        headerLable={formatMessage(messages.createEditProductCategory)}
        onClose={() => {
          setCreateOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <CreateProductCategoryModal />
      </SideModalWrapper>
      <SideModalWrapper
        open={editOpen}
        key={'edit'}
        headerLable={formatMessage(messages.createEditProductCategory)}
        onClose={() => {
          setEditOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <ProductCategoryModal productCategoryId={selectedProductCategoryId} />
      </SideModalWrapper>
      <SideModalWrapper
        open={viewOpen}
        key={'view'}
        headerLable={formatMessage(messages.viewProductCategory)}
        onClose={() => {
          setViewOpen((pv) => {
            return !pv
          })
        }}
        withBottomBox={false}
      >
        <ProductCategoryModal productCategoryId={selectedProductCategoryId} isDisabled={true} />
      </SideModalWrapper>
      <MRTDataGridv2
        state={{ isLoading: isLoading }}
        leftHeadingComponent={
          isCustomerUser ? (
            <></>
          ) : (
            <>
              <FormDropdown
                id='customerId'
                items={customers as IDropDownItem[]}
                onChange={(e: any, name: any, newValue: any) => {
                  setCustomerId && setCustomerId(newValue)
                }}
                sx={{ width: '200px' }}
                value={sharedCustomerId}
                label='Customer'
              />
            </>
          )
        }
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newProductCategory)}
            onClick={() => setCreateOpen(true)}
            disabled={!sharedCustomerId || sharedCustomerId < 1}
          />
        }
        columns={columns}
        data={productCategories || []}
        getRowId={(row) => row.id as any}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row, table }: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <Link
                  underline='none'
                  color='secondary'
                  key={row.id}
                  onClick={() => {
                    setSelectedProductCategoryId(row.id)
                    setEditOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.editLink)}
                </Link>
                <Typography color='secondary'>|</Typography>
                <Link
                  underline='none'
                  color='secondary'
                  key={row.id}
                  onClick={() => {
                    setSelectedProductCategoryId(row.id)
                    setViewOpen(true)
                  }}
                  sx={{
                    fontWeight: 700,
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(messages.viewLink)}
                </Link>
              </Box>
            </>
          )
        }}
      />
    </>
  )
}

export default ProductCategoryTable
