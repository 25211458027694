import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, Typography } from '@mui/material'
import { mergeAll } from 'ramda'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { useAppSelector } from '../../../app/hooks'
import { useGetAllSubHsHeadingsQuery } from '../../../app/redux-fetch/apiQuery'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../components/Typographies/styles'
import { fetchAllCountriesSelector } from '../../Shipments/selectors'
import { IHsLocalTariff, IHsSubheading } from '../types'
import { useProductCategoryFormContext } from './hooks/useProductCategoryFormContext'
import messages from './messages'
import { getHsLocalTariffValidationSchema } from './validatationSchemas'


export interface IHsLocalTariffModalProps {
    isOpen: boolean
    hsLocalTariffToEdit?: IHsLocalTariff
    handleSubmit?: (hsLocalTariff: IHsLocalTariff) => void
    handleCancel: () => void
}

const HsLocalTariffModal = ({
    hsLocalTariffToEdit: hsLocalTariffToEdit,
    isOpen,
    handleSubmit: handleClose,
    handleCancel,
}: IHsLocalTariffModalProps) => {
    const { formatMessage } = useIntl()
    const [hsLocalTariff, setHsLocalTariff] = useState(hsLocalTariffToEdit ?? {})
    const { values } = useProductCategoryFormContext()
    const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
    const [shouldValidate, setShouldValidate] = useState(false)
    const hsLocalTariffSchema = getHsLocalTariffValidationSchema(formatMessage)
    const isDisabled = !handleClose
    const { data: hsSubheadingItems = [] as IHsSubheading[] } = useGetAllSubHsHeadingsQuery()
    const hsSubheading = hsSubheadingItems.find((x) => x.id === values?.hsSubheadingId)?.subheadingCode ?? ''
    const removeSubHeading = (localTariff: string): string => {
        if (hsLocalTariff?.classificationCode?.substring(0, hsSubheading.length) === hsSubheading) {
            return localTariff.substring(hsSubheading.length, hsLocalTariff?.classificationCode?.length);
        }
        return localTariff;
    }
    const validateHsLocalTariff = (hsLocalTariff: IHsLocalTariff, shouldValidate: boolean) => {
        try {
            shouldValidate && hsLocalTariffSchema.validateSync(hsLocalTariff, { abortEarly: false })
            setErrors({})
        } catch (err: any) {
            if (err.name === 'ValidationError') {
                const errs = mergeAll(err.inner.flatMap((e: any) => ({ [e.path]: e.errors })))
                setErrors(errs)
            }
        }
    }
    const handleSubmit = async () => {
        setShouldValidate(true)
        validateHsLocalTariff(hsLocalTariff, true)
        const isValid = await hsLocalTariffSchema.isValid(hsLocalTariff)
        if (isValid) {
            if(!hsLocalTariff.classificationCode || hsLocalTariff.classificationCode.substring(0, hsSubheading.length) != hsSubheading) {
                hsLocalTariff.classificationCode = hsSubheading + (hsLocalTariff.classificationCode ?? '');
            }
            isValid && handleClose && handleClose(hsLocalTariff)
            setHsLocalTariff({})
            setErrors({})
        }
    }
    const handleCancelBtn = () => {
        setErrors({})
        handleCancel()
    }
    useEffect(() => {
        if (hsLocalTariffToEdit) {
            setHsLocalTariff(hsLocalTariffToEdit)
        } else {
            setHsLocalTariff({})
        }
    }, [hsLocalTariffToEdit])
    const [errors, setErrors] = useState<any>([])

    useEffect(() => {
        validateHsLocalTariff(hsLocalTariff, shouldValidate)
    }, [hsLocalTariff, shouldValidate])


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.id === 'classificationCode'){
            if (removeSubHeading(event.target.value).length > 6) {
                return;
            }
            if (isNaN(Number(event.target.value)) || event.target.value.indexOf('.') > -1){
                return;
            }
        }
        setHsLocalTariff({ ...hsLocalTariff, [event.target.name]: event.target.value })
    }

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: '20px',
                        }}
                    >
                        {isDisabled ? formatMessage(messages.localTariffLbl) : formatMessage(messages.editTariffLbl)}
                    </Typography>
                </DialogTitle>
                <DialogContent sx={{ minWidth: '450px' }}>

                    <Box sx={{ mb: '16px' }}>
                        <Box sx={{ mb: '16px' }}>
                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                {formatMessage(messages.description)}
                                <RequiredAsterisk />
                            </SectionLabelTypography>
                            <TextField
                                fullWidth
                                id={'description'}
                                name={'description'}
                                value={hsLocalTariff?.description ?? ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                helperText={errors?.description}
                                error={Boolean(errors?.description)}
                            />
                        </Box>
                        <Box sx={{ mb: '16px' }}>
                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                {formatMessage(messages.classificationCode)}
                            </SectionLabelTypography>
                            <TextField
                                fullWidth
                                id={'classificationCode'}
                                name={'classificationCode'}
                                onChange={handleChange}
                                value={removeSubHeading(hsLocalTariff?.classificationCode ?? '')}
                                helperText={errors?.classificationCode}
                                disabled={isDisabled}
                                error={Boolean(errors?.classificationCode)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">{hsSubheading}</InputAdornment>
                                }}
                            />
                        </Box>
                        <Box sx={{ mb: '16px' }}>
                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                {formatMessage(messages.tariff)}
                                <RequiredAsterisk />
                            </SectionLabelTypography>
                            <NumericFormat
                                customInput={TextField}
                                fullWidth={true}
                                decimalScale={4}
                                id={'tariff'}
                                name={'tariff'}
                                thousandSeparator={true}
                                type='text'
                                rows={4}
                                allowNegative={false}
                                onValueChange={(vals) => {
                                    setHsLocalTariff({ ...hsLocalTariff, tariff: vals.floatValue })
                                }}
                                value={hsLocalTariff.tariff}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 11 }}
                                disabled={isDisabled}
                                error={Boolean(errors?.tariff)}
                                helperText={errors?.tariff}
                            />
                        </Box>

                        <Box sx={{ mb: '16px' }}>
                            <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                                {formatMessage(messages.country)}
                                <RequiredAsterisk />
                            </SectionLabelTypography>
                            <FormDropdown
                                id={`${name}.contactTypeId`}
                                items={countries}
                                disabled={isDisabled}
                                onChange={(e, name, newValue) => {
                                    setHsLocalTariff({ ...hsLocalTariff, countryId: newValue })
                                }}
                                value={countries.find((x) => x.id === hsLocalTariff?.countryId ?? -1)?.id}
                                error={Boolean(errors?.contactTypeId)}
                                errorText={errors?.contactTypeId}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' variant='outlined' onClick={handleCancelBtn}>
                        {formatMessage(messages.cancelBtn)}
                    </Button>
                    {!isDisabled && (
                        <Button color='secondary' variant='contained' onClick={handleSubmit}>
                            {hsLocalTariffToEdit ? formatMessage(messages.updateBtn) : formatMessage(messages.addBtn)}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}

export default HsLocalTariffModal
