import { Box, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import TabPanel from '../../../../components/TabPanel/TabPanel'
import UserGroupSettings from '../../../CommonSettings/UserGroupSettings/UserGroupSettings'
import UserSettings from '../../../CommonSettings/UserSettings/UserSettings'

const UserManagmentTab = () => {
  const { formatMessage } = useIntl()

  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  let theme = useTheme()

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <ThemeProvider theme={theme}>
          <Tabs
            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
            value={activeTab}
            onChange={handleChange}
            centered
            variant='standard'
            scrollButtons={false}
            aria-label='Tenant settings scrollable tabs'
          >
            <Tab label='Users' />
            <Tab label='Users Groups' />
          </Tabs>
        </ThemeProvider>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Grid item xs={12}>
          <UserSettings />
        </Grid>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Grid item xs={12}>
          <UserGroupSettings />
        </Grid>
      </TabPanel>
    </>
  )
}

export default UserManagmentTab
