import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Checkbox,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material'
import Menu from '@mui/material/Menu'
import { FormikProps } from 'formik'
import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import CenteredBox from '../../../../components/Layout/CenterBox'
import LeftBox from '../../../../components/Layout/LeftBox'
import { ILocationByTenantCustomer } from '../../../Shipments/types'
import { IJourneyLegRoutesLoad, IJourneyLegRoutesLocation, ITransportJourney } from '../../types'
import { messages } from './messages'

export interface ILocationLoadProps {
  location: IJourneyLegRoutesLocation
  loads: IJourneyLegRoutesLoad[]
  formik: FormikProps<ITransportJourney>
  legIdx: number
  rIdx: number
  loadRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>
  locations: ILocationByTenantCustomer[]
}

const LocationLoad = (props: ILocationLoadProps): JSX.Element => {
  const { loads, locations, location, formik, legIdx, rIdx, loadRefs } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const [currRef, _] = useState(React.createRef<HTMLDivElement>())

  const currentIndex = loadRefs.current.findIndex((x) => x === currRef)

  if (currentIndex === -1) {
    loadRefs.current = [...loadRefs.current, currRef]
  }
  const getContacts = useCallback(
    (locationId: number | undefined) => {
      return (
        locations
          .find((l) => l.id === locationId)
          ?.contacts?.map((c) => {
            return { id: c.id ?? -1, name: c.name ?? '' }
          }) ?? []
      )
    },
    [location]
  )
  const { formatMessage } = useIntl()

  const handleClick = useCallback((event: any) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target
    formik.setFieldValue(id, checked ? location.locationId : undefined)
  }, [location.locationId])

  const handleChangeContact = useCallback((e: any, name: string, newValue: any) => {
    formik.setFieldValue(name, newValue)
  }, [])

  const handleRemoveLoadFromRoute = useCallback((loadId: number) => {
    const loadIdx = loads.findIndex((l) => l.id === loadId)
    loads.splice(loadIdx, 1)
    formik.setFieldValue(`legs.${legIdx}.routes.${rIdx}.loads`, loads)
    setAnchorEl(null)
  }, [])

  const renderPickupRow = useCallback(
    (load: IJourneyLegRoutesLoad, loadIdx: number): JSX.Element => {
      const isChecked = Boolean(load.pickupRouteLocationId) && load.pickupRouteLocationId === location.locationId
      const isDisabled = Boolean(load.dropoffRouteLocationId) && load.dropoffRouteLocationId === location.locationId

      return (
        <FormControl sx={{ width: '20px' }}>
          <Checkbox
            id={`legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.pickupRouteLocationId`}
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleCheckboxChange}
          />
        </FormControl>
      )
    },
    [location.locationId]
  )

  const renderDropoffRow = useCallback(
    (load: IJourneyLegRoutesLoad, loadIdx: number): JSX.Element => {
      const isChecked = Boolean(load.dropoffRouteLocationId) && load.dropoffRouteLocationId === location.locationId
      const isDisabled = Boolean(load.pickupRouteLocationId) && load.pickupRouteLocationId === location.locationId

      return (
        <FormControl sx={{ width: '20px' }}>
          <Checkbox
            id={`legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}.dropoffRouteLocationId`}
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleCheckboxChange}
          />
        </FormControl>
      )
    },
    [location.locationId]
  )

  const renderContactRow = useCallback(
    (load: IJourneyLegRoutesLoad, loadIdx: number): JSX.Element => {
      const isPickup = load.pickupRouteLocationId === location.locationId
      const isDropoff = load.dropoffRouteLocationId === location.locationId

      const contacts = getContacts(location.locationId)

      return (
        <FormDropdown
          id={`legs.${legIdx}.routes.${rIdx}.loads.${loadIdx}`}
          label={formatMessage(messages.selectContact)}
          value={isPickup ? load.pickupNotifyPartyId : load.deliveryNotifyPartyId}
          items={contacts}
          disabled={!isPickup && !isDropoff}
          onChange={(event: any, name: string, value: any): void => {
            const notifyIdName = isPickup
              ? `${name}.pickupNotifyPartyId`
              : `${name}.deliveryNotifyPartyId`
            handleChangeContact(event, notifyIdName, value)
          }}
          size='small'
        />
      )
    },
    [location, getContacts]
  )
  const handleScroll = (index: number) => {
    if (loadRefs.current[index]) {
      const scrollPos = loadRefs.current[index].current?.scrollTop
      if (typeof scrollPos === 'number') {
        loadRefs.current.forEach((ref, idx) => {
          if (idx !== index && ref.current) {
            ref.current.scrollTop = scrollPos
          }
        })
      }
    }
  }
  return (
    <Paper
      ref={loadRefs.current[currentIndex]}
      onScroll={() => handleScroll(currentIndex)}
      elevation={3}
      sx={{ maxHeight: '222px', overflow: 'auto', padding: '8px' }}
    >
      <Grid container rowSpacing={1}>
        {/* Headers */}
        <Grid item xs={1}>
          {/* Menu header */}
        </Grid>
        <Grid item xs={3}>
          <CenteredBox>
            <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
              {formatMessage(messages.load)}
            </Typography>
          </CenteredBox>
        </Grid>
        <Grid item xs={2.5}>
          <LeftBox>
            <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
              {formatMessage(messages.pickup)}
            </Typography>
          </LeftBox>
        </Grid>
        <Grid item xs={3.5}>
          <LeftBox>
            <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
              {formatMessage(messages.dropoff)}
            </Typography>
          </LeftBox>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={12}>
          <Divider style={{ marginBottom: '16px' }} />
        </Grid>
        {/* Rows of content */}
        {loads.map((load, rowIndex) => (
          <React.Fragment key={rowIndex}>
            <Grid item xs={1}>
              <CenteredBox>
                <IconButton
                  aria-label='more'
                  aria-controls='long-menu'
                  aria-haspopup='true'
                  onClick={handleClick}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id='long-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem key='delete' onClick={() => handleRemoveLoadFromRoute(rowIndex)}>
                    {formatMessage(messages.removeLoadFromRoute)}
                  </MenuItem>
                </Menu>
              </CenteredBox>
            </Grid>
            <Grid item xs={3}>
              <CenteredBox>
                <Typography noWrap>{load.name}</Typography>
              </CenteredBox>
            </Grid>
            <Grid item xs={2}>
              <CenteredBox>{renderPickupRow(load, rowIndex)}</CenteredBox>
            </Grid>
            <Grid item xs={2}>
              <CenteredBox>{renderDropoffRow(load, rowIndex)}</CenteredBox>
            </Grid>
            <Grid item xs={4}>
              <CenteredBox>{renderContactRow(load, rowIndex)}</CenteredBox>
            </Grid>
            {rowIndex < loads.length - 1 && (
              <Grid item xs={12}>
                <Divider style={{ margin: '8px' }} />
              </Grid>
            )}
          </React.Fragment>
        ))}
        {loads.length === 0 && (
          <Grid item xs={12}>
            <CenteredBox>
              <Typography variant='subtitle1' sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {formatMessage(messages.noLoads)}
              </Typography>
            </CenteredBox>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default LocationLoad
