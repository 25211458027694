import { Box, TextField } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useIntl } from 'react-intl'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../components/Typographies/styles'
import ConditionalRender from '../../Shipments/components/EditShipment/Components/ConditionalRender'
import messages from './messages'
import { getError } from './utils'

type MainInfoProps = {
    disabled?: boolean
    type: 'create' | 'view' | 'edit'
    formik: any
    updateMarker?: any
    customers?: any
    isTenantUser?: boolean
}

const MainInfo = ({ disabled = false, type, formik, updateMarker, customers, isTenantUser }: MainInfoProps): ReactElement<any, any> => {
    const [isCustomerSelected] = useState(formik.values.customerId)
    const { formatMessage } = useIntl()

    if (updateMarker) {
        updateMarker({ lat: formik.values.lat, lng: formik.values.lng })
    }

    const isCustomerIdNotEditable = type === 'edit'

    return (
        <Box sx={{ mb: '16px', width: '75%' }}>
            <Box sx={{ mb: '16px' }}>
                <SectionTitleTypography >{formatMessage(messages.mainInfo)}</SectionTitleTypography>
            </Box>
            <Box sx={{ mb: '8px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.name)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={disabled}
                    fullWidth
                    id='name'
                    name='name'
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={getError('name', formik)}
                />
            </Box>
            <ConditionalRender condition={isTenantUser && (type !== 'edit' || (type === 'edit' && formik.values.customerId))}>
                <Box >
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.customerLabel)}</SectionLabelTypography>
                    <FormDropdown
                        id='customerId'
                        disabled={disabled || isCustomerIdNotEditable}
                        onChange={(e, name, newValue) => {
                            formik.setFieldValue(name, newValue)
                        }}
                        value={formik.values.customerId}
                        items={customers}
                        handleOnBlur={formik.handleBlur}
                        errorText={isCustomerIdNotEditable ? '' : formatMessage(messages.customerOptionalLabel)}
                    />
                </Box>
            </ConditionalRender>
        </Box>
    )
}

export default MainInfo