import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { MRT_Row as MRTRow } from 'material-react-table';
import { IAssetManagement } from '../../types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../messages';
import { SubmitButton } from '../../../components/Buttons/SubmitButton';
import { useDeleteAssetMutation } from '../../../app/redux-fetch/apiQuery';
import { useNotificationStack } from '../../../app/hooks/useNotificationStack';

interface DeleteAssetManagementModalProps {
  onClose: () => void
  row: MRTRow<Partial<IAssetManagement>>;
  columnDef: { [key: string]: string };
}
export function DeleteAssetManagementModal({
  onClose,
  row,
  columnDef,
}: DeleteAssetManagementModalProps) {

  const { tenantId = 0, id: assetId = 0 } = row.original;
  const { enqueueSuccess, enqueueFailure } = useNotificationStack();
  const { formatMessage } = useIntl();


  const [deleteAssetConfiguration, { isSuccess, isUninitialized, isLoading, isError }] = useDeleteAssetMutation();
  useEffect(() => {
    if (!isUninitialized && isSuccess) {
      onClose();
      enqueueSuccess(messages.deleteSuccessful);
    } else if (!isUninitialized && isError) {
      onClose();
      enqueueFailure(messages.deleteFailed);
    }
  }, [isUninitialized, isSuccess, onClose]);

  const submit = () => {
    deleteAssetConfiguration({ tenantId, assetId });
  }

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{formatMessage(messages.deleteModalTitle)}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          <TextField
            label={columnDef['name']}
            name="name"
            value={row.original.identification}
            defaultValue={row.original.identification}
            disabled
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose} disabled={isLoading}>{formatMessage(messages.cancelAssetBtn)}</Button>
        <SubmitButton
          btnText={formatMessage(messages.deleteAssetBtn)}
          btnColor='error'
          onClick={submit}
          disabled={isLoading}
          loading={isLoading}
        />
      </DialogActions>
    </Dialog>
  )
}
