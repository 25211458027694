import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  shipmentSummary: {
    id: 'shipmentSummaryHeader.shipmentSummary',
    defaultMessage: 'Shipment summary',
    description: 'Title for the shipment summary',
  },
  crn: {
    id: 'shipmentSummaryHeader.CRN',
    defaultMessage: 'Customer Reference Number',
    description: 'Customer Reference Number',
  },
  customer: {
    id: 'shipmentSummaryHeader.customer',
    defaultMessage: 'Customer: {value}',
    description: 'Customer label',
  },
  shipper: {
    id: 'shipmentSummaryHeader.shipper',
    defaultMessage: 'Shipper',
    description: 'Entity responsible for shipping',
  },
  description: {
    id: 'shipmentSummaryHeader.description',
    defaultMessage: 'Description',
    description: 'Description of the shipment',
  },
  currency: {
    id: 'shipmentSummaryHeader.currency',
    defaultMessage: 'Cargo Value',
    description: 'Currency used in the transaction',
  },
})
