import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { IAssetType, IUserType } from '../../types';
import { UserTypesAutocomplete } from '../fields/UserTypesAutocomplete';
import { useState } from 'react';
import { userAssetTypeSchema } from '../schemas/userAssetTypeSchema';
import { useIntl } from 'react-intl';
import { SubType } from '../../../../../../types/common';
import { SubmitButton } from '../buttons/SubmitButton';

interface AddAssetTypeMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetType) => void
  dialogTitle: string
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function AddAssetTypeMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  columnDef,
  loading
}: AddAssetTypeMrtModalProps){

  const { formatMessage } = useIntl();
  const [values, setValues] = useState({ subType: SubType.UserDefined } as IAssetType);
  const onChange = (propName: string, value: string | boolean | IUserType[]) => {
      setValues((prev) => ({...prev, [propName]: value }))
  }

  const submit = () => {
    onSubmit(values);
  }
  
  const isValid = userAssetTypeSchema(formatMessage).isValidSync(values);

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
             <TextField
                label={columnDef['name']}
                name="name"
                value={values.name}
                defaultValue={values.name}
                onChange={({target}) => onChange('name', target.value)}
                disabled={loading}
              />
              <FormGroup aria-label="position">
                <FormControlLabel
                    value={values.enabled}
                    control={
                          <Switch 
                            color="primary" 
                            checked={values.enabled} 
                            value={values.enabled}
                            onChange={({target}) => onChange('enabled', target.checked) }
                            disabled={loading}
                          />
                    }
                    label={columnDef['enabled']}
                  />
                <FormControlLabel
                    value={values.isMotorized}
                    label={columnDef['isMotorized']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.isMotorized} 
                        value={values.isMotorized}
                        onChange={({target}) => onChange('isMotorized', target.checked) }
                        disabled={loading}
                      />
                }
                  />
                <FormControlLabel
                    value={values.requiresDriver}
                    label={columnDef['requiresDriver']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.requiresDriver} 
                        value={values.requiresDriver}
                        onChange={({target}) => onChange('requiresDriver', target.checked) }
                        disabled={loading}
                      />
                }
                  />
                <FormControlLabel
                    value={values.sealable}
                    label={columnDef['sealable']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.sealable} 
                        value={values.sealable}
                        onChange={({ target }) => onChange('sealable', target.checked) }
                        disabled={loading}
                      />
                    }
                />
                 <FormControlLabel
                    value={values.sealable}
                    label={columnDef['carriesPayload']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.carriesPayload} 
                        value={values.carriesPayload}
                        onChange={({ target }) => onChange('carriesPayload', target.checked) }
                        disabled={loading}
                      />
                    }
                />
              </FormGroup>
              <UserTypesAutocomplete 
                  defaultValue={values.userTypes} 
                  label={columnDef['userTypes']}
                  onChange={(items:  IUserType[]) => onChange('userTypes', items)}
                  disabled={loading}

                />
          </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton 
          btnText='Save' 
          onClick={submit} 
          disabled={!isValid} 
          loading={loading} 
        />
      </DialogActions>
    </Dialog>
  )
}
