import { defineMessages } from 'react-intl'

const messages = defineMessages({
  usersTableHeading: {
    id: 'customerSettings.users.usersTableHeading',
    description: 'Users settings table heading',
    defaultMessage: 'Users',
  },
})

export default messages
