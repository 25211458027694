import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import {
  fetchAllCountriesSelector,
  fetchAllCustomersByTenantSelector,
  fetchAllMeasureUnitsSelector,
  fetchAllPalletTypesSelector,
  fetchAllPickupConsigneeSelector,
  fetchAllPickupLocationsSelector,
  fetchAllShippersSelector,
  fetchAllTemperatureRangesSelector,
  fetchAllTemperatureUnitsSelector,
  fetchSortedCurrenciesSelector,
} from '../../selectors'
import { ILoad, IShipment } from '../../types'
import ShipmentSummaryHeader from '../Common/ShipmentSummaryHeader/ShimpenSummaryHeader'
import ConditionalRender from '../EditShipment/Components/ConditionalRender'
import { ShipmentActionButtonBehaviour } from '../EditShipment/types'
import ShipmentContainersOrAssetRequestsStep from './ShipmentContainersOrAssetRequestsStep'
import messages from './messages'
import { PalletAccordion, ShipmentSummaryAccordion } from './styles'

export interface ShipmentSummaryProps {
  formik: FormikProps<IShipment>
  isSameWithShipmentCurrency?: boolean
  isSameLoadsCurrency?: boolean
  isSameValue?: boolean
  isTrackingNumberSetup?: boolean
  viewMode?: boolean
  tabMode?: boolean
}
const ShipmentSummary = (props: ShipmentSummaryProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const currencies = useAppSelector(fetchSortedCurrenciesSelector)
  const shippers = useAppSelector(fetchAllShippersSelector)
  const countries = useAppSelector(fetchAllCountriesSelector.data) ?? []
  const customers = useAppSelector(fetchAllCustomersByTenantSelector.data) ?? []
  const pickUpLocations = useAppSelector(fetchAllPickupLocationsSelector)
  const consigneeLocations = useAppSelector(fetchAllPickupConsigneeSelector)
  const tempUnitItems = useAppSelector(fetchAllTemperatureUnitsSelector)
  const temperatureRangesItems = useAppSelector(fetchAllTemperatureRangesSelector.data) ?? []
  const physicalPropertyUnits = useAppSelector(fetchAllMeasureUnitsSelector.data) ?? []
  const palletTypesItems = useAppSelector(fetchAllPalletTypesSelector.data) ?? []
  const {
    formik,
    isSameWithShipmentCurrency,
    isSameLoadsCurrency,
    isSameValue,
    viewMode = false,
  } = props
  const mockFunction = () => {
    return 1
  }
  const shipment = formik.values
  const { shipmentActionButtonBehavior: { showAccept } = {} as ShipmentActionButtonBehaviour } =
    shipment

  const renderRow = (label: string, value: string | number | undefined): JSX.Element => {
    return (
      <>
        <Grid item xs={3}>
          {label}:
        </Grid>
        <Grid item xs={3}>
          {value ?? ''}
        </Grid>
      </>
    )
  }

  const renderMoneyValue = (value: number, currencyId: number) => {
    return `${currencies.find((c) => c.id === currencyId)?.symbol} ${value
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }
  const renderTemperatureUnit = (value: number, unitId: number) => {
    return value ? `${value} ${tempUnitItems.find((t) => t.id === unitId)?.name}` : undefined
  }
  const renderPhysicalPropertyUnit = (value: number, unitId: number) => {
    return value
      ? `${value} ${physicalPropertyUnits.find((t) => t.id === unitId)?.units}`
      : undefined
  }

  const renderLoad = (load: ILoad, idx: number): JSX.Element => {
    return (
      <ShipmentSummaryAccordion>
        <AccordionSummary
          expandIcon={<KeyboardArrowRightIcon />}
          sx={{ flexDirection: 'row-reverse' }}
        >
          <Grid container>
            {' '}
            <Grid item xs={6}>
              {' '}
              Load # {load.displayOrder}
            </Grid>
            <Grid item xs={6}>
              {load.temperatureSetting?.isTemperatureControlled ? (
                <Box display='flex' justifyContent='flex-end'>
                  <Typography sx={{ color: 'red' }}>Temperature Controlled</Typography>
                </Box>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
                {formatMessage(messages.createLoadTitle)}
              </Divider>
            </Grid>
            {renderRow(formatMessage(messages.quantity), load.quantity)}
            {renderRow(formatMessage(messages.description), load.description)}
            {renderRow(
              formatMessage(messages.countryOfOrigin),
              countries.find((c) => c.id === load.countryOfOriginId)?.name
            )}
            {renderRow(
              formatMessage(messages.loadValue),
              renderMoneyValue(load.value ?? 0, load.currencyId ?? 0)
            )}
            <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
              <Divider textAlign='left' sx={{ mt: 0.5, ml: 2 }} color='text.secondary'>
                {formatMessage(messages.transportDetailTitle)}
              </Divider>
            </Grid>
            {renderRow(
              formatMessage(messages.pickUpDate),
              dayjs(load.transportDetail?.pickupDate).format('DD/MM/YYYY')
            )}
            {renderRow(
              formatMessage(messages.pickUpLocation),
              pickUpLocations.find((l) => l.id === load.transportDetail?.pickupLocationId)?.name
            )}
            {renderRow(
              formatMessage(messages.requestedDeliveryLocationStatementLabel),
              load.transportDetail?.deliveryDate
                ? dayjs(load.transportDetail?.deliveryDate).format('DD/MM/YYYY')
                : undefined
            )}
            {renderRow(
              formatMessage(messages.requestedDeliveryLocationLabel),
              consigneeLocations.find((l) => l.id === load.transportDetail?.deliveryLocationId)
                ?.name
            )}
            {load.temperatureSetting && load.temperatureSetting.isTemperatureControlled && (
              <>
                <Grid item xs={12}>
                  <Divider
                    textAlign='left'
                    sx={{ mt: 0.5, ml: 2, pt: 2, pb: 2 }}
                    color='text.secondary'
                  >
                    {formatMessage(messages.temperatureSettings)}
                  </Divider>
                </Grid>
                {renderRow(
                  formatMessage(messages.temperatureRange),
                  temperatureRangesItems.find(
                    (l) => l.id === load.temperatureSetting?.temperatureRangeId
                  )?.name
                )}
                {renderRow(
                  formatMessage(messages.setPoint),
                  renderTemperatureUnit(
                    load.temperatureSetting.setPoint,
                    load.temperatureSetting.temperatureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.lowerThresholdWarning),
                  renderTemperatureUnit(
                    load.temperatureSetting.lowerThresholdWarning,
                    load.temperatureSetting.temperatureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.lowerThresholdCritical),
                  renderTemperatureUnit(
                    load.temperatureSetting.lowerThresholdCritical,
                    load.temperatureSetting.temperatureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.upperThresholdWarning),
                  renderTemperatureUnit(
                    load.temperatureSetting.upperThresholdWarning,
                    load.temperatureSetting.temperatureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.upperThresholdCritical),
                  renderTemperatureUnit(
                    load.temperatureSetting.upperThresholdWarning,
                    load.temperatureSetting.temperatureUnitId
                  )
                )}
              </>
            )}
            {load.physicalProperties?.usePhysicalProperties && (
              <>
                <Grid item xs={12}>
                  <Divider
                    textAlign='left'
                    sx={{ mt: 0.5, ml: 2, pt: 2, pb: 2 }}
                    color='text.secondary'
                  >
                    {formatMessage(messages.physicalProperties)}
                  </Divider>
                </Grid>
                {renderRow(
                  formatMessage(messages.length),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.length,
                    load.physicalProperties.lengthMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.width),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.width,
                    load.physicalProperties.lengthMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.height),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.height,
                    load.physicalProperties.lengthMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.grossWeight),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.grossWeight,
                    load.physicalProperties.weightMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.netWeight),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.netWeight,
                    load.physicalProperties.weightMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.chargeableWeight),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.chargeableWeight,
                    load.physicalProperties.weightMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.volume),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.volume,
                    load.physicalProperties.volumeMeasureUnitId
                  )
                )}
                {renderRow(
                  formatMessage(messages.density),
                  renderPhysicalPropertyUnit(
                    load.physicalProperties.density,
                    load.physicalProperties.densityMeasureUnitId
                  )
                )}
              </>
            )}
            {load.palletDetails &&
              load.palletDetails.length > 0 &&
              load.palletDetails.map((pallet, palletIndex) => {
                return (
                  <>
                    <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
                      <Divider
                        color='rgba (0, 0, 0, 0.125)'
                        sx={{ backgroundColor: 'transparent' }}
                        textAlign='left'
                      >
                        {formatMessage(messages.palletDetails)}
                      </Divider>
                    </Grid>
                    <Grid item xs={12}>
                      {' '}
                      <PalletAccordion>
                        <AccordionSummary>
                          <Typography>
                            {formatMessage(messages.pallet)} # {palletIndex + 1}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            {renderRow(formatMessage(messages.quantity), pallet.quantity)}
                            {renderRow(
                              formatMessage(messages.palletType),
                              palletTypesItems.find((pt) => pt.id === pallet.palletTypeId)?.name
                            )}
                            {renderRow(
                              formatMessage(messages.height),
                              renderPhysicalPropertyUnit(pallet.height, pallet.heightUnitId)
                            )}
                            {renderRow(
                              formatMessage(messages.palletStackable),
                              pallet.isStackable ? 'yes' : 'no'
                            )}
                          </Grid>
                        </AccordionDetails>
                      </PalletAccordion>
                    </Grid>
                  </>
                )
              })}
          </Grid>
        </AccordionDetails>
      </ShipmentSummaryAccordion>
    )
  }
  const isPage2 =
    formik.values.assetRequests &&
    formik.values.assetRequests.length === 0 &&
    formik.values.containerDetails &&
    formik.values.containerDetails.length === 0
  const haveLoads = formik.values.loads.length > 0

  return (
    <>
      {!viewMode && (
        <>
          {!haveLoads && (
            <Grid item xs={12}>
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                You must specify either load(s) and/or asset request(s) before you can submit this
                shipment.
              </Alert>
            </Grid>
          )}
          <Grid container>
            {haveLoads && isSameWithShipmentCurrency && isSameLoadsCurrency && !isSameValue && (
              <Grid item xs={12}>
                <Alert severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  The Value of the shipment must equal the sum of the values of all the loads within
                  the shipment.
                </Alert>
              </Grid>
            )}
            {haveLoads && !isSameLoadsCurrency && (
              <Grid item xs={12}>
                <Alert severity='warning'>
                  <AlertTitle>Warning</AlertTitle>
                  There are multiple currencies used in the loads of this shipment. The value of the
                  shipment cannot be compared to the sum of the values of the loads. Please tick
                  this box to confirm that the information submitted is accurate.
                </Alert>
              </Grid>
            )}
            {haveLoads && !isSameWithShipmentCurrency && (
              <Grid item xs={12}>
                <Alert severity='error'>
                  <AlertTitle>Error</AlertTitle>
                  The Currency of the shipment must not be different from the currencies of the
                  loads if they are all consistent.
                </Alert>
              </Grid>
            )}
          </Grid>
        </>
      )}

      <ConditionalRender condition={!props.tabMode}>
        <ShipmentSummaryHeader formik={formik} pb={'16px'} />
      </ConditionalRender>
      {!viewMode && (
        <Grid container sx={{ pb: '10px' }}>
          {/* <ConditionalRender condition={showAccept}> */}
          {!isSameLoadsCurrency && (
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='acceptWarrnings'
                      name='acceptWarrnings'
                      checked={formik.values.acceptWarrnings}
                      value={formik.values.acceptWarrnings}
                      onChange={formik.handleChange}
                    />
                  }
                  label={formatMessage(messages.acceptWarrnings)}
                />
              </FormGroup>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id='acceptTermsAndCondition'
                    name='acceptTermsAndCondition'
                    checked={formik.values.acceptTermsAndCondition}
                    value={formik.values.acceptTermsAndCondition}
                    onChange={formik.handleChange}
                  />
                }
                label={formatMessage(messages.termsAndConditions)}
              />
            </FormGroup>
          </Grid>
          {/* </ConditionalRender> */}

        </Grid >
      )}
      {
        isPage2 ? (
          shipment.loads.map((load, idx) => {
            return renderLoad(load, idx)
          })
        ) : (
          <ShipmentContainersOrAssetRequestsStep
            formik={formik}
            handleRemoveAssetRequest={mockFunction}
            handleSelectLoadChanges={mockFunction}
            handleSwitchLoadChanges={mockFunction}
            handleFormDropDownChanges={mockFunction}
            disabled
            viewMode={viewMode}
          />
        )
      }
    </>
  )
}
export default ShipmentSummary
