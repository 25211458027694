import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Dialog, DialogProps, IconButton, Slide, Toolbar, Typography } from '@mui/material';

import { ReactNode, useState } from 'react';
import FixedBottomBox from './FixedBottomBox';
import { SideModalContext } from './SideModalContext';

const Transition = (props: any) => {
    return <Slide direction="left" {...props} />;
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        width: '62.5%',
        margin: 0,
        height: '100%', // set to full height
        maxHeight: 'none', // override default max height
        maxWidth: 'none', // override default max height
        right: 0, // anchor to the right side
        top: 0,
        bottom: 0,
        position: 'fixed', // ensure it's positioned correctly
        overflowY: 'auto', // add scrolling if content exceeds height
        borderRadius: 0, // optionally remove border-radius for a flush look
    },
}));

interface SideModalWrapperProps extends DialogProps {
    open: boolean;
    onClose: () => void;
    onDelete?: () => void;
    headerLable: string;
    withBottomBox?: boolean;
    children?: ReactNode;
}

const SideModalWrapper = ({ open, onClose, onDelete, children, headerLable, withBottomBox = true }: SideModalWrapperProps) => {
    const [bottomComponent, setBottomComponent] = useState<ReactNode>(null)

    const value = {
        bottomComponent,
        onClose,
        onDelete,
        setBottomComponent: (component: ReactNode) => setBottomComponent(component),
    }

    return (
        <SideModalContext.Provider value={value}>
            <StyledDialog
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}
                aria-labelledby="custom-dialog-title"
                aria-describedby="custom-dialog-description"
            >
                <AppBar sx={{ position: 'fixed', width: '62.5%' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {headerLable}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {withBottomBox ? (
                    <>
                        <Box sx={{ mt: '48px' }}>
                            {children}
                        </Box>
                        <FixedBottomBox />
                    </>) : (<>{children}</>)}
            </StyledDialog>
        </SideModalContext.Provider>

    );
}

export default SideModalWrapper