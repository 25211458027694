/* eslint-disable react/prop-types */
import { Checkbox, FormControl, Grid } from '@mui/material'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import MaterialReactTable, { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo, type ReactElement } from 'react'
import { IJourneyLegLoad, ITransportJourney } from '../../types'

interface ICreateTransportStepProps {
  formik: FormikProps<ITransportJourney>
}

const JourneyLoadTable = ({ formik }: ICreateTransportStepProps): ReactElement<any, any> => {
  const handleLoadSelected = (event: any, id: any): void => {
    if (!isLoadInRoute(formik.values.loads[id].id)) {
      formik.setFieldValue(`loads[${id}].isSelected`, Boolean(event.target.checked))
    }
  }

  const isLoadInRoute = (loadId: number) =>
    formik.values.legs.some((tl) => tl.routes.some((r) => r.loads.some((rl) => rl.id === loadId)))

  const columns = useMemo<MRTColumnDef<IJourneyLegLoad>[]>(() => {
    return [
      {
        id: 'select',
        header: '',
        enableColumnActions: false,
        size: 32,
        Cell: ({ row }: any) => {
          const currentLoad = formik.values.loads.find((item: any) => item.id === row.original.id)
          return (
            <FormControl sx={{ width: '20px' }}>
              <Checkbox
                id={row.original.id}
                checked={currentLoad && currentLoad.isSelected}
                onChange={(evt) => handleLoadSelected(evt, row.index)}
              />
            </FormControl>
          )
        },
      },
      {
        accessorFn: (row) => row.trackingNumber,
        header: 'Tracking Number',
      },
      {
        accessorFn: (row) => row.customerName,
        header: 'Customer',
      },
      {
        accessorFn: (row) => row.pickupLocationName,
        header: 'Pickup Location',
      },
      {
        accessorFn: (row) => (row.pickupDate ? dayjs(row.pickupDate).format('DD/MM/YYYY') : ''),
        header: 'Pickup Date',
      },
      {
        accessorFn: (row) => row.consignee,
        header: 'Consignee',
      },
      {
        accessorFn: (row) => (row.deliveryDate ? dayjs(row.deliveryDate).format('DD/MM/YYYY') : ''),
        header: 'Delivery Date',
      },
      {
        accessorFn: (row) =>
          row.containerDetails && row.containerDetails.length > 0
            ? row.containerDetails.reduce((acc: any, curr: any) => {
                return `${acc ? `${acc}, ` : ''}${curr.containerNumber}`
              }, '')
            : '',
        header: 'Containter No.',
      },
      {
        accessorFn: (row) => (!row.isDefined ? 'Asset Request' : row.description),
        header: 'Description',
      },
      {
        accessorFn: (row) => row.temperatureRange,
        header: 'Temp. Range',
      },
    ]
  }, [formik.values])
  return (
    <Grid container>
      <Grid item xs={12}>
        <MaterialReactTable
          columns={columns as any}
          data={formik.values.loads}
          autoResetPageIndex={false}
        />
      </Grid>
    </Grid>
  )
}

export default JourneyLoadTable
