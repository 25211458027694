import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { MRT_Row as MRTRow } from 'material-react-table';
import { IAssetType, IUserType } from '../../types';
import { FieldInputProps } from 'formik';
import { useAssetTypeMrtFormContext } from '../hooks/useAssetTypeMrtFormContext';
import { UserTypesAutocomplete } from '../fields/UserTypesAutocomplete';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { userAssetTypeSchema } from '../schemas/userAssetTypeSchema';
import { SubType } from '../../../../../../types/common';
import { SubmitButton } from '../buttons/SubmitButton';
import isEqual from 'lodash/isEqual';

interface EditAssetTypeMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetType) => void
  dialogTitle: string
  row: MRTRow<Partial<IAssetType>>;
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function EditAssetTypeMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  row,
  columnDef,
  loading,
}: EditAssetTypeMrtModalProps){

  const { formatMessage } = useIntl();
  const fieldName = `assetTypes.${row.index}`;
  const { getFieldProps } = useAssetTypeMrtFormContext();
  const field = getFieldProps(fieldName) as FieldInputProps<IAssetType>;
  const [values, setValues] = useState<IAssetType>({...field.value, isCloneOfSysmtemDefined: row.original.isCloneOfSysmtemDefined });

  const isValid = userAssetTypeSchema(formatMessage).isValidSync(values);
  const isSystemDefined = field.value.subType === SubType.SystemDefined || field.value.isCloneOfSysmtemDefined;
  const submitIsDisabled = !isValid || isEqual(values, row.original);

  const onChange = (propName: string, value: string | boolean | IUserType[]) => {
      setValues((prev) => ({...prev, [propName]: value }))
  }

  const submit = () => {
    onSubmit(values);
  }
  
  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
             <TextField
                label={columnDef['name']}
                name="name"
                value={values.name}
                defaultValue={values.name}
                onChange={({target}) => onChange('name', target.value)}
                disabled={isSystemDefined || loading}
              />
              <FormGroup aria-label="position">
                <FormControlLabel
                    value={values.enabled}
                    control={
                          <Switch 
                            color="primary" 
                            checked={values.enabled} 
                            value={values.enabled}
                            onChange={({target}) => onChange('enabled', target.checked) }
                            disabled={loading}
                          />
                    }
                    label={columnDef['enabled']}
                  />
                <FormControlLabel
                    value={values.isMotorized}
                    label={columnDef['isMotorized']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.isMotorized} 
                        value={values.isMotorized}
                        onChange={({target}) => onChange('isMotorized', target.checked) }
                        disabled={isSystemDefined || loading}
                      />
                }
                  />
                <FormControlLabel
                    value={values.requiresDriver}
                    label={columnDef['requiresDriver']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.requiresDriver} 
                        value={values.requiresDriver}
                        onChange={({target}) => onChange('requiresDriver', target.checked) }
                        disabled={isSystemDefined || loading}
                      />
                }
                  />
                <FormControlLabel
                    value={values.sealable}
                    label={columnDef['sealable']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.sealable} 
                        value={values.sealable}
                        onChange={({ target }) => onChange('sealable', target.checked) }
                        disabled={isSystemDefined || loading}
                      />
                  } 
                />
                <FormControlLabel
                    value={values.sealable}
                    label={columnDef['carriesPayload']}
                    control={
                      <Switch 
                        color="primary" 
                        checked={values.carriesPayload} 
                        value={values.carriesPayload}
                        onChange={({ target }) => onChange('carriesPayload', target.checked) }
                        disabled={isSystemDefined || loading}
                      />
                    }
                />
              </FormGroup>
              <UserTypesAutocomplete 
                  defaultValue={values.userTypes} 
                  label={columnDef['userTypes']}
                  onChange={(items:  IUserType[]) => onChange('userTypes', items)}
                  disabled={isSystemDefined || loading}
                />
          </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
         <SubmitButton 
            btnText='Save' 
            onClick={submit} 
            disabled={submitIsDisabled} 
            loading={loading} 
          />
      </DialogActions>
    </Dialog>
  )
}
