import { SubType } from '../../../../types/common'
import { isLocaleEquals } from '../../../utils'
import { IExtendedUserGroup, IUserGroup } from './types'

export const compareUserGroup = (a: IUserGroup, b: IUserGroup) => {
  const result = isLocaleEquals(a.name, b.name) && isLocaleEquals(a.description, b.description)
  return result
}

export const isPureUserDefined = (user: IExtendedUserGroup) =>
  SubType.UserDefined === user.subType && !user.isCloneOfSysmtemDefined
