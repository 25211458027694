import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  assetsCategories: {
    id: 'tenantSettings.assetCategory.title',
    description: 'Assets categories title',
    defaultMessage: 'Asset Categories',
  },
  confirmDialogTitle: {
    id: 'tenantSettings.assetCategory.confirmDialogTitle',
    description: 'Confirm dialog title text',
    defaultMessage: 'You have unsaved changes',
  },
  confirmDialogDiscardSelection: {
    id: 'tenantSettings.assetCategory.confirmDialogDiscardSelection',
    description: 'Confirm dialog discard selection text',
    defaultMessage: 'Discard changes',
  },
  confirmDialogContinueEditing: {
    id: 'tenantSettings.assetCategory.confirmDialogContinueEditing',
    description: 'Continue editing text',
    defaultMessage: 'Continue editing',
  },
  confirmDialogMessageContent: {
    id: 'tenantSettings.assetCategory.confirmDialogMessageContent',
    description: 'Confirm dialog message content',
    defaultMessage:
      'If you change your selection you will lose your changes. Would you like to continue editing or discard your changes?',
  },
  addAssetCategoryTitle: {
    id: 'tenantSettings.assetCategory.addAssetCategoryTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Add Asset Category',
  },
  editAssetCategoryTitle: {
    id: 'tenantSettings.assetCategory.editAssetCategoryTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Edit Asset Category',
  },
  deleteAssetCategoryTitle: {
    id: 'tenantSettings.assetCategory.deleteAssetCategoryTitle',
    description: 'Confirm dialog title',
    defaultMessage: 'Delete Asset Category',
  },
  assetsTransportMode: {
    id: 'tenantSettings.asset.transportModeDropdown',
    description: 'Assets transport mode dropdown title',
    defaultMessage: 'Transport mode',
  },
})

// export default messages
