import { defineMessages } from 'react-intl'

const messages = defineMessages({
  currencySaveSettings: {
    id: 'customerSettings.saveCustomerSettings',
    description: 'Save customer settings',
    defaultMessage: 'Save settings',
  },
  currencyDropdownTitle: {
    id: 'customerSettings.currency.currencyDropdown',
    description: 'Currency dropdown title',
    defaultMessage: 'Default Currency',
  },
  currencyTableHeading: {
    id: 'customerSettings.currency.currencyTableHeading',
    description: 'Currency settings table heading',
    defaultMessage: 'Currencies',
  },
  currencyIso3ColumnHeading: {
    id: 'customerSettings.currency.currencyIso3ColumnHeading',
    description: 'Currency ISO3 column heading',
    defaultMessage: 'ISO3',
  },
  currencyNameColumnHeading: {
    id: 'customerSettings.currency.currencyNameColumnHeading',
    description: 'Currency Name column heading',
    defaultMessage: 'Name',
  },
  currencySymbolColumnHeading: {
    id: 'customerSettings.currency.currencySymbolColumnHeading',
    description: 'Currency Symbol column heading',
    defaultMessage: 'Symbol',
  },
  currencyLocalisedSymbolColumnHeading: {
    id: 'customerSettings.currency.currencyLocalisedSymbolColumnHeading',
    description: 'Currency LocalisedSymbol column heading',
    defaultMessage: 'Localised Symbol',
  },
  currencyDeleteColumnHeading: {
    id: 'customerSettings.currency.currencyDeleteColumnHeading',
    description: 'Currency Delete column heading',
    defaultMessage: 'Delete',
  },
  currencyEnabledColumnHeading: {
    id: 'customerSettings.currency.currencyEnabledColumnHeading',
    description: 'Currency Enabled column heading',
    defaultMessage: 'Enabled',
  },
  // CRUD messages
  currencyCreatedSuccess: {
    id: 'customerSettings.currency.currencyCreatedSuccess',
    description: 'Currency created successfully',
    defaultMessage: 'Currency "{iso3}" created successfully',
  },
  currencyCreatedError: {
    id: 'customerSettings.currency.currencyCreatedError',
    description: 'Currency created error',
    defaultMessage: 'Failed to create currency "{iso3}"',
  },
  currencyEnabledSuccess: {
    id: 'customerSettings.currency.currencyEnabledSuccess',
    description: 'Currency enabled successfully',
    defaultMessage: 'Currency "{iso3}" enabled successfully',
  },
  currencyEnabledError: {
    id: 'customerSettings.currency.currencyEnabledError',
    description: 'Currency enabled error',
    defaultMessage: 'Failed to enable currency "{iso3}"',
  },
  currencyUpdatedSuccess: {
    id: 'customerSettings.currency.currencyUpdatedSuccess',
    description: 'Currency updated successfully',
    defaultMessage: 'Currency updated "{iso3}" successfully',
  },
  currencyUpdatedError: {
    id: 'customerSettings.currency.currencyUpdatedError',
    description: 'Currency updated error',
    defaultMessage: 'Failed to update currency "{iso3}"',
  },
  currencyDeletedSuccess: {
    id: 'customerSettings.currency.currencyDeletedSuccess',
    description: 'Currency deleted successfully',
    defaultMessage: 'Currency "{iso3}" deleted successfully',
  },
  currencyDeletedError: {
    id: 'customerSettings.currency.currencyDeletedError',
    description: 'Currency deleted error',
    defaultMessage: 'Failed to delete currency "{iso3}"',
  },
  defaultCurrencyUpdatedSuccess: {
    id: 'customerSettings.currency.defaultCurrencyUpdatedSuccess',
    description: 'Default currency updated successfully',
    defaultMessage: 'Default currency set to "{name}" successfully',
  },
  defaultCurrencyUpdatedError: {
    id: 'customerSettings.currency.defaultCurrencyUpdatedError',
    description: 'Default currency updated error',
    defaultMessage: 'Failed to update default currency "{name}"',
  },
  // Validation messages
  propertyValueRequired: {
    id: 'customerSettings.currency.propertyValueRequired',
    description: 'Currency value required',
    defaultMessage: 'Currency {propertyName} is required',
  },
  propertyValueMustContainAlphabetOnly: {
    id: 'customerSettings.currency.propertyValueMustContainAlphabet',
    description: 'Currency value must contain alphabet only',
    defaultMessage: 'Currency {propertyName} must contain alphabet only',
  },
  propertyValueMustBeExactLength: {
    id: 'customerSettings.currency.propertyValueMustBeExactLength',
    description: 'Currency value length',
    defaultMessage: 'Currency {propertyName} must be {exactLength} characters',
  },
  propertyValueMaxLength: {
    id: 'customerSettings.currency.propertyValueMaxLength',
    description: 'Currency value length',
    defaultMessage: 'Currency {propertyName} must be max {maxLength} characters',
  },
  propertyValueMustBeUnique: {
    id: 'customerSettings.currency.propertyValueMustBeUnique',
    description: 'Currency value must be unique',
    defaultMessage: 'Currency {propertyName} must be unique',
  },
  currencyNameRequired: {
    id: 'customerSettings.currency.currencyNameRequired',
    description: 'Currency name required',
    defaultMessage: 'Currency name is required',
  },
  currencyIso3Required: {
    id: 'customerSettings.currency.currencyIso3Required',
    description: 'Currency ISO3 required',
    defaultMessage: 'Currency ISO3 is required',
  },
  currencySymbolRequired: {
    id: 'customerSettings.currency.currencySymbolRequired',
    description: 'Currency symbol required',
    defaultMessage: 'Currency symbol is required',
  },
  currencyLocalisedSymbolRequired: {
    id: 'customerSettings.currency.currencyLocalisedSymbolRequired',
    description: 'Currency localised symbol required',
    defaultMessage: 'Currency localised symbol is required',
  },
  currencyEnabledRequired: {
    id: 'customerSettings.currency.currencyEnabledRequired',
    description: 'Currency enabled required',
    defaultMessage: 'Currency enabled is required',
  },
  currencyIso3Length: {
    id: 'customerSettings.currency.currencyIso3Length',
    description: 'Currency ISO3 length',
    defaultMessage: 'Currency ISO3 must be 3 characters',
  },
  currencyNameLength: {
    id: 'customerSettings.currency.currencyNameLength',
    description: 'Currency name length',
    defaultMessage: 'Currency name must be less than 50 characters',
  },
  currencySymbolLength: {
    id: 'customerSettings.currency.currencySymbolLength',
    description: 'Currency symbol length',
    defaultMessage: 'Currency symbol must be less than 10 characters',
  },
  currencyLocalisedSymbolLength: {
    id: 'customerSettings.currency.currencyLocalisedSymbolLength',
    description: 'Currency localised symbol length',
    defaultMessage: 'Currency localised symbol must be less than 10 characters',
  },
  currencyIso3MustBeUnique: {
    id: 'customerSettings.currency.currencyIso3MustBeUnique',
    description: 'Currency ISO3 must be unique',
    defaultMessage: 'Currency ISO3 must be unique',
  },
  currencyNameMustBeUnique: {
    id: 'customerSettings.currency.currencyNameMustBeUnique',
    description: 'Currency name must be unique',
    defaultMessage: 'Currency name must be unique',
  },
  // Other messages
  defaultCurrencyCannotBeDisabled: {
    id: 'customerSettings.currency.defaultCurrencyCannotBeDisabled',
    description: 'Default currency cannot be disabled',
    defaultMessage: 'Default currency cannot be disabled',
  },
})

export default messages
