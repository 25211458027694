import {
  Action,
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

import { resetStore } from './actions'
import { WHITE_LISTED_REDUCERS } from './consts'
import { reducers } from './reducers'
import { StateKeysType } from './types'
import { apiSlice } from '../redux-fetch/apiQuery'

const combinedReducer = combineReducers(reducers)

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === resetStore.toString()) {
    const newState: Partial<Record<StateKeysType, unknown>> = {}
    if (!isEmpty(state) && !isEmpty(WHITE_LISTED_REDUCERS)) {
      WHITE_LISTED_REDUCERS.forEach(
        (key: any) => (newState[key as keyof typeof newState] = state?.[key as keyof typeof state])
      )
    }
    state = newState as unknown as RootState
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
})

export type AppDispatch = ThunkDispatch<RootState, unknown, AnyAction>
export type AppGetState = typeof combinedReducer
export type RootState = ReturnType<typeof combinedReducer>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
