import { Box, alpha, styled } from '@mui/material'

export const TableItem = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '14.28%',
  backgroundColor: alpha(theme.palette.secondary.main, 0.08),
}))

export const TableColumn = styled(Box)(({ theme }) => ({
  flex: 1,
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '14.28%',
  padding: theme.spacing(2),
}))
