import { Box, TextField } from '@mui/material';
import React, { type ReactElement } from 'react';
import { NumericFormat } from 'react-number-format';
import FormDropdown, { DropdownItem } from '../../../components/FormDropDown/FormDropdown';

interface IValueUnitControlProps {
    unitId: string
    valueId?: string
    handleChangeOfUnit: (name: string, newValue: string) => void
    handleChangeOfValue: (name: string, newValue: number | string | undefined) => void
    isAllowed?: boolean
    labelUnit: string
    labelValue: string
    noSelectPrompt?: boolean
    name?: string
    selectedUnitId: number | string
    unitList: DropdownItem[]
    value: number | string
    disabled?: boolean
}

export function ValueUnitControl({
    unitId,
    valueId,
    handleChangeOfUnit,
    handleChangeOfValue,
    isAllowed,
    labelUnit,
    labelValue,
    unitList,
    value,
    disabled,
    selectedUnitId,
}: IValueUnitControlProps): ReactElement<any, any> {
    return (
        <Box display='flex'>
            <NumericFormat
                customInput={TextField}
                fullWidth={true}
                id={valueId}
                name={valueId}
                label={labelValue}
                value={value}
                onValueChange={(vals) => {
                    handleChangeOfValue(valueId ?? '', vals.floatValue);
                }}
                allowNegative={isAllowed}
                decimalScale={6}
                inputProps={{ maxLength: 22 }}
                thousandSeparator={true}
                sx={{
                    borderRight: 'none',
                    flex: 2,
                }}
                InputLabelProps={{ shrink: true }}
                disabled={disabled}
            />
            <FormDropdown
                id={unitId}
                sx={{
                    borderRight: 'none',
                    flex: 1,
                }}
                label={labelUnit}
                onChange={(e, id, newValue) => {
                    handleChangeOfUnit(unitId, newValue)
                }}
                value={selectedUnitId}
                items={unitList}
                disabled={disabled}
            />
        </Box>
    )
}
