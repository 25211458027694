import { Grid } from '@mui/material'
import { useFormik } from 'formik'
import { useCallback, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import logger from '../../../app/middleware/log'
import PolygonMap from '../../../components/PolygonMap/PolygonMap'
import { SectionTitleTypography } from '../../../components/Typographies/styles'
import ContactTable from '../../Contacts/ContactTable'
import { ILocationByTenantCustomer } from '../../Shipments/types'
import AddressDetails from './AddressDetails'
import DefaultValues from './DefaultValues'
import MainInfo from './MainInfo'
import messages from './messages'
import { getLocationValidationSchema } from './validation'

export interface IViewLocationFormProps {
  location?: ILocationByTenantCustomer
}

const ViewLocationForm = ({ location }: IViewLocationFormProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const [polygons, setPolygons] = useState<any>([])

  const initialValues = useCallback((): ILocationByTenantCustomer => {
    const initialValues = {
      id: location?.id ?? -1,
      name: location?.name ?? '',
      address: {
        id: location?.address?.id ?? -1,
        countryId: location?.address?.countryId ?? 1,
        address1: location?.address?.address1 ?? '',
        address2: location?.address?.address2 ?? '',
        address3: location?.address?.address3 ?? '',
        city: location?.address?.city ?? '',
        state: location?.address?.state ?? '',
        postalCode: location?.address?.postalCode ?? '',
      },
      lat: location?.lat,
      lng: location?.lng,
      defaultTransportModeId: location?.defaultTransportModeId,
      locationTypeId: 255, // TODO: Hardcoded for now as user defined
      defaultCurrencyId: location?.defaultCurrencyId,
      tenantId: location?.tenantId,
      customerId: location?.customerId,
      contacts: location?.contacts ?? [],
    }

    return initialValues
  }, [location, location?.address])

  const formik = useFormik<ILocationByTenantCustomer>({
    initialValues: initialValues(),
    enableReinitialize: true,
    validationSchema: getLocationValidationSchema(formatMessage),
    onSubmit: async () => {
      logger.log('View submit')
    },
  })

  const getMarker = () => {
    if (location?.lat && location?.lng) {
      const marker = { lat: location?.lat, lng: location?.lng }
      return marker
    }
    return undefined
  }

  return (
    <Grid container spacing={2} sx={{ p: '48px' }}>
      <Grid display='flex' flexDirection='row' gap={4} width='100%'>
        <Grid display='flex' flexDirection='column' width='50%'>
          <MainInfo type={'view'} formik={formik} disabled />
          <DefaultValues formik={formik} disabled />
          <ContactTable formik={formik} disabled />
        </Grid>
        <Grid display='flex' flexDirection='column' width='50%'>
          <Grid container item xs={12} gap={2} justifyContent="space-between" sx={{ mb: '16px' }}>
            <Grid item>
              <SectionTitleTypography >{formatMessage(messages.addressDetails)}</SectionTitleTypography>
            </Grid>
          </Grid>
          <Grid item xs={12} marginBottom={2}>
            <PolygonMap
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
              defaultCenter={{
                lat: 24.638517161652892,
                lng: 45.84234953431573,
                zoom: 5,
              }}
              marker={getMarker()}
              height={239}
              width='85%'
              polygons={polygons}
              onChangePolygons={setPolygons}
            />
          </Grid>
          <Grid item xs={12}>
            <AddressDetails formik={formik} type='view' disabled />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ViewLocationForm
