import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useLocationByIdQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../components/Spinner/SpinnerModalBlock'
import { fetchContactTypesSelector } from '../../Contacts/selectors'
import { fetchContactTypesThunk } from '../../Contacts/slice'
import {
  fetchAllCountriesSelector,
  fetchAllTransportModesSelector,
  fetchSystemCurrenciesSelector,
} from '../../Shipments/selectors'
import {
  fetchAllCountriesThunk,
  fetchAllTransportModesThunk,
  fetchSystemCurrenciesThunk,
} from '../../Shipments/slice'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import ViewLocationForm from './ViewLocationForm'

interface IViewLocationProps {
  id: number
}

const ViewLocation = ({ id }: IViewLocationProps) => {
  const dispatch = useAppDispatch()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const isCountriesLoaded = useAppSelector(fetchAllCountriesSelector.isFinished)
  const isTransportModeLoaded = useAppSelector(fetchAllTransportModesSelector.isFinished)
  const isContactTypesLoaded = useAppSelector(fetchContactTypesSelector.isFinished)
  const isSystemCurrencyLoaded = useAppSelector(fetchSystemCurrenciesSelector.isFinished)

  const isCountriesFetching = useAppSelector(fetchAllCountriesSelector.isFetching)
  const isTransportModeFetching = useAppSelector(fetchAllTransportModesSelector.isFetching)
  const isContactTypesFetching = useAppSelector(fetchContactTypesSelector.isFetching)
  const isSystemCurrencyFetching = useAppSelector(fetchSystemCurrenciesSelector.isFetching)

  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId

  const {
    data: location,
    isSuccess: locationLoaded,
    refetch,
  } = useLocationByIdQuery({
    locationId: +id,
    tenantId: tenantId ?? -1,
    customerId: customerId,
  })

  useEffect(() => {
    refetch()
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchAllTransportModesThunk())
    dispatch(fetchSystemCurrenciesThunk())
    dispatch(fetchContactTypesThunk(tenantId ?? -1, customerId))
  }, [tenantId, customerId])

  const isLoaded =
    locationLoaded &&
    isCountriesLoaded &&
    !isCountriesFetching &&
    isTransportModeLoaded &&
    !isTransportModeFetching &&
    isContactTypesLoaded &&
    !isContactTypesFetching &&
    isSystemCurrencyLoaded &&
    !isSystemCurrencyFetching



  if (!isLoaded) return <SpinnerModalBlock />
  return (
    <>
      <ViewLocationForm location={location} />
    </>
  )
}

export default ViewLocation
