import {
  Autocomplete,
  TextField,
} from '@mui/material';
import { IUserType } from '../../types';
import differenceBy from 'lodash/differenceBy';
import { useAssetTypeMrtFormContext } from '../hooks/useAssetTypeMrtFormContext';

interface UserTypesAutocompleteProps {
  label: string;
  defaultValue?: IUserType[];
  onChange?: (userTypes: IUserType[]) => void;
  disabled?: boolean;
}
export function UserTypesAutocomplete({
  label,
  defaultValue = [],
  onChange,
  disabled,
}: UserTypesAutocompleteProps){

  const { values } = useAssetTypeMrtFormContext();
  const userTypeOptions: IUserType[] = differenceBy(values.userTypes, defaultValue, 'name');

  return (
      <Autocomplete
          multiple
          options={userTypeOptions}
          getOptionLabel={(option) => option.name}
          defaultValue={defaultValue}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder="types" />
          )}
          sx={{maxWidth: '400px'}}
          onChange={(_, value) => {
            if(onChange){ 
              onChange(value);
            }
          }}
          disabled={disabled}
      />
  )
}
