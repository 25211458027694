import { ApiResult, IAssetCategory } from '../../types';

import { deleteAssetCategory as deleteCategory } from '../../api';


export const deleteAssetCategory = async (values: IAssetCategory): Promise<ApiResult> => {
      const { tenantId } = values;
      const deleteResult = { success: true };
      try {
        await deleteCategory(values, tenantId as number);
      } catch (error) {
        deleteResult.success = false;
      }
      return deleteResult;
}
