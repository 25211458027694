import { Delete, Edit } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { MRT_ColumnDef as MRTColumnDef, MRT_Row as MRTRow } from 'material-react-table'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../app/hooks'
import { useGetAssetsQuery, useGetTransportModeQuery } from '../../app/redux-fetch/apiQuery'
import MRTDataGrid from '../../components/MRTDataGrid/MRTDataGrid'
import { fetchLoggedInUserSelector } from '../selectors'
import { IAssetManagement, ILoggedInUser } from '../types'
import { messages } from './messages'
import { AssetManagementModal } from './modals/AssetManagementModal'
import { DeleteAssetManagementModal } from './modals/DeleteAssetManagementModal'

export function Assets() {
  const { formatMessage } = useIntl()

  const columns = useMemo<MRTColumnDef<Partial<IAssetManagement>>[]>(() => {
    return [
      {
        id: 'identification',
        accessorKey: 'identification',
        header: 'Fleet Numnber',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ] as MRTColumnDef<Partial<IAssetManagement>>[]
  }, [])

  const handleRowSelectionChange = ({ row }: { row: MRTRow<Partial<IAssetManagement>> }) => {
    return {
      onClick: (e: { preventDefault: () => void; stopPropagation: () => void }) => {
        e.preventDefault()
        e.stopPropagation()
      },
      sx: {
        cursor: 'pointer',
        backgroundColor: Number(row.id) === 0 ? 'lightgrey' : 'initial',
      },
    }
  }

  const columnsDef = useMemo(() => {
    return columns.reduce((acc, item) => {
      acc[item.id ?? ''] = item.header
      return acc
    }, {} as { [key: string]: string })
  }, [columns])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [deleteRow, setDeleteRow] = useState<MRTRow<Partial<IAssetManagement>> | null>(null)
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetManagement>> | undefined>(undefined)
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data = [] } = useGetAssetsQuery({ tenantId })
  const { data: transportModes } = useGetTransportModeQuery()
  return (
    <Grid container spacing={2} pt={4} direction='column'>
      <Grid item>
        <Typography variant='h4'>{formatMessage(messages.title)}</Typography>
      </Grid>
      <Grid item>
        {deleteRow && (
          <DeleteAssetManagementModal
            columnDef={columnsDef}
            onClose={() => setDeleteRow(null)}
            row={deleteRow}
          />
        )}
        {modalIsOpen && (
          <AssetManagementModal
            onClose={() => {
              setEditRow(undefined)
              setModalIsOpen(false)
            }}
            isOpen={modalIsOpen}
            tenantId={tenantId}
            row={editRow}
          />
        )}
        <MRTDataGrid
          heading={messages.createAssetBtn}
          columns={columns}
          data={data}
          handleAddItem={() => {
            setModalIsOpen(true)
          }}
          getRowId={(row) => `${row.id}`}
          muiTableBodyRowProps={handleRowSelectionChange}
          state={{ isLoading: false }}
          enableEditing
          renderRowActions={({ row }) => {
            const { tenantId } = row.original as IAssetManagement

            const isUserDefined = !!tenantId
            const editable = isUserDefined

            return (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip arrow placement='left' title='Edit'>
                  <IconButton
                    color='info'
                    onClick={() => {
                      setEditRow(row)
                      setModalIsOpen(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {editable && (
                  <Tooltip arrow placement='right' title='Delete'>
                    <IconButton
                      color='info'
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setDeleteRow(row)
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
