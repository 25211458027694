import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../app/hooks'
import { fetchAllTransportModesThunk } from '../../../Shipments/slice'
import { AssetTypeMrtForm } from './AssetType/AssetTypeMrtForm'
import { AssetCategoryMrtForm } from './AssetCategory/AssetCategoryMrtForm'
import { AssetClassMRTForm } from './AssetClass/AssetClassMrtForm'

export const AssetCategories = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllTransportModesThunk());
  }, [dispatch])


  const [asstCategoryId, setAssetCategoryId] = useState<number|undefined>();
  return (
    <>
      <AssetCategoryMrtForm onCategoryChange={setAssetCategoryId} />
      <AssetTypeMrtForm assetCategoryId={asstCategoryId} />
      <AssetClassMRTForm assetCategoryId={asstCategoryId} />
    </>
  )
}
