import { useIntl } from 'react-intl';
import logger from '../../../app/middleware/log';
import { getShipmentValidationSchema } from '../components/CreateShipment/ShipmentValidationSchema';
import { IShipment } from '../types';


export function useValidation() {
    const { formatMessage } = useIntl();
    const schema = getShipmentValidationSchema(formatMessage);
    const validAt = (prop: string, data: IShipment) => {
        let isValid = true;

        try {
            schema.validateSyncAt(prop, data);
        } catch (error) {
            isValid = false;
            logger.error(`error for ${prop}`, error);
        }
        return isValid;
    }
    return {
        validAt,
    }
}