import AddIcon from '@mui/icons-material/Add'
import { FabStyled } from './FabStyled'

export interface IAddItemActionProps {
  onClick?: () => void
}
export const AddItemAction = ({ onClick }: IAddItemActionProps) => {
  return (
    <FabStyled
      aria-label='add'
      color='primary'
      onClick={onClick}
      size='small'
      sx={{ margin: '5px 5px' }}
    >
      <AddIcon />
    </FabStyled>
  )
}
