// import { SubType } from '../../../../../../types/common';
import { ApiResult, IAssetCategory } from '../../types';

import { updateAssetCategory as update } from '../../api';

export const updateAssetCategory = async (
    values: IAssetCategory
): Promise<ApiResult> => {
      const { tenantId } = values;
      const updateResult = { success: true };
      try {
        const payload = { ...values, tenantId } as IAssetCategory
        await update(payload, tenantId as number)
      } catch (error) {
        updateResult.success = false;
      }

      return updateResult;
  }
