import { Box, Button, Grid } from '@mui/material'
import { useCallback, useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../app/hooks'
import SwatchColorPicker from '../../../components/SwatchColorPicker/SwatchColorPicker'

import { useSetDefaultBrandingMutation } from '../../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock'
import { ThemeContext } from '../../../components/Theme/Theme'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { postBranding, updateBranding } from '../api'
import messages from '../messages'
import { fetchAllBrandingSelector } from '../selectors'
import { IBranding } from '../types'

const BrandingConfiguration = () => {
  const { formatMessage } = useIntl()
  const themeContextState = useContext(ThemeContext)
  const [theme, setState] = useState(themeContextState.theme)
  const brandingInfo = useAppSelector(fetchAllBrandingSelector.data)
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const [setDefaultBranding, { isLoading }] = useSetDefaultBrandingMutation()
  const styles = {
    boxStyles: {
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '3px',
      padding: '10px',
      margin: '20px 0',
    },
  }

  const onChangeCompleteHandler = (propertyName: string, color: string): void => {
    const workingThemeSource = {
      palette: {
        primary: {
          contrastText: theme.palette.primary.contrastText,
          main: theme.palette.primary.main,
        },
        secondary: {
          main: theme.palette.secondary.main,
        },
        text: {
          primary: theme.palette.text.primary,
        },
      },
    }

    switch (propertyName) {
      case 'primary':
        workingThemeSource.palette.primary.main = color
        break
      case 'secondary':
        workingThemeSource.palette.secondary.main = color
        break
      case 'text':
        workingThemeSource.palette.text.primary = color
        break
      case 'contrastText':
        workingThemeSource.palette.primary.contrastText = color
        break

      default:
        break
    }

    themeContextState.createAndSetTheme(workingThemeSource)
    setState(themeContextState.theme)
  }

  const handleSaveBranding = () => {
    const payload: IBranding = {
      primaryPalletColor: theme.palette.primary.main,
      secondaryPalletColor: theme.palette.secondary.main,
      textColor: theme.palette.text.primary,
      contrastTextColor: theme.palette.primary.contrastText,
      tenantId: 1, // TODO: get tenantId from state
      id: brandingInfo?.id ?? 0,
    }

    if (!brandingInfo) return postBranding(payload)

    updateBranding(payload)
  }
  const updateDefaultTheme = useCallback(async () => {
    const defaultBranding = await setDefaultBranding(loggedInUser.tenantId)
    if ('data' in defaultBranding) {
      const brandingData: IBranding = defaultBranding.data

      const workingThemeSource = {
        palette: {
          primary: {
            contrastText: `#${brandingData.contrastTextColor}`,
            main: `#${brandingData.primaryPalletColor}`,
          },
          secondary: {
            main: `#${brandingData.secondaryPalletColor}`,
          },
          text: {
            primary: `#${brandingData.textColor}`,
          },
        },
      }
      const newTheme = themeContextState.createAndSetTheme(workingThemeSource)
      setState(newTheme)
    } else {
      console.error('Error occurred while setting default branding:', defaultBranding.error)
    }
  }, [loggedInUser.tenantId, themeContextState])
  if (isLoading) {
    return <SpinnerBlock />
  }
  return (
    <>
      <Box sx={styles.boxStyles}>
        <SwatchColorPicker
          color={theme.palette.primary.main}
          name={formatMessage(messages.colorPrimary)}
          onChangeComplete={(color) => {
            onChangeCompleteHandler('primary', color.hex)
          }}
        />
        <SwatchColorPicker
          name={formatMessage(messages.colorSecondary)}
          color={theme.palette.secondary.main}
          onChangeComplete={(color) => {
            onChangeCompleteHandler('secondary', color.hex)
          }}
        />
        <SwatchColorPicker
          name={formatMessage(messages.colorText)}
          color={theme.palette.text.primary}
          onChangeComplete={(color) => {
            onChangeCompleteHandler('text', color.hex)
          }}
        />
        <SwatchColorPicker
          name={formatMessage(messages.colorContrastText)}
          color={theme.palette.primary.contrastText}
          onChangeComplete={(color) => {
            onChangeCompleteHandler('contrastText', color.hex)
          }}
        />
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box mt={4}>
            <Button variant='contained' onClick={handleSaveBranding}>
              {formatMessage(messages.saveBranding)}
            </Button>
            <Button variant='contained' onClick={() => updateDefaultTheme()} sx={{ ml: '16px' }}>
              {formatMessage(messages.resetToDefault)}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default BrandingConfiguration
