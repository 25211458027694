import { Divider, type SelectChangeEvent } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'

import { type IShipment } from '../../types'
import ShipmentDetail from './ShipmentDetail'
import ShipmentIdentity from './ShipmentIdentity'

interface IShipmentSummaryStepProps {
  formik: FormikProps<IShipment>
  handleFormDropDownChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
  disabled?: boolean
}

const ShipmentSummaryStep = (props: IShipmentSummaryStepProps): ReactElement<any, any> => {
  const { formik, disabled, handleFormDropDownChanges } = props

  return (
    <>
      <ShipmentIdentity
        formik={formik}
        disabled={disabled}
        handleFormDropDownChanges={handleFormDropDownChanges}
      />
      <Divider
        color='rgba (0, 0, 0, 0.125)'
        textAlign='left'
        sx={{
          backgroundColor: 'transparent',
          marginBottom: '20px',
          marginLeft: '16px',
          marginRight: '16px',
          marginTop: '20px',
        }}
      />
      <ShipmentDetail
        formik={formik}
        handleFormDropDownChanges={handleFormDropDownChanges}
        disabled={disabled}
      />
    </>
  )
}

export default ShipmentSummaryStep
