import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { AddItemAction } from '../../../components/Buttons/AddItemAction';
import { RemoveItemAction } from '../../../components/Buttons/RemoveItemAction';
import { DatePicker } from '../../../components/DatePicker/DatePicker';
import { PortPass } from '../../types';
import { messages } from '../messages';
import { useState } from 'react';
import { getMaxDate, getMinDate } from '../utils/utils';

export interface PortPassesCardProps {
    disabled?: boolean;
    index: number;
    onAdd?: () => void;
    onChange: (itemIndex: number, prop: string, value: PortPass[keyof PortPass]) => void;
    onRemove?: (itemIndex: number) => void;
    portPass: PortPass;
}

export function PortPassesCard({
    disabled,
    index,
    portPass,
    onAdd,
    onRemove,
    onChange,
}: PortPassesCardProps) {
    const { formatMessage } = useIntl();
    const [portName, setPortName] = useState(portPass.portName);
    const onBlur = () => {
        onChange(index, 'portName', portName);
    }

    const expiryMinDate = getMinDate(portPass.issueDate);
    const issueMaxDate = getMaxDate(portPass.expiryDate);

    return (
        <Card sx={{ minWidth: 275, maxWidth: 275, position: 'relative' }}>
            <Box sx={{ position: 'absolute', top: 3, right: 0 }}>
                {onRemove && <RemoveItemAction onClick={() => onRemove(index)} />}
                {onAdd && <AddItemAction onClick={onAdd} />}
            </Box>
            <CardContent>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                    {formatMessage(messages.assetsPortPass)}
                </Typography>
                <FormGroup aria-label="position"
                    sx={{
                        display: 'flex',
                        direction: 'column',
                        rowGap: '16px'
                    }}>
                    <TextField
                        label={formatMessage(messages.assetsPortName)}
                        name="portName"
                        onChange={({ target }) => setPortName(target.value)}
                        disabled={disabled}
                        value={portName}
                        defaultValue={portName}
                        onBlur={onBlur}
                    />
                    <DatePicker
                        label={formatMessage(messages.assetsPortPassIssueDate)}
                        name={'issueDate'}
                        value={portPass.issueDate}
                        disabled={!portName}
                        onChange={(date) => onChange(index, 'issueDate', date)}
                        maxDate={issueMaxDate}
                    />
                    <DatePicker
                        label={formatMessage(messages.assetsPortPassExpiryDate)}
                        name={'expiryDate'}
                        value={portPass.expiryDate}
                        onChange={(date) => onChange(index, 'expiryDate', date)}
                        disabled={!portName || !portPass.issueDate}
                        minDate={expiryMinDate}
                    />
                </FormGroup>
            </CardContent>
        </Card>
    );
}
