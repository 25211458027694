
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';

interface EnabledProps {
  isEnabled?: boolean
}


export const Enabled = ({
    isEnabled
}: EnabledProps) => {
 
 return <Box sx={{'text-align': 'center'}}>{isEnabled ? <DoneIcon /> : <ClearIcon/>}</Box>
}
