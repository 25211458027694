import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock'
import { fetchUserTypesSelector } from '../../TenantSettings/Components/AssetSettings/selectors'
import { fetchUserTypeThunk } from '../../TenantSettings/Components/AssetSettings/slice'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import ControlBar from './Components/ControlBar'
import { UserMRT } from './User/UserMRT'
import { deleteUserByCustomerId, postUserByCustomerId, putUserByCustomerId } from './User/api'
import messages from './User/messages'
import {
  fetchAllCustomerSettingsSortedUsersSelector,
  fetchAllCustomerSettingsUsersSelector,
} from './User/selectors'
import {
  fetchAllCustomerSettingsUserByCustomeIdActions,
  fetchAllCustomerSettingsUserThunk,
} from './User/slice'
import { IExtendedUser, IUser } from './User/types'
import { FormikUserSettings, IUserSettingValues } from './types'

const UserSettings = () => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId

  const isFetchingUsers = useAppSelector(fetchAllCustomerSettingsUsersSelector.isFetching)
  const allUsers = useAppSelector(fetchAllCustomerSettingsSortedUsersSelector)
  const isUserTypesFetching = useAppSelector(fetchUserTypesSelector.isFetching)
  const isUserTypesLoaded = useAppSelector(fetchUserTypesSelector.isFinished)

  useEffect(() => {
    void dispatch(fetchAllCustomerSettingsUserThunk(tenantId, customerId))
  }, [fetchAllCustomerSettingsUserThunk, tenantId, customerId])

  useEffect(() => {
    !isUserTypesLoaded && dispatch(fetchUserTypeThunk())
  }, [isUserTypesLoaded])

  const initialValues = useMemo<FormikUserSettings['initialValues']>(
    (): IUserSettingValues => ({
      formikUsers: allUsers,
      formIsDirty: false,
      formHasErrors: false,
      formSubmissionSuccessful: false,
      formSubmissionFailure: false,
    }),
    [allUsers]
  )

  const submitForm = async (values: IUserSettingValues) => {
    const formikUsers = values.formikUsers.map((user) => user) as IExtendedUser[]
    formik.setFieldValue('formHasErrors', false)
    const isFormClean = formikUsers?.find((user) => (user as IExtendedUser).error) === undefined

    if (!isFormClean) {
      enqueueSnackbar(formatMessage(messages.usersTableFormHasErrors), { variant: 'warning' })
      formik.setFieldValue('formHasErrors', true)
      return
    }

    const usersToSave = formikUsers.filter((user) => user.dirty)
    if (!usersToSave.length) {
      formikUsers.forEach((user) => (user.dirty = false))
      formik.setFieldValue('formikUsers', formikUsers)
      formik.setFieldValue('formIsDirty', false)
      return
    }

    const updates = usersToSave.map((user) => (async () => {
      if (user.httpAction === 'POST') {
        try {
          user.id = undefined
          await postUserByCustomerId({ tenantId, customerId, user })
          enqueueSnackbar(formatMessage(messages.usersTableUserCreatedSuccessfully), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        } catch (error) {
          enqueueSnackbar(formatMessage(messages.usersTableUserCreationFailed), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        }
        return
      }

      if (user.httpAction === 'DELETE') {
        try {
          await deleteUserByCustomerId({ tenantId, customerId, user })
          enqueueSnackbar(formatMessage(messages.usersTableUserDeletedSuccessfully), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        } catch (error) {
          enqueueSnackbar(formatMessage(messages.usersTableUserDeleteFailed), { variant: 'error' })
        }
        return
      }

      try {
        await putUserByCustomerId({ tenantId, customerId, user })
        enqueueSnackbar(formatMessage(messages.usersTableUserUpdatedSuccessfully), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      } catch (error) {
        enqueueSnackbar(formatMessage(messages.usersTableUserUpdateFailed), { variant: 'error' })
        return
      }
    })());

    await Promise.all(updates);
    dispatch(fetchAllCustomerSettingsUserByCustomeIdActions.clear())
    dispatch(fetchAllCustomerSettingsUserThunk(tenantId, customerId))
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: submitForm,
    enableReinitialize: true,
  })

  const isFormDirty = () => {
    const { formikUsers } = formik.values
    const hasDirtyUser = (formikUsers as IExtendedUser[])?.find((user) => user.dirty)
    return hasDirtyUser !== undefined
  }

  const handleSubmit = (event: any) => formik.handleSubmit(event)
  const isLoaded = !isUserTypesFetching && isUserTypesLoaded
  if (!isLoaded) {
    return (<SpinnerBlock />)
  }
  return (
    <>
      <ControlBar formik={formik} isFormDirty={isFormDirty} onSubmit={handleSubmit} />
      <div style={{ marginBottom: '10px' }}></div>
      <UserMRT
        formik={formik}
        allUsers={formik.values.formikUsers as IUser[]}
        isFetchingUsers={isFetchingUsers}
        isFormDirty={isFormDirty}
      />
    </>
  )
}

export default UserSettings
