import { IAssetType } from '../../types';

import { deleteAssetType as deleteAsset } from '../../api';

interface Result {
    success: boolean;
}

export const deleteAssetType = async (assetType: IAssetType): Promise<Result> => {
      const { tenantId } = assetType;
      const deleteResult = { success: true };
      try {
        const payload = { ...assetType, tenantId } as unknown as IAssetType;
        await deleteAsset(payload, tenantId as number);
      } catch (error) {
        deleteResult.success = false;
      }
      return deleteResult;
}
