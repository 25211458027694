import { Typography } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'

import { type IShipment } from '../../types'
import { sortByDisplayOrder } from '../../utils'
import AssignmentLoad from './AssignmentLoad'
import messages from './messages'

interface IAssignmentLoadPaneProps {
  formik: FormikProps<IShipment>
  disabled?: boolean
}

const AssignmentLoadPane = (props: IAssignmentLoadPaneProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { formik, disabled } = props

  return (
    <>
      {formik.values.loads.length === 0 && (
        <Typography>{formatMessage(messages.noLoads)}</Typography>
      )}
      {formik.values.loads.length > 0 &&
        formik.values.loads
          .filter((l) => l.isDefined)
          .sort(sortByDisplayOrder)
          .map((load) => {
            const index = formik.values.loads.findIndex((l) => load.id === l.id)
            return (
              <AssignmentLoad
                key={index}
                loadIndex={index}
                load={load}
                formik={formik}
                disabled={disabled}
              />
            )
          })}
    </>
  )
}

export default AssignmentLoadPane
