import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { IAssetCategory } from '../../types';
import { useState } from 'react';
import { assetCategoryTypeSchema } from '../schemas/assetCategoryTypeSchema';
import { useIntl } from 'react-intl';
import { SubType } from '../../../../../../types/common';
import { SubmitButton } from '../../../../../../components/Buttons/SubmitButton';

interface AddAssetCategoryMrtModalProps {
  onClose: () => void
  onSubmit: (values: IAssetCategory) => void
  dialogTitle: string
  columnDef: { [key: string]: string };
  loading?: boolean;
}
export function AddAssetCategoryMrtModal({
  onClose,
  onSubmit,
  dialogTitle,
  columnDef,
  loading
}: AddAssetCategoryMrtModalProps) {

  const { formatMessage } = useIntl();
  const [values, setValues] = useState({ subType: SubType.UserDefined } as IAssetCategory);
  const onChange = (propName: string, value: string | boolean) => {
    setValues((prev) => ({ ...prev, [propName]: value }))
  }

  const submit = () => {
    onSubmit(values);
  }

  const isValid = assetCategoryTypeSchema(formatMessage).isValidSync(values);

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root + [class*="MuiDialogContent-root"]': {
          paddingTop: '20px',
        },
      }}
    >
      <DialogTitle textAlign='center'>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: '100%',
            minWidth: { xs: '300px', sm: '360px', md: '400px' },
            gap: '1.5rem',
          }}
        >
          <TextField
            label={columnDef['name']}
            name="name"
            value={values.name}
            defaultValue={values.name}
            onChange={({ target }) => onChange('name', target.value)}
            disabled={loading}
          />
          <FormGroup aria-label="position">
            <FormControlLabel
              value={values.enabled}
              control={
                <Switch
                  color="primary"
                  checked={values.enabled}
                  value={values.enabled}
                  onChange={({ target }) => onChange('enabled', target.checked)}
                  disabled={loading}
                />
              }
              label={columnDef['enabled']}
            />
          </FormGroup>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <SubmitButton
          btnText='Save'
          onClick={submit}
          disabled={!isValid}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  )
}
