import { MenuItem, Typography } from '@mui/material'
import { FormikProps } from 'formik'
import MaterialReactTable, { MRT_ColumnDef as MRTColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { ICreateTransportForm, ITransportAsset, ITransportRouteLoad } from '../../types'

interface IAssetLoadsProps {
  formik: FormikProps<ICreateTransportForm>
  asset: ITransportAsset
  assetIdx: number
  acIdx: number
}
const AssetLoads = ({ formik, asset, acIdx, assetIdx }: IAssetLoadsProps) => {
  const loads = formik.values.route.loads.filter(
    (l) => asset.loadIds && asset.loadIds.some((id) => id === l.loadId)
  )

  const isLoadAssigned = (loadId: number) => {
    return formik.values.assetConfigurations.some((ac) =>
      ac.allocations
        .filter((aca) => aca.uid !== asset.uid)
        .some((aca) => aca.loadIds && aca.loadIds.some((acal) => acal === loadId))
    )
  }

  const columns = useMemo<Array<MRTColumnDef<Partial<ITransportRouteLoad>>>>(() => {
    return [
      {
        accessorFn: (row) =>
          isLoadAssigned(row.id ?? -1) ? (
            <Typography sx={{ p: 0 }} color={'brown'}>{`(Split Load) ${
              row.description ?? ''
            }`}</Typography>
          ) : (
            <Typography sx={{ p: 0 }}>{`${row.description ?? ''}`}</Typography>
          ),
        header: 'Load',
        size: 150,
      },
    ]
  }, [loads])

  const handleRemoveLoadFromAsset = (loadId: number) => {
    const loadIdx = (asset.loadIds && asset.loadIds.findIndex((id) => id === loadId)) ?? -1
    if (asset.loadIds && loadIdx > -1) {
      asset.loadIds.splice(loadIdx, 1)
      formik.setFieldValue(`assetConfigurations.${acIdx}.assets.${assetIdx}.loadIds`, asset.loadIds)
    }
  }

  return (
    <MaterialReactTable
      key={uuidv4()}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: '',
          maxSize: 16,
          minSize: 16,
        },
      }}
      columns={columns}
      data={loads}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
      enableRowActions={true}
      muiTableContainerProps={{ sx: { maxHeight: '200px' } }}
      renderRowActionMenuItems={({ row }: { row: any }) => [
        <MenuItem key='delete' onClick={() => handleRemoveLoadFromAsset(row.original.loadId ?? -1)}>
          Remove load
        </MenuItem>,
      ]}
    />
  )
}

export default AssetLoads
