import AddIcon from '@mui/icons-material/Add'
import { FabStyled } from './FabStyled'

export interface IAddItemActionProps {
  show: boolean
  isDisabled?: boolean
  onAddItem?: () => void
}
export const AddItemAction = ({ show, onAddItem, isDisabled }: IAddItemActionProps) => {
  if (!show) return null
  return (
    <FabStyled
      aria-label='add'
      color='primary'
      onClick={onAddItem}
      disabled={isDisabled}
      size='small'
      sx={{ margin: '10px 10px' }}
    >
      <AddIcon />
    </FabStyled>
  )
}
