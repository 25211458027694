import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { fetchLoggedInUserSelector, getIsCustomerUserTypeSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { fetchOffice365TenantSync } from './api'
import messages from './messages'
import {
  fetchIdentityProviderIntegrationIdSelector,
  fetchOffice365OnBoardingSelector,
} from './selectors'
import {
  fetchIdentityProviderIntegrationIdActions,
  fetchIdentityProviderIntegrationIdThunk,
  fetchOffice365OnBoardingActions,
  fetchOffice365OnBoardingThunk,
} from './slice'
import { IIdentityProvider } from './types'

const Office365Configuration = () => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const isCustomerUser = useAppSelector(getIsCustomerUserTypeSelector)
  const tenantId = loggedInUser.tenantId
  const customerId = loggedInUser.customerId

  const errorFetchingOnboardingLink = useAppSelector(fetchOffice365OnBoardingSelector.error)
  const onBoardingLink = useAppSelector(fetchOffice365OnBoardingSelector.data) as unknown as string
  const isFetchingIdentityProviderIntegrationId = useAppSelector(
    fetchIdentityProviderIntegrationIdSelector.isFetching
  )
  const identityProviderIntegrationInfo = useAppSelector(
    fetchIdentityProviderIntegrationIdSelector.data
  ) || [{} as IIdentityProvider]

  const { identityProviderIntegrationTypeId = undefined, externalId = undefined } =
    identityProviderIntegrationInfo.length > 0
      ? identityProviderIntegrationInfo[0]
      : ({} as IIdentityProvider)

  const hasIdpId = identityProviderIntegrationTypeId !== undefined
  const hasIdpIdAndNoExternalId = hasIdpId && externalId === undefined
  const hasIdpIdAndExternalId = hasIdpId && externalId !== undefined

  useEffect(() => {
    return () => {
      void dispatch(fetchIdentityProviderIntegrationIdActions.clear())
      void dispatch(fetchOffice365OnBoardingActions.clear())
    }
  }, [dispatch, fetchIdentityProviderIntegrationIdActions.clear])

  useEffect(() => {
    if (!onBoardingLink) return
    // TODO: Warn user that they will be redirected to a new tab
    enqueueSnackbar(formatMessage(messages.userFetchOnBoardingLinkSuccess), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    })
    setTimeout(() => window.open(onBoardingLink, '_blank'), 500)
  }, [onBoardingLink, formatMessage, enqueueSnackbar, messages.userFetchOnBoardingLinkSuccess])

  useEffect(() => {
    void dispatch(fetchIdentityProviderIntegrationIdThunk(tenantId, customerId))
  }, [fetchIdentityProviderIntegrationIdThunk])

  useEffect(() => {
    if (!errorFetchingOnboardingLink) return
    enqueueSnackbar(formatMessage(messages.userFetchOnBoardingLinkFailure), { variant: 'error' })
  }, [errorFetchingOnboardingLink])

  const styles = {
    boxStyles: {
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '3px',
      padding: '20px',
      margin: '20px 0',
    },
  }

  const handleOnBoarding = () => {
    // open link in new tab
    void dispatch(fetchOffice365OnBoardingThunk(tenantId, identityProviderIntegrationTypeId || 1))
  }

  const handleSyncronise = async () => {
    try {
      await fetchOffice365TenantSync(tenantId, customerId)
      enqueueSnackbar(formatMessage(messages.userSyncroniseSuccess), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(formatMessage(messages.userSyncroniseFailure), { variant: 'error' })
    }
  }

  return (
    <>
      <Grid>
        <Box sx={styles.boxStyles}>
          <Typography sx={{ marginBottom: '20px' }}>
            Integrate with Microsoft Azure Active Directory
          </Typography>
          {hasIdpIdAndNoExternalId && !hasIdpIdAndExternalId ? (
            <Typography>
              <Alert severity='warning'>
                {formatMessage(messages.userConsentLinkAlreadyIssued)}
              </Alert>
            </Typography>
          ) : null}
          <Grid>
            {!hasIdpId || hasIdpIdAndNoExternalId ? (
              <Button
                sx={{ margin: '20px 20px 20px 0' }}
                onClick={handleOnBoarding}
                variant='contained'
                disabled={
                  hasIdpId || hasIdpIdAndNoExternalId || isFetchingIdentityProviderIntegrationId
                }
              >
                {formatMessage(messages.userConnectTo365Button)}
              </Button>
            ) : null}
            {hasIdpIdAndNoExternalId ? (
              <Grid>
                <Link variant='button' onClick={handleOnBoarding} href='#'>
                  {formatMessage(messages.userConsentLink)}
                </Link>
              </Grid>
            ) : null}
            {hasIdpIdAndExternalId ? (
              <Button
                variant='contained'
                sx={{ margin: '20px 20px 20px 0' }}
                onClick={handleSyncronise}
                disabled={isFetchingIdentityProviderIntegrationId}
              >
                {formatMessage(messages.userSyncroniseButton)}
              </Button>
            ) : null}
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default Office365Configuration
