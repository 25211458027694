import { Grid, SelectChangeEvent, TextField } from '@mui/material'
import { type FormikProps } from 'formik'
import { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'

import { getProperty } from '../../../../app/utils'
import FormDropdown from '../../../../components/FormDropDown/FormDropdown'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { fetchAllCustomersByTenantSelector } from '../../selectors'
import {
  fetchCurrenciesByCustomerIdThunk,
  fetchLocationsByTenantOrCustomerThunk,
} from '../../slice'
import { type IShipment } from '../../types'
import messages from './messages'

interface IShipmentIdentity {
  formik: FormikProps<IShipment>
  disabled?: boolean
  handleFormDropDownChanges: (e: SelectChangeEvent<any>, name: string, newValue: any) => void
}

const ShipmentIdentity = (props: IShipmentIdentity): ReactElement<any, any> => {
  const { formik, disabled, handleFormDropDownChanges } = props
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const customers = useAppSelector(fetchAllCustomersByTenantSelector.data) ?? []

  const getError = (propertyName: string): boolean => {
    const touched = getProperty(formik.touched, propertyName)
    const errorMessage = getProperty(formik.errors as unknown as IShipment, propertyName)
    return touched && (errorMessage as any)
  }
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data)
  const isTenantUser = loggedInUser?.tenantId && !loggedInUser?.customerId

  return (
    <Grid container paddingTop='10px' spacing={4}>
      {isTenantUser && (
        <Grid item xs={4}>
          <FormDropdown
            id='customerId'
            label={formatMessage(messages.createShipment_customerId)}
            onChange={(e: SelectChangeEvent<any>, name: string, newValue: any) => {
              dispatch(fetchCurrenciesByCustomerIdThunk(newValue, true))
              dispatch(fetchLocationsByTenantOrCustomerThunk(loggedInUser.tenantId, newValue, true))
              formik.setFieldValue('shipperId', 0)
              formik.setFieldValue('currencyId', 0)
              handleFormDropDownChanges(e, name, newValue)
            }}
            value={formik.values.customerId}
            items={customers}
            errorText={getError('customerId')}
            error={Boolean(getError('customerId'))}
            disabled={disabled}
          />
        </Grid>
      )}
      <Grid item xs={isTenantUser ? 4 : 4}>
        <TextField
          fullWidth={true}
          id='customerReferenceNumber'
          name='customerReferenceNumber'
          label={formatMessage(messages.customerReferenceNumber)}
          value={formik.values.customerReferenceNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={getError('customerReferenceNumber')}
          helperText={getError('customerReferenceNumber')}
          placeholder={formatMessage(messages.customerReferenceNumberPlaceholder)}
          InputLabelProps={{ shrink: true }}
          disabled={disabled}
        />
      </Grid>
      {formik.values.submittedDate && (
        <Grid item xs={isTenantUser ? 4 : 4}>
          <TextField
            fullWidth={true}
            id='trackingNumber'
            name='trackingNumber'
            label={formatMessage(messages.trackingNumber)}
            value={formik.values.trackingNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={getError('trackingNumber')}
            helperText={getError('trackingNumber')}
            type='text'
            InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ShipmentIdentity
