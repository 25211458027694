// eslint-disable-next-line
import { Box, Typography } from '@mui/material'
// eslint-disable-next-line
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useAppSelector } from '../../app/hooks'
import { useGetTransportJobsQuery } from '../../app/redux-fetch/apiQuery'
import MRTDataGridv2 from '../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../components/Typographies/TableTitleTypography'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import { LoadAllocation, TransportJob } from './types'

const TransportJobTable = () => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { data, isLoading, isError } = useGetTransportJobsQuery(loggedInUser.tenantId ?? -1)
  // eslint-disable-next-line
  const transportJobColumns = useMemo<Array<MRT_ColumnDef<TransportJob>>>(() => {
    // eslint-disable-next-line
    const columns: Array<MRT_ColumnDef<TransportJob>> = [
      {
        header: 'Destionations',
        accessorFn: (row) => {
          return row.route.destinations.map((d, index) => {
            return (
              <>
                <Typography>
                  {d.location.name}{index !== row.route.destinations.length - 1 && (<> &gt;</>)}
                </Typography>
              </>
            );
          });
        }
      },
      {
        header: 'Drivers',
        accessorFn: (row) => {
          const userNames = row.assetGroup ? row.assetGroup.assetAllocations
            .flatMap(assetAllocation => assetAllocation.userAllocations)
            .filter(userAllocation => userAllocation.isDriver)
            .map(userAllocation => `${userAllocation.user.firstName} ${userAllocation.user.lastName}`) : [];
          return userNames.map((d, index) => {
            return (
              <>
                <Typography>
                  {d}
                </Typography>
              </>
            );
          });


        }
      },
      {
        header: 'Assets',
        accessorFn: (row) => {

          const identifications = row.assetGroup ? row.assetGroup.assetAllocations
            .map(assetAllocation => assetAllocation.asset.identification) : [];
          return identifications.map((d, index) => {

            return (
              <>
                <Typography>
                  {d}
                </Typography>
              </>
            );
          });
        }
      },
    ]
    return columns
  }, [data])

  // eslint-disable-next-line
  const loadColumns = useMemo<Array<MRT_ColumnDef<LoadAllocation>>>(() => {
    // eslint-disable-next-line
    const columns: Array<MRT_ColumnDef<LoadAllocation>> = [
      {
        header: 'Quantity',
        accessorFn: (row) => {
          return row.load.quantity
        }
      },
      {
        header: 'Description',
        accessorFn: (row) => {
          return row.load.description
        }
      },
      {
        header: 'Tracking Number',
        accessorFn: (row) => {
          return row.load.shipment.trackingNumber
        }
      },
      {
        header: 'Customer',
        accessorFn: (row) => {
          return row.load.shipment?.customer?.name ?? ''
        }
      }
    ]
    return columns
  }, [])
  return <MRTDataGridv2
    state={{ isLoading: isLoading }}
    leftHeadingComponent={
      <Box sx={{ display: 'inline-flex' }}>
        <TableTitleTypography>Transport Jobs</TableTitleTypography>
      </Box>
    }
    columns={transportJobColumns}
    data={data || []}
    getRowId={(row) => row.id as any}
    enableExpanding
    positionActionsColumn='last'
    renderDetailPanel={({ row }) => {
      const loadData = row.original.loadAllocations
      const loadTable = (loads: LoadAllocation[]) => {
        return (
          <MRTDataGridv2
            state={{ isLoading: isLoading }}
            columns={loadColumns}
            data={loads || []}
            getRowId={(row) => row.id as any}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
          />
        )
      }
      return loadData.length > 0
        ? loadTable(loadData)
        : 'No Loads in the Transport Job'
    }}
  />
}

export default TransportJobTable
