import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../../../app/redux-fetch'
import { CUSTOMER_SETTINGS_REDUCER } from '../../../CustomerSettings/consts'
import { getSubType } from '../../../CustomerSettings/utils'
import { CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME } from './consts'
import { IExtendedUserGroup, IUserGroup } from './types'
import { SubType } from '../../../../types/common'
import { compareUserGroup, isPureUserDefined } from './utils'
import { IRole } from '../UserRoleAllocation/Role/types'

export const fetchAllCustomerSettingsUserGroupsSelector = makeTypedFetchSelectors<IUserGroup[]>(
  CUSTOMER_SETTINGS_REDUCER,
  CUSTOMER_SETTINGS_ALL_USER_GROUP_REDUCER_NAME
)

export const fetchAllCustomerSettingsSortedUserGroupsSelector = createSelector(
  fetchAllCustomerSettingsUserGroupsSelector.data,
  (users: IUserGroup[] | null) => {
    if (!users) return []
    const allUserGroups = users.map((user) => ({
      ...user,
      entity: 'userGroup',
      dirty: false,
      subType: getSubType(user.customerId),
      isCloneOfSysmtemDefined: false,
      isPureUserDefined: false,
      canDelete: false,
      httpAction: undefined,
    }))

    // Only pure user defined currencies can be deleted
    allUserGroups.forEach((c) => {
      if (c.subType !== SubType.UserDefined) return
      c.canDelete = true
    })

    type SortedUserGrups = typeof allUserGroups

    const duplicateSystemDefinedUserGroups = allUserGroups
      .map((c) => c)
      .reduce((acc, curr, index, array) => {
        const found = array.filter((a) => compareUserGroup(a, curr))
        if (found && found.length > 1) {
          const systemDefined = found.find((f) => f.subType === SubType.SystemDefined)
          if (systemDefined) {
            const exists = acc.find((a) => compareUserGroup(a, systemDefined))
            if (exists) return acc
            acc.push(systemDefined)
            return acc
          }
        }
        return acc
      }, [] as SortedUserGrups)

    // Only pure user defined currencies can be deleted
    allUserGroups.forEach((c) => {
      if (c.subType === SubType.SystemDefined) return
      const exists = duplicateSystemDefinedUserGroups.find((a) => compareUserGroup(a, c))
      if (exists) {
        c.isCloneOfSysmtemDefined = true
        c.canDelete = false
      }
    })

    const userGroupsSortedByName = allUserGroups
      .filter((c) => !duplicateSystemDefinedUserGroups.includes(c))
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    const pureUserDefinedUserGroups = userGroupsSortedByName
      .filter((user) => isPureUserDefined(user as IExtendedUserGroup))
      .sort((a, b) => (a.name > b.name ? 1 : -1))

    pureUserDefinedUserGroups.forEach((c) => (c.isPureUserDefined = true))

    const allOtherUserGroups = userGroupsSortedByName.filter(
      (user) => !isPureUserDefined(user as IExtendedUserGroup)
    )

    const userGroupsSortedByPureUserDefined = pureUserDefinedUserGroups.concat(allOtherUserGroups)

    return userGroupsSortedByPureUserDefined as IUserGroup[]
  }
)
export interface IExtendedRole extends IRole {
  customerId?: number
}
