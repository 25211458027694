import { Formik, Form } from 'formik';
import { PropsWithChildren, useEffect } from 'react';
import { IAssetSettingsValues } from '../types';
import { AssetClassMRT } from './AssetClassMRTV2';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { 
  fetchAssetClassesByCategorySelector,
  fetchSortedAssetClassByCategorySelector, 
} from '../selectors';
import { fetchAssetClassesByCategoryThunk  } from '../slice';
import { ILoggedInUser } from '../../../../types';
import { fetchLoggedInUserSelector } from '../../../../selectors';

 type AssetClassMRTFormProps = Pick<IAssetSettingsValues, 'assetClasses' > & { assetCategoryId?: number };

export function AssetClassMRTForm({ assetCategoryId }: PropsWithChildren<AssetClassMRTFormProps>) {
  const { tenantId } = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser);
  const isFetchingClasses = useAppSelector(fetchAssetClassesByCategorySelector.isFetching);
  const assetClasses = useAppSelector(fetchSortedAssetClassByCategorySelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (assetCategoryId){
      dispatch(fetchAssetClassesByCategoryThunk(tenantId, assetCategoryId))
    }
  }, [tenantId, assetCategoryId]);

  return (
      <Formik
        initialValues={{assetClasses, selectRow: 0, assetCategoryId }}
        enableReinitialize
        onSubmit={() => {
          dispatch(fetchAssetClassesByCategoryThunk(tenantId, assetCategoryId as number));
        }}
      >
         {Boolean(assetCategoryId) && (<Form>
          <AssetClassMRT 
            isLoading={ isFetchingClasses } 
            tenantId={tenantId} 
          />
          </Form>)
        }
      </Formik>
  );
};

