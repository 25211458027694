import { useIntl } from 'react-intl'
import {
  MRT_ColumnDef as MRTColumnDef,
  MRT_Row as MRTRow,
  MRT_TableInstance as MRTTableInstance,
} from 'material-react-table'
import MRTDataGrid from '../../../../../components/MRTDataGrid/MRTDataGrid'
import { IAsset, IAssetType, IUserTypeAllocations } from '../types'
import { messages as assetTypeMrtvMessage } from './messages'
import { useMemo, useState } from 'react'
import { ICommonAttributes, SubType } from '../../../../../types/common'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Delete, Edit, Undo } from '@mui/icons-material'
import { HttpAction } from '../../../../enums'
import { EditAssetTypeMrtModal } from './Modals/EditAssetTypeMrtModal'
import { useAssetTypeMrtFormContext } from './hooks/useAssetTypeMrtFormContext'
import { AddAssetTypeMrtModal } from './Modals/AddAssetTypeMrtModal'
import { updateAssetType } from './api/updateAssetType'
import messages from '../messages';
import { useNotificationStack } from '../../../../Shipments/hooks/useNotificationStack'
import { createAssetType } from './api/createAssetType'
import { DeleteAssetTypeMrtModal } from './Modals/DeleteAssetTypeMrtModal'
import { deleteAssetType } from './api/deleteAssetType'
import { Enabled } from '../../../../../components/Enabled/Enabled'

interface IAssetTypeProps {
  isLoading: boolean;
  tenantId: number;
}

export const AssetTypeMRT = ({ isLoading, tenantId }: IAssetTypeProps) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, submitForm } = useAssetTypeMrtFormContext();
  const { assetTypes: data = [], assetCategoryId = -1, userTypes = [] } = values;
  const { enqueue, enqueueFailureMsg } = useNotificationStack();

  const columns = useMemo<MRTColumnDef<Partial<IAssetType>>[]>(() => {
    return [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        id: 'enabled',
        header: 'Enabled',
        columnDefType: 'boolean',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        accessorFn: (originalRow) => (originalRow.enabled ? 'true' : 'false'),
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetType>>
          table: MRTTableInstance<Partial<IAssetType>>
        }) => {
          const { httpAction } = row.original as ICommonAttributes
          const disableControl = httpAction === HttpAction.DELETE

          return <Enabled isEnabled={row.original.enabled} />
        },
      },
      {
        id: 'isMotorized',
        accessorKey: 'isMotorized',
        header: 'Motorized',
        columnDefType: 'boolean',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetType>>
        }) => <Enabled isEnabled={row.original.isMotorized} />,
      },
      {
        id: 'requiresDriver',
        accessorKey: 'requiresDriver',
        header: 'Requires driver',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetType>>
        }) => <Enabled isEnabled={row.original.requiresDriver} />,
      },
      {
        id: 'sealable',
        accessorKey: 'sealable',
        header: 'Sealable',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetType>>
        }) => <Enabled isEnabled={row.original.sealable} />,
      },
      {
        id: 'carriesPayload',
        accessorKey: 'carriesPayload',
        header: 'Carries payload',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({
          row,
        }: {
          row: MRTRow<Partial<IAssetType>>
        }) => <Enabled isEnabled={row.original.carriesPayload} />
      },
      {
        id: 'userTypes',
        header: 'User types',
        accessorKey: 'userTypes',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        accessorFn: (originalRow) => ((originalRow.userTypes)?.map(({ name }) => name).join(',')),
      }
    ] as MRTColumnDef<Partial<IAssetType>>[]
  }, [])

  const columnsDef = useMemo(() => {
    return columns.reduce((acc, item) => {
      acc[item.id ?? ''] = item.header;
      return acc;
    }, {} as { [key: string]: string });
  }, [columns]);

  const { assetsTypes } = assetTypeMrtvMessage;
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [editRow, setEditRow] = useState<MRTRow<Partial<IAssetType>> | null>(null);
  const [deleteRow, setDeleteRow] = useState<MRTRow<Partial<IAssetType>> | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleRowSelectionChange = ({
    row,
  }: {
    isDetailPanel?: boolean | undefined
    row: MRTRow<Partial<IAssetType>>
    table: MRTTableInstance<Partial<IAssetType>>
  }) => {
    return {
      onClick: () => {
        setFieldValue('selectedRow', Number(row.id));
      },
      sx: {
        cursor: 'pointer',
        backgroundColor:
          Number(row.id) === values.selectedRow ? 'lightgrey' : 'initial',
      },
    }
  }

  const onAddSubmit = async (values: IAssetType) => {
    setIsSubmitting(true);
    const userTypeAllocations = (values.userTypes || []).reduce((acc, item) => {
      const userType = userTypes.find((f) => f.name === item.name);
      if (userType) {
        acc.push({ userTypeId: userType.id } as IUserTypeAllocations);
      }
      return acc;
    }, [] as IUserTypeAllocations[]);

    const result = await createAssetType({
      ...values,
      tenantId,
      assetCategoryId,
      userTypeAllocations,
    });

    if (result.success) {
      enqueue(formatMessage(messages.typeCreatedSuccessfully));
      setCreateModalIsOpen(false);
      submitForm();
    } else {
      enqueueFailureMsg(formatMessage(messages.typeCreationFailed));
    }

    setIsSubmitting(false);
  }

  const onEditSubmit = async (values: IAssetType) => {
    setIsSubmitting(true);
    if (values.subType === SubType.SystemDefined) {
      await onAddSubmit(values);
      setEditRow(null);
    } else if (values.isCloneOfSysmtemDefined) {
      await onDeleteSubmit(values);
      setEditRow(null);
    } else {
      const userTypeAllocations = (values.userTypes || []).reduce((acc, item) => {
        const userType = userTypes.find((f) => f.name === item.name);
        if (userType) {
          acc.push({ userTypeId: userType.id, assetTypeId: values.id } as IUserTypeAllocations);
        }
        return acc;
      }, [] as IUserTypeAllocations[]);

      const result = await updateAssetType({ ...values, tenantId, userTypeAllocations });
      const resultMsgs = result.isSystemDefined ? {
        success: messages.typeEnabledSuccessfully,
        failure: messages.typeEnabledFailed,
      } : {
        success: messages.typeUpdatedSuccessfully,
        failure: messages.typeUpdateFailed,
      };

      if (result.success) {
        enqueue(formatMessage(resultMsgs.success));
        setEditRow(null);
        submitForm();
      } else {
        enqueueFailureMsg(formatMessage(resultMsgs.failure));
      }
    }
    setIsSubmitting(false);
  }

  const onDeleteSubmit = async (values: IAssetType) => {
    setIsSubmitting(true);
    const result = await deleteAssetType({ ...values, tenantId, assetCategoryId });

    if (result.success) {
      enqueue(formatMessage(messages.typeDeletedSuccessfully));
      setDeleteRow(null);
      submitForm();
    } else {
      enqueueFailureMsg(formatMessage(messages.typeDeletionFailed));
    }

    setIsSubmitting(false);
  }

  return (
    <>
      {createModalIsOpen &&
        (<AddAssetTypeMrtModal
          columnDef={columnsDef}
          onClose={() => setCreateModalIsOpen(false)}
          onSubmit={onAddSubmit}
          dialogTitle={formatMessage(assetTypeMrtvMessage.addAssetTypeTitle)}
          loading={isSubmitting}
        />)}
      {editRow &&
        (<EditAssetTypeMrtModal
          columnDef={columnsDef}
          row={editRow}
          onClose={() => setEditRow(null)}
          onSubmit={onEditSubmit}
          dialogTitle={formatMessage(assetTypeMrtvMessage.editAssetTypeTitle)}
          loading={isSubmitting}
        />)}
      {deleteRow &&
        (<DeleteAssetTypeMrtModal
          columnDef={columnsDef}
          row={deleteRow}
          onClose={() => setDeleteRow(null)}
          onSubmit={onDeleteSubmit}
          dialogTitle={formatMessage(assetTypeMrtvMessage.deleteAssetTypeTitle)}
          loading={isSubmitting}
        />)}
      <MRTDataGrid
        heading={assetsTypes}
        columns={columns}
        data={data}
        handleAddItem={() => setCreateModalIsOpen(true)}
        getRowId={(row) => `${row.id}`}
        muiTableBodyRowProps={handleRowSelectionChange}
        state={{ isLoading }}
        enableEditing
        renderRowActions={({ row, }) => {
          const { tenantId, isCloneOfSysmtemDefined, httpAction } =
            row.original as IAsset

          const markedForDeletion = httpAction === HttpAction.DELETE
          const isUserDefined = !!tenantId
          const editable = isUserDefined && !isCloneOfSysmtemDefined

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement='left' title='Edit'>
                <IconButton color='info' onClick={() => setEditRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {editable && (
                <Tooltip arrow placement='right' title={!markedForDeletion ? 'Delete' : 'Undo'}>
                  <IconButton
                    color='info'
                    onClick={() => {
                      setDeleteRow(row);
                    }}
                  >
                    {!markedForDeletion ? <Delete /> : <Undo color='info' />}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }}
      />
    </>
  )
}
