import { Box, BoxProps, Typography, TypographyProps, styled } from '@mui/material'

export const LoadSummaryHeaderBox = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexFlow: 'row wrap',
  gap: '12px',
  justifyContent: 'space-between',
  paddingRight: '12px',
}))

export const LoadSummaryHeaderItem = styled(Typography)<TypographyProps>(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))
