import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid'
import { SubType } from '../../types/common'
import { isLocaleEquals } from '../utils'
import { ICurrencyRow } from './CurrencySettings/Currency/types'

export const getSubType = (customerId?: number | null) =>
  customerId === undefined ? SubType.SystemDefined : SubType.UserDefined

export const matchCurrencies = (a: ICurrencyRow, b: ICurrencyRow) => {
  const result =
    isLocaleEquals(a.name, b.name) &&
    isLocaleEquals(a.iso3, b.iso3) &&
    isLocaleEquals(a.symbol, b.symbol) &&
    isLocaleEquals(a.localisedSymbol, b.localisedSymbol)

  return result
}

export const compareCurrencyAllProps = (a: ICurrencyRow, b: ICurrencyRow) =>
  a.enabled === b.enabled && a.httpAction === b.httpAction && matchCurrencies(a, b)

type MuiGridRow = GridRenderCellParams<any, any, any, GridTreeNodeWithRender>['row']

export const isPureUserDefined = (row: MuiGridRow) => {
  const { enabled, isCloneOfSysmtemDefined, subType } = row
  return subType === SubType.UserDefined && enabled && !isCloneOfSysmtemDefined
}
