import { AppBar, Box, Button } from '@mui/material'
import MemberRoleTabPanel from '../../MemberRoleTabPanel/MemberRoleTabPanel'
import MultiGridLayout from '../../../../../components/MultiGridLayout/MultiGridLayout'

interface IControlBar {
  isFormDirty: () => boolean
  handleSubmit: (event: any) => void
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
}
const ControlBar = ({ isFormDirty, handleSubmit, activeTab, setActiveTab }: IControlBar) => {
  return (
    <AppBar
      position='sticky'
      sx={{
        top: 118,
        backgroundColor: '#fff',
        boxShadow: 'none',
        // outline: '1px solid blue',
        zIndex: 1100,
      }}
      className='user-group-tab-controls-appbar'
    >
      <MultiGridLayout>
        <Box sx={{ padding: '20px 20px 0 10px' }}>
          <Button
            disabled={!isFormDirty()}
            // sx={{ margin: '10px 0' }}
            onClick={handleSubmit}
            variant='contained'
          >
            {'Save settings'}
          </Button>
        </Box>
        <Box sx={{ padding: '20px' }}>
          <MemberRoleTabPanel activeTab={activeTab} setActiveTab={setActiveTab} />
        </Box>
      </MultiGridLayout>
    </AppBar>
  )
}

export default ControlBar
