import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../../../app/redux-fetch'
import { COMMON_SETTINGS_REDUCER } from '../../consts'
import { COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME } from './consts'
import { ISecurityPrincipal } from './types'

export const fetchAllSecurityPrincipalSelector = makeTypedFetchSelectors<ISecurityPrincipal[]>(
  COMMON_SETTINGS_REDUCER,
  COMMON_SETTINGS_ALL_SECURITY_PRINCIPALS_REDUCER_NAME
)

export const fetchAllSortedSecurityPrincipalSelector = createSelector(
  fetchAllSecurityPrincipalSelector.data,
  (securityPrincipals: ISecurityPrincipal[] | null) => {
    if (!securityPrincipals) return []
    const sortedSecurityPrincipals = securityPrincipals?.sort((a, b) => (a.name > b.name ? 1 : -1))
    return sortedSecurityPrincipals as ISecurityPrincipal[]
  }
)
