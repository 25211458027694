import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { NotiStackOptions } from '../../utils';
import { getApiErrorMsgs } from '../dataUtils/dataUtils';
import { ApiErrorMsgsType } from '../types';


export function useNotificationStack() {
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();

    const enqueueMsg = (data: ApiErrorMsgsType) => {
        const msgs = getApiErrorMsgs(data);
        msgs.forEach((msg) => {
            enqueueSnackbar(formatMessage({
                id: msg,
                defaultMessage: msg,
            }), NotiStackOptions.error);
        })
    };

    const enqueue = (msg: string) => {
        enqueueSnackbar(formatMessage({
            id: msg,
            defaultMessage: msg,
        }), NotiStackOptions.success);
    };

    const enqueueFailureMsg = (msg: string) => {
        enqueueSnackbar(formatMessage({
            id: msg,
            defaultMessage: msg,
        }), NotiStackOptions.error);
    };

    const enqueueErrorMsg = (data: ApiErrorMsgsType) => {
        const msgs = getApiErrorMsgs(data);
        msgs.forEach((msg) => {
            enqueueSnackbar(formatMessage({
                id: msg,
                defaultMessage: msg,
            }), NotiStackOptions.error);
        })
    };

    return {
        enqueueErrorMsg,
        enqueueMsg,
        enqueue,
        enqueueFailureMsg,
    }
}