import { useFormik } from 'formik'

export enum SubType {
  SystemDefined = 'SystemDefined',
  UserDefined = 'UserDefined',
}
// remove
export interface ICommonAttributes {
  id?: number
  name: string
  enabled: boolean
  customerId?: number
  isMotorized: boolean
  entity: 'category' | 'type' | 'class' | 'currency' | 'user' | 'userGroup' | 'role' | 'allocation'
  dirty: boolean
  httpAction: 'POST' | 'PUT' | 'DELETE' | undefined
  assetCategoryId?: number
  transportModeId?: number
  tenantId?: number
  error?: string
  formHasErrors?: boolean
  canDelete?: boolean
  isCloneOfSysmtemDefined?: boolean
  isNewCurrencyRow?: boolean
  isPureUserDefined: boolean
  subType: SubType
  isNew: boolean
}

export type IFormik = ReturnType<typeof useFormik> | any
