import { FormikProps } from 'formik'
import { ILoggedInUser } from '../features/types'

export const getProperty = <T>(o: T, propertyName: string): T[keyof T] => {
  return o[propertyName as keyof T]
}

const get = (from: any, ...selectors: any) => {
  return [...selectors].map((s) =>
    s
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t: any) => t !== '')
      .reduce((prev: any, cur: any) => prev && prev[cur], from)
  )
}

export const getError = (formik: FormikProps<any>, id: string) => {
  const touched = formik.touched && get(formik.touched, id)[0]
  const errorMessage = formik.errors && get(formik.errors, id)[0]
  return touched && errorMessage
}

export const REQUEST_INITIAL_STATE = {
  data: null,
  error: null,
  isFetching: false,
  isFinsihed: false,
}

interface IStringMap {
  [key: string]: any
}

export const getAddressObject = (addressComponents: any) => {
  const ShouldBeComponent: IStringMap = {
    home: ['street_number'],
    postalCode: ['postal_code'],
    street: ['street_address', 'route'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    city: [
      'locality',
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
      'postal_town',
    ],
    country: ['country'],
  }

  const address: IStringMap = {
    home: '',
    postalCode: '',
    street: '',
    region: '',
    city: '',
    country: '',
  }
  addressComponents.forEach((component: any) => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name
        } else {
          address[shouldBe] = component.long_name
        }
      }
    }
  })
  return address
}


export const getCompanyName = (user: ILoggedInUser | null): string | null => {
  if (!user) return null

  if (user.customerId) {
    const selectedCustomer = user.customers.find((customer) => customer.id === user.customerId)
    return selectedCustomer ? selectedCustomer.name : null
  }

  if (user.tenantId) {
    const selectedTenant = user.tenants.find((tenant) => tenant.id === user.tenantId)
    return selectedTenant ? selectedTenant.name ?? '' : null
  }

  return null
}