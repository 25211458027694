import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../../../app/axios/omnialog'
import { IExtendedUserGroup } from './types'

export interface IUserGroupPayload {
  tenantId: number
  customerId?: number
  userGroup: IExtendedUserGroup
}

export const fetchAllUserGroupsByCustomerId = async ({
  tenantId,
  customerId,
}: Pick<IUserGroupPayload, 'tenantId' | 'customerId'>): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.get(`/tenant/${tenantId}/user-group`)
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}/user-group`)
}

export const postUserGroupByCustomerId = async ({
  tenantId,
  customerId,
  userGroup,
}: IUserGroupPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId)
    return await usersAuthorizedFetch.post(`/tenant/${tenantId}/user-group`, userGroup)

  return await usersAuthorizedFetch.post(
    `/tenant/${tenantId}/customer/${customerId}/user-group`,
    userGroup
  )
}

export const putUserGroupByCustomerId = async ({
  tenantId,
  customerId,
  userGroup,
}: IUserGroupPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId)
    return await usersAuthorizedFetch.put(`/tenant/${tenantId}/user-group`, userGroup)

  return await usersAuthorizedFetch.put(
    `/tenant/${tenantId}/customer/${customerId}/user-group`,
    userGroup
  )
}

export const deleteUserGroupByCustomerId = async ({
  tenantId,
  customerId,
  userGroup,
}: IUserGroupPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId)
    return await usersAuthorizedFetch.delete(`/tenant/${tenantId}/user-group/${userGroup.id}`)

  return await usersAuthorizedFetch.delete(
    `/tenant/${tenantId}/customer/${customerId}/user-group/${userGroup.id}`
  )
}
