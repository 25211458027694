import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../../../app/redux-fetch'
import { CUSTOMER_SETTINGS_REDUCER } from '../../../CustomerSettings/consts'
import { getSubType } from '../../../CustomerSettings/utils'
import {
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME,
  CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME,
} from './consts'
import { IExtendedUser, IUser } from './types'
import { SubType } from '../../../../types/common'
import { compareUser, isPureUserDefined } from './utils'

export const fetchAllCustomerSettingsUsersSelector = makeTypedFetchSelectors<IUser[]>(
  CUSTOMER_SETTINGS_REDUCER,
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME
)

export const fetchAllCustomerSettingsSortedUsersSelector = createSelector(
  fetchAllCustomerSettingsUsersSelector.data,
  (users: IUser[] | null) => {
    if (!users) return []
    const allUsers = users.map((user) => ({
      ...user,
      externalId: user?.externalId,
      IdentityProviderIntegrationId: user?.IdentityProviderIntegrationId,
      entity: 'user',
      dirty: false,
      subType: getSubType(user.customerId),
      isCloneOfSysmtemDefined: false,
      isPureUserDefined: false,
      canDelete: false,
      httpAction: undefined,
    }))

    // Only pure user defined currencies can be deleted
    allUsers.forEach((c) => {
      if (c.subType !== SubType.UserDefined) return
      c.canDelete = true
    })

    type SortedUsers = typeof allUsers

    const duplicateSystemDefinedUsers = allUsers
      .map((c) => c)
      .reduce((acc, curr, index, array) => {
        const found = array.filter((a) => compareUser(a, curr))
        if (found && found.length > 1) {
          const systemDefined = found.find((f) => f.subType === SubType.SystemDefined)
          if (systemDefined) {
            const exists = acc.find((a) => compareUser(a, systemDefined))
            if (exists) return acc
            acc.push(systemDefined)
            return acc
          }
        }
        return acc
      }, [] as SortedUsers)

    // Only pure user defined currencies can be deleted
    allUsers.forEach((c) => {
      if (c.subType === SubType.SystemDefined) return
      const exists = duplicateSystemDefinedUsers.find((a) => compareUser(a, c))
      if (exists) {
        c.isCloneOfSysmtemDefined = true
        c.canDelete = false
      }
    })

    const usersSortedByName = allUsers
      .filter((c) => !duplicateSystemDefinedUsers.includes(c))
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

    const pureUserDefinedUsers = usersSortedByName
      .filter((user) => isPureUserDefined(user as IExtendedUser))
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))

    pureUserDefinedUsers.forEach((c) => (c.isPureUserDefined = true))

    const allOtherUsers = usersSortedByName.filter(
      (user) => !isPureUserDefined(user as IExtendedUser)
    )

    const usersSortedByPureUserDefined = pureUserDefinedUsers.concat(allOtherUsers)

    return usersSortedByPureUserDefined as IUser[]
  }
)

export const postUserByCustomerIdSelector = makeTypedFetchSelectors<IUser[]>(
  CUSTOMER_SETTINGS_REDUCER,
  CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME
)
