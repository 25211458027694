import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../app/redux-fetch'
import { ICustomer } from '../types'
import {
  SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME,
  SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME,
  SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_REDUCER_NAME,
  SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME,
  SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME,
  SHIPMENTS_BY_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_REDUCER,
  SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME,
} from './consts'
import {
  ICountry,
  ICurrency,
  ILocationByTenantCustomer,
  IMeasureUnit,
  IPalletType,
  IShipment,
  ITemperatureRange,
  ITransportMode,
} from './types'

export const fetchAllCountriesSelector = makeTypedFetchSelectors<ICountry[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME
)

export const fetchAllShipmentsSelector = makeTypedFetchSelectors<IShipment[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_REDUCER_NAME
)

export const fetchShipmentByIdSelector = makeTypedFetchSelectors<IShipment>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_BY_ID_REDUCER_NAME
)

export const fetchAllCurrenciesSelector = makeTypedFetchSelectors<ICurrency[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME
)

export const fetchAllTenantCurrenciesSelector = makeTypedFetchSelectors<ICurrency[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME
)

export const fetchSystemCurrenciesSelector = makeTypedFetchSelectors<ICurrency[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME
)

export const fetchAllLocationsByTenantCustomerSelector = makeTypedFetchSelectors<
  ILocationByTenantCustomer[]
>(SHIPMENTS_REDUCER, SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME)

export const fetchLocationByIdSelector = createSelector(
  [
    // Usual first input - extract value from `state`
    fetchAllLocationsByTenantCustomerSelector.data,
    // Take the second arg, `category`, and forward to the output selector
    (state, id) => id,
  ],
  // Output selector gets (`items, category)` as args
  (locations, id) => locations?.find((i) => i.id === id)
)

export const fetchAllShippersSelector = createSelector(
  fetchAllLocationsByTenantCustomerSelector.data,
  (locations: ILocationByTenantCustomer[] | null) => {
    return locations ?? []
  }
)

export const fetchAllPickupLocationsSelector = createSelector(
  fetchAllLocationsByTenantCustomerSelector.data,
  (locations: ILocationByTenantCustomer[] | null) => {
    return locations ?? []
  }
)

export const fetchAllPickupConsigneeSelector = createSelector(
  fetchAllLocationsByTenantCustomerSelector.data,
  (locations: ILocationByTenantCustomer[] | null) => {
    return locations ?? []
  }
)

export const fetchSortedCurrenciesSelector = createSelector(
  fetchAllCurrenciesSelector.data,
  (currencies: ICurrency[] | null) => {
    const result = currencies
      ? (currencies.map((x) => {
          return { id: x.id, name: x.iso3 ? x.iso3 : x.name, symbol: x.symbol }
        }) as ICurrency[])
      : []
    return result
      ? result.sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      : []
  }
)

export const fetchSortedSystemCurrenciesSelector = createSelector(
  fetchSystemCurrenciesSelector.data,
  (currencies: ICurrency[] | null) => {
    const result = currencies
      ? (currencies.map((x) => {
          return { id: x.id, name: x.iso3 ? x.iso3 : x.name, symbol: x.symbol }
        }) as ICurrency[])
      : []
    return result
      ? result.sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      : []
  }
)

export const fetchAllMeasureUnitsSelector = makeTypedFetchSelectors<IMeasureUnit[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME
)

export const fetchAllWeightUnitsSelector = createSelector(
  fetchAllMeasureUnitsSelector.data,
  (units: IMeasureUnit[] | null) => {
    return units
      ? units
          .filter((l) => l.measureTypeId === 1)
          .map((u) => {
            return { id: u.id, name: u.units }
          })
      : []
  }
)

export const fetchAllLengthUnitsSelector = createSelector(
  fetchAllMeasureUnitsSelector.data,
  (units: IMeasureUnit[] | null) => {
    return units
      ? units
          .filter((l) => l.measureTypeId === 4)
          .map((u) => {
            return { id: u.id, name: u.units }
          })
      : []
  }
)

export const fetchAllDensityUnitsSelector = createSelector(
  fetchAllMeasureUnitsSelector.data,
  (units: IMeasureUnit[] | null) => {
    return units
      ? units
          .filter((l) => l.measureTypeId === 6)
          .map((u) => {
            return { id: u.id, name: u.units }
          })
      : []
  }
)

export const fetchAllVolumeUnitsSelector = createSelector(
  fetchAllMeasureUnitsSelector.data,
  (units: IMeasureUnit[] | null) => {
    return units
      ? units
          .filter((l) => l.measureTypeId === 2)
          .map((u) => {
            return { id: u.id, name: u.units }
          })
      : []
  }
)

export const fetchAllTemperatureUnitsSelector = createSelector(
  fetchAllMeasureUnitsSelector.data,
  (units: IMeasureUnit[] | null) => {
    return units
      ? units
          .filter((l) => l.measureTypeId === 3)
          .map((u) => {
            return { id: u.id, name: u.units }
          })
      : []
  }
)

export const fetchAllTemperatureRangesSelector = makeTypedFetchSelectors<ITemperatureRange[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME
)

export const fetchAllPalletTypesSelector = makeTypedFetchSelectors<IPalletType[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME
)

export const fetchAllTransportModesSelector = makeTypedFetchSelectors<ITransportMode[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME
)

export const fetchAllLocationTypesSelector = makeTypedFetchSelectors<ITransportMode[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME
)

export const fetchAllCustomersByTenantSelector = makeTypedFetchSelectors<ICustomer[]>(
  SHIPMENTS_REDUCER,
  SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME
)
