import {
  Box,
  Button,
  Grid,
  SnackbarOrigin,
  Step,
  StepButton,
  Stepper,
  type SelectChangeEvent,
} from '@mui/material'
import { FormikErrors, setNestedObjectValues, useFormik } from 'formik'
import { cloneDeep } from 'lodash/fp'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAppSelector, useScrollToFormikFieldError } from '../../../../app/hooks'
import logger from '../../../../app/middleware/log'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { default as RoutesEnum, default as rootEnum } from '../../../../components/Routes/rootEnum'
import { fetchLoggedInUserSelector } from '../../../selectors'
import { ILoggedInUser } from '../../../types'
import { NotiStackOptions } from '../../../utils'
import { acceptShipment, submitShipment, updateShipment } from '../../api'
import { useNavigateToErrorStep } from '../../hooks/useNavigateToErrorStep'
import { useNotificationStack } from '../../hooks/useNotificationStack'
import { ApiErrorMsgsType, ILoad, IShipment } from '../../types'
import { sortByDisplayOrder, sortById } from '../../utils'
import ShipmentContainersOrAssetRequestsStep from '../CreateShipment/ShipmentContainersOrAssetRequestsStep'
import ShipmentLoadStep from '../CreateShipment/ShipmentLoadStep'
import ShipmentSummary from '../CreateShipment/ShipmentSummary'
import ShipmentSummaryStep from '../CreateShipment/ShipmentSummaryStep'
import { getShipmentValidationSchema } from '../CreateShipment/ShipmentValidationSchema'
import messages from '../CreateShipment/messages'
import { type ILoadPalletVector } from '../CreateShipment/types'
import ConditionalRender from './Components/ConditionalRender'
import { ShipmentActionButtonBehaviour } from './types'
interface EditShipmentProps {
  shipment: IShipment
  refresh: () => void
}

const EditShipmentForm = (props: EditShipmentProps): ReactElement<any, any> => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const {
    hideAllButtonsExceptBackToShipment,
    showSubmit,
    showSubmitAndAccept,
    showAccept,
  } = props.shipment.shipmentActionButtonBehavior || ({} as ShipmentActionButtonBehaviour)

  const { enqueueMsg } = useNotificationStack()

  // Localisation support.
  const anchorOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'center',
  }

  const { formatMessage } = useIntl()

  const { id = -1 } = useParams()

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const tenantId = loggedInUser?.tenantId
  const customerId = loggedInUser?.customerId
  const isCustomerUser = !!tenantId && !!customerId

  const { hash } = useLocation()
  // Initialise stepper.
  const [activeStep, setActiveStep] = React.useState(+hash.replace('#', ''))
  const [isStepRendered, setIsStepRendered] = useState(false);

  const isFinalStep = activeStep === 3

  const CreateShipmentSteps = [
    formatMessage(messages.summaryStepName),
    formatMessage(messages.createLoadsStepName),
    formatMessage(messages.assignContainersOrAssetRequestStepName),
    formatMessage(messages.confirmShipment),
  ]
  const [isDraft, setIsDraft] = useState(true)
  const [isAccept, setIsAccept] = useState(false)
  const [isSavingDraft, setIsSavingDraft] = useState(false)
  // Initialise state.
  const [lastCreatedLoadIndex, setLastCreatedLoadIndex] = useState<number | null>(null)
  const [lastCreatedPallet, setLastCreatedPallet] = useState<ILoadPalletVector | null>(null)

  const formik = useFormik<IShipment>({
    initialValues: props.shipment,
    validationSchema: getShipmentValidationSchema(formatMessage),
    onSubmit: async (values) => {
      values.trackingNumber = values.customerReferenceNumber
      if (isDraft) {
        await updateShipment(id, values, tenantId, customerId)
          .then(() => {
            setIsSavingDraft(false)
            enqueueSnackbar(formatMessage(messages.shipmentUpdateSuccess), NotiStackOptions.success)
            setIsSavingDraft(false)
            formik.resetForm()
            props.refresh()
          })
          .catch((e: any) => {
            logger.warn('shipment saving error', e.response?.data?.data?.errors);
            setIsSavingDraft(false)
            enqueueSnackbar(formatMessage(messages.shipmentUpdateError), NotiStackOptions.error);
            enqueueMsg(e.response.data as ApiErrorMsgsType)
          })
      } else {
        await updateShipment(id, values, tenantId, customerId)
          .then(async () => {
            if (!isAccept) {
              await submitShipment(tenantId, values, id, customerId)
                .then(() => {
                  enqueueSnackbar('Shipment submitted successfully', {
                    variant: 'success',
                    anchorOrigin,
                  })
                  navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(id))}`)
                  setIsSavingDraft(false)
                })
                .catch((e: any) => {
                  logger.warn('shipment submitting error', e.response?.data?.data?.errors)
                  enqueueSnackbar('Shipment has not been submitted', {
                    variant: 'error',
                    anchorOrigin,
                  })
                  enqueueMsg(e.response.data as ApiErrorMsgsType)
                  setIsSavingDraft(false)
                })
            }
            else {
              await submitShipment(tenantId, values, id, customerId)
                .then(async () => {
                  await acceptShipment(tenantId, values, id)
                    .then(() => {
                      enqueueSnackbar('Shipment accepted successfully', {
                        variant: 'success',
                        anchorOrigin,
                      })
                      navigate(`${RoutesEnum.VIEW_SHIPMENT.replace(':id', String(id))}`)
                      setIsSavingDraft(false)
                      setIsAccept(false)
                    })
                    .catch((e: any) => {
                      logger.warn('shipment submitting error', e.response?.data?.data?.errors)
                      enqueueSnackbar('Shipment has not been accepted', {
                        variant: 'error',
                        anchorOrigin,
                      })
                      enqueueMsg(e.response.data as ApiErrorMsgsType)
                      setIsSavingDraft(false)
                      setIsAccept(false)
                    })
                })
                .catch((e: any) => {
                  logger.warn('shipment submitting error', e.response?.data?.data?.errors)
                  enqueueSnackbar('Shipment has not been submitted', {
                    variant: 'error',
                    anchorOrigin,
                  })
                  enqueueMsg(e.response.data as ApiErrorMsgsType)
                  setIsSavingDraft(false)
                  setIsAccept(false)
                })
            }
          })
          .catch((e: any) => {
            logger.warn('shipment saving error', e.response?.data?.data?.errors)
            setIsSavingDraft(false)
            enqueueSnackbar('Shipment has not been saved', {
              variant: 'error',
              anchorOrigin,
            })
          })
      }
    },
  })

  useNavigateToErrorStep(formik, setActiveStep)



  useEffect(() => {
    setIsStepRendered(false);
  }, [activeStep])



  useScrollToFormikFieldError(formik, isStepRendered)

  useEffect(() => {
    formik
      .validateForm()
      .then((errors: FormikErrors<IShipment>) => {
        formik.setTouched(setNestedObjectValues(errors, true))
        if (errors.loads) {
          if (formik.values.loads.some((load: ILoad) => load.isDefined)) {
            navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${1}`)
            setActiveStep(1);
            return
          }
          navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${2}`)
          setActiveStep(2);
          return
        }
      })
  }, [])

  const handleSubmit = () => {
    formik.validateForm().then((errors: FormikErrors<IShipment>) => {
      formik.submitForm().finally(() => {
        setIsSavingDraft(false)
        formik.setTouched(setNestedObjectValues(errors, true))
      })
    })
  }

  const handleNext = (): void => {
    formik
      .validateForm()
      .then((errors: FormikErrors<IShipment>) => {
        formik.setTouched(setNestedObjectValues(errors, true))
        const newActiveStep = activeStep + 1
        if (activeStep === 2) {
          setActiveStep(newActiveStep)
          navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${newActiveStep}`)
          return
        }
        const excludedLoads = Object.keys(errors).filter((item) => item !== 'loads')
        if (activeStep <= 1 && excludedLoads.length === 0) {
          setActiveStep(newActiveStep)
          navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${newActiveStep}`)
          return
        }
      })
      .catch(() => {
        logger.error('handleBack error')
      })
  }

  const handleBack = (): void => {
    formik
      .validateForm()
      .then((errors: FormikErrors<IShipment>) => {
        formik.setTouched(setNestedObjectValues(errors, true))
        const newActiveStep = activeStep - 1
        if (activeStep === 1) {
          setActiveStep(newActiveStep)
          navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${newActiveStep}`)
          return
        }

        const excludedLoads = Object.keys(errors).filter((item) => item !== 'loads')
        if (activeStep > 0 && (excludedLoads.length === 0 || isNaN(excludedLoads.length))) {
          if (activeStep > 0) {
            setActiveStep(newActiveStep)
            navigate(`${RoutesEnum.EDIT_SHIPMENT.replace(':id', String(id))}#${newActiveStep}`)
          }
        }
      })
      .catch(() => {
        logger.error('handleBack error')
      })
  }

  const handleAddLoad = (): number => {
    setLastCreatedLoadIndex(formik.values.loads.length)
    const lastLoad = formik.values.loads.sort(sortById).slice(-1)[0]
    const lastId = lastLoad?.id ?? 0
    const lastDisplayLoad = formik.values.loads
      .filter((l) => l.isDefined)
      .sort(sortByDisplayOrder)
      .slice(-1)[0]
    const lastDisplayOrder = lastDisplayLoad?.displayOrder ?? 0
    if (formik.values.assetRequests.length === 0) {
      void formik.setFieldValue('usesContainer', true)
    }
    const initialLoadPaylaod = {
      id: lastId > 0 ? -1 * (lastId - 1) : lastId - 1,
      value: 0,
      palletDetails: [],
      isDefined: true,
      shipmentId: formik.values.id,
      displayOrder: lastDisplayOrder + 1,
    }
    void formik.setFieldValue('loads', [...formik.values.loads, initialLoadPaylaod])
    return initialLoadPaylaod.id
  }

  const handleAddPallet = (index: number): void => {
    const lastPalletVector: ILoadPalletVector = {
      loadIndex: index,
      palletIndex: formik.values.loads[index].palletDetails?.length ?? 0,
    }
    setLastCreatedPallet(lastPalletVector)
    void formik.setFieldValue(`loads.${index}.palletDetails`, [
      ...(formik.values.loads[index].palletDetails ?? []),
      {},
    ])
  }

  const handleCopyLoads = (loadIndex: number, count: number): void => {
    let lastId = formik.values.loads.sort(sortById).slice(-1)[0]?.id - 1
    let lastDisplayLoad = formik.values.loads
      .filter((l) => l.isDefined)
      .sort(sortByDisplayOrder)
      .slice(-1)[0]?.displayOrder
    const newLoads = []
    for (let i = 0; i < count; i++) {
      lastDisplayLoad++
      const copyLoad = cloneDeep(formik.values.loads[loadIndex])
      copyLoad.id = lastId > 0 ? -1 * (lastId - 1) : lastId - 1
      copyLoad.displayOrder = lastDisplayLoad
      copyLoad.assetRequestAllocations = []
      copyLoad.containerAllocations = []
      newLoads.push(copyLoad)
      lastId--
    }
    void formik.setFieldValue('loads', [...formik.values.loads, ...newLoads])
  }

  const handleFormDropDownChanges = (
    e: SelectChangeEvent<number>,
    name: string,
    newValue: any
  ): void => {
    formik.validateField(name)
    void formik.setFieldValue(name, newValue, true)
  }

  const handleRemoveAssetRequests = (index: number): void => {
    const assetRequests = [...formik.values.assetRequests]
    const remAssetRequest = assetRequests.splice(index, 1)[0]
    if (remAssetRequest.loadsId)
      for (const loadId of remAssetRequest.loadsId) {
        const loadIndex = formik.values.loads.findIndex((x) => x.id === loadId)
        const loadContainerAllocations = formik.values.loads[
          loadIndex
        ].assetRequestAllocations.filter((ar) => ar.assetRequestId !== remAssetRequest.id)
        void formik.setFieldValue(
          `loads.${loadIndex}.assetRequestAllocations`,
          loadContainerAllocations
        )
      }
    void formik.setFieldValue('assetRequests', assetRequests)
  }

  const handleRemovePallet = (loadIndex: number, palletIndex: number): void => {
    const load = formik.values.loads[loadIndex]
    const newPallets = [...(load.palletDetails ?? [])]
    newPallets.splice(palletIndex, 1)
    void formik.setFieldValue(`loads.${loadIndex}.palletDetails`, newPallets)
  }

  const handleSwitchLoadChanges = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: boolean
  ): void => {
    const { name } = e.target
    void formik.setFieldValue(name, value)
  }

  const renderContainersOrAssetRequestsStep = (): ReactElement<any, any> => {
    return (
      <ShipmentContainersOrAssetRequestsStep
        formik={formik}
        handleRemoveAssetRequest={handleRemoveAssetRequests}
        handleSelectLoadChanges={handleFormDropDownChanges}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        handleFormDropDownChanges={handleFormDropDownChanges}
      />
    )
  }

  const renderFinalSummaryStep = (): ReactElement<any, any> => {
    return (
      <ShipmentSummary
        formik={formik}
        isSameValue={isSameValue}
        isSameLoadsCurrency={isSameLoadsCurrency}
        isSameWithShipmentCurrency={isSameWithShipmentCurrency}
      />
    )
  }

  const renderLoadStep = (): ReactElement<any, any> => {
    return (
      <ShipmentLoadStep
        formik={formik}
        handleAddLoad={handleAddLoad}
        handleAddPallet={handleAddPallet}
        handleCopyLoads={handleCopyLoads}
        handleFormDropDownChanges={handleFormDropDownChanges}
        handleRemovePallet={handleRemovePallet}
        handleSwitchLoadChanges={handleSwitchLoadChanges}
        lastCreatedLoadIndex={lastCreatedLoadIndex}
        lastCreatedPallet={lastCreatedPallet}
        disabled={false}
        disabledPast={false}
      />
    )
  }

  const renderSummaryStep = (): ReactElement<any, any> => {
    return (
      <ShipmentSummaryStep formik={formik} handleFormDropDownChanges={handleFormDropDownChanges} />
    )
  }

  const isPage2 =
    formik.values.assetRequests &&
    formik.values.assetRequests.length === 0 &&
    formik.values.containerDetails &&
    formik.values.containerDetails.length === 0

  const loadsValue = formik.values.loads.reduce((acc, curr) => {
    return (acc += curr.value ? curr.value : 0)
  }, 0)

  const isSameValue = isPage2 ? loadsValue === +formik.values.value : true

  const isSameLoadsCurrency =
    formik.values.loads && formik.values.loads.length > 0
      ? formik.values.loads.every((l) => l.currencyId === formik.values.loads[0].currencyId)
      : true

  const isSameWithShipmentCurrency =
    formik.values.loads && formik.values.loads.length > 0 && isSameLoadsCurrency
      ? formik.values.loads[0].currencyId === formik.values.currencyId
      : true

  const haveLoads = formik.values.loads.length > 0

  const isSubmitDisable = () => {
    return (
      !(
        haveLoads &&
        formik.isValid &&
        formik.values.acceptTermsAndCondition &&
        (formik.values.acceptWarrnings || isSameLoadsCurrency) &&
        isSameWithShipmentCurrency &&
        isSameValue
      ) ||
      isSavingDraft ||
      formik.isSubmitting
    )
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handleBackToShipment = useCallback(() => {
    if (!formik.dirty) {
      return navigate(rootEnum.SHIPMENTS)
    }

    if (formik.dirty) {
      setOpenConfirmDialog(true)
    }
  }, [formik.dirty, navigate])

  const handleContinueEdit = () => setOpenConfirmDialog(false)

  const handleDiscardChanges = () => {
    setOpenConfirmDialog(false)
    navigate(rootEnum.SHIPMENTS)
  }
  useEffect(() => {
    setIsStepRendered(true);
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent='center' pt='10px'>
        <Grid item xs={12} minWidth='800px' pb={'30px'}>
          <Stepper activeStep={activeStep}>
            {CreateShipmentSteps.map((label) => (
              <Step key={label}>
                <StepButton color='inherit'>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} minWidth='800px' pb={'20px'} pl={'10px'}>
          <Grid container spacing={1}>
            <Grid container spacing={1} xs>
              <Grid item>
                <ConditionalRender condition={!hideAllButtonsExceptBackToShipment}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {formatMessage(messages.buttonBack)}
                  </Button>
                </ConditionalRender>
              </Grid>
              <Grid item>
                <Button variant='contained' onClick={handleBackToShipment}>
                  {formatMessage(messages.buttonBackToShipmentst)}
                </Button>
              </Grid>
            </Grid>
            <Grid item></Grid>
            <Grid container justifyContent='flex-end' spacing={1} xs>
              <Grid item>
                <ConditionalRender condition={isCustomerUser && !hideAllButtonsExceptBackToShipment}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      setIsDraft(true)
                      handleSubmit()
                    }}
                  >


                    {formatMessage(messages.buttonSaveDraft)}
                  </Button>
                </ConditionalRender>
              </Grid>
              {!isFinalStep ? (
                <Grid item>
                  <ConditionalRender condition={!hideAllButtonsExceptBackToShipment}>
                    <Button color='primary' variant='contained' onClick={handleNext}>
                      {formatMessage(messages.buttonNext)}
                    </Button>
                  </ConditionalRender>
                </Grid>
              ) : (
                <ConditionalRender condition={!hideAllButtonsExceptBackToShipment}>
                  <Grid item>
                    <ConditionalRender condition={showSubmitAndAccept || showAccept}>
                      <Button
                        color='primary'
                        variant='contained'
                        disabled={isSubmitDisable()}
                        onClick={() => {
                          setIsDraft(false)
                          setIsAccept(true)
                          handleSubmit()
                        }}
                      >
                        {formatMessage(messages.buttonSubmitAndAccept)}
                      </Button>
                    </ConditionalRender>
                  </Grid>

                  <Grid item>
                    <ConditionalRender
                      condition={!hideAllButtonsExceptBackToShipment || showSubmit}
                    >
                      <Button
                        color='primary'
                        variant='contained'
                        disabled={isSubmitDisable()}
                        onClick={() => {
                          setIsDraft(false)
                          setIsAccept(false)
                          handleSubmit()
                        }}
                      >
                        {formatMessage(messages.buttonSubmit)}
                      </Button>
                    </ConditionalRender>
                  </Grid>
                </ConditionalRender>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} minHeight='400px' minWidth='800px' pb='20px'>
          <Box>
            {activeStep === 0 && renderSummaryStep()}
            {activeStep === 1 && renderLoadStep()}
            {activeStep === 2 && renderContainersOrAssetRequestsStep()}
            {isFinalStep && renderFinalSummaryStep()}
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openConfirmDialog}
        title={formatMessage(messages.confirmDialogTitle)}
        content={formatMessage(messages.confirmDialogMessageContent)}
        continueButtonText={formatMessage(messages.confirmDialogContinueEditing)}
        discardButtonText={formatMessage(messages.confirmDialogDiscardSelection)}
        onContinueEdit={handleContinueEdit}
        onDiscardChanges={handleDiscardChanges}
      />
    </form>
  )
}

export default EditShipmentForm
