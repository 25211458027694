import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { AnyObjectSchema } from 'yup';
import { IAssetManagement } from '../../types';
import { useAssetManagementFormContext } from '../hooks/useAssetManagementFormContext';
import { messages } from '../messages';

interface ButtonGroupProps {
    disabled?: boolean;
    onSave: (values: IAssetManagement) => void,
    onClose: () => void,
    schema: AnyObjectSchema,
}

export function ButtonGroup({
    disabled,
    onSave,
    onClose,
    schema
}: ButtonGroupProps) {
    const { formatMessage } = useIntl();
    const { values, errors } = useAssetManagementFormContext();

    const isValid = schema.isValidSync(values);

    return (
        <>
            <Button
                autoFocus
                color="inherit"
                onClick={onClose}
                disabled={disabled}
            >
                {formatMessage(messages.cancelAssetBtn)}
            </Button>
            <Button
                autoFocus
                color="inherit"
                onClick={() => onSave(values)}
                disabled={disabled || !isValid}
            >
                {formatMessage(messages.saveAssetBtn)}
            </Button>
        </>
    );
}
