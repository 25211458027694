import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../../../../app/axios/omnialog'
import { IRole } from './types'

export interface IRolePayload {
  tenantId: number
  customerId?: number
  role: IRole
}

export const fetchAllRoles = async ({
  tenantId,
  customerId,
}: Omit<IRolePayload, 'role'>): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.get(`/tenant/${tenantId}/role`)
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}/role`)
}

export const postRole = async ({
  tenantId,
  customerId,
  role,
}: IRolePayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.post(`/tenant/${tenantId}/role`, role)
  return await usersAuthorizedFetch.post(`/tenant/${tenantId}/customer/${customerId}/role`, role)
}

export const putRole = async ({
  tenantId,
  customerId,
  role,
}: IRolePayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.put(`/tenant/${tenantId}/role`, role)
  return await usersAuthorizedFetch.put(`/tenant/${tenantId}/customer/${customerId}/role`, role)
}

export const deleteRole = async ({
  tenantId,
  customerId,
  role,
}: IRolePayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.put(`/tenant/${tenantId}/role/${role.id}`)
  return await usersAuthorizedFetch.delete(
    `/tenant/${tenantId}/customer/${customerId}/role/${role.id}`
  )
}
