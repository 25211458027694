import * as yup from 'yup'

export const assetConfigurationCreateSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    name: yup.string().required(),
    tenantId: yup.number().required().positive(),
    transportModeId: yup.number().required().positive(),
    allocations: yup.array().of(yup.object({
      assetClassId: yup.number().required().positive(),
      assetTypeId: yup.number().required().positive(),
    })).min(1).required()
  })
}

export const assetConfigurationUpdateSchema = (): yup.AnyObjectSchema => {
  return yup.object({
    id: yup.number().required().positive(),
    name: yup.string().required(),
    tenantId: yup.number().required().positive(),
    transportModeId: yup.number().required().positive(),
    allocations: yup.array().of(yup.object({
      id: yup.number(),
      assetClassId: yup.number().required().positive(),
      assetTypeId: yup.number().required().positive(),
    })).min(1).required()
  })
}
