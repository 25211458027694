import { AxiosResponse } from 'axios'
import { ICurrency } from './types'
import { customerSettingsAuthorizedFetch } from '../../../app/axios/omnialog'

export const putCurrency = async (currency: ICurrency): Promise<AxiosResponse<any, any>> => {
  return await customerSettingsAuthorizedFetch.put('/currency', currency)
}

export const postCurrency = async (currency: ICurrency): Promise<AxiosResponse<any, any>> => {
  return await customerSettingsAuthorizedFetch.post('/currency', currency)
}

export const deleteCurrency = async (currency: ICurrency): Promise<AxiosResponse<any, any>> => {
  const { id, customerId } = currency
  return await customerSettingsAuthorizedFetch.delete(`/customer/${customerId}/currency/${id}`)
}
