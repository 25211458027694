import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tableHeading: {
    id: 'tenantSettings.securityPrincipal.tableHeading',
    description: 'Security Principal settings table',
    defaultMessage: 'Security Principals',
  },
})

export default messages
