import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { FormikProps } from 'formik'
import { ReactElement, useState, type DragEvent } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../app/hooks'
import { useGetProductsQuery } from '../../../../app/redux-fetch/apiQuery'
import { IDropDownItem } from '../../../../app/types'
import { fetchTransportsAssetConfigurationSelector } from '../../../Transport/selectors'
import {
  fetchAllPalletTypesSelector,
  fetchAllPickupConsigneeSelector,
  fetchAllPickupLocationsSelector,
} from '../../selectors'
import {
  IAssetRequest,
  IAssetRequestAllocation,
  IContainerDetail,
  ILoad,
  IShipment,
  ITemperatureSetting,
} from '../../types'
import { handleMapLoad, handleRemoveLoadMap } from '../../utils'
import messages from './messages'
import { LoadAccordion } from './styles'

interface IAssignmentLoadPaneProps {
  formik: FormikProps<IShipment>
  load: ILoad
  loadIndex: number
  disabled?: boolean
}

const AssignmentLoad = (props: IAssignmentLoadPaneProps): ReactElement<any, any> => {
  const { formatMessage } = useIntl()
  const { formik, disabled, load, loadIndex } = props
  const { data: products } = useGetProductsQuery(formik.values.customerId ?? -1)
  const consigneeLocations = useAppSelector(fetchAllPickupConsigneeSelector)
  const pickUpLocations = useAppSelector(fetchAllPickupLocationsSelector)
  const palletTypesItems = useAppSelector(fetchAllPalletTypesSelector.data) ?? []
  const assetConfigurations = useAppSelector(fetchTransportsAssetConfigurationSelector.data) ?? []

  const [isExpanded, setIsExpanded] = useState(false)
  const pickupDate = load.transportDetail?.pickupDate
    ? dayjs(load.transportDetail?.pickupDate).format('DD/MM/YYYY')
    : ''
  const deliveryDate = load.transportDetail?.deliveryDate
    ? dayjs(load.transportDetail?.deliveryDate).format('DD/MM/YYYY')
    : ''
  const handleDragOver = (event: DragEvent): void => {
    event.preventDefault()
  }

  const handleDrop = (event: DragEvent, targetIndex: number): void => {
    event.preventDefault()
    const target = formik.values.loads[targetIndex]

    const incomingData = event.dataTransfer.getData('string')
    if ((incomingData?.substring(0, 10) ?? '') === 'CONTAINER_') {
      const incomingContainerIndex = Number(incomingData.substring(10))
      const incomingContainer = formik.values.containerDetails.find(
        (c) => c.id === incomingContainerIndex
      )
      handleMapLoad(formik, target.id, null, incomingContainer?.id)
    }

    if ((incomingData?.substring(0, 13) ?? '') === 'ASSETREQUEST_') {
      const incomingAssetRequestIndex = Number(incomingData.substring(13))
      const incomingAssetRequest = formik.values.assetRequests.find(
        (ar) => ar.id === incomingAssetRequestIndex
      )
      handleMapLoad(formik, target.id, incomingAssetRequest?.id, null)
    }
  }

  const removeAssetRequest = (loadId: number, assetRequestId: number): void => {
    handleRemoveLoadMap(formik, loadId, assetRequestId, null)
  }

  const removeContainer = (loadId: number, containerId: number): void => {
    handleRemoveLoadMap(formik, loadId, null, containerId)
  }
  const RenderAssetRequests = (
    loadAssetRequestIndex: number,
    removeAssetRequest: (loadId: number, assetRequestId: number) => void,
    load: ILoad,
    assetRequestAllocation: IAssetRequestAllocation,
    arIndex: number,
    assetRequest: IAssetRequest | undefined
  ): JSX.Element => {
    const config = assetConfigurations?.find(
      (item) => item.id === assetRequest?.assetConfigurationId
    )

    return (
      <Grid item key={loadAssetRequestIndex} xs={12} sx={{ paddingTop: '12px' }}>
        <Box
          display='flex'
          justifyContent='start'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '3px',
            margin: '2px',
          }}
        >
          {!disabled && (
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                removeAssetRequest(load.id, assetRequestAllocation.assetRequestId)
              }}
              sx={{ color: 'red', cursor: 'pointer' }}
            />
          )}
          AssetRequest #{arIndex + 1}: {assetRequest?.quantity} {config?.name}
        </Box>
      </Grid>
    )
  }

  const handleChangeAssignmentDropDown = (
    event: SelectChangeEvent<IDropDownItem>,
    isContainer: boolean
  ) => {
    const {
      target: { value },
    } = event
    if (isContainer) {
      handleMapLoad(formik, load.id, null, +value)
    } else {
      handleMapLoad(formik, load.id, +value, null)
    }
  }
  const RenderAssignmentDropDown = (): JSX.Element => {
    const notSelectedContainers = formik.values.containerDetails.reduce((acc, curr, index) => {
      if (!load?.containerAllocations || load?.containerAllocations.length === 0) {
        acc.push({ id: curr.id, name: `Container # ${index + 1}` })
      }
      if (
        load?.containerAllocations &&
        load?.containerAllocations.length > 0 &&
        !load?.containerAllocations?.some((c) => c.containerDetailId === curr.id)
      ) {
        acc.push({ id: curr.id, name: `Container # ${index + 1}` })
      }
      return acc
    }, [] as IDropDownItem[])
    const notSelectedAssetRequests = formik.values.assetRequests.reduce((acc, curr, index) => {
      if (!load?.assetRequestAllocations || load?.assetRequestAllocations.length === 0) {
        acc.push({ id: curr.id, name: `Asset Request # ${index + 1}` })
      }
      if (
        load?.assetRequestAllocations &&
        load?.assetRequestAllocations.length > 0 &&
        !load?.assetRequestAllocations?.some((c) => c.assetRequestId === curr.id)
      ) {
        acc.push({ id: curr.id, name: `Asset Request # ${index + 1}` })
      }
      return acc
    }, [] as IDropDownItem[])
    const items = [...notSelectedContainers, ...notSelectedAssetRequests]
    if (items.length === 0) {
      return <></>
    }
    let title = ''
    if (notSelectedContainers && notSelectedContainers.length > 0) {
      title = formatMessage(messages.assignContainerRequest)
    } else {
      title = formatMessage(messages.assignAssetRequest)
    }
    return (
      <Box sx={{ paddingTop: '16px' }}>
        <FormControl fullWidth>
          <InputLabel>{title}</InputLabel>
          <Select
            label='Assignments'
            onChange={(e: SelectChangeEvent<IDropDownItem>) => {
              handleChangeAssignmentDropDown(
                e,
                notSelectedContainers && notSelectedContainers.length > 0
              )
            }}
          >
            {items.map((item) => (
              <MenuItem key={item?.id ?? -1} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const RenderContainers = (
    containerIndex: number,
    removeContainer: (loadId: number, containerId: number) => void,
    load: ILoad,
    container: IContainerDetail | undefined,
    conIndex: number
  ): JSX.Element => {
    return (
      <Grid item key={containerIndex} xs={12} sx={{ paddingTop: '12px' }}>
        <Box
          display='flex'
          justifyContent='start'
          alignItems='center'
          sx={{
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '3px',
            margin: '2px',
          }}
        >
          {!disabled && (
            <DeleteIcon
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                removeContainer(load.id, container?.id ?? 0)
              }}
              sx={{ color: 'red', cursor: 'pointer' }}
            />
          )}
          Container #{conIndex + 1}: Container Number {container?.containerNumber}
        </Box>
      </Grid>
    )
  }

  return (
    <LoadAccordion
      draggable
      defaultExpanded={false}
      key={loadIndex}
      onDragOver={handleDragOver}
      onDragStart={(event: DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('string', `LOAD_${loadIndex}`)
      }}
      onDrop={(event: any) => {
        handleDrop(event, loadIndex)
      }}
      onChange={(event: any, expanded: boolean) => {
        setIsExpanded(expanded)
      }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowRightIcon />}
        sx={{ flexDirection: 'row-reverse', margin: '2px 0' }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography fontSize={'1.2rem'}>
              {formatMessage(messages.load)} # {load.displayOrder}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {(load.temperatureSetting && (load.temperatureSetting as ITemperatureSetting)?.isTemperatureControlled) ? (
              <Box display='flex' justifyContent='flex-end'>
                <Typography sx={{ color: 'red' }}>Temperature Controlled</Typography>
              </Box>
            ) : (
              ''
            )}
          </Grid>
          <Grid item>
            <Typography>
              {`${load.quantity ?? 0} ${load.isProduct
                ? products && products.find((p) => p.id === load.productId)?.name
                : load.description
                }`}
            </Typography>
          </Grid>
          {!isExpanded &&
            (load.assetRequestAllocations?.length ?? 0) > 0 &&
            load.assetRequestAllocations.map((assetRequestAllocation, loadAssetRequestIndex) => {
              const assetRequest = formik.values.assetRequests.find(
                (ar) => ar.id === assetRequestAllocation.assetRequestId
              )
              const arIndex = formik.values.assetRequests.findIndex(
                (cd) => cd.id === assetRequestAllocation.assetRequestId
              )

              return RenderAssetRequests(
                loadAssetRequestIndex,
                removeAssetRequest,
                load,
                assetRequestAllocation,
                arIndex,
                assetRequest
              )
            })}
          {!isExpanded &&
            (load.containerAllocations?.length ?? 0) > 0 &&
            load.containerAllocations.map((containerAllocation, containerIndex) => {
              const container = formik.values.containerDetails.find(
                (cd) => cd.id === containerAllocation.containerDetailId
              )
              const conIndex = formik.values.containerDetails.findIndex(
                (cd) => cd.id === containerAllocation.containerDetailId
              )

              return RenderContainers(containerIndex, removeContainer, load, container, conIndex)
            })}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid item xs={6}>
            Pickup Location
          </Grid>
          <Grid item xs={6}>
            {`${pickUpLocations.find((l) => l.id === load.transportDetail?.pickupLocationId)?.name
              }`}
          </Grid>
          <Grid item xs={6}>
            Pickup Date
          </Grid>
          <Grid item xs={6}>
            {pickupDate}
          </Grid>
          <Grid item xs={6}>
            Consignee
          </Grid>
          <Grid item xs={6}>
            {`${consigneeLocations.find((c) => c.id === load.transportDetail?.deliveryLocationId)
              ?.name
              }`}
          </Grid>
          <Grid item xs={6}>
            Delivery date
          </Grid>
          <Grid item xs={6}>
            {deliveryDate}
          </Grid>
        </Grid>
        {(load.palletDetails?.length ?? 0) > 0 &&
          load.palletDetails?.map((pallet, palletIndex) => {
            return (
              <Grid item key={palletIndex} xs={12} sx={{ paddingTop: '12px' }}>
                <Box display='flex' justifyContent='start' alignItems='center'>
                  Pallet Type #{palletIndex + 1}: {pallet.quantity} x{' '}
                  {palletTypesItems.find(
                    (soughtPackaging) => soughtPackaging.id === pallet.palletTypeId
                  )?.name ?? ''}
                </Box>
              </Grid>
            )
          })}
        {isExpanded &&
          (load.assetRequestAllocations?.length ?? 0) > 0 &&
          load.assetRequestAllocations.map((assetRequestAllocation, loadAssetRequestIndex) => {
            const assetRequest = formik.values.assetRequests.find(
              (ar) => ar.id === assetRequestAllocation.assetRequestId
            )
            const arIndex = formik.values.assetRequests.findIndex(
              (cd) => cd.id === assetRequestAllocation.assetRequestId
            )
            return RenderAssetRequests(
              loadAssetRequestIndex,
              removeAssetRequest,
              load,
              assetRequestAllocation,
              arIndex,
              assetRequest
            )
          })}
        {isExpanded &&
          (load.containerAllocations?.length ?? 0) > 0 &&
          load.containerAllocations.map((containerAllocation, containerIndex) => {
            const container = formik.values.containerDetails.find(
              (cd) => cd.id === containerAllocation.containerDetailId
            )
            const conIndex = formik.values.containerDetails.findIndex(
              (cd) => cd.id === containerAllocation.containerDetailId
            )

            return RenderContainers(containerIndex, removeContainer, load, container, conIndex)
          })}
        {!disabled && RenderAssignmentDropDown()}
      </AccordionDetails>
    </LoadAccordion>
  )
}

export default AssignmentLoad
